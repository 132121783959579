import React, { Component } from "react";

import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";
import "../styles/ItemCard.css";
import { MdRestaurantMenu } from "react-icons/md";
import { withTheme } from "../styles/theming";
import { BsCashCoin, BsFillCreditCardFill } from 'react-icons/bs';
import defaultIcon from "../styles/img/icon/oo_default_img.png";

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

class ItemCard extends Component {
  _onCardClick = () => {
    const { item, category, onCardClick } = this.props;
    onCardClick(item, category);
  };

  renderItemPrice = () => {
    const { item, theme, store, order } = this.props;
    if(store.onlineOrderProgram.creditCardProgram == "dualPrice") {
      if(order.kioskName || order.tableName) {
        return(
          <div>
            <div style={{display: "flex", alignItems: "center"}}>
              <BsCashCoin style={{marginRight: "1vw", fontSize: "1.3rem", color: theme.menuCardTitle}}/>
              <div className="price" 
                style={{
                  color: theme.menuCardPrice,
                  fontFamily: theme.fontBold
              }}>
                {item.price
                  ? `$${parseFloat(item.price).toFixed(2) || "n.a."}`
                  : ""
                }
              </div>
            </div>
            <div style={{display: "flex", alignItems: "center"}}>
              <BsFillCreditCardFill style={{marginRight: "1vw", fontSize: "1.3rem", color: theme.menuCardTitle}}/>
              <div className="price" 
                style={{
                  color: theme.menuCardPrice,
                  fontFamily: theme.fontBold
                }}>
                  {item.price
                    ? `$${parseFloat(item.price + item.price * store.onlineOrderProgram.ccFeePercentage).toFixed(2) || "n.a."}`
                    : ""
                  }
              </div>
            </div>
          </div>
        )
      } else {
        return(
          <div className="price" 
            style={{
              color: theme.menuCardPrice,
              fontFamily: theme.fontBold
          }}>
            {item.price
              ? `$${parseFloat(item.price + item.price * store.onlineOrderProgram.ccFeePercentage).toFixed(2) || "n.a."}`
              : ""}
          </div>
        )
      }
    } else {
      return(
        <div className="price" 
          style={{
            color: theme.menuCardPrice,
            fontFamily: theme.fontBold
        }}>
          {item.price
            ? `$${parseFloat(item.price).toFixed(2) || "n.a."}`
            : ""}
        </div>
      )
    }
  }

  render() {
    const { item, theme, store } = this.props;
    return (
      <div className="item-card" style={{backgroundColor: theme.menuCardBgd}}>
        <div className="item" onClick={this._onCardClick}>
          {store.kioskService ? (
            item.logoUrl ? (
              <div className="kioskImg"
              style={{backgroundImage: `url(${item.logoUrl})`}}>
              </div>
            ) : 
              <div className="defaultImg"
                style={{backgroundColor: theme.menuCardPicBgd}}>
                <MdRestaurantMenu style={{color:theme.menuCardPicIcon}}/>
              </div>
          ) : (
            item.logoUrl ? (
              <img className="img" src={item.logoUrl} alt="Item" />
            ) : 
              <div className="defaultImg"
                style={{backgroundColor: theme.menuCardPicBgd}}>
                <MdRestaurantMenu style={{color:theme.menuCardPicIcon}}/>
              </div>
          )}
          <div className="info">
            <div className='namePrice' >
                <div className="name" 
                  style={{
                    color: theme.menuCardTitle,
                    fontFamily: theme.fontBold
                  }}>
                    {item.displayName}
                </div>
                {this.renderItemPrice()}
            </div>
            <div className="dividing-line"
              style={{
                color: theme.menuCardPrice,
                fontFamily: theme.fontBold
              }}>
            </div>
            <div className="itemDescription">
              <ResponsiveEllipsis
                className="description"
                style={{
                  color: theme.menuCardDescription,
                  fontFamily: theme.fontRegular
                }}
                text={item.description}
                maxLine="2"
                ellipsis="..."
                trimRight
                baseon="letters"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTheme(ItemCard);
