import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import Payment from "payment";
import { Row, Col, Form } from "react-bootstrap";
import { confirmable } from "react-confirm";
import { StyleSheet, css } from "aphrodite";
import { withTheme } from "../styles/theming";
// import { getStateAbbr } from "../util/util";
import "../styles/PopupWindow.css";
import VisaIcon from "../styles/img/icon/visa-450.png";
import MasterCardIcon from "../styles/img/icon/master-card-450.png";
import DiscoverNetworkIcon from "../styles/img/icon/discover-network-450.png";
import AmericanExpressIcon from "../styles/img/icon/american-express-450.png";

class PaymentOneTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      selectedState: {},
      expirationErrorcode: null,
      zipCodeErrorcode: null,
      customerNameErrorCode: "",
      error: "",
      expiration: "",
      expiryMonth: "",
      expiryYear: "",
      agreePolicy: false,
    };
  }

  componentDidMount = () => {
    Payment.formatCardNumber(this.numberInput);
    Payment.formatCardExpiry(this.expInput);
    Payment.formatCardCVC(this.cvcInput);
    Payment.restrictNumeric(this.cardZipInput);
  };

  handleSubmit = e => {
    e.preventDefault();
    const { textNotification } = this.state;

    const accountNumber = this.numberInput.value.replace(/\s/g, "");
    const { expiryYear, expiryMonth } = this.state;
    const cvc = this.cvcInput.value;
    const holderName = this.nameInput.value;
    const cardType = this.ccTypeInput.value;
    const cardZip = this.cardZipInput.value;
    const remark = this.props.store.pickupInstruction == true ? this.remarkInput.value : null;
    const entryMode = "Keyed";
    const carrier = this.carrierInput ? this.carrierInput.value : null;

    if (this.props.isGuestCheckout) {
      if (this.props.serviceMode === "delivery") {
        const phone = this.phoneInput.value.replace(/[^\d]/g, '');
        const email = this.emailInput.value;
        const customerName = this.customerNameInput.value;
        const deliveryAddress = this.deliveryAddressInput.value;
        const deliveryZip = this.deliveryZipInput.value;
        const deliveryCity = this.deliveryCityInput.value;
        const deliveryState = this.deliveryStateInput.value;
        const card = {
          accountNumber,
          expiryMonth,
          expiryYear,
          cvc,
          deliveryZip,
          cardType,
          holderName,
          entryMode,
          cardZip,
          phone,
          email,
          customerName,
          deliveryAddress,
          deliveryCity,
          textNotification,
          carrier,
          deliveryState,
          remark
        };
        const { showLoading, proceed } = this.props;
        showLoading();
        proceed(card);
      } else if (
        this.props.serviceMode === "pickUp" ||
        this.props.serviceMode === "dineIn"
      ) {
        const phone = this.phoneInput.value.replace(/[^\d]/g, '');
        const email = this.emailInput.value;
        const customerName = this.customerNameInput.value;
        const card = {
          accountNumber,
          expiryMonth,
          expiryYear,
          cvc,
          cardZip,
          cardType,
          holderName,
          entryMode,
          phone,
          email,
          customerName,
          textNotification,
          carrier,
          remark
        };
        const { showLoading, proceed } = this.props;
        showLoading();
        proceed(card);
      }
    } else {
      const card = {
        accountNumber,
        expiryMonth,
        expiryYear,
        cvc,
        cardZip,
        cardType,
        holderName,
        entryMode,
        textNotification,
        carrier,
        remark
      };
      const { showLoading, proceed } = this.props;
      showLoading();
      proceed(card);
    }
  };

  renderError = () => {
    const { error } = this.state;
    return <div>{error ? "Error occured: " + error : ""}</div>;
  };

  renderCustomerContact = () => {
    const { theme } = this.props;
    const themeStyles = StyleSheet.create({
      formControl: {
        backgroundColor: theme.placeholderBgd,
        borderColor: theme.modifierModalDescription,
        color: theme.placeholderTitle,
        fontFamily: theme.fontRegular,
        height: 50,
        ":focus": {
          borderColor: theme.placeholderSelectBorder,
          borderWidth: 2,
        }
      },
      placeholderTitle: {
        color: theme.placeholderTitle,
        fontFamily: theme.fontRegular,
        alignItems: "center",
      },
      warning: {
        fontFamily: theme.fontRegular,
        alignItems: "center",
        marginLeft: "0.5rem",
      }
    });
    return (
      <>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Form.Group>
              <Form.Label className={"placeholder-title "+css(themeStyles.placeholderTitle)}>
                Customer Name
              </Form.Label>
              <Form.Label
                className={"payment-info-error placeholder-title "+css(themeStyles.warning)}>
                {this.state.customerNameErrorCode}
              </Form.Label>
              <Form.Control
                className={"input-control "+css(themeStyles.formControl)}
                type="text"
                ref={ref => {
                  this.customerNameInput = ref;
                }}
                placeholder="Customer Name"
                onChange={e => this.handleCustomerNameValidate(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Form.Group>
              <Form.Label className={"placeholder-title "+css(themeStyles.placeholderTitle)}>
                Email
              </Form.Label>
              <Form.Control
                className={"input-control "+css(themeStyles.formControl)}
                type="text"
                ref={ref => {
                  this.emailInput = ref;
                }}
                placeholder="Email"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Form.Group>
              <Form.Label className={"placeholder-title "+css(themeStyles.placeholderTitle)}>
                Phone
              </Form.Label>
              <Form.Control
                className={"input-control "+css(themeStyles.formControl)}
                type="text"
                ref={ref => {
                  this.phoneInput = ref;
                }}
                placeholder="Phone Number"
              />
            </Form.Group>
          </Col>
          {this.renderSmsButton()}
        </Row>
      </>
    );
  };

  handleTextNotification = () => {
    this.setState({
      textNotification: this.state.textNotification ? false : true
    });
  };
  handleSelectCarrier = e => {
    const { carriers } = this.props;
    const carrier = carriers[e];
    this.setState({ selectedCarrier: carrier });
  };

  //TODO: styling
  renderSmsButton = () => {
    const { theme } = this.props;
    const { textNotification } = this.state;
    const themeStyles = StyleSheet.create({
      placeholderTitle: {
        color: theme.placeholderTitle,
        fontFamily: theme.fontRegular,
        alignItems: "center",
      },
    });
    return (
      <>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Form.Group>
            <Form.Label className={"placeholder-title "+css(themeStyles.placeholderTitle)}>
              Receive SMS Notifications?
            </Form.Label>
            <Row className="pl-3 pt-1">
              <Button
                className="pr-3"
                variant="secondary"
                style={{
                  color: theme.btnText,
                  borderColor: "transparent",
                  backgroundColor: theme.btnBgd,
                  fontFamily: theme.fontMedium,
                }}
                checked={textNotification}
                onClick={e => this.handleTextNotification()}
                {...(textNotification ? { active: true } : { active: false })}
              >
                {textNotification ? "Cancel" : "Yes"}
              </Button>
              {textNotification && this.renderCarrierDropdown()}
            </Row>
          </Form.Group>
        </Col>
      </>
    );
  };

  //TODO: styling
  renderCarrierDropdown = () => {
    const { carriers, theme } = this.props;
    const themeStyles = StyleSheet.create({
      formControl: {
        backgroundColor: theme.placeholderBgd,
        borderColor: theme.modifierModalDescription,
        color: theme.placeholderTitle,
        fontFamily: theme.fontRegular,
        height: 50,
        ':focus': {
          borderColor: theme.placeholderSelectBorder,
          borderWidth: 2,
        }
      },
      dropdownItem: {
        color: theme.btnNavText,
        backgroundColor: theme.btnNavBgd,
        ":hover": {
          backgroundColor: theme.btnNavHoverBgd,
          fontWeight: theme.fontBold,
        }
      }
    });
    return (
      <Form.Control
        as="select"
        className={"input-control "+css(themeStyles.formControl)}
        placeholder="Carrier"
        ref={ref => {
          this.carrierInput = ref;
        }}>
        {carriers.map(carrier => {
          return (
            <option key={carrier.id} value={carrier.domain}
              className={css(themeStyles.dropdownItem)}>
              {carrier.name}
            </option>
          );
        })}
      </Form.Control>
    );
  };
  renderCustomerDeliveryInfo = () => {
    const { theme } = this.props;
    const themeStyles = StyleSheet.create({
      formControl: {
        backgroundColor: theme.placeholderBgd,
        borderColor: theme.modifierModalDescription,
        color: theme.placeholderTitle,
        fontFamily: theme.fontRegular,
        height: 50,
        ':focus': {
          borderColor: theme.placeholderSelectBorder,
          borderWidth: 2,
        }
      },
      placeholderTitle: {
        color: theme.placeholderTitle,
        fontFamily: theme.fontRegular,
        alignItems: "center",
      },
      dropdownItem: {
        color: theme.btnNavText,
        backgroundColor: theme.btnNavBgd,
        ":hover": {
          backgroundColor: theme.btnNavHoverBgd,
          fontWeight: theme.fontBold,
        }
      }
    });
    return (
      <>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Form.Group>
              <Form.Label className={"placeholder-title "+css(themeStyles.placeholderTitle)}>
                Delivery Address
              </Form.Label>
              <Form.Control
                className={"input-control "+css(themeStyles.formControl)}
                type="text"
                ref={ref => {
                  this.deliveryAddressInput = ref;
                }}
                placeholder="Address"
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Form.Group>
              <Form.Label className={"placeholder-title "+css(themeStyles.placeholderTitle)}>
                City
              </Form.Label>
              <Form.Control
                className={"input-control "+css(themeStyles.formControl)}
                type="text"
                ref={ref => {
                  this.deliveryCityInput = ref;
                }}
                placeholder="City"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Form.Group>
              <Form.Label className={"placeholder-title "+css(themeStyles.placeholderTitle)}>
                State
              </Form.Label>
              <Form.Control
                className={"input-control "+css(themeStyles.formControl)}
                as="select"
                placeholder="Type"
                ref={ref => {
                  this.deliveryStateInput = ref;
                }}>
                {this.props.states.map(state => {
                  var stateAbbr = state.displayName;
                  return (
                    <option key={state.id} value={stateAbbr}
                      className={css(themeStyles.dropdownItem)}>
                      {stateAbbr}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Form.Group>
              <Form.Label className={"placeholder-title "+css(themeStyles.placeholderTitle)}>
                Zip Code
              </Form.Label>
              <Form.Control
                className={"input-control "+css(themeStyles.formControl)}
                type="text"
                ref={ref => {
                  this.deliveryZipInput = ref;
                }}
                placeholder="Zip Code"
              />
            </Form.Group>
          </Col>
        </Row>
      </>
    );
  };
  renderCustomerCardForm = () => {
    const { serviceMode } = this.props;
    if (this.props.isGuestCheckout) {
      return (
        <>
          {this.renderCustomerContact()}
          {serviceMode === "delivery" && this.renderCustomerDeliveryInfo()}
        </>
      );
    } else {
      return (
        <>
          {this.renderSmsButton()}
        </>
      );
    }
  };

  handleExpirationValidate = (date) => {
    const expiration = date.split("/");
    const expiryMonth = expiration[0].trim();
    let expiryYear = 0;

    if (expiration.length > 1) {
      expiryYear = expiration[1].trim().slice(-2);
    }
    if ((expiryMonth < 1 || expiryMonth > 12) || expiryMonth.length < 2) {
      this.setState({
        expirationErrorcode: "Month Error",
      });
    }
    else if (expiration.length > 1 && (expiration[1].trim().substring(0, 1) == '0' || expiryYear.trim().length > 2 ||
      expiration[1].trim().length != 2)) {
      this.setState({
        expirationErrorcode: "Year Error",
      });
    }
    else if (expiration.length < 2) {
      this.setState({
        expirationErrorcode: "Date Error",
      });
    }
    else {
      this.setState({
        expirationErrorcode: "",
        expiryMonth: expiryMonth,
        expiryYear: expiryYear,
      });
    }
  }

  handleZipCodeValidate = (zipCode) => {
    if (zipCode.length != 5) {
      this.setState({
        zipCodeErrorcode: "Length Error",
      });
    }
    else {
      this.setState({
        zipCodeErrorcode: "",
      });
    }
  }

  handleCustomerNameValidate = (customerName) => {
    if (customerName.length > 50) {
      this.setState({
        customerNameErrorCode: "Length Error",
      });
    }
    else {
      this.setState({
        customerNameErrorCode: "",
      });
    }
  }

  handleAgreePolicyChanged = (agreePolicy) => {
    if (agreePolicy) {
      this.setState({
        agreePolicy: agreePolicy,
      });
    }
    else {
      this.setState({
        agreePolicy: agreePolicy,
      });
    }
  }

  renderPaymentCardForm = () => {
    const { theme } = this.props;
    const creditCardImage = {
      "Visa": VisaIcon,
      "MasterCard": MasterCardIcon,
      "AmericanExpress": AmericanExpressIcon,
      "Discover": DiscoverNetworkIcon,
    }
    const themeStyles = StyleSheet.create({
      formControl: {
        backgroundColor: theme.placeholderBgd,
        borderColor: theme.modifierModalDescription,
        color: theme.placeholderTitle,
        fontFamily: theme.fontRegular,
        height: 50,
        ":focus":{
          borderColor: theme.placeholderSelectBorder,
          borderWidth: 2,
        }
      },
      placeholderTitle: {
        color: theme.placeholderTitle,
        fontFamily: theme.fontRegular,
        alignItems: "center",
      },
      dropdownItem: {
        color: theme.btnNavText,
        backgroundColor: theme.btnNavBgd,
        ":hover": {
          backgroundColor: theme.btnNavHoverBgd,
          fontWeight: theme.fontBold,
        }
      },
      creditCardTypes: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gridGap: "0.5rem",
        marginTop: "0.25rem",
        width: "100%",
        height: "2rem",
      },
      warning: {
        fontFamily: theme.fontRegular,
        alignItems: "center",
        marginLeft: "0.5rem",
      }
    });
    return (
      <div>
        <Row>
          <Col xs={12} sm={12} md={5} lg={5}>
            <Form.Group>
              <Form.Label className={"placeholder-title "+css(themeStyles.placeholderTitle)}>
                Card Type
              </Form.Label>
              <Form.Control
                as="select"
                className={"input-control "+css(themeStyles.formControl)}
                placeholder="Type"
                ref={ref => {
                  this.ccTypeInput = ref;
                }}>
                {this.props.creditCardTypes.map(type => {
                  return (
                    <option key={type.id} value={type.name}
                      className={css(themeStyles.dropdownItem)}>
                      {type.displayName}
                    </option>
                  );
                })}
              </Form.Control>
              <div className={css(themeStyles.creditCardTypes)}>
                {this.props.creditCardTypes.map((type) => {
                  return (
                    <img 
                      style={{ height: "100%" }}
                      key={type.name}
                      src={creditCardImage[type.name]}
                      alt={type.name} />
                  )})}
              </div>
            </Form.Group>
          </Col>
          <Col xs={12} sm={12} md={7} lg={7}>
            <Form.Group>
              <Form.Label className={"placeholder-title "+css(themeStyles.placeholderTitle)}>
                Card Number
              </Form.Label>
              <Form.Control
                className={"input-control cc-number "+css(themeStyles.formControl)}
                type="text"
                ref={ref => {
                  this.numberInput = ref;
                }}
                placeholder="Card Number"
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={6} md={6} lg={6}>
            <Form.Group>
              <Form.Label className={"placeholder-title "+css(themeStyles.placeholderTitle)}>
                Expiration
              </Form.Label>
              <Form.Label
                className={"payment-info-error placeholder-title "+css(themeStyles.warning)}>
                {this.state.expirationErrorcode}
              </Form.Label>
              <Form.Control
                className={"input-control "+css(themeStyles.formControl)}
                type="text"
                ref={ref => {
                  this.expInput = ref;
                }}
                placeholder="MM/YY"
                maxlength="7"
                onChange={e => this.handleExpirationValidate(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <Form.Group>
              <Form.Label className={"placeholder-title "+css(themeStyles.placeholderTitle)}>
                CVC
              </Form.Label>
              <Form.Control
                className={"input-control "+css(themeStyles.formControl)}
                type="text"
                ref={ref => {
                  this.cvcInput = ref;
                }}
                placeholder="CVC"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Form.Group>
              <Form.Label className={"placeholder-title "+css(themeStyles.placeholderTitle)}>
                Holder Name
              </Form.Label>
              <Form.Control
                className={"input-control "+css(themeStyles.formControl)}
                type="text"
                ref={ref => {
                  this.nameInput = ref;
                }}
                placeholder="Holder Name"
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Form.Group>
              <Form.Label className={"placeholder-title "+css(themeStyles.placeholderTitle)}>
                Zip Code
              </Form.Label>
              <Form.Label
                className={"payment-info-error placeholder-title "+css(themeStyles.warning)}>
                {this.state.zipCodeErrorcode}
              </Form.Label>
              <Form.Control
                className={"input-control "+css(themeStyles.formControl)}
                type="text"
                ref={ref => {
                  this.cardZipInput = ref;
                }}
                placeholder="Zip Code"
                onChange={e => this.handleZipCodeValidate(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
      </div>
    );
  };

  renderRemarkCardForm = () => {
    const { theme } = this.props;
    const themeStyles = StyleSheet.create({
      formControl: {
        backgroundColor: theme.placeholderBgd,
        borderColor: theme.modifierModalDescription,
        color: theme.placeholderTitle,
        fontFamily: theme.fontRegular,
        height: 50,
        ":focus": {
          borderColor: theme.placeholderSelectBorder,
          borderWidth: 2,
        }
      },
      placeholderTitle: {
        color: theme.placeholderTitle,
        fontFamily: theme.fontRegular,
        alignItems: "center",
      },
    });
    return (
      <div>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Form.Group>
              <Form.Label className={"placeholder-title "+css(themeStyles.placeholderTitle)}>
                Delivery and Pickup Instructions
              </Form.Label>
              <Form.Control
                className={"input-control "+css(themeStyles.formControl)}
                type="text"
                maxLength="255"
                ref={ref => {
                  this.remarkInput = ref;
                }}
                placeholder="Instructions..."
              />
            </Form.Group>
          </Col>
        </Row>
      </div>
    );
  };

  renderPolicyCardForm = () => {
    const { theme } = this.props;
    const themeStyles = StyleSheet.create({
      agreement: {
        color: theme.placeholderTitle,
        fontFamily: theme.fontRegular,
      },
      agreementLink: {
        color: theme.centerCategoryTitle,
        fontFamily: theme.fontMedium,
        ":hover": {
          color: theme.modifierModalPrice,
        }
      }
    });
    return (
      <div>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Form.Group>
              <Form.Check
                type="checkbox"
                label={
                  <label className={"input-control "+css(themeStyles.agreement)}>I have read and agree to the
                    <a href="/terms-of-use" className={"input-control "+css(themeStyles.agreementLink)}> Terms & Conditions </a> and <a
                      href="/privacy-policy" className={"input-control "+css(themeStyles.agreementLink)}> Privacy Policies</a>
                  </label>
                }
                onChange={e => this.handleAgreePolicyChanged(e.target.checked)}
              />
            </Form.Group>
          </Col>
        </Row>
      </div>
    );
  };

  renderFileds = () => {
    return (
      <div className="window-margin">
        {this.renderCustomerCardForm()}
        {this.renderPaymentCardForm()}
        {this.renderRemarkCardForm()}
        {this.renderPolicyCardForm()}
        {this.renderError()}
      </div>
    );
  };

  render() {
    const { show, dismiss, theme } = this.props;
    const themeStyles = StyleSheet.create({
      title: {
        color: theme.modifierModalPrice,
        fontFamily: theme.fontMedium,
      },
      btn: {
        color: theme.btnText,
        borderColor: "transparent",
        backgroundColor: theme.btnBgd,
        fontFamily: theme.fontMedium,
      },
    });
    return (
      <Modal show={show} onHide={dismiss}>
        <div
          style={{
            backgroundColor: theme.modifierBgd,
            fontFamily: theme.fontRegular,
            borderRadius: "10px",
            borderColor: "transparent",
            padding: "1.5rem",
          }}>
          <Modal.Header className={css(themeStyles.title)}>
            <Modal.Title>Customer Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.renderCustomerCardForm()}</Modal.Body>
          <Modal.Header className={css(themeStyles.title)}>
            <Modal.Title>Payment Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.renderPaymentCardForm()}</Modal.Body>
          <Modal.Header className={css(themeStyles.title)}>
            <Modal.Title>Other</Modal.Title>
          </Modal.Header>
          {this.props.store.pickupInstruction !== undefined ? this.props.store.pickupInstruction == true ?
            <Modal.Body>{this.renderRemarkCardForm()}</Modal.Body> : null : null}
          <Modal.Body>{this.renderPolicyCardForm()}</Modal.Body>
          <Modal.Footer className="button-block-margin">
            <Button
              onClick={dismiss}
              className={"button-width "+css(themeStyles.btn)}>
              Cancel
            </Button>
            <Button
              onClick={this.handleSubmit}
              variant="primary"
              className={"button-width "+css(themeStyles.btn)}
              disabled={
                this.state.expirationErrorcode == null || this.state.expirationErrorcode != "" ||
                this.state.zipCodeErrorcode == null || this.state.zipCodeErrorcode != "" ||
                this.state.customerNameErrorCode != "" || !this.state.agreePolicy}
            >
              Pay
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
}

export default withTheme(confirmable(PaymentOneTime));
