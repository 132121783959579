export const SET_CARRIERS = "SET_CARRIERS";
export const SET_STORE = "SET_STORE";
export const CLEAR_STORE = "CLEAR_STORE";

export const SET_ACCEPTABLE_CC_TYPES = "SET_ACCEPTABLE_CC_TYPES";
export const SET_ACCEPTABLE_KIOSK_PAYMENT_TYPES = "SET_ACCEPTABLE_KIOSK_PAYMENT_TYPES";

export const SET_MENU = "SET_MENU";
export const CLEAR_MENU = "CLEAR_MENU";
export const SET_ACTIVE_ITEM = "SET_ACTIVE_ITEM";
export const CLEAR_ACTIVE_ITEM = "CLEAR_ACTIVE_ITEM";
export const SET_ACTIVE_CATEGORY = "SET_ACTIVE_CATEGORY";
export const CLEAR_ACTIVE_CATEGORY = "CLEAR_ACTIVE_CATEGORY";
export const SET_MODIFIER_OPTIONS = "SET_MODIFIER_OPTIONS";
export const CLEAR_MODIFIER_OPTIONS = "CLEAR_MODIFIER_OPTIONS";
export const TOGGLE_MODIFIER = "TOGGLE_MODIFIER";

export const SET_CUSTOMER = "SET_CUSTOMER";
export const SET_CUSTOMER_TOKEN = "SET_CUSTOMER_TOKEN";
export const SET_CUSTOMER_INFO = "SET_CUSTOMER_INFO";
export const SET_CONTACT_INFO = "SET_CONTACT_INFO";
export const SET_PHONE_INFO = "SET_PHONE_INFO";
export const SET_PAYMENT_TOKEN = "SET_PAYMENT_TOKEN";
export const SET_CUSTOMER_EMAIL = "SET_CUSTOMER_EMAIL";
export const CLEAR_CUSTOMER = "CLEAR_CUSTOMER";
export const SET_CUSTOMER_AUTH_INFO = "SET_CUSTOMER_AUTH_INFO";

export const SET_CART_ITEMS = "SET_CART_ITEMS";
export const CLEAR_CART_ITEMS = "CLEAR_CART_ITEMS";
export const SET_ORDER = "SET_ORDER";
export const SET_ORDER_STORE = "SET_ORDER_STORE";
export const SET_ORDER_TIP = "SET_ORDER_TIP";
export const SET_ORDER_TIPPERCENT = "SET_ORDER_TIPPERCENT";
export const SET_ORDER_TOTAL = "SET_ORDER_TOTAL";
export const CLEAR_ORDER = "CLEAR_ORDER";

export const SET_STATES = "SET_STATES";
export const SET_CREDIT_CARD_TYPES = "SET_CREDIT_CARD_TYPES";

export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

export const SET_INSTORE_ORDER = "SET_INSTORE_ORDER";

export const SET_THEME = "SET_THEME";
