import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getStoreAcceptableCCTypes,
  getStoreAcceptableKioskPaymentTypes,
  getValidDeliveryAddresses,
  createOrder,
  createOrderWithoutPayment,
  createPaymentBySavedCard,
  createPaymentByNewCard,
  showLoading,
  hideLoading,
  saveOrderHistory,
  clearOrder,
  getCarriers,
  getOrderById,
} from "../redux/actions";
import { AUTH_ERROR } from "../redux/actions/errors";
import {
  getStateAbbr,
  formatUserAddressForDeliveryValidation,
  formatTime,
  getWeekdayStr,
} from "../util/util";
import {
  confirmPaymentOneTime,
  existingPaymentConfirmation,
  paymentMethodConfirmation,
  swipeCardConfirmation,
  confirm,
} from "../util/confirm";
import Nav from "../components/Nav";
import CartItemCard from "../components/CartItemCard";
import OrderSummary from "../components/OrderSummary";
import TimeScheduler from "../components/TimeScheduler";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { css, StyleSheet } from "aphrodite";
import { withTheme } from "../styles/theming";
import moment from "moment";
import "../styles/Cart.css";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import "flatpickr/dist/themes/airbnb.css";
class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      orderSummaryOpen: false,
      deliveryEnabled: false,
      deliveryAllowed: false,
      toGoEnabled: false,
      dineInEnabled: false,
      pickUpEnabled: false,
      scheduleTime: moment(
        new Date().getTime() + (this.props.store.asapTime ? this.props.store.asapTime : 30) * 60000
      ).format("YYYY-MM-DD hh:mm A"),
      schedulerOption: "",
      scheduleDate: moment(new Date().getTime()).format("YYYY-MM-DD"),
      weekdayStr: getWeekdayStr(),
      openTimes: [],
      closeTimes: [],
      minTime: moment(new Date().getTime()).format("HH:mm A"),
      maxTime: moment(new Date().getTime()).format("HH:mm A"),
      asapTime: 0,
      asapScheduleTime: moment(new Date().getTime()).format("HH:mm A"),
      isDateTimeValid: true,
      isOpen: false,
      backToStoreSecs: 0,
    };
    this.resetOrderTimer = null;
    this.backToStoreTimer = this.backToStore.bind(this);
  }

  RESET_ORDER_TIME_INTERVAL = 120; //Unit: (Time) Second

  componentDidMount = async () => {
    const {
      customer,
      store,
      getStoreAcceptableCCTypes,
      getStoreAcceptableKioskPaymentTypes,
      getCarriers,
    } = this.props;
    this.resetOrderTimer = null;
    const { weekdayStr, scheduleDate } = this.state;

    var isGuestCheckout = customer.customer ? false : true;

    getStoreAcceptableCCTypes(store.id);
    if (this.props.store.kioskService) {
      getStoreAcceptableKioskPaymentTypes(store.id);
    }

    getCarriers();

    const isValidAddress = await getValidDeliveryAddresses(
      store.id,
      formatUserAddressForDeliveryValidation(customer)
    );

    if (store.acceptedServiceModes.includes("toGo")) {
      this.setState({ toGoEnabled: true });
    }
    if (store.acceptedServiceModes.includes("dineIn")) {
      this.setState({ dineInEnabled: true });
    }
    if (store.acceptedServiceModes.includes("pickUp")) {
      this.setState({ pickUpEnabled: true });
    }
    if (isGuestCheckout) {
      this.setState({
        deliveryEnabled: true,
        deliveryAllowed: store.acceptedServiceModes.includes("delivery"),
      });
    } else {
      this.setState({
        deliveryEnabled: isValidAddress,
        deliveryAllowed: store.acceptedServiceModes.includes("delivery"),
      });
    }

    let openTimes = [];
    let closeTimes = [];
    const asapTime = store.asapTime ? store.asapTime : 30;
    const asapScheduleTime = store.asapTime
      ? moment(new Date().getTime() + store.asapTime * 60000).format("hh:mm A")
      : moment(new Date().getTime() + 30 * 60000).format("hh:mm A");

    store.businessHours.forEach((item) => {
      openTimes[item.weekday] = formatTime(item.openTime);
      closeTimes[item.weekday] = formatTime(item.closeTime);
    });

    const isOpen = undefined !== openTimes[weekdayStr] && undefined !== closeTimes[weekdayStr];
    const maxTime = isOpen
      ? moment(
          moment(closeTimes[weekdayStr].trim().toString(), "HH:mm A") - asapTime * 60000
        ).format("HH:mm A")
      : undefined;
    const minTime = isOpen
      ? this.validateMinTime(
          openTimes[weekdayStr],
          closeTimes[weekdayStr],
          asapTime,
          weekdayStr,
          scheduleDate
        )
      : undefined;
    const scheduleTime = isOpen
      ? this.validateDefaultScheduleTime(
          openTimes[weekdayStr],
          closeTimes[weekdayStr],
          asapTime,
          weekdayStr,
          scheduleDate
        )
      : undefined;
    const schedulerOption = store.open ? "ASAP" : "Later";
    const isDateTimeValid = isOpen
      ? this.validateCurrentTime(scheduleDate, closeTimes[weekdayStr])
      : false;
    this.setState({
      weekdayStr: weekdayStr,
      openTimes: openTimes,
      closeTimes: closeTimes,
      minTime: minTime,
      maxTime: maxTime,
      scheduleTime: scheduleTime,
      schedulerOption: schedulerOption,
      asapTime: asapTime,
      asapScheduleTime: asapScheduleTime,
      isDateTimeValid: isDateTimeValid,
      isOpen: isOpen,
      acceptableCCTypes: this.props.acceptableCCTypes,
      acceptableKioskPaymentTypes: this.props.acceptableKioskPaymentTypes,
    });
  };

  shouldComponentUpdate(nextProps) {
    if (nextProps.order !== this.props.order && this.props.order.kioskName) {
      clearInterval(this.backToStoreTimer);
      this.backToStoreTimer = null;
      this.activateBackToStoreTimer();
    }
    return true;
  }

  componentWillUnmount = () => {
    if (this.props.order.kioskName) {
      clearInterval(this.backToStoreTimer);
      this.backToStoreTimer = null;
      window.location.reload(false);
    }
  };

  validateMinTime = (openTime, closeTime, StoreAsapTime, weekdayString, scheduledate) => {
    const weekdayStr = getWeekdayStr(new Date().getDay());
    const currentDate = moment(new Date().getTime()).format("YYYY-MM-DD");
    const minTime =
      moment(openTime.trim().toString(), "HH:mm A").isBefore(moment(new Date().getTime())) &&
      moment(closeTime.trim().toString(), "HH:mm A").isSameOrAfter(
        moment(new Date().getTime() + StoreAsapTime * 60000)
      ) &&
      weekdayString === weekdayStr &&
      scheduledate === currentDate
        ? moment(new Date().getTime() + StoreAsapTime * 60000).format("HH:mm A")
        : moment(moment(openTime.trim().toString(), "HH:mm A").valueOf()).format("HH:mm A");
    return minTime;
  };

  validateCurrentTime = (scheduleDate, closeTime) => {
    const currentDate = moment(new Date().getTime()).format("YYYY-MM-DD");
    const legalTime = moment(closeTime.trim().toString(), "hh:mm A").isSameOrAfter(
      moment(moment(new Date().getTime()) + this.state.asapTime * 60000)
    );
    const legalDateAndTime = scheduleDate === currentDate;

    if (!legalTime && legalDateAndTime) {
      return false;
    } else {
      return true;
    }
  };

  validateDefaultScheduleTime = (
    openTime,
    closeTime,
    StoreAsapTime,
    weekdayString,
    scheduledate
  ) => {
    const weekdayStr = getWeekdayStr(new Date().getDay());
    const currentDate = moment(new Date().getTime()).format("YYYY-MM-DD");
    const scheduleTime =
      moment(openTime.trim().toString(), "hh:mm A").isBefore(moment(new Date().getTime())) &&
      moment(closeTime.trim().toString(), "hh:mm A").isSameOrAfter(
        moment(moment(new Date().getTime()) + StoreAsapTime * 60000)
      ) &&
      weekdayString === weekdayStr &&
      scheduledate === currentDate
        ? moment(new Date().getTime() + StoreAsapTime * 60000).format("hh:mm A")
        : moment(moment(openTime.trim().toString(), "hh:mm A").valueOf()).format("hh:mm A");

    return scheduleTime;
  };

  delay = (ms) => new Promise((res) => setTimeout(res, ms));

  activateBackToStoreTimer = () => {
    this.setState({
      backToStoreSecs: 0,
    });
    this.backToStoreTimer = setInterval(this.backToStore, 1000);
  };

  backToStore = () => {
    if (this.state.backToStoreSecs >= this.RESET_ORDER_TIME_INTERVAL) {
      const { clearOrder, history } = this.props;
      clearInterval(this.backToStoreTimer);
      clearOrder().then(
        history.push(
          "/store/" +
            this.props.store.urlName +
            "?kiosk=" +
            (this.props.order.kioskName ? this.props.order.kioskName : "1")
        )
      );
    } else {
      this.setState({
        backToStoreSecs: this.state.backToStoreSecs + 1,
      });
    }
    console.log("System Idle Time: " + this.state.backToStoreSecs + " secs");
  };

  submitOrder = () => {
    const {
      history,
      order,
      createOrder,
      clearOrder,
      theme,
      createOrderWithoutPayment,
      showLoading,
      hideLoading,
      acceptableCCTypes,
      acceptableKioskPaymentTypes,
      getOrderById,
    } = this.props;
    const { schedulerOption, scheduleDate } = this.state;
    let scheduleTime = scheduleDate.concat(" ").concat(this.state.scheduleTime);
    var scheduleChoice = schedulerOption === "ASAP" ? schedulerOption : scheduleTime;

    if (order.kioskName) {  // 06/11/2024 Yoyo: Disabled table order cash payment
      clearInterval(this.backToStoreTimer);
      paymentMethodConfirmation({
        theme: theme,
        acceptableCCTypes: acceptableCCTypes,
        acceptableKioskPaymentTypes: acceptableKioskPaymentTypes,
        isKiosk: order.kioskName ? true : false,
        showLoading: showLoading,
      })
        .then(async (payment) => {
          const orderCustomer = {
            customerId: {
              type: "email",
              id: order.kioskName ? "KioskGuest" : "InStoreGuest",
            },
            name: order.kioskName ? `Kiosk Guest` : `InStore Guest`,
            contact: {},
          };
          const newOrder = {
            ...order,
            lineItemGroups: [{ ...order.lineItemGroups[0], customer: orderCustomer }],
            scheduleTime: scheduleChoice,
            cardType: payment.cardType ? payment.cardType : null,
          };

          if (payment.method === "Cash") {
            const orderRes = await createOrder(newOrder);
            if (orderRes && !orderRes.message) {
              const firebaseOrder = await createOrderWithoutPayment(orderRes);

              if (firebaseOrder && !firebaseOrder.error) {
                if (this.resetOrderTimer == null) {
                  if (order.kioskName) {
                    showLoading();
                    this.resetOrderTimer = setInterval(async () => {
                      const kioskOrder = await getOrderById(orderRes.id);
                      if (kioskOrder) {
                        console.log(
                          "Web Order ID #" + orderRes.id + " status: " + kioskOrder.status
                        );
                        if (kioskOrder.status === "received") {
                          clearOrder();
                          clearInterval(this.resetOrderTimer);
                          this.resetOrderTimer = null;
                          hideLoading();
                          confirm(
                            "Order has been sent!" + "\n\n Please check out at the counter.",
                            true,
                            { theme: theme }
                          ).then(() =>
                            history.push(
                              "/store/" +
                                this.props.store.urlName +
                                "?kiosk=" +
                                (order.kioskName ? order.kioskName : "1")
                            )
                          );
                        } else if (kioskOrder.status === "canceled") {
                          clearInterval(this.resetOrderTimer);
                          this.resetOrderTimer = null;
                          hideLoading();
                          confirm("Your order is canceled. Please contact support!", true, {
                            theme: theme,
                          });
                        }
                      }
                    }, 3000);
                  } else {
                    showLoading();
                    clearOrder();
                    hideLoading();
                    confirm(
                      "Order has been sent!" + "\n\n Please check out at the counter.",
                      true,
                      { theme: theme }
                    ).then(() =>
                      history.push(
                        "/store/" +
                          this.props.store.urlName +
                          "?table=" +
                          (order.tableName ? order.tableName : "1")
                      )
                    );
                  }
                }
              } else {
                confirm("Order error" + firebaseOrder.message + "Please try again later.", true, {
                  theme: theme,
                });
              }
            } else {
              confirm("Order error. Please try again later.", true, {
                theme: theme,
              });
            }
          } else {
            if (order.kioskName) {
              const orderRes = await createOrder(newOrder);

              if (orderRes && !orderRes.message) {
                const firebaseOrder = await createOrderWithoutPayment(orderRes);

                if (firebaseOrder && !firebaseOrder.error) {
                  swipeCardConfirmation({
                    theme: theme,
                    acceptableCCTypes: acceptableCCTypes,
                    showLoading: showLoading,
                  }).then(async () => {
                    if (this.resetOrderTimer == null) {
                      console.log("Start Payment");
                      this.resetOrderTimer = setInterval(async () => {
                        const kioskOrder = await getOrderById(orderRes.id);
                        if (kioskOrder) {
                          console.log("Order #" + orderRes.id + " status: " + kioskOrder.status);
                          if (kioskOrder.status === "received") {
                            clearOrder();
                            clearInterval(this.resetOrderTimer);
                            this.resetOrderTimer = null;
                            hideLoading();
                            confirm(
                              "Order has been paid by credit card! Please check your order ID on your receipt.",
                              true,
                              { theme: theme }
                            ).then(() =>
                              history.push(
                                "/store/" +
                                  this.props.store.urlName +
                                  "?kiosk=" +
                                  (order.kioskName ? order.kioskName : "1")
                              )
                            );
                          } else if (kioskOrder.status === "canceled") {
                            clearInterval(this.resetOrderTimer);
                            this.resetOrderTimer = null;
                            hideLoading();
                            confirm("Please check if your credit card is valid", true, {
                              theme: theme,
                            });
                          }
                        }
                      }, 3000);
                    }
                  });
                } else {
                  confirm("Order error" + firebaseOrder.message + "Please try again later.", true, {
                    theme: theme,
                  });
                }
              } else {
                confirm("Order error. Please try again later.", true, {
                  theme: theme,
                });
              }
            } else {
              this.renderOneTimePayment();
            }
          }
          // hideLoading();
        })
        .catch(() => {
          if (order.kioskName) {
            this.activateBackToStoreTimer();
          }
        });
    } else {
      this.renderCreditCardPayment(this.props, scheduleChoice);
    }
  };

  resetOrder = () => {
    const { theme, clearOrder, history, order } = this.props;
    confirm("Are you sure you want to remove all the items?", false, { theme: theme })
      .then(() => {
        clearOrder();
      })
      .catch(() => {});
  };

  renderCreditCardPayment = (props, scheduleChoice) => {
    const {
      history,
      customer,
      acceptableCCTypes,
      order,
      createOrder,
      createPaymentBySavedCard,
      showLoading,
      hideLoading,
      saveOrderHistory,
      clearOrder,
      theme,
    } = props;

    if (customer && customer.tokenData) {
      existingPaymentConfirmation({
        card: customer.tokenData,
        acceptableCreditCardTypes: acceptableCCTypes,
        orderTotal: order.total,
        showLoading: showLoading,
        theme: theme,
      }).then(
        async (cvv) => {
          const orderCustomer = {
            customerId: {
              type: "email",
              id: customer.customer ? customer.customer.email : null,
            },
            name: `${customer.customer.firstName} ${customer.customer.lastName}`,
            contact: customer.contact,
          };

          const newOrder = {
            ...order,
            lineItemGroups: [{ ...order.lineItemGroups[0], customer: orderCustomer }],
            scheduleTime: scheduleChoice,
            cardType: "CreditCard",
          };

          const orderRes = await createOrder(newOrder);
          if (orderRes && !orderRes.message) {
            const paymentRes = await createPaymentBySavedCard(orderRes, customer.tokenData, cvv);

            if (paymentRes && !paymentRes.error) {
              confirm("Order has been sent!", true, { theme: theme }).then(() =>
                history.push("/orderHistory")
              );
              saveOrderHistory(orderRes.id);
              clearOrder();
            } else {
              confirm("Payment error. Please try again later.", true, {
                theme: theme,
              });
            }
          } else if (orderRes && orderRes.message === AUTH_ERROR) {
            confirm("Your session has expired. Please login again.", true, {
              theme: theme,
            }).then(() => history.push("/login"));
          } else {
            confirm(orderRes.message, true, { theme: theme });
          }

          hideLoading();
        },
        () => {
          this.renderOneTimePayment();
        }
      );
    } else {
      this.renderOneTimePayment();
    }
  };

  renderOneTimePayment = () => {
    const {
      history,
      states,
      customer,
      acceptableCCTypes,
      order,
      createOrder,
      createPaymentByNewCard,
      showLoading,
      hideLoading,
      saveOrderHistory,
      clearOrder,
      carriers,
      theme,
      store,
    } = this.props;

    const { schedulerOption, scheduleDate } = this.state;

    let scheduleTime = scheduleDate.concat(" ").concat(this.state.scheduleTime);

    var scheduleChoice = schedulerOption === "ASAP" ? schedulerOption : scheduleTime;

    var isGuestCheckout = customer.customer ? false : true;

    confirmPaymentOneTime({
      states: states,
      creditCardTypes: acceptableCCTypes,
      showLoading: showLoading,
      isGuestCheckout: isGuestCheckout,
      serviceMode: order.serviceMode,
      carriers: carriers,
      theme: theme,
      store: store,
    }).then(
      async (card) => {
        const orderCustomer = {
          customerId: {
            type: "email",
            id: customer.customer ? customer.customer.email : "Guest",
          },
          name: `${customer.customer ? customer.customer.firstName : "Guest"} ${
            customer.customer ? customer.customer.lastName : "Guest"
          }`,
          contact: customer.contact,
        };
        const newOrder = {
          ...order,
          lineItemGroups: [{ ...order.lineItemGroups[0], customer: orderCustomer }],
          scheduleTime: scheduleChoice,
          cardType: "CreditCard",
        };

        const orderRes = await createOrder(newOrder);

        if (orderRes && !orderRes.message) {
          const checkDeliveryAddress = {
            contact: {
              address: {
                street1: card.deliveryAddress,
                zip1: card.deliveryZip,
                city: card.deliveryCity,
                state: card.deliveryState,
              },
            },
          };

          if (order.serviceMode === "delivery" && isGuestCheckout) {
            const isValidAddress = await getValidDeliveryAddresses(
              this.props.store.id,
              formatUserAddressForDeliveryValidation(checkDeliveryAddress)
            );
            if (!isValidAddress) {
              confirm("Your address is out of range.", true, { theme: theme });
              hideLoading();
              return;
            }
          }

          const paymentRes = await createPaymentByNewCard(orderRes, card);

          if (paymentRes && !paymentRes.error) {
            confirm("Order has been sent!", true, { theme: theme });
            if (customer.customer) {
              saveOrderHistory(orderRes.id);
            }
            clearOrder();
          } else {
            if (paymentRes.status >= 400 && paymentRes.status <= 500) {
              confirm(
                "Payment error. Please check your card information and try again later. ",
                true,
                { theme: theme }
              );
            } else if (paymentRes.status >= 500) {
              confirm("Payment error. Please connect to the store. ", true, {
                theme: theme,
              });
            } else {
              confirm("Payment error. Please try again later.", true, {
                theme: theme,
              });
            }
          }
        } else if (orderRes && orderRes.message === AUTH_ERROR) {
          confirm("Your session has expired. Please login again.", true, {
            theme: theme,
          }).then(() => history.push("/login"));
        } else {
          confirm(orderRes.message, true, { theme: theme });
        }

        hideLoading();
      },
      () => {
        hideLoading();
      }
    );
  };

  getAddress = (address) => {
    if (!address || !address.street1 || !address.city || !address.state || !address.zip1) {
      return undefined;
    }

    return (
      address.street1 +
      (address.street2 ? " " + address.street2 : "") +
      ", " +
      address.city +
      ", " +
      getStateAbbr(address.state) +
      " " +
      address.zip1
    );
  };

  renderItems = () => {
    const { store, theme, order } = this.props;
    const { lineItems } = this.props.order.lineItemGroups[0];

    if (lineItems.length < 1) {
      return (
        <div
          className="text-first"
          style={{
            color: theme.cartContent,
            fontFamily: theme.fontRegular,
          }}
        >
          Your cart is currently empty.{" "}
          <Link
            className="textAccent-strong"
            style={{
              color: theme.cartSubTitle,
              fontFamily: theme.fontMedium,
            }}
            to={
              store.kioskService
                ? `/store/${store.urlName}?kiosk=${order.kioskName ? order.kioskName : `1`}`
                : order.tableName
                ? `/store/${store.urlName}?table=${order.kioskName ? order.tableName : `1`}`
                : `/store/${store.urlName}`
            }
          >
            Continue shopping.
          </Link>
        </div>
      );
    } else {
      return lineItems.map((lineItem, i) => {
        return (
          <div key={lineItem.id}>
            <div id={"item" + i}>
              <CartItemCard lineItem={lineItem} index={i} />
            </div>
          </div>
        );
      });
    }
  };

  goToStore = () => {
    const { history, store, order } = this.props;
    if (store.kioskService) {
      history.push({
        pathname: `/store/${store.urlName}?kiosk=${order.kioskName ? order.kioskName : "1"}`,
      });
    } else if (order.tableName) {
      history.push({
        pathname: `/store/${store.urlName}?table=${order.tableName ? order.tableName : "1"}`,
      });
    } else {
      history.push({ pathname: `/store/${store.urlName}` });
    }
  };

  renderStoreInfo = () => {
    const { store, theme } = this.props;
    const themeStyles = StyleSheet.create({
      cartTitle: {
        color: theme.cartTitle,
        borderBottomColor: theme.cartDecorateLine,
        fontFamily: theme.fontBold,
      },
      cartContent: {
        color: theme.cartContent,
        fontFamily: theme.fontRegular,
      },
    });
    if (store.name) {
      return (
        <div className="col-12 col-md-6 my-3 cart-title-section">
          <h4 className={"cart-title " + css(themeStyles.cartTitle)}>Order From:</h4>
          <div className="cart-info">
            <Button
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
            >
              <div
                className="store-pic"
                onClick={this.goToStore}
                style={{
                  backgroundColor: "white",
                  backgroundImage: `url(${store.logoUrl})`,
                }}
              />
            </Button>
            <div className="store-info">
              <div className="store-name">
                <div
                  className={"textAccent-strong " + css(themeStyles.cartTitle)}
                  onClick={this.goToStore}
                >
                  Back to Store
                </div>
                <FaArrowAltCircleLeft
                  className="store-icon"
                  onClick={this.goToStore}
                  style={{ color: theme.cartSubTitle }}
                />
              </div>
              <div className={"text-secondary " + css(themeStyles.cartContent)}>
                {store.address.street1 ? store.address.street1 : "Address not set"},{" "}
                {store.address.city ? store.address.city : ""},{" "}
                {store.address.state ? getStateAbbr(store.address.state) : ""}{" "}
                {store.address.zip1 ? store.address.zip1 : ""}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="col-12 col-md-6 my-3 cart-title-section">
          <h4 className={"cart-title " + css(themeStyles.cartTitle)}>Order From:</h4>
        </div>
      );
    }
  };

  changeDeliveraddress = () => {
    this.props.history.push("/user");
  };

  renderCustomer = () => {
    const { customer, order, theme } = this.props;
    const { deliveryAllowed } = this.state;
    const themeStyles = StyleSheet.create({
      cartTitle: {
        color: theme.cartTitle,
        borderBottomColor: theme.cartDecorateLine,
        fontFamily: theme.fontBold,
      },
      cartSubTitle: {
        color: theme.cartSubTitle,
        fontFamily: theme.fontBold,
      },
      cartContent: {
        color: theme.cartContent,
        fontFamily: theme.fontRegular,
      },
    });
    if (deliveryAllowed && order.serviceMode === "delivery") {
      if (customer.customer) {
        return (
          <div className="col-12 col-md-6 my-3 cart-title-section">
            <h4 className={"cart-title " + css(themeStyles.cartTitle)}>Deliver To:</h4>
            <div className="cart-info">
              <div className="store-info">
                {this.state.deliveryEnabled ? (
                  ""
                ) : (
                  <p className={"text-danger " + css(themeStyles.cartContent)}>
                    Your address is out of our delivery range.
                  </p>
                )}
                <div className="store-info-title">
                  <p className={"textAccent-strong " + css(themeStyles.cartSubTitle)}>
                    {`${customer.customer.firstName} ${customer.customer.lastName}`}
                  </p>
                </div>
                <div className="store-info-sub">
                  <p className={"text-secondary " + css(themeStyles.cartContent)}>
                    {`${
                      customer.contact
                        ? this.getAddress(customer.contact.address)
                        : "No Address Set"
                    }`}
                  </p>
                  <Button
                    variant="secondary"
                    className="change-address-btn my-3"
                    style={{
                      color: theme.cartFunctionBtnTitle,
                      borderColor: theme.cartFunctionBtnTitle,
                      backgroundColor: theme.cartFunctionBtnBgd,
                      fontFamily: theme.fontRegular,
                    }}
                    onClick={this.changeDeliveraddress}
                  >
                    Change Deliver Address
                  </Button>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="col-12 col-md-6 my-3 cart-title-section">
            <h4 className={"cart-title " + css(themeStyles.cartTitle)}>Deliver To:</h4>
            <div className="cart-info">
              <div className={"text-first " + css(themeStyles.cartContent)}>
                Click here to{" "}
                <Link
                  className={"text-first login-text " + css(themeStyles.cartSubTitle)}
                  to="/login"
                >
                  Log In
                </Link>
              </div>
            </div>
          </div>
        );
      }
    }
  };

  handleScheduleTimeChange = (value) => {
    const { closeTimes, weekdayStr, scheduleDate } = this.state;
    const isDateTimeValid = this.validateCurrentTime(scheduleDate, closeTimes[weekdayStr]);
    const scheduleTime = moment(value[0]).format("hh:mm A");
    if (isDateTimeValid) {
      this.setState({
        scheduleTime: scheduleTime,
        isDateTimeValid: isDateTimeValid,
      });
    } else {
      this.setState({
        isDateTimeValid: isDateTimeValid,
      });
    }
  };

  handleScheduleDateChange = (value) => {
    const { openTimes, closeTimes, asapTime } = this.state;
    const scheduleDate = moment(value[0]).format("YYYY-MM-DD");
    const weekdayStr = getWeekdayStr(moment(value[0]).days());
    const isOpen = undefined !== openTimes[weekdayStr] && undefined !== closeTimes[weekdayStr];
    const minTime = isOpen
      ? this.validateMinTime(
          openTimes[weekdayStr],
          closeTimes[weekdayStr],
          asapTime,
          weekdayStr,
          scheduleDate
        )
      : undefined;
    const maxTime = isOpen
      ? moment(
          moment(closeTimes[weekdayStr].trim().toString(), "HH:mm A") - asapTime * 60000
        ).format("HH:mm A")
      : undefined;
    const scheduleTime = isOpen
      ? this.validateDefaultScheduleTime(
          openTimes[weekdayStr],
          closeTimes[weekdayStr],
          asapTime,
          weekdayStr,
          scheduleDate
        )
      : undefined;
    const isDateTimeValid = isOpen
      ? this.validateCurrentTime(scheduleDate, closeTimes[weekdayStr])
      : false;

    this.setState({
      scheduleDate: scheduleDate,
      scheduleTime: scheduleTime,
      minTime: minTime,
      maxTime: maxTime,
      weekdayStr: weekdayStr,
      isDateTimeValid: isDateTimeValid,
      isOpen: isOpen,
    });
  };

  handleCheckChange = (e) => {
    const { store } = this.props;
    const { openTimes, closeTimes, asapTime, weekdayStr } = this.state;
    const schedulerOption = e.target.value;
    const scheduleDate = moment(new Date().getTime()).format("YYYY-MM-DD");
    const scheduleTime = this.validateDefaultScheduleTime(
      openTimes[weekdayStr],
      closeTimes[weekdayStr],
      asapTime,
      weekdayStr,
      scheduleDate
    );
    const asapScheduleTime = store.asapTime
      ? moment(new Date().getTime() + store.asapTime * 60000).format("hh:mm A")
      : moment(new Date().getTime() + 30 * 60000).format("hh:mm A");

    this.setState({
      schedulerOption: schedulerOption,
      scheduleTime: scheduleTime,
      scheduleDate: scheduleDate,
      asapScheduleTime: asapScheduleTime,
    });
  };

  renderScheduler = () => {
    const { store, order, theme } = this.props;
    const { schedulerOption, closeTimes, weekdayStr, asapScheduleTime } = this.state;

    const themeStyles = StyleSheet.create({
      timePicker: {
        marginLeft: "2vw",
        ":hover": {
          borderColor: theme.cartDecorateLine,
          borderWidth: 2,
          borderStyle: "solid",
        },
      },
      formCheck: {
        display: "flex",
        alignItems: "center",
        marginRight: "0.5rem",
      },
    });
    return (
      <div className="col-12 col-md-6 my-3 cart-title-section">
        <h4
          className="cart-title"
          style={{
            color: theme.cartTitle,
            borderBottomColor: theme.cartDecorateLine,
            fontFamily: theme.fontBold,
          }}
        >
          {order.serviceMode.charAt(0).toUpperCase() + order.serviceMode.slice(1)} Time:
        </h4>
        <div className="cart-info">
          <div className="store-info">
            <div className="store-info-sub">
              <Form.Group
                className="d-flex justify-content-between scheduler textAccent-strong"
                style={{
                  color: theme.cartContent,
                  fontFamily: theme.fontRegular,
                  marginBottom: "0",
                }}
              >
                {store.open ? (
                  <Form.Check
                    type="radio"
                    className={css(themeStyles.formCheck)}
                    label={
                      "ASAP · ~" +
                      (store.asapTime ? store.asapTime : "30") +
                      " mins ( At about " +
                      asapScheduleTime +
                      " )"
                    }
                    onChange={this.handleCheckChange}
                    checked={schedulerOption === "ASAP"}
                    value="ASAP"
                    inline
                  />
                ) : (
                  <p
                    className="scheduler-warning"
                    style={{
                      color: theme.centerCategoryTitle,
                      fontFamily: theme.fontRegular,
                    }}
                  >
                    The store is closed now. You can only schedule for later.
                  </p>
                )}
                <Form.Check
                  type="radio"
                  className={"schedule-later " + css(themeStyles.formCheck)}
                  label="Schedule for later"
                  onChange={this.handleCheckChange}
                  checked={schedulerOption === "Later"}
                  value="Later"
                  inline
                />
              </Form.Group>
              {this.state.schedulerOption === "Later" ? (
                <TimeScheduler
                  className={"time-picker " + css(themeStyles.timePicker)}
                  minTime={this.state.minTime}
                  maxTime={this.state.maxTime}
                  scheduleTime={this.state.scheduleTime}
                  scheduleDate={this.state.scheduleDate}
                  closeTime={closeTimes[weekdayStr]}
                  isDateTimeValid={this.state.isDateTimeValid}
                  handleScheduleTimeChange={this.handleScheduleTimeChange}
                  handleScheduleDateChange={this.handleScheduleDateChange}
                  asapTime={store.asapTime ? store.asapTime : "30"}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { store, theme } = this.props;
    const transitionOption = {
      transitionName: "fade",
      transitionEnterTimeout: 1000,
      transitionLeaveTimeout: 0,
    };
    const themeStyles = StyleSheet.create({
      cleanCartBtn: {
        color: theme.btnText,
        borderColor: "transparent",
        backgroundColor: theme.btnBgd,
        fontFamily: theme.fontMedium,
      },
      ":hover": {
        borderColor: "transparent",
      },
    });

    return (
      <div style={{ backgroundColor: theme.pageBgd, minHeight: "100vh" }}>
        <Nav />
        <div className="cart-contents">
          <div className="cart-section d-flex flex-wrap col-12 col-sm-6 col-md-8 col-xl-9">
            {this.renderCustomer()}
            {this.renderStoreInfo()}
            {store.timeScheduler ? this.renderScheduler() : ""}
          </div>
          <div className="cart-section d-flex flex-wrap col-12 col-sm-6 col-md-8 col-xl-9">
            <div className="col-12 cart-list">
              <h4
                className="cart-list-title"
                style={{
                  color: theme.cartTitle,
                  borderBottomColor: theme.cartDecorateLine,
                  fontFamily: theme.fontBold,
                }}
              >
                Shopping Cart
              </h4>
              <div
                className="cart-caption-circle"
                style={{ backgroundColor: theme.cartTotalAmountBgd }}
              >
                <p
                  className="caption"
                  style={{
                    color: theme.cartTotalAmountText,
                    fontFamily: theme.fontMedium,
                  }}
                >
                  {this.props.order.lineItemGroups[0].lineItems.length}
                </p>
              </div>
              {store.kioskService && (
                <Button
                  className={"cart-reset-button " + css(themeStyles.cleanCartBtn)}
                  onClick={this.resetOrder}
                >
                  Remove All
                </Button>
              )}
            </div>
            <div className="cart-list-contents">
              <div className="cart-list-section cart-items">
                <ReactCSSTransitionGroup {...transitionOption}>
                  {this.renderItems()}
                </ReactCSSTransitionGroup>
              </div>
              <div className="cart-list-section">
                <OrderSummary
                  deliveryEnabled={this.state.deliveryEnabled}
                  deliveryAllowed={this.state.deliveryAllowed}
                  toGoEnabled={this.state.toGoEnabled}
                  dineInEnabled={this.state.dineInEnabled}
                  pickUpEnabled={this.state.pickUpEnabled}
                  submitOrder={this.submitOrder}
                  history={this.props.history}
                  schedulerOption={this.state.schedulerOption}
                  isOpen={this.state.isOpen}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const reduxStateToProps = (state) => {
  return {
    states: state.adminState.states,
    customer: state.customerState.customer,
    store: state.storeState.store,
    acceptableCCTypes: state.storeState.acceptableCCTypes,
    acceptableKioskPaymentTypes: state.storeState.acceptableKioskPaymentTypes,
    order: state.cartState.order,
    carriers: state.adminState.carriers,
  };
};

export default withTheme(
  connect(reduxStateToProps, {
    getStoreAcceptableCCTypes,
    getStoreAcceptableKioskPaymentTypes,
    getValidDeliveryAddresses,
    createOrder,
    createOrderWithoutPayment,
    createPaymentBySavedCard,
    createPaymentByNewCard,
    showLoading,
    hideLoading,
    saveOrderHistory,
    clearOrder,
    getCarriers,
    getOrderById,
  })(Cart)
);
