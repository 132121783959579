import React, {Component} from 'react';
import {connect} from 'react-redux';
import {logout} from '../redux/actions';
import {Link} from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import {ButtonToolbar, Dropdown, ButtonGroup} from 'react-bootstrap';
import navLogo from '../styles/img/logo.png';
import { StyleSheet, css } from "aphrodite";
import { withTheme } from "../styles/theming";
import { unstable_batchedUpdates } from 'react-dom';
import '../styles/Nav.css';
import { IoHome } from "react-icons/io5";
class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  displayName = () => {
    const {customer, logout, theme} = this.props;
    const themeStyles = StyleSheet.create({
      dropdownItem:{
        color: theme.btnNavText,
        ":hover": {
          backgroundColor: theme.btnNavHoverBgd,
        }
      },
      loginText: {
        color: theme.primary,
        fontFamily: theme.fontBold,
      }
    });
    if (customer && customer.customer && customer.customer.firstName) {
      return (
        <ButtonToolbar className="login">
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              variant="secondary"
              className="login-option"
              id="dropdown-no-caret"
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
                color: theme.primary,
                boxShadow: "unset",
                fontFamily: theme.fontBold,
              }}>
                {`Welcome back ${customer.customer.firstName}!`}
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                backgroundColor: theme.btnNavBgd,
                transform: "translateX(0)",
                overflowY: "unset",}}>
              <Dropdown.Item
                eventKey="1"
                className={css(themeStyles.dropdownItem)}>
                <Link to="/orderHistory" 
                  style={{color: theme.btnNavText, fontFamily: theme.fontRegular}}>
                  Order History
                </Link>
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="2"
                className={css(themeStyles.dropdownItem)}>
                <Link to="/user" 
                  style={{color: theme.btnNavText, fontFamily: theme.fontRegular}}>
                  Change User Setting
                </Link>
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="3"
                className={css(themeStyles.dropdownItem)}>
              <div onClick={logout}
                style={{color: theme.btnNavText, fontFamily: theme.fontRegular}}>
                Sign Off
              </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </ButtonToolbar>
      );
    } else if(this.props.store.kioskService) {
      return (
        <div className={"login text "+css(themeStyles.loginText)}>
          <div>Your Cart</div>
        </div>
      );
    } else {
      return (
        <div className={"login text "+css(themeStyles.loginText)}>
          <Link to="/login" className={"login-option "+css(themeStyles.loginText)}>
            Sign in</Link> | <Link to="/register" className={"login-option "+css(themeStyles.loginText)}> Register
          </Link>
        </div>
      );
    }
  };

  displayItemAmount = () => {
    const {theme} = this.props;
    return (
      <div className="caption-circle" style={{backgroundColor: theme.cartTotalAmountBgd}}>
        <p className="caption" 
          style={{
            alignItems: 'center', 
            color: theme.cartTotalAmountText,
            fontFamily: theme.fontRegular}}>
          {this.props.order.lineItemGroups[0].lineItems.length}
        </p>
      </div>
    );
  };

  render() {
    const {store, theme} = this.props;

    return (
      <div className="app-nav" style={{backgroundColor: theme.pageBgd}} >
        {!store.kioskService && (
          <a className='store-homepage' style={{color: theme.primary}} href={store.storeHomePageUrl} >
            <IoHome className="store-homepage-icon" />
              Home Page
          </a>
        )}
        
        {this.displayName()}
        <div className="cart-icon">
          <Link to="/cart">
            <FontAwesome name="shopping-cart" className="shopping-cart-icon" style={{color: theme.primary}}/>
            {this.displayItemAmount()}
          </Link>
        </div>
      </div>
    );
  }
}

const reduxStateToProps = state => {
  return {
    customer: state.customerState.customer,
    order: state.cartState.order,
    store: state.storeState.store,
  };
};

export default withTheme(connect(reduxStateToProps, {
  logout,
})(Nav));
