import React, { Component } from "react";
import { connect } from "react-redux";
import { validateOrder, setTip, setTipPercent, setTotal } from "../redux/actions";
import { Button, Form, Row, Col } from "react-bootstrap";
import { formatPrice } from "../util/util";
import { StyleSheet, css } from "aphrodite";
import { withTheme } from "../styles/theming";
import "../styles/OrderSummary.css";

class OrderSummary extends Component {
  constructor(props) {
    super(props);
    this.btn_1 = React.createRef();
    this.btn_2 = React.createRef();
    this.btn_3 = React.createRef();
    this.state = {
      width: window.innerWidth,
      height: window.innerHeight,
      tipPercent: 0,
      tipPercentages:{
        tip1: 0.15,
        tip2: 0.18,
        tip3: 0.20,
      },
      accounting: {
        tipPercent: 0,
        customize: false,
      },
      tip: null,
      nonCashFee: 0
    };
  }

  componentDidMount = () => {
    const { store, accounting, order, setTip } = this.props;
    if(!store.kioskService) {
      this.setFocus();
    }
    
    this.setState({
      tipPercentages: store.tipPercentages
        ? store.tipPercentages
        : this.state.tipPercentages,
      accounting: accounting ? accounting : "",
    });
    if(order.tip === 0){
      const total = order.total + (order.subtotal + order.tax) * store.onlineOrderProgram.ccFeePercentage
      this.props.setTotal(total)
      this.setState({
        tip: 0
      })
      const tipAndNonCashFee = {
        tip: 0,
        nonCashFeePercentage: this.props.store.onlineOrderProgram.ccFeePercentage,
        creditCardProgram: this.props.store.onlineOrderProgram.creditCardProgram,
        }
      setTip(tipAndNonCashFee);
    } else {
      const total = order.total + (order.subtotal + order.tax) * store.onlineOrderProgram.ccFeePercentage
      this.props.setTotal(total)
      this.setState({
        tip: order.tip
      })
      const tipAndNonCashFee = {
        tip: order.tip,
        nonCashFeePercentage: this.props.store.onlineOrderProgram.ccFeePercentage,
        creditCardProgram: this.props.store.onlineOrderProgram.creditCardProgram,
        }
      setTip(tipAndNonCashFee);
    }
    this.setState({
      nonCashFee: order.total * store.onlineOrderProgram.ccFeePercentage
    })
  }

  shouldComponentUpdate = async (nextProps, nextState) => {
    if(this.props.order !== nextProps.order){
      let tip = nextProps.order.subtotal * nextProps.accounting.tipPercent;
      let tipAndNonCashFee = {
        tip: nextProps.order.subtotal * nextProps.accounting.tipPercent,
        nonCashFeePercentage: this.props.store.onlineOrderProgram.ccFeePercentage,
        creditCardProgram: this.props.store.onlineOrderProgram.creditCardProgram,
      }
      if(nextProps.accounting.tipPercent === 0){
        if(this.state.tip >= 0){
          tipAndNonCashFee = {
          tip: this.state.tip,
          nonCashFeePercentage: this.props.store.onlineOrderProgram.ccFeePercentage,
          creditCardProgram: this.props.store.onlineOrderProgram.creditCardProgram,
          }
          await this.props.setTip(tipAndNonCashFee);
        } else {
            tipAndNonCashFee = {
              tip: 0,
              nonCashFeePercentage: this.props.store.onlineOrderProgram.ccFeePercentage,
              creditCardProgram: this.props.store.onlineOrderProgram.creditCardProgram,
              }
            await this.props.setTip(tipAndNonCashFee);
            }
      }
      else{
        await this.props.setTip(tipAndNonCashFee);
        if(!this.props.store.kioskService) {
          this.setFocus();
        }
      }
      return true;
    }
    else{
      return false;
    }
  }

  setFocus = () => {
    const { store, accounting } = this.props;
    if(store.tipPercentages){
      switch(accounting.tipPercent){
          case store.tipPercentages.tip1:
            this.btn_1.current.focus();
            break;
          case store.tipPercentages.tip2:
            this.btn_2.current.focus();
            break;
          case store.tipPercentages.tip3:
            this.btn_3.current.focus();
            break;
          default:
            break;
      }
    }
  }

  renderCheckOut = () => {
    const { store, order, submitOrder, theme, schedulerOption, isOpen } = this.props;
    const minOrderAmt = store.minOrderAmount || 0;
    const subtotal = order.subtotal || 0;
    const themeStyles = StyleSheet.create({
      payBtn: {
        color: theme.btnText,
        borderColor: "transparent",
        backgroundColor: theme.btnBgd,
        fontFamily: theme.fontMedium,
      },
      ":hover" :{
        borderColor: "transparent"
      }
    });
    return (
      <div className="mt-3">
        {order.lineItemGroups[0].lineItems.length > 0 ? (
           schedulerOption === "ASAP" || schedulerOption === "Later" ? (
              !minOrderAmt || minOrderAmt === 0 || subtotal >= minOrderAmt ? (
                <Button 
                  className={"pay-btn "+css(themeStyles.payBtn)}
                  block 
                  onClick={submitOrder}
                  disabled={!isOpen || (!store.open && !store.timeScheduler)}>
                  Pay
                </Button>
              ) : (
                <Button 
                  className={"pay-btn "+css(themeStyles.payBtn)}
                  block disabled>
                  Min Amount ${minOrderAmt ? minOrderAmt.toFixed(2) : "0.00"}
                </Button>
              )
           ) :(
            <Button 
              className={"pay-btn "+css(themeStyles.payBtn)}
              block disabled>
              Please Schedule Order
            </Button>
           )
          ) : (
            <Button 
              className={"pay-btn "+css(themeStyles.payBtn)}
              block disabled>
              No Item in Cart
            </Button>
          )}
      </div>
    );
  };

  onTipChange = e => {
    const tip = parseFloat(e.target.value);
    const accounting = {
      tipPercent: 0,
      customize: true,
    }
    if(tip >= 0) {
      const tipAndNonCashFee = {
      tip: tip,
      nonCashFeePercentage: this.props.store.onlineOrderProgram.ccFeePercentage,
      creditCardProgram: this.props.store.onlineOrderProgram.creditCardProgram,
        }
      this.props.setTip(tipAndNonCashFee);
      this.props.setTipPercent(accounting);
    } else {
      const tipAndNonCashFee = {
        tip: 0,
        nonCashFeePercentage: this.props.store.onlineOrderProgram.ccFeePercentage,
        creditCardProgram: this.props.store.onlineOrderProgram.creditCardProgram,
        }
      this.props.setTip(tipAndNonCashFee);
      this.props.setTipPercent(accounting);
    }
    this.setState({
      tip: tip
    })
  };

  onTipBlur = e => {
    const tip = e.target.value.toString();
    const accounting = {
      tipPercent: 0,
      customize: false,
    }
    if(tip == "") {
      const tipAndNonCashFee = {
        tip: 0,
        nonCashFeePercentage: this.props.store.onlineOrderProgram.ccFeePercentage,
        creditCardProgram: this.props.store.onlineOrderProgram.creditCardProgram,
        }
      this.props.setTip(tipAndNonCashFee);
      this.props.setTipPercent(accounting);
      this.setState({
        tip: 0
      })
    } else {
      const tipAndNonCashFee = {
        tip: tip,
        nonCashFeePercentage: this.props.store.onlineOrderProgram.ccFeePercentage,
        creditCardProgram: this.props.store.onlineOrderProgram.creditCardProgram,
          }
      this.props.setTip(tipAndNonCashFee);
      this.props.setTipPercent(accounting);
      this.setState({
        tip: tip
      })
    }
  }

  renderTipPercentage = tipPercent => e => {
    const { setTip, setTipPercent, order, store } = this.props;
    const subtotal = order.subtotal || 0;
    let nonCashFeePercentage = store.onlineOrderProgram.ccFeePercentage;
    let creditCardProgram = store.onlineOrderProgram.creditCardProgram;
    let tip = Math.round(subtotal * tipPercent * 100) / 100;
    const accounting = {
      tipPercent: tipPercent,
      customize: false,
    }
    const tipAndNonCashFee = {
      tip: tip,
      nonCashFeePercentage: nonCashFeePercentage,
      creditCardProgram: creditCardProgram,
    }
    setTip(tipAndNonCashFee);
    setTipPercent(accounting);
    this.setState({
      tip: tip
    })
  }

  formatNum = (num) => {
    let customize = this.props.accounting.customize;
    let value = Math.round(parseFloat(num) * 100) / 100;
    let arrayNum = value.toString().split(".");
    if (customize == true) {
      return value;
    } else {
      if (arrayNum.length == 1) {
      return value.toString() + ".00";
      }
      if (arrayNum.length > 1) {
        if (arrayNum[1].length < 2) {
          return value.toString() + "0";
        }
        return value;
      }
    }
  }

  renderTipOptions = () => {
    const { tip } = this.props.order;
    const { theme } =this.props;
    const { tipPercentages } = this.state;
    const roundedTip = this.state.tip !== null ? tip !== 0 ? this.formatNum(tip) : this.formatNum(this.state.tip) : "";
    const themeStyles = StyleSheet.create({
      tipBtn: {
        color: theme.btnTipText,
        fontFamily: theme.fontRegular,
        borderColor: theme.btnTipBorder,
        backgroundColor: theme.btnTipBgd,
        ":hover": {
          color: theme.btnTipHoverText,
          backgroundColor: theme.btnTipHoverBgd,
          borderColor: "transparent",
        },
        ":focus": {
          boxShadow: "unset",
          backgroundColor: theme.btnTipHoverBgd,
        }
      },
    });
    return (
      <div>
        <Form.Group as={Row}>
          <Form.Label column 
            style={{
              color: theme.orderSummaryContent,
              fontFamily: theme.fontRegular,}}>
            Tip:
          </Form.Label>
        </Form.Group>
        <Form.Group className="tip-section d-flex justify-content-between" >
          <Button
            ref = {this.btn_1}
            className={"tip-btn tip-option-1 "+css(themeStyles.tipBtn)}
            value={tipPercentages.tip1}
            onClick={this.renderTipPercentage(tipPercentages.tip1)}
            inline>
            {tipPercentages.tip1 * 100 + '%'}
          </Button>
          <Button
            ref = {this.btn_2}
            className={"tip-btn tip-option-2 "+css(themeStyles.tipBtn)}
            value={tipPercentages.tip2}
            onClick={this.renderTipPercentage(tipPercentages.tip2)}
            inline>
            {tipPercentages.tip2 * 100 + '%'}
          </Button>
          <Button
            ref = {this.btn_3}
            className={"tip-btn tip-option-3 "+css(themeStyles.tipBtn)}
            value={tipPercentages.tip3}
            onClick={this.renderTipPercentage(tipPercentages.tip3)}
            inline>
            {tipPercentages.tip3 * 100 + '%'}
          </Button>
          <Col className="tip-preview"
            style={{color: theme.orderSummaryContent,
            fontFamily: theme.fontRegular,}}>
            $
            <Form.Control
              type="number"
              disabled={this.state.height>=1500 ? true : false}
              className={"tip-preview-num "+css(
                StyleSheet.create({
                  tipPreview: {
                    color: theme.cardContent,
                    borderBottomColor: theme.cardContent, 
                    backgroundColor: 'transparent',
                    fontFamily: theme.fontRegular,
                  }
                }).tipPreview
              )}
              value={roundedTip}
              onChange={this.onTipChange}
              onBlur={this.onTipBlur}
              placeholder="Tip"
            />
            
          </Col>
        </Form.Group>
      </div>
    );
  };

  changeDeliverType = e => {
    const { store, order, validateOrder } = this.props;
    let updatedOrder = Object.assign({}, order);
    const company = { id: store.companyId };
    updatedOrder.serviceMode = e.target.value;
    updatedOrder.company = company;
    updatedOrder.store = store;

    validateOrder(updatedOrder);
  };

  renderDeliveryType = () => {
    const { 
      deliveryEnabled,
      deliveryAllowed,
      toGoEnabled,
      pickUpEnabled,
      dineInEnabled,
      order,
      theme,
      store
    } = this.props;
    const deliveryType = order.serviceMode || "delivery";

    if(store.kioskService && order.kioskName) {
      return (
        <Form.Group className="delivery-type">
          <div className="radio-option" 
            style={{
              color: theme.orderSummaryTitle,
              fontFamily: theme.fontBold}}>
            {toGoEnabled && (
              <Form.Check
                type="radio"
                label="To Go"
                onChange={this.changeDeliverType}
                checked={deliveryType === "toGo"}
                value="toGo"
                inline
              />
            )}
            {dineInEnabled && (
              <Form.Check
                type="radio"
                label="Dine in"
                onChange={this.changeDeliverType}
                checked={deliveryType === "dineIn"}
                value="dineIn"
                inline
              />
            )}
          </div>
        </Form.Group>
      );
    }
    else {
      return (
        <Form.Group className="delivery-type">
          <div className="radio-option" 
            style={{
              color: theme.orderSummaryTitle,
              fontFamily: theme.fontBold}}>
            <Form.Check
              type="radio"
              label="Pick up"
              onChange={this.changeDeliverType}
              checked={deliveryType === "pickUp"}
              value="pickUp"
              inline/>
            {toGoEnabled && (
              <Form.Check
                type="radio"
                label="To Go"
                onChange={this.changeDeliverType}
                checked={deliveryType === "toGo"}
                value="toGo"
                inline
              />
            )}
            {deliveryAllowed && (
              <Form.Check
                type="radio"
                label="Delivery"
                onChange={this.changeDeliverType}
                checked={deliveryEnabled && deliveryType === "delivery"}
                value="delivery"
                disabled={!deliveryEnabled}
                inline/>
            )}
          </div>
          <p className="text-danger"
            style={{
              color: theme.orderSummaryContent,
              fontFamily: theme.fontMedium}}>
            {!deliveryAllowed
              ? "This store does not offer delivery."
              : !deliveryEnabled
              ? "Your address is out of our delivery range."
              : ""}
          </p>
        </Form.Group>
      );
    }
  };

  renderConvenienceFee = order => {
    const { theme } = this.props;
    let convenienceFee = order.convenienceFee;
    if (convenienceFee > 0) {
      return(
        <div className="suboption-fee d-flex justify-content-between" 
          style={{
            color: theme.orderSummaryContent,
            fontFamily: theme.fontRegular}}
        >
          <span>Convenience Fee: </span>
          <span>${convenienceFee.toFixed(2)}</span>
        </div>
      )
    }
  };

  renderNonCashFee = () => {
    const { store, order, theme, nonCashFee } = this.props;
    const total = order.total || 0;
    const nonCashFeePercentage = store.onlineOrderProgram.ccFeePercentage || 0;
    const creditCardProgram = store.onlineOrderProgram.creditCardProgram;
    const subtotal = order.subtotal || 0;
    const ccSubtotal = order.ccSubTotal || 0;
    const tax = order.tax || 0;
    const ccTax = order.ccTax || 0;
    const themeStyles = StyleSheet.create({
      feeText: {
        color: theme.cardContent,
        fontFamily: theme.fontRegular,
        flex: 1,
      },
    });
    if(creditCardProgram === "nonCashFee"){
      return (
      <div className={"suboption-fee d-flex justify-content-between "
              +css(themeStyles.feeText)}>
            <span>Non-Cash Fee: </span>
            {(order.tableName || order.kioskName) && store.onlineOrderProgram.creditCardProgram == "nonCashFee"  ?
            <div>
              <span>$0.00</span>
              <span> / </span>
              <span>${this.state.nonCashFee !== 0 ? nonCashFee : ((ccSubtotal + ccTax) * nonCashFeePercentage).toFixed(2)}</span>
            </div>
            : <span>${this.state.nonCashFee !== 0 ? nonCashFee : ((subtotal + tax) * nonCashFeePercentage).toFixed(2)}</span>
          }
      </div>
      );
    }
    
  }

  renderTableInfo = () => {
    const { order, theme } = this.props;

    return (
      <div 
        className="table-info" 
        style={{ backgroundColor: "transparent",}}
      >
        <span 
          className="table-info-text"
          style={{
            color: theme.orderSummaryTitle,
            fontFamily: theme.fontBold,}}
        >
          Dine In
        </span>
        <div className="table-info-text">
          <span 
            style={{
              color: theme.orderSummaryTitle,
              fontFamily: theme.fontBold,}}>
            {order.tableName ? "Table " : order.tentName ? "Tent " : " "}
          </span>
          <span style={{
              color: theme.orderSummaryTitle,
              fontFamily: theme.fontBold,}}>
            {order.tableName
              ? order.tableName
              : order.tentName
              ? order.tentName
              : ""}
          </span>
        </div>
      </div>
    );
  };

  renderPriceTitle = () => {
    const { order, theme, store } = this.props;
    const creditCardProgram = store.onlineOrderProgram.creditCardProgram;
    const themeStyles = StyleSheet.create({
      feeText: {
        color: theme.cardContent,
        fontFamily: theme.fontRegular,
        flex: 1,
      },
    });

    if((creditCardProgram == "dualPrice" && (order.tableName || order.kioskName)) ||
      (order.tableName && creditCardProgram == "nonCashFee" )) {
      return (
        <div className={"d-flex " +css(themeStyles.feeText)} style={{justifyContent: "flex-end"}}>
          (Cash/CC)
        </div>
      )
    }
  }

  renderSubTotal = () => {
    const { order, theme, store } = this.props;
    const subtotal = order.subtotal || 0;
    const ccSubTotal = order.ccSubTotal || 0;
    const { creditCardProgram } = store.onlineOrderProgram;
    const themeStyles = StyleSheet.create({
      feeText: {
        color: theme.cardContent,
        fontFamily: theme.fontRegular,
        flex: 1,
      },
    });
    
    if(creditCardProgram == "dualPrice") {
      if(order.kioskName || order.tableName) {
        return (
          <div className={"suboption-fee d-flex justify-content-between " +css(themeStyles.feeText)}>
            <span>Subtotal:</span>
            <div>
              <span>${subtotal.toFixed(2)}</span>
              <span> / </span>
              <span>${ccSubTotal.toFixed(2)}</span>
            </div>
          </div>
        )
      } else {
        return (
          <div className={"suboption-fee d-flex justify-content-between " +css(themeStyles.feeText)}>
            <span>Subtotal:</span>
            <div>
              <span>${ccSubTotal.toFixed(2)}</span>
            </div>
          </div>
        )
      }
    } else if(creditCardProgram == "nonCashFee" && (order.tableName || order.kioskName)) {
      return (
        <div className={"suboption-fee d-flex justify-content-between " +css(themeStyles.feeText)}>
          <span>Subtotal:</span>
          <div>
            <span>${subtotal.toFixed(2)}</span>
            <span> / </span>
            <span>${subtotal.toFixed(2)}</span>
          </div>
        </div>
      )
    } else {
      return (
        <div className={"suboption-fee d-flex justify-content-between " +css(themeStyles.feeText)}>
          <span>Subtotal:</span>
          <span>${ccSubTotal.toFixed(2)}</span>
        </div>
      )
    }
  }

  renderEstimatedTax = () => {
    const { order, theme, store } = this.props;
    const tax = order.tax || 0;
    const ccTax = order.ccTax || 0;
    const { creditCardProgram } = store.onlineOrderProgram;
    const themeStyles = StyleSheet.create({
      feeText: {
        color: theme.cardContent,
        fontFamily: theme.fontRegular,
        flex: 1,
      },
    });

    if(creditCardProgram == "dualPrice") {
      if(order.tableName || order.kioskName) {
        return (
          <div className={"suboption-fee d-flex justify-content-between " +css(themeStyles.feeText)}>
            <span>Estimated Tax: </span>
            <div>
              <span>${tax.toFixed(2)}</span>
              <span> / </span>
              <span>${ccTax.toFixed(2)}</span>
            </div>
          </div>
        )
      } else {
        return (
          <div className={"suboption-fee d-flex justify-content-between " +css(themeStyles.feeText)}>
            <span>Estimated Tax: </span>
            <span>${ccTax.toFixed(2)}</span>
          </div>
        )
      }
    } else if(creditCardProgram == "nonCashFee" && (order.tableName || order.kioskName)) {
      return (
        <div className={"suboption-fee d-flex justify-content-between " +css(themeStyles.feeText)}>
          <span>Estimated Tax: </span>
          <div>
            <span>${tax.toFixed(2)}</span>
            <span> / </span>
            <span>${tax.toFixed(2)}</span>
          </div>
        </div>
      )
    } else {
      return (
        <div className={"suboption-fee d-flex justify-content-between " +css(themeStyles.feeText)}>
          <span>Estimated Tax: </span>
          <span>${ccTax.toFixed(2)}</span>
        </div>
      )
    }
  }

  renderDeliveryFee = () => {
    const { order, theme } = this.props;
    const deliveryFee = order.deliveryFee || 0;
    const themeStyles = StyleSheet.create({
      feeText: {
        color: theme.cardContent,
        fontFamily: theme.fontRegular,
        flex: 1,
      },
    });

    if(deliveryFee > 0) {
      return (
        <div className={"suboption-fee d-flex justify-content-between " +css(themeStyles.feeText)}>
          <span>Delivery fee: </span>
          <span>${deliveryFee.toFixed(2)}</span>
        </div>
      )
    }
  }

  renderTotal = () => {
    const { order, theme, store } = this.props;
    const total = order.total || 0;
    const ccTotal = order.ccTotal || 0;
    const { convenienceFee } = order;
    const { creditCardProgram, ccFeePercentage } = store.onlineOrderProgram;
    if(creditCardProgram == "dualPrice") {
      if(order.tableName || order.kioskName) {
        return (
          <div className="total-fee d-flex justify-content-between font-weight-bold"
            style={{
              color: theme.orderSummaryContent,
              fontFamily: theme.fontBold}}
          >
            <span>Total: </span>
            <div>
              <span>${formatPrice(total)}</span>
              <span> / </span>
              <span>${formatPrice(ccTotal - convenienceFee)}</span>
            </div>
          </div>
        )
      } else {
        return (
          <div className="total-fee d-flex justify-content-between font-weight-bold"
            style={{
              color: theme.orderSummaryContent,
              fontFamily: theme.fontBold}}
          >
            <span>Total: </span>
            <span>${formatPrice(ccTotal)}</span> 
          </div>
        )
      }
    } else if(creditCardProgram == "nonCashFee" && (order.tableName || order.kioskName)) {
      return (
        <div className="total-fee d-flex justify-content-between font-weight-bold"
          style={{
            color: theme.orderSummaryContent,
            fontFamily: theme.fontBold}}
        >
          <span>Total: </span>
          <div>
            <span>${formatPrice(total)}</span>
            <span> / </span>
            <span>${formatPrice(ccTotal)}</span>
          </div>
        </div>
      )
    } else {
      return (
        <div className="total-fee d-flex justify-content-between font-weight-bold"
          style={{
            color: theme.orderSummaryContent,
            fontFamily: theme.fontBold}}
        >
          <span>Total: </span>
          <span>${order.kioskName ? formatPrice(ccTotal - convenienceFee) : formatPrice(ccTotal)}</span> 
        </div>
      )
    }
  }
  render() {
    const { order, theme } = this.props;
    return (
      <Form className="order-summary " style={{backgroundColor: theme.cardBgd}}>
        {order.serviceMode === "dineIn" && !order.kioskName
          ? this.renderTableInfo()
          : this.renderDeliveryType()}
        <div className="fee" style={{display:'flex', flexDirection: 'column'}}>
          {this.renderPriceTitle()}
          {this.renderSubTotal()}
          {this.renderEstimatedTax()}
          {this.renderDeliveryFee()}
          {order.tableName || order.kioskName ? "" : this.renderConvenienceFee(order)}
          <br />
          {!order.kioskName && this.renderTipOptions()}
          {this.renderNonCashFee()}
          <br />
          {this.renderTotal()}
        </div>
        <div className="checkout-button-section" >
          {this.renderCheckOut()}
        </div>
      </Form>
    );
  }
}

const reduxStateToProps = state => {
  return {
    customer: state.customerState.customer,
    store: state.storeState.store,
    order: state.cartState.order,
    accounting: state.cartState.accounting,
    nonCashFee: state.cartState.nonCashFee
  };
};

export default withTheme(connect(reduxStateToProps, { validateOrder, setTip, setTipPercent, setTotal })(
  OrderSummary)
);
