import React, { Component } from "react";
import FontAwesome from "react-fontawesome";

class SubHeader extends Component {
  render() {
    const titleStyles = {
      textTransform: this.props.uppercase === true ? "uppercase" : "none"
    };
    const hrStyles = {
      width: "100%",
      backgroundColor: "black",
      padding: "0.6px",
      margin: "5px 0 20px"
    };
    const flexRowStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent:
        this.props.collapsable === true ? "space-between" : "flex-end"
    };
    const triggerStyle = {
      display: this.props.collapsable === true ? "block" : "none"
    };

    const showOpen = this.props.isOpen === true ? "minus" : "plus";

    return (
      <div>
        <div style={flexRowStyle}>
          <div style={triggerStyle}>
            {" "}
            <FontAwesome name={showOpen} />{" "}
          </div>
          {this.props.header === true ? (
            <h4 style={titleStyles}>{this.props.title}</h4>
          ) : (
            <div className="textAccent-strong" style={titleStyles}>
              {this.props.title}
            </div>
          )}
        </div>
        {/* <div className="hr" style={hrStyles}></div> */}
      </div>
    );
  }
}

export default SubHeader;
