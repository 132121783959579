import {
  SET_MENU,
  CLEAR_MENU,
  SET_ACTIVE_ITEM,
  CLEAR_ACTIVE_ITEM,
  SET_ACTIVE_CATEGORY,
  CLEAR_ACTIVE_CATEGORY,
  SET_MODIFIER_OPTIONS,
  CLEAR_MODIFIER_OPTIONS,
  TOGGLE_MODIFIER
} from "../actions/types";
import { createReducer } from "redux-starter-kit";

const DEFAULT_STATE = {
  menu: {},
  item: {},
  category: {},
  modifierCategories: []
};

export default createReducer(DEFAULT_STATE, {
  [SET_MENU]: (state, action) => {
    state.menu = action.payload;
  },
  [CLEAR_MENU]: state => {
    state.menu = {};
  },
  [SET_ACTIVE_ITEM]: (state, action) => {
    state.item = action.payload;
  },
  [CLEAR_ACTIVE_ITEM]: state => {
    state.item = {};
  },
  [SET_ACTIVE_CATEGORY]: (state, action) => {
    state.category = action.payload;
  },
  [CLEAR_ACTIVE_CATEGORY]: state => {
    state.category = {};
  },
  [SET_MODIFIER_OPTIONS]: (state, action) => {
    state.modifierCategories = action.payload;
  },
  [CLEAR_MODIFIER_OPTIONS]: state => {
    state.modifierCategories = [];
  },
  [TOGGLE_MODIFIER]: (state, action) => {
    const {
      firstLayerModifierCategoryId,
      firstLayerModifierId,
      secondLayerModifierCategoryId,
      secondLayerModifierId,
      thirdLayerModifierCategoryId,
      thirdLayerModifierId,
      fourthLayerModifierCategoryId,
      fourthLayerModifierId
    } = action.payload;

    const firstLayerModifierCategory = state.modifierCategories.find(
      mc => mc.id === firstLayerModifierCategoryId
    );

    let firstLayerModifier = firstLayerModifierCategory.firstLayerModifiers.find(
      m => m.modifier.id === firstLayerModifierId
    );

    if (secondLayerModifierCategoryId) {
      const secondLayerModifierCategory = firstLayerModifier.secondLayerModifierCategories.find(
        mc => mc.id === secondLayerModifierCategoryId
      );

      let secondLayerModifier = secondLayerModifierCategory.secondLayerModifiers.find(
        m => m.modifier.id === secondLayerModifierId
      );
      if (thirdLayerModifierCategoryId) {
        const thirdLayerModifierCategory = secondLayerModifier.thirdLayerModifierCategories.find(
          mc => mc.id === thirdLayerModifierCategoryId
        );

        let thirdLayerModifier = thirdLayerModifierCategory.thirdLayerModifiers.find(
          m => m.modifier.id === thirdLayerModifierId
        );
        if (fourthLayerModifierCategoryId) {
          const fourthLayerModifierCategory = thirdLayerModifier.fourthLayerModifierCategories.find(
            mc => mc.id === fourthLayerModifierCategoryId
          );
  
          let fourthLayerModifier = fourthLayerModifierCategory.fourthLayerModifiers.find(
            m => m.id === fourthLayerModifierId
          );
          fourthLayerModifier.checked = !fourthLayerModifier.checked;
      } else {
        if (thirdLayerModifier.modifier.checked) {
          const categories = thirdLayerModifier.fourthLayerModifierCategories;
          
          if (categories && categories.length > 0) {
            categories.forEach(c => {
              const modifiers = c.thirdLayerModifiers;
              if (modifiers && modifiers.length > 0) {
                modifiers.forEach(m => {
                  m.modifier.checked = false;
                });
              }
            });
          }
        }

        thirdLayerModifier.modifier.checked = !thirdLayerModifier.modifier.checked;
      }
      } else {
        if (secondLayerModifier.modifier.checked) {
          const categories = secondLayerModifier.thirdLayerModifierCategories;
          if (categories && categories.length > 0) {
            categories.forEach(c => {
              const modifiers = c.thirdLayerModifiers;
              if (modifiers && modifiers.length > 0) {
                modifiers.forEach(m => {
                  m.modifier.checked = false;
                });
              }
            });
          }
        }

        secondLayerModifier.modifier.checked = !secondLayerModifier.modifier.checked;
      }
    } else {
      // uncheck all second layer modifiers when unchecking first layer modifier
      if (firstLayerModifier.modifier.checked) {
        const categories = firstLayerModifier.secondLayerModifierCategories;
        if (categories && categories.length > 0) {
          categories.forEach(c => {
            const modifiers = c.secondLayerModifiers;
            if (modifiers && modifiers.length > 0) {
              modifiers.forEach(m => {
                m.checked = false;
              });
            }
          });
        }
      }

      firstLayerModifier.modifier.checked = !firstLayerModifier.modifier.checked;
    }
  }
});
