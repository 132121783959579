import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { getStateAbbr } from "../util/util";
import { StyleSheet, css } from "aphrodite";
import { withTheme } from "../styles/theming";
import "../styles/HistoryItemCard.css";

class HistoryItemCard extends Component {
  renderModifierOfModifiers = modifiers => {
    const { theme } = this.props;
    return modifiers && modifiers.length > 0
      ? modifiers.map((lineItemModifier, i) => {
          return (
            <div key={i} 
              className="d-flex justify-content-between"
              style={{
                fontFamily: theme.fontLight,
                color: theme.menuCardDescription,
              }}>
              <p className="ml-4">
                {`- ${lineItemModifier.modifier.name}`}
              </p>
              <p>
                ${lineItemModifier.effectivePrice.toFixed(2)}
              </p>
            </div>
          );
        })
      : null;
  };

  renderModifiers = modifiers => {
    const { theme } = this.props;
    const themeStyles = StyleSheet.create({
      modifierContent: {
        fontFamily: theme.fontMedium,
        color: theme.menuCardDescription,
      },
    });
    return modifiers && modifiers.length > 0
      ? modifiers.map((lineItemModifier, i) => {
          return (
            <div key={i}>
              <div className="d-flex justify-content-between">
                <p className={"ml-4 "+css(themeStyles.modifierContent)}>
                  {lineItemModifier.modifier.name}
                </p>
                <p className={css(themeStyles.modifierContent)}>
                  {!lineItemModifier.modifier.ignorePrice
                    ? `$${lineItemModifier.effectivePrice.toFixed(2)}`
                    : ""}
                </p>
              </div>
              {this.renderModifierOfModifiers(
                lineItemModifier.lineItemModifiers
              )}
            </div>
          );
        })
      : "";
  };

  renderItems = lineItems => {
    const { theme } = this.props;
    const themeStyles = StyleSheet.create({
      historyItem: {
        fontFamily: theme.fontMedium,
        color: theme.menuCardDescription,
      },
    });
    return lineItems && lineItems.length > 0
      ? lineItems.map((lineItem, i) => {
          return (
            <div key={i}>
              <hr style={{
                color: theme.menuCardPrice,
                fontFamily: theme.fontBold
              }}/>
              <div className="d-flex justify-content-between history-order-content">
                <div>
                  <span className={"mx-2 "+css(themeStyles.historyItem)}>
                    {lineItem.quantity.toFixed(0)}
                  </span>
                  <span className={"mr-2 "+css(themeStyles.historyItem)}>
                    {lineItem.item.name}
                  </span>
                </div>
                <p className={css(themeStyles.historyItem)}>
                  {lineItem.effectivePrice
                    ? `$${lineItem.effectivePrice.toFixed(2)}`
                    : ""}
                </p>
              </div>
              {this.renderModifiers(lineItem.modifiers)}
            </div>
          );
        })
      : "";
  };

  renderConvenienceFee = (fee) => {
    const { theme } = this.props;
    if(fee > 0){
      return (
        <div className="align-self-end"
          style={{
            fontFamily: theme.fontMedium,
            color: theme.menuCardTitle,
          }}>
          {`Convenience Fee: ${fee.toFixed(2) || "n.a."}`}
        </div>
      )
    }
  };

  gotoStore = () => {
    const { history, order } = this.props;

    history.push({ pathname: `/store/${order.store.urlName}` });
  };

  render() {
    const { order, theme } = this.props;
    const { store } = order;
    const { address } = store;
    const date = new Date(order.creationDate);
    const convenienceFee = order.convenienceFee;
    const themeStyles = StyleSheet.create({
      orderTitle: {
        fontFamily: theme.fontBold,
        color: theme.menuCardTitle,
      },
      storeInfo: {
        fontFamily: theme.fontRegular,
        color: theme.menuCardDescription,
      },
    });
    return (
      <div className={"order-card d-flex px-4 py-3 m-3 "+ css(
        StyleSheet.create({
          gotoStoreBtn: {
            backgroundColor: theme.menuCardBgd,
            ":hover": {
              borderColor: theme.btnBgd,
            }
          }
        }).gotoStoreBtn)}>
        {/* style={{backgroundColor: theme.menuCardBgd}}> */}
        <div className="d-none d-lg-flex flex-column mr-3">
          <img
            className="restaurant-img"
            src={store.logoUrl}
            alt="restaurant"
          />
          <Button onClick={this.gotoStore}
            className="m-2"
            style={{
              backgroundColor: theme.btnBgd,
              color: theme.btnText,
              fontFamily: theme.fontBold,
              borderColor: "transparent"}}>
            Go To Store
          </Button>
        </div>
        <div className="d-flex flex-column w-100">
          <div className="d-flex justify-content-between">
            <h5 className={"history-order-title " +css(themeStyles.orderTitle)} >
              {`#${order.id}`}
            </h5>
            <h5 className={"history-order-title "+css(themeStyles.orderTitle)} >
              {order.status.toUpperCase()}
            </h5>
          </div>
          <div className="d-flex justify-content-between">
            <p className={css(themeStyles.storeInfo)} >
              {store.name}
            </p>
            <p className={css(themeStyles.storeInfo)} >
              {`${date.getMonth() +
              1}/${date.getDate()}/${date.getFullYear()}`}
            </p>
          </div>
          <p className={css(themeStyles.storeInfo)} >
            {`${address.street1}, ${address.city}, ${getStateAbbr(
              address.state
            )} ${address.zip1}`}
          </p>
          {this.renderItems(order.lineItemGroups[0].lineItems)}
          <hr style={{
            color: theme.menuCardPrice,
            fontFamily: theme.fontBold
          }}/>
          <div className="align-self-end history-order-fee"
            style={{
              fontFamily: theme.fontMedium,
              color: theme.menuCardTitle
            }}>
            {`Tip: ${order.tip.toFixed(2) || "n.a."}`}
          </div>
          {this.renderConvenienceFee(convenienceFee)}
          <div className="align-self-end font-weight-bold history-order-fee"
            style={{
              fontFamily: theme.fontBold,
              color: theme.menuCardTitle
            }}>
            {`Total: ${order.total.toFixed(2) || "n.a."}`}
          </div>
          <Button
            onClick={this.gotoStore}
            className="col-12 col-md-6 d-lg-none align-self-center mt-2"
            style={{
              backgroundColor: theme.btnBgd,
              color: theme.btnText,
              fontFamily: theme.fontBold,
              borderColor: "transparent"}}>
            Go To Store
          </Button>
        </div>
      </div>
    );
  }
}

export default withTheme(HistoryItemCard);
