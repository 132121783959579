import React, { Component } from "react";
import Nav from "../components/Nav";
import { withTheme } from "../styles/theming";
import { css, StyleSheet } from "aphrodite";
import "../styles/legal-doc.css";

class PrivacyPolicy extends Component {
  render() {
    const { theme } = this.props;
    const themeStyles = StyleSheet.create({
      title: {
        color: theme.cartTitle,
        fontFamily: theme.fontBold,
      },
      subTitle: {
        color: theme.cartTitle,
        fontFamily: theme.fontRegular
      },
      paragraph: {
        color: theme.cartContent,
        fontFamily: theme.fontRegular
      }
    });
    return (
      <div style={{
        backgroundColor: theme.pageBgd, 
        color: theme.cartContent, 
        fontFamily:theme.fontRegular,
        minHeight: "100vh",}}>
        <Nav />
        <div className="doc-body">
          <h4 className={css(themeStyles.title)}>
            Privacy Policy
          </h4>
          <p className={"paragraph bold "+css(themeStyles.title)}>
            Last Updated: December 16th, 2020
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            At HotSauce, we are concerned about privacy issues and want you to
            be familiar with how we collect, use, and disclose Personal
            Information (as defined below). This Privacy Policy describes our
            practices in connection with privacy and also describes the Personal
            Information we collect through our websites, including
            hotsaucepos.com as well as any sites and mobile applications now or
            in the future that reference this Privacy Policy (collectively, the
            &quot;Sites&quot;). Except as indicated below, this Privacy Policy
            does not address the collection, use, or disclosure of information
            by us or by third parties on any websites or mobile applications
            other than the Sites. As used in this Privacy Policy,
            &quot;HotSauce,&quot; &quot;we,&quot; &quot;us,&quot; and
            &quot;our&quot; means HSAC, LLC and its subsidiaries and affiliates.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            By accessing and/or using the Sites, you agree to all the terms and
            conditions of this Privacy Policy and our Terms of Use, available
            at https://onlineordering.hotsaucepos.com/#/terms-of-use. If you do
            not agree to all the terms and conditions of this Privacy Policy, do
            not use the Sites.
          </p>
          <p className={"paragraph bold "+css(themeStyles.subTitle)}>
            1. What Types of Information Do We and Our Third Party Service
            Providers Collect from You and in What Circumstances Do We Collect
            It?
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            We, and our third party service providers who perform services on
            our behalf, collect information in a variety of circumstances when
            you visit and use our Sites.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            For example, we may collect information:
          </p>
          <p className={"paragraph bullet-point "+css(themeStyles.paragraph)}>
            when you register for our service, sign up for our mailing list,
            enter a contest or sweepstakes or communicate with us;
          </p>
          <p className={"paragraph bullet-point "+css(themeStyles.paragraph)}>
            when you use and interact with our Sites and service, such as when
            you place an order, post a review, or interact with other users or
            with restaurants on the Sites;
          </p>
          <p className={"paragraph bullet-point "+css(themeStyles.paragraph)}>
            when you make a payment for services;
          </p>
          <p className={"paragraph bullet-point "+css(themeStyles.paragraph)}>
            if you log in through a social network or authentication service,
            such as Facebook or Google, these services will authenticate your
            identity and may provide you the option to share Personal
            Information (such as your name, email address, address book and
            contacts, or other information) with us; and
          </p>
          <p className={"paragraph bullet-point "+css(themeStyles.paragraph)}>
            if you give permission for the Sites to access your address book or
            camera roll on your device, for example, to send an invitation to a
            friend or to upload a photo to the Sites.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            The information we collect may include Personal Information that can
            uniquely identify you (whether alone or in combination with other
            data or information), such as your name, postal address, telephone
            number, email address, date of birth, or similar data. Personal
            Information also includes certain sensitive information related to
            your finances or, for business accounts, related to the finances of
            your employer, such as a credit card number or other payment account
            number (including the three [3] or four [4] digit validation code
            for your credit card). In order for you to take advantage of the
            services provided through the Sites, we may require that you furnish
            Personal Information or, for certain business accounts, that your
            employer furnish Personal Information for you. We may also collect
            non-personal information from you, including information about your
            use of our Sites, but when such non-personal information is linked
            to your Personal Information, we will treat it as Personal
            Information. We may also receive information about you from third
            party sources, such as other users of our Sites, drivers, merchants,
            and other partners.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            Information collected automatically when you visit or use our Sites.
            We and our third party service providers may collect certain types
            of usage information when you visit our Sites, read our emails, or
            otherwise engage with us. We may use this information to enhance and
            personalize your user experience, to monitor and improve our
            websites and services, and for other similar purposes. In some
            instances, we and our third party service providers may combine this
            usage information with Personal Information. If we do combine any
            usage information with Personal Information, the combined
            information will be treated by us as Personal Information hereunder
            (as long as it is so combined).
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            We and our third party partners use tracking technologies, including
            cookies, web beacons, embedded scripts, location-identifying
            technologies, file information, and similar technology, to
            automatically collect usage and device information, such as:
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            Information about your device and its software, including your IP
            address, browser type, Internet service provider, device
            type/model/manufacturer, operating system, date and time stamp, and
            a unique ID that allows us to identify your browser, mobile device,
            or your account, and other similar information. We may also
            work with third party partners to employ technologies, including the
            application of statistical modeling tools, that permit us to
            recognize and contact you across multiple devices.
          </p>
          <p className={"paragraph bullet-point "+css(themeStyles.paragraph)}>
            Information about the way you access and use our Sites, for example,
            the site from which you came and the site to which you are going
            when you leave our Sites, the pages you visit, the links you click,
            whether you open emails or click the links contained in emails,
            whether you access the Sites from multiple devices, and other
            actions you take on the Sites.
          </p>
          <p className={"paragraph bullet-point "+css(themeStyles.paragraph)}>
            Analytics information. We may collect analytics data or use third
            party analytics tools such as Google Analytics to help us measure
            traffic and usage trends for the Sites and to understand more about
            the demographics of our users. You can learn more about Google&#39;s
            practices at http://www.google.com/policies/privacy/partners.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            If you would prefer not to accept cookies, most browsers will allow
            you to: (i) change your browser settings to notify you when you
            receive a cookie, which lets you choose whether or not to accept it;
            (ii) disable existing cookies; or (iii) set your browser to
            automatically reject cookies; however, doing so may negatively
            impact your experience using the Sites, as some features and
            services on our Sites may not work properly. You may also set your
            email options to prevent the automatic downloading of images that
            may contain technologies that would allow us to know whether you
            have accessed our email and performed certain functions with it. See
            Section 4 below to learn more about our online advertising
            practices.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            Location Information. With your consent, we may obtain information
            about your physical location through the information you provide us.
            We do not otherwise track your location information at this time.
          </p>
          <p className={"paragraph bold "+css(themeStyles.subTitle)}>
            2. How Do We and Our Third Party Service Providers Use Information
            Collected from You?
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            We and our third party service providers may use Personal
            Information:
          </p>
          <p className={"paragraph bullet-point "+css(themeStyles.paragraph)}>
            to process your registration on our Sites, including verifying your
            information to ensure its accuracy and validity.
          </p>
          <p className={"paragraph bullet-point "+css(themeStyles.paragraph)}>
            to fulfill your requests (e.g., to allow you to order food through
            our restaurant partners and have it delivered to you if they offer
            delivery services, or to provide you with information that you
            request).
          </p>
          <p className={"paragraph bullet-point "+css(themeStyles.paragraph)}>
            to fulfill your purchases (e.g., to process credit card payments in
            connection with your food order).
          </p>
          <p className={"paragraph bullet-point "+css(themeStyles.paragraph)}>
            to customize content on our Sites (e.g., the restaurants that will
            deliver to your location).
          </p>
          <p className={"paragraph bullet-point "+css(themeStyles.paragraph)}>
            to send you important information regarding the Sites, such as
            certain changes to our terms, conditions, policies, and/or other
            administrative information. Because this information may be material
            to your use of the Sites, you may not opt out of receiving such
            communications.
          </p>
          <p className={"paragraph bullet-point "+css(themeStyles.paragraph)}>
            to evaluate your eligibility for certain types of Promotions,
            offers, products, or services that may be of interest to you.
          </p>
          <p className={"paragraph bullet-point "+css(themeStyles.paragraph)}>
            to permit you to refer us to your friends so that we may send them a
            promotional offer through the Sites. If you wish to use this
            feature, you may be required to provide us with, and we may use,
            your friend&#39;s name, email address, and other information you
            share with us. By using this functionality, you affirm that you are
            entitled to use and provide us with your friend&#39;s name and email
            address for this purpose. We will not use your friend&#39;s Personal
            Information for any purpose other than to allow a promotional offer
            to be sent and/or to troubleshoot any issues with respect to such
            promotional offer, unless we disclose such other purpose to you at
            the time you provide your friend&#39;s Personal Information to us.
          </p>
          <p className={"paragraph bullet-point "+css(themeStyles.paragraph)}>
            to perform analytics and conduct customer research.
          </p>
          <p className={"paragraph bullet-point "+css(themeStyles.paragraph)}>
            for our internal business purposes, such as data analysis, audits,
            fraud prevention, developing new products and/or features, enhancing
            the Sites, improving our services, identifying usage trends, and
            determining the effectiveness of our promotional campaigns.
          </p>
          <p className={"paragraph bullet-point "+css(themeStyles.paragraph)}>
            to enforce our corporate reporting obligations and our Terms of Use,
            or to comply with applicable laws.
          </p>
          <p className={"paragraph bullet-point "+css(themeStyles.paragraph)}>
            for purposes disclosed at the time you provide your Personal
            Information or as otherwise set forth in this Privacy Policy.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            We and our third party service providers may also use aggregate
            Personal Information in a manner such that the end-product does not
            uniquely identify you or any other user of the Sites. For example,
            we may use Personal Information to calculate the percentage of our
            users who have a particular telephone area code.
          </p>
          <p className={"paragraph bold "+css(themeStyles.subTitle)}>
            3. How Do We and Our Third Party Service Providers Share Your
            Personal Information with Others?
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            We and our third party service providers may disclose Personal
            Information:
          </p>
          <p className={"paragraph bullet-point "+css(themeStyles.paragraph)}>
            to our affiliates, so that they also may use such Personal
            Information for the purposes described in this Privacy Policy.
            HotSauce is the party responsible for the management of the
            jointly-used Personal Information.
          </p>
          <p className={"paragraph bullet-point "+css(themeStyles.paragraph)}>
            to our third party service providers that provide services on our
            behalf, such as payment processing, website hosting, data analysis,
            infrastructure provisioning, IT services, customer service, email
            delivery services, targeted advertising and marketing, and other
            similar services.
          </p>
          <p className={"paragraph bullet-point "+css(themeStyles.paragraph)}>
            to third parties such as attorneys, collection agencies, tribunals
            or law enforcement authorities pursuant to valid requests in
            connection with alleged violations of our Terms of Use or other
            alleged contract violations, actual or alleged infringement, actual
            or suspected harm to persons or property, or alleged violations of
            laws, rules, or regulations.
          </p>
          <p className={"paragraph bullet-point "+css(themeStyles.paragraph)}>
            to third parties as needed to detect, prevent, or otherwise address
            actual or suspected fraud, harassment, security or technical issues,
            violations of any law, rule, regulation or the policies of the
            Sites, and to verify your purchase to holder(s) of any credit
            card(s) or other payment account(s) used to place order(s) using
            your account or your information.
          </p>
          <p className={"paragraph bullet-point "+css(themeStyles.paragraph)}>
            as required to fulfill your order (e.g., to our restaurant partners
            to enable them to fulfill your order).
          </p>
          <p className={"paragraph bullet-point "+css(themeStyles.paragraph)}>
            to restaurants and brands from which you have placed orders through
            the Sites as well as their affiliates and third party service
            providers. These restaurants, brands, and their affiliates, may use
            your information as permitted by their own privacy policies, which
            could include sending you marketing communications and other
            promotional content.
          </p>
          <p className={"paragraph bullet-point "+css(themeStyles.paragraph)}>
            to identify you to any person with whom you interact through the
            Sites.
          </p>
          <p className={"paragraph bullet-point "+css(themeStyles.paragraph)}>
            to third party partners in connection with integration, co-marketing
            and certain other coordinated efforts.
          </p>
          <p className={"paragraph bullet-point "+css(themeStyles.paragraph)}>
            to an affiliate or other third party in connection with a
            reorganization, merger, sale, joint venture, assignment, transfer,
            or other disposition of all or any portion of our business, assets,
            or stock (including without limitation in connection with any
            bankruptcy and/or similar proceedings).
          </p>
          <p className={"paragraph bullet-point "+css(themeStyles.paragraph)}>
            as we believe to be appropriate: (a) under applicable law, including
            laws outside your country of residence; (b) to comply with legal
            process; (c) to respond to requests from public and government
            authorities, including public and government authorities outside
            your country of residence; (d) to enforce our terms and conditions;
            (e) to protect our operations and/or those of any of our affiliates;
            (f) to protect our rights, privacy, safety, and/or property, and/or
            that of our affiliates, you, and/or others; (g) to permit us to
            pursue available remedies and/or limit the damages that we may
            sustain; and (h) for recordkeeping purposes.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            We may also share Personal Information which has been aggregated or
            de-identified in a manner such that the end-product does not
            uniquely identify you or any other user of the Sites.
          </p>
          <p className={"paragraph bold "+css(themeStyles.subTitle)}>
            4. Profiles and Interactive Areas.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            We and our third party service providers may make available through
            the Sites certain features and services (for example, ratings and
            reviews, photo uploads, and chat functionality) to which you may be
            able to post information and materials. Please note that any
            information you provide in connection with such services may become
            public, and may be available to visitors to the Sites and to the
            general public, including without limitation the ratings, reviews,
            and photographs that accompany many restaurant listings. We urge you
            to exercise discretion and caution when deciding to disclose your
            Personal Information, and/or any other information and/or materials,
            on the Sites.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            NEITHER WE, NOR ANY OF OUR THIRD PARTY SERVICE PROVIDERS, NOR ANY
            OTHER PERSON OR ENTITY DULY AUTHORIZED BY US AND/OR BY OUR THIRD
            PARTY SERVICE PROVIDERS, IS OR SHALL BE RESPONSIBLE FOR ANY USE BY
            ANY UNAUTHORIZED PERSON OR ENTITY OF ANY PERSONAL INFORMATION YOU
            DISCLOSE IN THE MANNER DESCRIBED IN THIS SECTION 5 THROUGH THE
            SITES.
          </p>
          <p className={"paragraph bold "+css(themeStyles.subTitle)}>
            5. Third Party Sites.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            Except as expressly set forth herein, this Privacy Policy does not
            address, and neither we nor our third party service providers is
            responsible for, the privacy, information, or other practices of
            other websites and/or any third parties, including without
            limitation any of our affiliates and/or any third party operating
            any site to which the Sites contain a link. For clarity, the
            inclusion of a link to a third party website on the Sites does not
            imply endorsement of the linked site by us or by our affiliates.
            Please note that your access and/or use of any third party websites,
            including your provision of any information, materials and/or other
            content to such sites, is entirely at your own risk. We encourage
            you to read the policies and terms of every website you visit.
          </p>
          <p className={"paragraph bold "+css(themeStyles.subTitle)}>
            6. Security.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            We and our third party service providers use reasonable
            organizational, technical, and administrative measures designed to
            protect Personal Information under our control. However, no data
            transmission over the Internet or data storage system can be
            guaranteed to be 100% secure. If you have reason to believe that
            your interaction with us is not secure (for example, if you feel
            that the security of any account you might have with us has been
            compromised), you must immediately notify us of the problem by
            contacting us in accordance with the &quot;Contacting Us&quot;
            section below.
          </p>
          <p className={"paragraph bold "+css(themeStyles.subTitle)}>
            7. Control Over Your Personal Information.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            You may review, correct, and/or update certain elements of your
            Personal Information by adjusting your preferences in the &quot;Your
            account&quot; section of the Sites. However, neither we nor our
            third party service providers are responsible for altering Personal
            Information from the databases and/or other records of third parties
            with whom we and our third party service providers have previously
            shared your Personal Information.
          </p>
          <p className={"paragraph bold "+css(themeStyles.subTitle)}>
            8. Data Retention.
            </p>
            <p className={"paragraph "+css(themeStyles.paragraph)}>
            We and our third party service providers will retain Personal
            Information for at least the period reasonably necessary to fulfill
            the purposes outlined in this Privacy Policy, unless a longer
            retention period is required or permitted by law. If we delete some
            or all of your Personal Information, we may continue to retain and
            use aggregate or anonymous data previously collected and/or
            aggregate or anonymize your Personal Information.
          </p>
          <p className={"paragraph bold "+css(themeStyles.subTitle)}>
            9. Note Regarding the Use of the Sites by Children.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            Under no circumstances are the Sites directed to and/or intended for
            use by individuals under the age of thirteen (13), and we do not
            knowingly collect Personal Information from children under age 13.
            If you believe that we might have any information from a child under
            13, please contact us at privacy@hotsaucepos.com so that we may
            delete it as soon as possible.
          </p>
          <p className={"paragraph bold "+css(themeStyles.subTitle)}>
            10. Disclaimer.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            If you choose to access the Sites, you do so at your own risk and
            are responsible for complying with all applicable laws, rules, and
            regulations. We may limit the availability of the Sites, in whole or
            in part, to any person, geographic area, and/or jurisdiction we
            choose, at any time and in our sole discretion. By using the Sites
            and submitting any Personal Information, you consent to the transfer
            of Personal Information to other countries, which may provide a
            different level of data security than your country of residence
            provides.
          </p>
          <p className={"paragraph bold "+css(themeStyles.subTitle)}>
            11. California Privacy Rights.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            If you are a user of the Sites and a California resident,
            California&#39;s &quot;Shine the Light&quot; law (California Civil
            Code §1798.83) permits you to request and obtain from us once a
            year, free of charge, information regarding our disclosure of your
            Personal Information (if any) to third parties for direct marketing
            purposes. If this law applies to you and you would like to make such
            a request, please submit your request in writing to the address
            provided below.
          </p>
          <p className={"paragraph bold "+css(themeStyles.subTitle)}>
            12. Changes to this Policy.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            We may change this Privacy Policy from time to time and without
            prior notice, except that if we make a material change, we will
            notify you prior to the effective date of the change. Changes to
            this Privacy Policy will be effective as soon as they are posted,
            unless a later effective date is provided. By continuing to access
            and/or use the Sites after the effective date of a revised Privacy
            Policy, you agree to be bound by the revised Privacy Policy. For
            clarity, if you do not agree to the new terms of the Privacy Policy,
            do not continue using the Sites. The most current version of this
            policy will always be posted on the Sites.
          </p>
          <p className={"paragraph bold "+css(themeStyles.subTitle)}>
            13. Contacting Us.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            If you have any questions regarding this Privacy Policy, please
            contact us by email at privacy@hotsaucepos.com, or write to the
            following address:
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            HSAC, LLC Attention: Privacy Team 7001 Peachtree Industrial Blvd
            Suite 4200 Norcross, GA 30092
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            Please note that email communications will not necessarily be
            secure; accordingly, please do not include credit card information
            and/or other sensitive Personal Information in your email
            correspondence with us.
          </p>
        </div>
      </div>
    );
  }
}

export default withTheme(PrivacyPolicy);
