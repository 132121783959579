import React, { Component } from "react";
import { connect } from "react-redux";
import { withTheme } from "../styles/theming";
import { formatPhoneNumber } from "../util/util";
import { css, StyleSheet } from "aphrodite";
class InactiveStore extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  
  render() {
    const { theme } = this.props;
    const { store } = this.props;
    return (
      <>
        <div style={{ ...styles.textContainer,
          color: theme.primary,
          fontFamily: theme.fontRegular, }}>
          Sorry, we aren't accepting orders at the moment. 
          Please call our restaurant number at {formatPhoneNumber(store.phoneNumber)}.
        </div>
      </>
    );
  }
}

const styles = {
  textContainer: {
    display: "flex",
    flex: 1,
    height: "50vh",
    justifyContent: "center",
    alignItems: "center",
    fontAlign: "center",
    fontSize: 32,
    fontWeight: 400
  }
};

const stateToProps = state => {};

const dispatchToProps = {};

export default withTheme(connect(stateToProps, dispatchToProps)(InactiveStore));
