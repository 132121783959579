import React, {Component} from 'react';
import {Modal, Button} from 'react-bootstrap';
import Payment from 'payment';
import {Row, Col, Form} from 'react-bootstrap';
import {confirmable} from 'react-confirm';
import { StyleSheet, css } from 'aphrodite';
import { withTheme } from "../styles/theming";
import '../styles/PopupWindow.css';
import {getStateAbbr} from '../util/util';

class PaymentSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      number: null,
      exp_month: null,
      exp_year: null,
      cvc: null,
      token: null,
      error: null,
      cardType: null,
      loaded: true,
      stateSelections: [],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetCard = this.resetCard.bind(this);
  }

  componentDidMount = () => {
    Payment.formatCardNumber(this.numberInput);
    Payment.formatCardExpiry(this.expInput);
    Payment.formatCardCVC(this.cvcInput);
    Payment.restrictNumeric(this.zipInput);
  };

  resetCard = () => {
    this.setState({
      number: null,
      exp_month: null,
      exp_year: null,
      cvc: null,
      token: null,
    });
  };

  handleSubmit = () => {
    this.resetCard();

    const accountNumber = this.numberInput.value.replace(/\s/g, '');
    const expiration = this.expInput.value.split('/');
    const expiryMonth = expiration[0].trim();
    const expiryYear = expiration[1].trim().substring(expiration[1].length - 3);
    const cvc = this.cvcInput.value;
    const zip = this.zipInput.value;
    const holderName = this.nameInput.value;
    const cardType = this.ccTypeInput.value;
    const entryMode = 'Keyed';
    const street = this.addressInput.value;
    const city = this.cityInput.value;
    const stateProvince = this.stateInput.value;
    var card = {
      accountNumber,
      expiryMonth,
      expiryYear,
      cvc,
      zip,
      cardType,
      holderName,
      entryMode,
      street,
      city,
      stateProvince,
    };

    const {showLoading, proceed} = this.props;
    showLoading();
    proceed(card);
  };

  renderError = () => {
    const {error} = this.state;
    return <div>{error ? 'Error occured: ' + error : ''}</div>;
  };

  renderCardForm = () => {
    const {theme} = this.props;
    const themeStyles = StyleSheet.create({
      formControl: {
        height: 50, 
        fontSize: 16,
        fontFamily: theme.fontRegular,
        backgroundColor: theme.placeholderBgd,
        borderColor: theme.modifierModalDescription,
        color: theme.placeholderTitle,
        ':focus':{
          borderColor: theme.placeholderSelectBorder,
          borderWidth: 2,
        }
      },
      formLabel:{
        color: theme.placeholderTitle,
        fontFamily: theme.fontRegular,
      },
      dropdownItem: {
        color: theme.btnNavText,
        backgroundColor: theme.btnNavBgd,
        fontFamily: theme.fontRegular,
        ":hover": {
          backgroundColor: theme.btnNavHoverBgd,
          fontWeight: "bold",
        }
      },
      btn: {
        color: theme.btnText,
        borderColor: "transparent",
        backgroundColor: theme.btnBgd,
        fontFamily: theme.fontMedium,
      },
    });
    return (
      <div>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Form.Group>
              <Form.Label className={"placeholder-title "+css(themeStyles.formLabel)}>
                Card Type
              </Form.Label>
              <Form.Control
                as="select"
                className={"input-control "+css(themeStyles.formControl)}
                placeholder="Type"
                ref={ref => {
                  this.ccTypeInput = ref;
                }}>
                {this.props.creditCardTypes.map(type => {
                  return (
                    <option key={type.id} value={type.name}
                      className={css(themeStyles.dropdownItem)}>
                      {type.displayName}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Form.Group>
              <Form.Label className={"placeholder-title "+css(themeStyles.formLabel)}>
                Card Number
              </Form.Label>
              <Form.Control
                className={"input-control "+css(themeStyles.formControl)}
                type="text"
                ref={ref => {
                  this.numberInput = ref;
                }}
                placeholder="Card Number"
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={6} md={6} lg={6}>
            <Form.Group>
              <Form.Label className={"placeholder-title "+css(themeStyles.formLabel)}>
                Expiration
              </Form.Label>
              <Form.Control
                className={"input-control "+css(themeStyles.formControl)}
                type="text"
                ref={ref => {
                  this.expInput = ref;
                }}
                placeholder="MM/YYYY"
              />
            </Form.Group>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <Form.Group>
              <Form.Label className={"placeholder-title "+css(themeStyles.formLabel)}>
                CVC
              </Form.Label>
              <Form.Control
                className={"input-control "+css(themeStyles.formControl)}
                type="text"
                ref={ref => {
                  this.cvcInput = ref;
                }}
                placeholder="CVC"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Form.Group>
              <Form.Label className={"placeholder-title "+css(themeStyles.formLabel)}>
                Holder Name
              </Form.Label>
              <Form.Control
                className={"input-control "+css(themeStyles.formControl)}
                type="text"
                ref={ref => {
                  this.nameInput = ref;
                }}
                placeholder="Holder Name"
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Form.Group>
              <Form.Label className={"placeholder-title "+css(themeStyles.formLabel)}>
                Zip Code
              </Form.Label>
              <Form.Control
                className={"input-control "+css(themeStyles.formControl)}
                type="text"
                ref={ref => {
                  this.zipInput = ref;
                }}
                placeholder="Zip Code"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Form.Group>
              <Form.Label className={"placeholder-title "+css(themeStyles.formLabel)}>
                Address
              </Form.Label>
              <Form.Control
                className={"input-control "+css(themeStyles.formControl)}
                type="text"
                ref={ref => {
                  this.addressInput = ref;
                }}
                placeholder="Street"
              />
            </Form.Group>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <Form.Group>
              <Form.Label className={"placeholder-title "+css(themeStyles.formLabel)}>
                City
              </Form.Label>
              <Form.Control
                className={"input-control "+css(themeStyles.formControl)}
                type="text"
                ref={ref => {
                  this.cityInput = ref;
                }}
                placeholder="City"
              />
            </Form.Group>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <Form.Group>
              <Form.Label className={"placeholder-title "+css(themeStyles.formLabel)}>
                State
              </Form.Label>
              <Form.Control
                as="select"
                className={"input-control "+css(themeStyles.formControl)}
                placeholder="Type"
                ref={ref => {
                  this.stateInput = ref;
                }}>
                {this.props.states.map(state => {
                  var stateAbbr = getStateAbbr(state.name);
                  return (
                    <option key={state.id} value={stateAbbr}
                    className={css(themeStyles.dropdownItem)}>
                      {stateAbbr}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
      </div>
    );
  };

  renderFields = () => {
    const { theme } = this.props;
    return (
      <div className="window-margin"
        tyle={{
          color: theme.modifierModalContent,
          fontFamily: theme.fontRegular,
        }}>
        {this.renderCardForm()}
        {this.renderError()}
      </div>
    );
  };

  render() {
    const {show, dismiss, theme} = this.props;
    const themeStyles = StyleSheet.create({
      btn: {
        color: theme.btnText,
        borderColor: "transparent",
        backgroundColor: theme.btnBgd,
        fontFamily: theme.fontMedium,
      },
    });
    return (
      <Modal show={show} onHide={dismiss}>
        <div className="modal-whole" 
          style={{
            backgroundColor: theme.modifierBgd,
            fontFamily: theme.fontBold,
            borderRadius: "10px",
            borderColor: "transparent",
          }}>
          <Modal.Header>
            <Modal.Title
              style={{
                color: theme.modifierModalPrice,
                fontFamily: theme.fontBold,
              }}>
              Payment Information
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.renderFields()}</Modal.Body>
          <Modal.Footer className="button-block-margin">
            <Button onClick={dismiss}
              className={"button-width " +css(themeStyles.btn)}>
              Cancel
            </Button>
            <Button onClick={this.handleSubmit}
              className={"button-width " +css(themeStyles.btn)}>
              Save
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
}

export default withTheme(confirmable(PaymentSetting));
