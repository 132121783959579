import React, { Component } from "react";
import {
  Modal,
  Button,
  FormControl,
  Row,
  Col,
  FormLabel,
  FormGroup,
  Form
} from "react-bootstrap";
import { confirmable } from "react-confirm";
import { StyleSheet, css } from 'aphrodite';
import { withTheme } from "../styles/theming";
import Payment from "payment";
import "../styles/ExistingPaymentConfirmation.css";

class ExistingPaymentConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cvc: "",
      agreePolicy: false,
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  componentDidMount = () => {
    Payment.formatCardCVC(this.state.cvc);
  };

  isValidCCType = () => {
    var isValid = false;
    this.props.acceptableCreditCardTypes.forEach(type => {
      if (type.name === this.props.card.cardType) {
        isValid = true;
      }
    });

    return isValid;
  };

  onCVCChange = e => {
    this.setState({ cvc: e.target.value });
  };

  handleAgreePolicyChanged = (agreePolicy) => {
    if(agreePolicy){
      this.setState({
        agreePolicy: agreePolicy,
      });
    }
    else{
      this.setState({
        agreePolicy: agreePolicy,
      });
    }
  }

  renderCardForm = () => {
    const { theme } = this.props;
    const themeStyles = StyleSheet.create({
      formControl:{
        backgroundColor: theme.placeholderBgd,
        borderColor: theme.modifierModalDescription,
        color: theme.placeholderTitle,
        fontFamily: theme.fontRegular,
        ':focus':{
          borderColor: theme.placeholderSelectBorder,
          borderWidth: 2,
        }
      },
      placeholderTitle: {
        color: theme.placeholderTitle,
        fontFamily: theme.fontRegular,
        alignItems: "center",
      },
      
    });
    // if(this.state.width>=600) {
    //   return (
    //     <div className="CardForm">
    //       <Row>
    //         <Col sm={3} className={css(themeStyles.placeholderTitle)}>
    //           <FormLabel className="placeholder-title">Card Type</FormLabel>
    //         </Col>
    //         <Col sm={6} className={css(themeStyles.placeholderTitle)}>
    //           <FormLabel className="placeholder-title">Card Number</FormLabel>
    //         </Col>
    //         <Col sm={3} className={css(themeStyles.placeholderTitle)}>
    //           <FormLabel className="placeholder-title">CVC</FormLabel>
    //         </Col>
    //       </Row>
    //       <Row>
    //         <Col sm={3} className={"cc-info "+css(themeStyles.placeholderTitle)}>
    //           <FormLabel className="input-control">{this.props.card.cardType}</FormLabel>
    //         </Col>
    //         <Col sm={6} className={"cc-info "+css(themeStyles.placeholderTitle)}>
    //           <FormLabel className="input-control">{this.props.card.last4Digit}</FormLabel>
    //         </Col>
    //         <Col sm={3}>
    //           <FormGroup>
    //             <FormControl
    //               type="text"
    //               value={this.state.cvc}
    //               placeholder="CVC"
    //               className={"input-control "+css(themeStyles.formControl)}
    //               onChange={this.onCVCChange}
    //               required
    //             />
    //           </FormGroup>
    //         </Col>
    //       </Row>
    //     </div>
    //   );
    // }
    // else if(this.state.width < 600) {
      return (
        <div className="CardForm">
          <Col style={{padding: 0}}>
            <Row className={css(themeStyles.placeholderTitle)}>
              <Col xs={4}>
                <FormLabel>
                  Card Type
                </FormLabel>
              </Col>
              <Col xs={8}>
                <FormLabel>
                {this.props.card.cardType}
                </FormLabel>
              </Col>
            </Row>
            <Row className={css(themeStyles.placeholderTitle)}>
              <Col xs={4}>
                <FormLabel>
                  Card Number
                </FormLabel>
              </Col>
              <Col xs={8}>
                <FormLabel>
                  {this.props.card.last4Digit}
                </FormLabel>
              </Col>
            </Row>
            <Row className={css(themeStyles.placeholderTitle)}>
              <Col xs={4}>
                <FormLabel>
                  CVC
                </FormLabel>
              </Col>
              <Col xs={8} >
                <FormGroup>
                  <FormControl
                    type="text"
                    value={this.state.cvc}
                    placeholder="CVC"
                    className={"input-control "+css(themeStyles.formControl)}
                    onChange={this.onCVCChange}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </div>
      );
    // }
  };

  renderPolicyCardForm = () => {
    const { theme } = this.props;
    const themeStyles = StyleSheet.create({
      agreement:{
        color: theme.placeholderTitle,
        fontFamily: theme.fontRegular,
      },
      agreementLink: {
        color: theme.centerCategoryTitle,
        fontFamily: theme.fontMedium,
        ":hover":{
          color: theme.modifierModalPrice,
        }
      }
    });
    return (
      <div>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Form.Group>
              <Form.Check
                type="checkbox"
                label={
                  <label className={"input-control "+css(themeStyles.agreement)}>I have read and agree to the 
                    <a href="/terms-of-use" className={"input-control "+css(themeStyles.agreementLink)}> Terms & Conditions </a> and <a 
                    href="/privacy-policy" className={"input-control "+css(themeStyles.agreementLink)}> Privacy Policies</a>
                  </label>
                }
                onChange={e => this.handleAgreePolicyChanged(e.target.checked)}      
              />
            </Form.Group>
          </Col>
        </Row>
      </div>
    );
  };

  renderFileds() {
    const { theme } = this.props;
    return (
      <div className="CreditCard margin-horizontal" style={{margin:0}}> 
        <FormLabel className="order-total"
          style={{
            color: theme.centerCategoryTitle,
            fontFamily: theme.fontBold,}}>
          Order total: $
          {this.props.orderTotal ? this.props.orderTotal.toFixed(2) : "0.00"}
        </FormLabel>
        {this.renderCardForm()}
        {this.renderPolicyCardForm()}
        {this.isValidCCType() ? (
          ""
        ) : (
          <FormLabel className="cc-type-warning input-control"
            style={{
              color: theme.content,
              fontFamily: theme.fontRegular}}>
            Your credit card type is not acceptable by this restaurant.
          </FormLabel>
        )}
      </div>
    );
  }

  handleSubmit = () => {
    const { showLoading, proceed } = this.props;

    if (this.state.cvc.length === 0) {
    } else {
      showLoading();
      proceed(this.state.cvc);
    }
  };

  render() {
    const {
      okLabbel = "Continue",
      cancelLabel = "Use Another Card",
      show,
      dismiss,
      cancel,
      theme,
    } = this.props;
    const themeStyles = StyleSheet.create({
      btn: {
        color: theme.btnHText,
        borderColor: "transparent",
        backgroundColor: theme.btnBgd,
        fontFamily: theme.fontMedium,
        padding:"0.5rem"
      },
    });
    return (
      <div className="static-modal">
        <Modal show={show} onHide={dismiss}>
          <div className="modal-whole" 
            style={{
              backgroundColor: theme.modifierBgd,
              fontFamily: theme.fontBold,
              borderRadius: "10px",
              borderColor: "transparent",
            }}>
            <Modal.Header
              style={{
                color: theme.modifierModalPrice,
                fontFamily: theme.fontBold,
              }}>
              <Modal.Title
                style={{color: theme.modifierModalPrice}}>
                Payment Information
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.renderFileds()}</Modal.Body>
            <Modal.Footer>
              <Button onClick={cancel}
                className={css(themeStyles.btn)}>
                {cancelLabel}
              </Button>
              <Button
                className={css(themeStyles.btn)}
                variant="primary"
                onClick={this.handleSubmit}
                disabled={!this.isValidCCType() || this.state.cvc == "" || !this.state.agreePolicy}>
                {okLabbel}
              </Button>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withTheme(confirmable(ExistingPaymentConfirmation));
