import React, { Component } from "react";
import { Modal, Button, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import { confirmable } from "react-confirm";
import { StyleSheet, css } from 'aphrodite';
import { withTheme } from "../styles/theming"
import "../styles/PopupWindow.css";

class PasswordSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: ""
    };
  }

  getPwdValidation = pwd => {
    var isValid = false;
    const rule = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;

    isValid = pwd.length !== 0 && pwd.toString().match(rule);

    return isValid;
  };

  getPwdConfirmValidation = () => {
    var isValid =
      this.state.confirmPassword &&
      this.state.confirmPassword === this.state.password;

    return isValid;
  };

  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { password, confirmPassword } = this.state;

    if (
      this.getPwdValidation(password) &&
      this.getPwdConfirmValidation(password, confirmPassword)
    ) {
      const { showLoading, proceed } = this.props;
      showLoading();
      proceed(password);
    }
  };

  render() {
    const { show, dismiss,theme } = this.props;
    const tooltip = (
      <Tooltip id="tooltip">
        Minimum 6 characters, 1 uppercase, 1 lowercase, 1 number.
      </Tooltip>
    );
    const themeStyles = StyleSheet.create({
      formControl:{
        height: 50, 
        fontSize: 16,
        backgroundColor: theme.placeholderBgd,
        borderColor: theme.modifierModalDescription,
        color: theme.placeholderTitle,
        fontFamily: theme.fontRegular,
        ':focus':{
          borderColor: theme.placeholderSelectBorder,
          borderWidth: 2,
        }
      },
      btn: {
        color: theme.btnText,
        borderColor: "transparent",
        backgroundColor: theme.btnBgd,
        fontFamily: theme.fontMedium,
      },
    });
    return (
      <div>
        <Modal show={show} onHide={dismiss}>
          <div className="modal-whole" 
            style={{
              backgroundColor: theme.modifierBgd,
              fontFamily: theme.fontBold,
              borderRadius: "10px",
              borderColor: "transparent",
            }}>
            <Modal.Header>
              <Modal.Title
                style={{
                  color: theme.modifierModalPrice,
                  fontFamily: theme.fontBold,
                }}>
                Change Password
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                color: theme.modifierModalContent,
                fontFamily: theme.fontRegular,
              }}>
              <Form className="window-margin">
                <Form.Group
                  controlId="password"
                  validationState={
                    this.getPwdValidation(this.state.password)
                      ? "success"
                      : "error"
                  }>
                  <Form.Label
                    className="placeholder-title"
                    style={{color: theme.placeholderTitle}}>
                    New Password
                  </Form.Label>
                  <OverlayTrigger placement="bottom" overlay={tooltip}>
                    <Form.Control
                      className={"input-control "+css(themeStyles.formControl)}
                      type="password"
                      placeholder=""
                      onChange={this.handleChange}
                    />
                  </OverlayTrigger>
                </Form.Group>
                <Form.Group
                  controlId="confirmPassword"
                  validationState={
                    this.getPwdConfirmValidation(
                      this.state.password,
                      this.state.confirmPassword
                    )
                      ? "success"
                      : "error"
                  }>
                  <Form.Label
                    className="placeholder-title"
                    style={{color: theme.placeholderTitle}}>
                    Confirm Password
                  </Form.Label>
                  <Form.Control
                    className={"input-control "+css(themeStyles.formControl)}
                    type="password"
                    placeholder=""
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer className="button-block-margin">
              <Button onClick={dismiss}
                className={"button-width " +css(themeStyles.btn)}>
                Cancel
              </Button>
              <Button onClick={this.handleSubmit}
                className={"button-width " +css(themeStyles.btn)}>
                Save
              </Button>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withTheme(confirmable(PasswordSetting));
