import React, { Component } from "react";
import { connect } from "react-redux";
import {
  saveCustomerInfo,
  saveContactInfo,
  savePhoneInfo,
  updateCustomerEmail,
  changePassword,
  savePaymentInfo,
  showLoading,
  hideLoading,
  resendVerificationEmail
} from "../redux/actions";
import { AUTH_ERROR } from "../redux/actions/errors";
import {
  confirmPayment,
  confirmPersonalInfo,
  confirmEmail,
  confirmPassword,
  confirmContactInfo,
  confirmPhoneInfo,
  confirm
} from "../util/confirm";
import { formatStateForDisplay, formatPhoneNumber } from "../util/util";
import Nav from "../components/Nav";
import { Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { StyleSheet, css } from 'aphrodite';
import { withTheme } from "../styles/theming";
import "../styles/User.css";

class User extends Component {
  changeContactInfo = () => {
    const { states, saveContactInfo, showLoading, hideLoading, theme } = this.props;
    confirmContactInfo({
      states: states,
      showLoading: showLoading,
      theme: theme,
    }).then(
      async newContact => {
        const success = await saveContactInfo(newContact);
        hideLoading();

        if (success) {
          confirm(
            "Your contact info has been updated!",
            true,
            { theme: theme }
          );
        } else {
          confirm(
            "Error! Please try again later.",
            true,
            { theme: theme }
          );
        }
      },
      () => { }
    );
  };

  changePhoneInfo = () => {
    const { states, savePhoneInfo, showLoading, hideLoading, theme } = this.props;
    confirmPhoneInfo({
      states: states,
      showLoading: showLoading,
      theme: theme,
    }).then(
      async newContact => {
        const success = await savePhoneInfo(newContact);
        hideLoading();

        if (success) {
          confirm(
            "Your phone info has been updated! Please refresh the page.",
            true,
            { theme: theme }
          );
        } else {
          confirm(
            "Error! Please try again later.",
            true,
            { theme: theme }
          );
        }
      },
      () => { }
    );
  };

  renderAddress = () => {
    const { contact } = this.props.customer;
    const { theme } = this.props;
    const themeStyles = StyleSheet.create({
      content: {
        color: theme.cartContent,
        fontFamily: theme.fontRegular
      },
      btn: {
        color: theme.btnText,
        borderColor: "transparent",
        backgroundColor: theme.btnBgd,
        fontFamily: theme.fontMedium,
      }
    });
    const street1 =
      contact && contact.address && contact.address.street1
        ? contact.address.street1
        : "Not set";
    const city =
      contact && contact.address && contact.address.city
        ? contact.address.city
        : "Not set";
    const state =
      contact && contact.address && contact.address.state
        ? formatStateForDisplay(contact.address.state)
        : "Not set";
    const zip =
      contact && contact.address && contact.address.zip1
        ? contact.address.zip1
        : "Not set";
    const phone =
      contact && contact.mainNumber && contact.mainNumber.number
        ? formatPhoneNumber(contact.mainNumber.number)
        : "Not set";

    return (
      <div
        className="address"
        style={{
          fontFamily: theme.fontRegular,
          color: theme.content
        }}>
        <h4
          style={{
            color: theme.cartTitle,
            fontFamily: theme.fontBold,
          }}>
          Contact Info
        </h4>
        <hr style={{ borderColor: theme.cartDecorateLine }} />
        <div className={"contact-detail " + css(themeStyles.content)}>
          Street: {street1}
        </div>
        <div className={"contact-detail " + css(themeStyles.content)}>
          City: {city}
        </div>
        <div className={"contact-detail " + css(themeStyles.content)}>
          State: {state}
        </div>
        <div className={"contact-detail " + css(themeStyles.content)}>
          Zip Code: {zip}
        </div>
        <div className={"contact-detail " + css(themeStyles.content)}>
          Phone Number: {phone}
        </div>
        <div
          className="contact-info-button-block"
          style={{ fontFamily: theme.fontBold }}>
          <div className="contact-info-button">
            <Button
              variant="secondary"
              onClick={this.changeContactInfo}
              className={css(themeStyles.btn)}>
              Edit
            </Button>
          </div>
          <div className="contact-info-button">
            <Button
              variant="secondary"
              onClick={this.changePhoneInfo}
              className={css(themeStyles.btn)}>
              Edit Phone Number
            </Button>
          </div>
        </div>
      </div>
    );
  };

  changePayment = () => {
    const {
      states,
      savePaymentInfo,
      creditCardTypes,
      showLoading,
      hideLoading,
      theme,
    } = this.props;

    confirmPayment({
      states: states,
      creditCardTypes: creditCardTypes,
      showLoading: showLoading,
      theme: theme,
    }).then(async newCard => {
      const res = await savePaymentInfo(newCard);
      hideLoading();

      if (res && !res.error) {
        confirm(
          "Your payment info has been updated!",
          true,
          { theme: theme }
        );
      } else if (res && res.message === AUTH_ERROR) {
        confirm(
          "Your session has expired. Please login again.",
          true,
          { theme: theme }
        ).then(() => this.props.history.push("/login"));
      } else {
        confirm(
          "Error! Please try again later.",
          true,
          { theme: theme }
        );
      }
    });
  };

  renderAddPayment = () => {
    //   const { isEmailVerified } = this.props.customer;
    return (
      <>
        {/* {!isEmailVerified && <p>Please verify email to add payment</p>}
        <Button
          variant="secondary"
          onClick={this.changePayment}
          disabled={!isEmailVerified}
        >
          Add Payment
        </Button> */}
      </>
    );
  };

  renderPayment = () => {
    const { tokenData } = this.props.customer;
    const { theme } = this.props;
    const themeStyles = StyleSheet.create({
      content: {
        color: theme.cartContent,
        fontFamily: theme.fontRegular
      },
      btn: {
        color: theme.btnText,
        borderColor: "transparent",
        backgroundColor: theme.btnBgd,
        fontFamily: theme.fontMedium,
      }
    });
    return (
      <div
        className="text payment-info"
        style={{
          fontFamily: theme.fontRegular,
          color: theme.content
        }}>
        <h4
          style={{
            color: theme.cartTitle,
            fontFamily: theme.fontBold,
          }}>
          Payment Info
        </h4>
        <hr style={{ borderColor: theme.cartDecorateLine }} />
        <div className={"payment-detail " + css(themeStyles.content)}>
          Name: {tokenData.holderName}
        </div>
        <div className={"payment-detail " + css(themeStyles.content)}>
          Card Number: {tokenData.last4Digit}
        </div>
        <div className={"payment-detail " + css(themeStyles.content)}>
          Card Type: {tokenData.cardType}
        </div>
        <Button
          variant="secondary"
          onClick={this.changePayment}
          className={css(themeStyles.btn)}>
          Edit
        </Button>
      </div>
    );
  };

  changePassword = () => {
    const { changePassword, showLoading, hideLoading, theme } = this.props;
    confirmPassword({
      showLoading: showLoading,
      theme: theme,
    }).then(
      async newPassword => {
        const success = await changePassword(newPassword);
        hideLoading();

        if (success) {
          confirm(
            "Your password has been updated!",
            true,
            { theme: theme });
        } else {
          confirm(
            "Error! Please try again later.",
            true,
            { theme: theme }
          );
        }
      },
      () => { }
    );
  };

  changeEmail = () => {
    const { updateCustomerEmail, showLoading, hideLoading, theme } = this.props;

    confirmEmail({
      showLoading: showLoading,
      theme: theme,
    }).then(async newEmail => {
      const success = await updateCustomerEmail(newEmail);
      hideLoading();

      if (success) {
        confirm(
          "Your email has been updated!",
          true,
          { theme: theme }
        );
      } else {
        confirm(
          "Error! Please try again later.",
          true,
          { theme: theme }
        );
      }
    });
  };

  resendEmail = () => {
    const { resendVerificationEmail, theme } = this.props;
    showLoading();
    const sendEmail = async () => {
      const success = await resendVerificationEmail();
      if (success) {
        confirm(
          "Your verification email has been sent.",
          true,
          { theme: theme }
        );
      } else {
        confirm(
          "An error has occured",
          true,
          { theme: theme }
        );
      }
    };
    return sendEmail();
  };

  changePersonalInfo = () => {
    const {
      customer,
      saveCustomerInfo,
      showLoading,
      hideLoading,
      theme
    } = this.props;

    confirmPersonalInfo({
      showLoading: showLoading,
      theme: theme,
    }).then(
      async newCustomer => {
        newCustomer.email = customer.customer.email;
        const success = await saveCustomerInfo(newCustomer);
        hideLoading();

        if (success) {
          confirm(
            "Your information has been saved!",
            true,
            { theme: theme }
          );
        } else {
          confirm(
            "Error! Please try again later.",
            true,
            { theme: theme }
          );
        }
      }
    );
  };

  renderUser = () => {
    const { customer, isEmailVerified } = this.props.customer;
    const { theme } = this.props;
    const themeStyles = StyleSheet.create({
      content: {
        color: theme.cartContent,
        fontFamily: theme.fontRegular
      },
      btn: {
        color: theme.btnText,
        borderColor: "transparent",
        backgroundColor: theme.btnBgd,
        fontFamily: theme.fontMedium,
      },
    });
    return (
      <div className="personal"
        style={{
          fontFamily: theme.fontRegular,
          color: theme.content
        }}>
        <h4
          style={{
            color: theme.cartTitle,
            fontFamily: theme.fontBold,
          }}>
          Personal Info
        </h4>
        <hr style={{ borderColor: theme.cartDecorateLine }} />
        <div className={"personal-detail " + css(themeStyles.content)}>
          First Name: {"" || customer.firstName}
        </div>
        <div className={"personal-detail " + css(themeStyles.content)}>
          Last Name: {"" || customer.lastName}
        </div>
        <div className={"personal-detail " + css(themeStyles.content)}>
          Email: {customer.email}
        </div>
        <div className={"personal-detail " + css(themeStyles.content)}>
          Verified Email:{" "}
          {isEmailVerified ? (
            <span style={{ color: "green" }}>Yes</span>
          ) : (
              <>
                <span style={{ color: "red" }}>No</span>
                <Button className={css(themeStyles.btn)}
                  variant="secondary"
                  onClick={this.resendEmail}>
                  Resend
              </Button>
              </>
            )}
        </div>
        <div
          className="personal-info-button-block"
          style={{ fontFamily: theme.fontBold }}>
          <div className="personal-info-button">
            <Button className={css(themeStyles.btn)}
              variant="secondary"
              onClick={this.changePersonalInfo}>
              Change Personal Info
            </Button>
          </div>
          <div className="personal-info-button">
            <Button className={css(themeStyles.btn)}
              variant="secondary"
              onClick={this.changeEmail}>
              Change Emaill
            </Button>
          </div>
          <div>
            <Button className={css(themeStyles.btn)}
              variant="secondary"
              onClick={this.changePassword}>
              Change Password
            </Button>
          </div>
        </div>
        <br />
      </div>
    );
  };

  render() {
    const { customer, theme } = this.props;

    return customer.customer ? (
      <div style={{
        backgroundColor: theme.pageBgd,
        minHeight: "100vh",
      }}>
        <Nav />
        <div className="user-preferences view" >
          <div className="content">
            {this.renderUser()}
            {customer.tokenData
              ? this.renderPayment()
              : this.renderAddPayment()}
          </div>
          <div className="content">{this.renderAddress()}</div>
        </div>
      </div>
    ) : (
        <Redirect push to={"login"}></Redirect>
      );
  }
}

const mapReduxToProps = state => {
  return {
    customer: state.customerState.customer,
    states: state.adminState.states,
    creditCardTypes: state.adminState.creditCardTypes
  };
};

export default withTheme(connect(mapReduxToProps, {
  saveCustomerInfo,
  saveContactInfo,
  savePhoneInfo,
  updateCustomerEmail,
  changePassword,
  savePaymentInfo,
  showLoading,
  hideLoading,
  resendVerificationEmail
})(User));
