import { PUBLIC_1_0 } from "../../config/config";
import {
  SET_MENU,
  CLEAR_MENU,
  SET_ACTIVE_ITEM,
  CLEAR_ACTIVE_ITEM,
  SET_ACTIVE_CATEGORY,
  CLEAR_ACTIVE_CATEGORY,
  SET_MODIFIER_OPTIONS,
  TOGGLE_MODIFIER,
  CLEAR_MODIFIER_OPTIONS
} from "./types";
import { getOption } from "./utils";

export const getMenu = companyId => {
  return async dispatch => {
    const uri = `${PUBLIC_1_0}/menu/item/sorted?companyId=${companyId}`;
    const options = getOption();

    try {
      const request = new Request(uri, options);
      const response = await fetch(request);

      if (response.ok) {
        const menu = await response.json();
        dispatch({ type: SET_MENU, payload: menu });
      } else {
        dispatch({ type: SET_MENU, payload: {} });
      }
    } catch (e) {
      dispatch({ type: SET_MENU, payload: {} });
    }
  };
};

export const clearMenu = () => ({
  type: CLEAR_MENU
});

export const setActiveCategory = category => ({
  type: SET_ACTIVE_CATEGORY,
  payload: category
});

export const clearActiveCategory = () => ({
  type: CLEAR_ACTIVE_CATEGORY
});

export const getItem = itemId => {
  return async dispatch => {
    const uri = `${PUBLIC_1_0}/menu/item/${itemId}`;
    let options = getOption();

    try {
      const request = new Request(uri, options);
      const response = await fetch(request);
      const item = await response.json();

      dispatch({ type: SET_ACTIVE_ITEM, payload: item });
    } catch (e) {
      dispatch({ type: SET_ACTIVE_ITEM, payload: {} });
    }
  };
};

export const setActiveItem = item => ({ type: SET_ACTIVE_ITEM, payload: item });

export const clearActiveItem = () => ({
  type: CLEAR_ACTIVE_ITEM
});

export const getModifiers = (companyId, storeId, itemId, parentPrice) => {
  return async dispatch => {
    const uri = `${PUBLIC_1_0}/menu/modifierNew?companyId=${companyId}&storeId=${storeId}&itemId=${itemId}&parentPrice=${parentPrice}`;
    let options = getOption();

    try {
      const request = new Request(uri, options);
      const response = await fetch(request);
      const modifierCategories = await response.json();

      dispatch({ type: SET_MODIFIER_OPTIONS, payload: modifierCategories });
    } catch (e) {
      dispatch({ type: SET_MODIFIER_OPTIONS, payload: [] });
    }
  };
};

export const toggleModifier = targetInfo => ({
  type: TOGGLE_MODIFIER,
  payload: targetInfo
});

export const clearModifiers = () => ({
  type: CLEAR_MODIFIER_OPTIONS
});
