import { PUBLIC_1_0 } from "../../config/config";
import {
  SET_CUSTOMER,
  SET_CUSTOMER_INFO,
  SET_CONTACT_INFO,
  SET_PHONE_INFO,
  SET_PAYMENT_TOKEN,
  SET_CUSTOMER_EMAIL,
  CLEAR_CUSTOMER,
  CLEAR_ORDER,
  SET_CUSTOMER_AUTH_INFO
} from "./types";
import { AUTH_ERROR } from "./errors";
import * as firebase from "firebase";
import { getOption, postOption } from "./utils";

export const checkAuth = () => {
  return dispatch =>
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        getCustomerInfo()
          .then(snap => dispatch({ type: SET_CUSTOMER, payload: snap.val() }))
          .then(() =>
            dispatch({
              type: SET_CUSTOMER_AUTH_INFO,
              payload: user.emailVerified
            })
          )
          .catch(() => dispatch({ type: SET_CUSTOMER, payload: {} }));
      } else {
        dispatch({ type: SET_CUSTOMER, payload: {} });
      }
    });
};

export const getCustomerInfo = () => {
  return firebase
    .database()
    .ref()
    .child("users")
    .child(firebase.auth().currentUser.uid)
    .once("value");
};

export const registerCustomer = (customer, contact, password) => {
  return dispatch =>
    firebase
      .auth()
      .createUserWithEmailAndPassword(customer.email, password)
      .then(({ user }) => {
        if (user && !user.emailVerified) {
          user
            .sendEmailVerification()
            .then(res => {
              return true;
            })
            .catch(() => false);
        }
      })
      .then(() => createCustomer(customer, contact))
      .then(async () => {
        return await getCustomerInfo();
      })
      .then(snap => {
        dispatch({ type: SET_CUSTOMER, payload: snap.val() });
        return true;
      })
      .catch(e => {
        return false;
      });
};

export const createCustomer = (customer, contact) => {
  const uid = firebase.auth().currentUser.uid;
  return firebase
    .database()
    .ref("users/" + uid)
    .set({
      customer: customer,
      contact: contact
    });
};

export const resendVerificationEmail = () => {
  const user = firebase.auth().currentUser;
  return dispatch =>
    user
      .sendEmailVerification()
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
};

export const updateCustomerEmail = email => {
  return dispatch =>
    firebase
      .auth()
      .currentUser.verifyBeforeUpdateEmail(email)
      .then(() =>
        firebase
          .database()
          .ref()
          .child("users")
          .child(firebase.auth().currentUser.uid)
          .child("customer")
          .child("email")
          .set(email, error => {
            if (error) {
              return false;
            } else {
              dispatch({ type: SET_CUSTOMER_EMAIL, payload: email });
              return true;
            }
          })
      )
      .catch(() => false);
};

export const saveCustomerInfo = customer => {
  return dispatch =>
    firebase
      .database()
      .ref()
      .child("users")
      .child(firebase.auth().currentUser.uid)
      .child("customer")
      .set(customer)
      .then(() => {
        dispatch({ type: SET_CUSTOMER_INFO, payload: customer });
        return true;
      })
      .catch(e => {
        return false;
      });
};

export const saveContactInfo = contact => {
  return dispatch =>
    firebase
      .database()
      .ref()
      .child("users")
      .child(firebase.auth().currentUser.uid)
      .child("contact")
      .set(contact)
      .then(() => {
        dispatch({ type: SET_CONTACT_INFO, payload: contact });
        return true;
      })
      .catch(() => false);
};

export const savePhoneInfo = mainNumber =>{
  return dispatch =>
    firebase
      .database()
      .ref()
      .child('users')
      .child(firebase.auth().currentUser.uid)
      .child('contact')
      .update(mainNumber)
      .then(() => {
        dispatch({type: SET_PHONE_INFO, payload: mainNumber});
        return true;
      })
      .catch(() => false);
};

export const savePaymentInfo = card => {
  return async dispatch => {
    const user = firebase.auth().currentUser;
    if (user) {
      const { uid } = user;

      return user
        .getIdToken(true)
        .then(async idToken => {
          card.entryMode = "Keyed";
          const cvc = card.cvc;
          delete card.cvc;

          const payment = {
            amount: 0.0,
            tip: 0.0,
            currency: "USD",
            cardAccount: card,
            processor: "velocity",
            storeId: "1",
            companyId: "1",
            notes: {
              customerPresent: "Ecommerce",
              cvDataProvided: "Provided",
              accountType: "NotSet",
              cvData: cvc,
              industryType: "Ecommerce"
            }
          };

          const body = {
            payment: JSON.stringify(payment),
            token: idToken,
            uid: uid
          };

          const uri = `${PUBLIC_1_0}/user/token`;
          let options = postOption(JSON.stringify(body), {
            "Content-Type": "application/json"
          });

          try {
            const request = new Request(uri, options);
            const response = await fetch(request);

            if (response.ok) {
              const data = await response.json();

              if (
                data.error !== undefined &&
                !data.error &&
                data.response.bankcardTransactionResponse.maskedPAN &&
                data.authResponse.customerProfileId
              ) {
                const cardToken = {
                  holderName: payment.cardAccount.holderName,
                  cardType: payment.cardAccount.cardType,
                  last4Digit:
                    data.response.bankcardTransactionResponse.maskedPAN,
                  token:
                    data.response.bankcardTransactionResponse
                      .paymentAccountDataToken,
                  customerProfileId: data.authResponse.customerProfileId,
                  customerPaymentProfileId: data.authResponse.customerPaymentProfileIdList.numericString + "",
                  zip: payment.cardAccount.zip
                };

                return firebase
                  .database()
                  .ref()
                  .child("users")
                  .child(uid)
                  .child("tokenData")
                  .set(cardToken)
                  .then(() => {
                    dispatch({ type: SET_PAYMENT_TOKEN, payload: cardToken });
                    return { error: false };
                  })
                  .catch(() => {
                    return { error: true };
                  });
              } else {
                return { error: true };
              }
            } else {
              const error = await response.json();
              return { error: true, message: error.message };
            }
          } catch (e) {
            return { error: true };
          }
        })
        .catch(() => {
          return { error: true, message: AUTH_ERROR };
        });
    } else {
      return { error: true, message: AUTH_ERROR };
    }
  };
};

export const changePassword = password => {
  return () =>
    firebase
      .auth()
      .currentUser.updatePassword(password)
      .then(() => true)
      .catch(() => false);
};

export const resetPassword = email => {
  return () => firebase.auth().sendPasswordResetEmail(email);
};

export const login = (username, password) => {
  return dispatch =>
    firebase
      .auth()
      .signInWithEmailAndPassword(username, password)
      .then(() => getCustomerInfo())
      .then(snap => {
        dispatch({ type: SET_CUSTOMER, payload: snap.val() });
        return true;
      })
      .then(() => {
        const user = firebase.auth().currentUser;
        if (user) {
          dispatch({
            type: SET_CUSTOMER_AUTH_INFO,
            payload: user.emailVerified
          });
          return true;
        }
      })
      .catch(e => {
        return false;
      });
};

export const logout = () => {
  return dispatch => {
    firebase
      .auth()
      .signOut()
      .then(() => dispatch({ type: CLEAR_CUSTOMER }))
      .then(() => dispatch({ type: CLEAR_ORDER }));
  };
};

export const getOrderHistory = username => {
  return () => {
    const user = firebase.auth().currentUser;
    if (user) {
      const { uid } = user;

      return user
        .getIdToken(true)
        .then(async idToken => {
          const uri = `${PUBLIC_1_0}/order?username=${username}&token=${idToken}&uid=${uid}`;
          let options = getOption();

          try {
            const request = new Request(uri, options);
            const response = await fetch(request);

            if (response.ok) {
              const orders = await response.json();
              return orders;
            } else {
              const error = await response.json();
              return { error: true, message: error.message };
            }
          } catch (e) {
            return { error: true };
          }
        })
        .catch(() => {
          return { error: true, message: AUTH_ERROR };
        });
    } else {
      return { error: true, message: AUTH_ERROR };
    }
  };
};

export const getOrderById = orderId => {
  return async() => {
    const uri = `${PUBLIC_1_0}/order/${orderId}`;
    let options = getOption();

    try {
      const request = new Request(uri, options);
      const response = await fetch(request);

      if (response.ok) {
        const order = await response.json();
        return order;
      } else {
        const error = await response.json();
        return { error: true, message: error.message };
      }
    } catch (e) {
      return { error: true };
    }
  };
};

export const saveOrderHistory = orderId => {
  return () => {
    let pushRef = firebase
      .database()
      .ref()
      .child("users")
      .child(firebase.auth().currentUser.uid)
      .child("orderHistory")
      .push();

    pushRef.set({
      timeStamp: firebase.database.ServerValue.TIMESTAMP,
      orderId
    });
  };
};
