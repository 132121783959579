import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTheme } from "../styles/theming";
import "../styles/Footer.css";

class Footer extends Component {
  render() {
    const { theme } = this.props;
    const footerStyles = {
      backgroundColor: theme.pageBgd,
    };
    const linkStyles = {
      color: theme.primary,
      fontFamily: theme.fontMedium,
    };
    const copyrightStyles = {
      color: theme.btnNavText,
      fontFamily: theme.fontRegular,
    };

    return (
      <div className="footer" style={footerStyles}>
        <div className="required-container">
          <div className="link-paging" style={linkStyles}>
            <Link to="/terms-of-use" 
              className="link-paging"
              style={linkStyles}> 
              Terms Of Use 
            </Link> | <Link to="/privacy-policy" 
              className="link-paging"
              style={linkStyles}> 
              Privacy Policy
            </Link> | <Link to="/contact-us" 
              className="link-paging" 
              style={linkStyles}> 
              Contact Us 
            </Link>
          </div>
          <div style={copyrightStyles}>
            © {new Date().getUTCFullYear()} HotSauce Technologies
          </div>
        </div>
      </div>
    );
  }
}

export default withTheme(Footer);
