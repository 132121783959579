import React, { Component } from "react";
import { connect } from "react-redux";
import { getOrderHistory } from "../redux/actions";
import { AUTH_ERROR } from "../redux/actions/errors";
import Nav from "../components/Nav";
import { Collapse } from "react-bootstrap";
import HistoryItemCard from "../components/HistoryItemCard";
import SubHeader from "../components/SubHeader";
import { groupBy, compareDate } from "../util/util";
import { StyleSheet, css } from "aphrodite";
import { withTheme } from "../styles/theming";
import "../styles/orderHistory.css";

class OrderHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      categoryOpen: true
    };
  }

  componentDidMount = async () => {
    const { history, customer, getOrderHistory } = this.props;
    const res = await getOrderHistory(customer.customer.email);
    if (!res.error) {
      this.setState({ orders: res });
    } else if (res.message === AUTH_ERROR) {
      history.push("/login");
    }
  };

  renderHistory = orders => {
    return orders
      .sort((a, b) => {
        return compareDate(b.creationDate, a.creationDate);
      })
      .map(order => {
        return (
          <HistoryItemCard
            key={order.id}
            order={order}
            history={this.props.history}
          />
        );
      });
  };

  renderHistoryGroup = grouped => {
    return Object.keys(grouped)
      .sort((a, b) => {
        return compareDate(
          grouped[b][0].creationDate,
          grouped[a][0].creationDate
        );
      })
      .map(groupKey => {
        const orders = grouped[groupKey];
        return (
          <div className="month-block" id={groupKey} key={groupKey}>
            {this.renderHistory(orders)}
          </div>
        );
      });
  };

  renderSidenav = grouped => {
    const { theme } = this.props;
    const subheaderOptions = {
      title: "Dates",
      uppercase: true,
      collapsable: true,
      isOpen: this.state.categoryOpen
    };

    return (
      <div 
      style={{
        backgroundColor: theme.cardBgd,
        borderRadius: 6
      }}>
        <div
          style={{
            padding: "15px 20px",
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6,
            backgroundColor: theme.cardHeaderBgd,
            color: theme.cardHeader
          }}
          onClick={() =>
            this.setState({ categoryOpen: !this.state.categoryOpen })
          } >
          <SubHeader {...subheaderOptions} />
        </div>
        <Collapse in={this.state.categoryOpen}>
          <div style={{ padding: "15px 20px" }}>
            {Object.keys(grouped)
              .sort((a, b) => {
                return compareDate(
                  grouped[b][0].creationDate,
                  grouped[a][0].creationDate
                );
              })
              .map(groupKey => {
                return (
                  <div key={groupKey}  style={{ paddingBottom: "5px" }}>
                    <li>
                      <a href={"#" + groupKey}
                        className={"date-link " + css(
                          StyleSheet.create({
                            dateLink: {
                              color: theme.orderHistoryDate,
                              fontFamily: theme.fontMedium,
                              ":hover": {
                                color: theme.orderHistoryDateSelect,
                                fontFamily: theme.fontBold,
                                textDecoration: "none",
                              }}
                          }).dateLink
                        )}>
                        {groupKey}
                      </a>
                    </li>
                  </div>
                );
              })}
          </div>
        </Collapse>
      </div>
    );
  };

  goToHome = () => {
    const { history, store } = this.props;
    history.push({ pathname: `/store/${store.urlName}` });
  };

  render() {
    const { theme } = this.props;
    const { orders } = this.state;
    if (!orders || orders.length === 0) {
      return (
        <div
          style={{
            backgroundColor: theme.pageBgd,
            minHeight: "100vh"
          }}>
          <Nav />
          <div className="default-message">
            <div className="default-title"
              style={{
                fontFamily: theme.fontMedium,
                color: theme.orderHistoryDate,
              }}>
              You do not have any completed orders yet.
            </div>
            <div onClick={this.goToHome} 
              className={"home-link " +css(
                StyleSheet.create({
                  gotoRestaurant: {
                    fontFamily: theme.fontMedium,
                    color: theme.orderHistoryDateSelect,
                    ":hover": {
                      textDecoration: "underline"
                    }
                  }
                }).gotoRestaurant)}>
              Make your first order!
            </div>
          </div>
        </div>
      );
    } else {
      const grouped = groupBy(
        orders.map(g => {
          const date = new Date(g.creationDate);
          g.month = date.getFullYear() + "-" + (date.getMonth() + 1);
          return g;
        }),
        "month"
      );

      return (
        <div
          style={{
            backgroundColor: theme.pageBgd,
            minHeight: "100vh"
          }}>
          <Nav />
          <div className="mt-3">
            <div className="sideNav mx-4 mt-3">
              {this.renderSidenav(grouped)}
            </div>
            <div className="order-list">
              {this.renderHistoryGroup(grouped)}
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapReduxToProps = state => {
  return {
    customer: state.customerState.customer,
    store: state.storeState.store
  };
};

export default withTheme(connect(
  mapReduxToProps,
  { getOrderHistory }
)(OrderHistory));
