import React, { Component } from "react";
import { connect } from "react-redux";
import { validateOrder, setTip } from "../redux/actions";
import { Modal, Button } from "react-bootstrap";
import FontAwesome from 'react-fontawesome';
import { FaTrashAlt } from 'react-icons/fa';
import { BsCashCoin, BsFillCreditCardFill } from 'react-icons/bs';
import { StyleSheet, css } from "aphrodite";
import { withTheme } from "../styles/theming";
import "../styles/CartItemCard.css";

class CartItemCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMouseInside: false,
      modalShow: false,
      stopRemove: true,
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  componentDidMount = () => {
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
  }

  modalClose = () => this.setState({ modalShow: false });

  removeItem = () => {
    const { order, index, validateOrder } = this.props;
    const { stopRemove } = this.state;

    if (stopRemove) {
      this.setState({ stopRemove: false });
      let lineItems = [...order.lineItemGroups[0].lineItems];

      lineItems.splice(index, 1);

      const newOrder = { ...order, lineItemGroups: [{ lineItems: lineItems }] };

      validateOrder(newOrder);

      const tipAndNonCashFee = {
        tip: newOrder.tip,
        nonCashFeePercentage: this.props.store.onlineOrderProgram.ccFeePercentage,
        creditCardProgram: this.props.store.onlineOrderProgram.creditCardProgram,
        }
      this.props.setTip(tipAndNonCashFee);

      const that = this;

      setTimeout(function() {
        that.setState({ stopRemove: true });
      }, 1500);
    }
  };

  changeQuantity = change => {
    const { order, index, validateOrder } = this.props;
    let lineItem = { ...order.lineItemGroups[0].lineItems[index] };
    change > 0 ? lineItem.quantity++ : lineItem.quantity--;
    if (lineItem.quantity < 1) {
      this.setState({ modalShow: true });      
    }
    if (lineItem.quantity <= 1) {
      lineItem.quantity = 1;
    }
    let lineItems = [...order.lineItemGroups[0].lineItems];
    lineItems[index] = lineItem;

    const newOrder = { ...order, lineItemGroups: [{ lineItems: lineItems }] };

    validateOrder(newOrder);
  };

  mouseEnter = () => {
    this.setState({ isMouseInside: true });
  };

  mouseLeave = () => {
    this.setState({ isMouseInside: false });
  };

  handleWindowResize = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  renderForthLayerModifiersOfModifiers = modifiers => {
    const { theme } = this.props;
    if (modifiers !== undefined) {
      return modifiers
        .map(modifier => modifier)
        .map((modifier, i) => {
          return (
            <div className="forth-level-modifier" key={i}
              style={{
                color: theme.cartOrderModifier,
                fontSize:theme.fontRegular}}>
              {"- " + modifier.modifier.displayName }
            </div>
          );
        });
    }
  };

  renderThirdLayerModifiersOfModifiers = modifiers => {
    const { theme } = this.props;
    if (modifiers !== undefined) {
      return modifiers
        .map(modifier => modifier)
        .map((modifier, i) => {
          return (
            <div className="third-level-modifier" key={i}
              style={{
                color: theme.cartOrderModifier,
                fontSize:theme.fontRegular}}>
              {"- " + modifier.modifier.displayName }
              {this.renderForthLayerModifiersOfModifiers(modifier.lineItemModifiers)}
            </div>
          );
        });
    }
  };

  renderModifiersOfModifiers = modifiers => {
    const { theme } = this.props;
    if (modifiers !== undefined) {
      return modifiers
        .map(modifier => modifier)
        .map((modifier, i) => {
          return (
            <div className="second-level-modifier" key={i}
              style={{
                color: theme.cartItemModifier,
                fontSize:theme.fontRegular}}>
              {"- " + modifier.modifier.displayName }
              {this.renderThirdLayerModifiersOfModifiers(modifier.lineItemModifiers)}
            </div>
          );
        });
    }
  };

  renderModifiers = modifiers => {
    if (modifiers !== undefined) {
      return modifiers.map((modifier, i) => {
        return (
          <div key={i}>
            <div>
              {"- "+modifier.modifier.displayName}
            </div>
            {this.renderModifiersOfModifiers(modifier.lineItemModifiers)}
          </div>
        );
      });
    }
  };

  renderPrice = () => {
    const { lineItem, theme, store, order } = this.props;
    let subtotal = lineItem.priceWithModifier * lineItem.quantity;
    let ccSubtotal = lineItem.ccPriceWithModifier * lineItem.quantity;
    let creditCardProgram = store.onlineOrderProgram.creditCardProgram;
    if(creditCardProgram == "dualPrice") {
      if(order.kioskName || order.tableName) {
        return(
          <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <BsCashCoin style={{marginRight: "1vw", fontSize: "1.3rem", color: theme.cartItemTitle}}/>
            <div className="price"
              style={{
                color: theme.cartItemTitle,
                fontFamily:theme.fontBold}}
            >
              ${subtotal.toFixed(2) || "n.a."} 
            </div>
            <BsFillCreditCardFill style={{marginRight: "1vw", fontSize: "1.3rem", color: theme.cartItemTitle}}/>
            <div className="price"
              style={{
                color: theme.cartItemTitle,
                fontFamily:theme.fontBold}}
            >
              ${ccSubtotal.toFixed(2) || "n.a."} 
            </div>
          </div> 
        )
      } else {
        return(
          <div className="price"
                style={{
                  color: theme.cartItemTitle,
                  fontFamily:theme.fontBold}}>
                ${ccSubtotal.toFixed(2) || "n.a."} 
              </div>
        )
      }
    } else if(creditCardProgram == "nonCashFee" && order.tableName) {
      return(
        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <BsCashCoin style={{marginRight: "1vw", fontSize: "1.3rem", color: theme.cartItemTitle}}/>
                <div className="price"
                  style={{
                    color: theme.cartItemTitle,
                    fontFamily:theme.fontBold}}>
                  ${subtotal.toFixed(2) || "n.a."} 
                </div>
                <BsFillCreditCardFill style={{marginRight: "1vw", fontSize: "1.3rem", color: theme.cartItemTitle}}/>
                <div className="price"
                  style={{
                    color: theme.cartItemTitle,
                    fontFamily:theme.fontBold}}>
                  ${subtotal.toFixed(2) || "n.a."} 
                </div>
              </div> 
      )
    } else {
      return(
        <div className="price"
              style={{
                color: theme.cartItemTitle,
                fontFamily:theme.fontBold}}>
              ${subtotal.toFixed(2) || "n.a."} 
            </div>
      )
    }
  }

  renderWebCardContent=()=>{
    const { lineItem, theme } = this.props;
    const themeStyles = StyleSheet.create({
      qtyBtn: {
        backgroundColor: "transparent",
        color: theme.cartItemAmountBtnSymbol,
        borderColor: theme.cartItemAmountBtnSymbol,
        fontFamily: theme.fontMedium,
        ":hover": {
          backgroundColor: theme.cartItemAmountBtnHoverBgd,
          borderColor: theme.cartItemAmountBtnHoverBgd,
          color: theme.cartItemAmountBtnHoverSymbol
        }
      },
    });
    return(
        <div className="info" style={{flexDirection:'row'}} >
          <div className="item-name-price">
            <span className="name"
              style={{
                color: theme.cartItemTitle,
                fontFamily:theme.fontBold}}>
              {lineItem.item.displayName}
            </span>
            {this.renderPrice()}
          </div>
          <div className="item-modifier">
            <div className="modifier" 
              style={{
                color: theme.cartItemModifier,
                fontFamily:theme.fontRegular}}>
              {this.renderModifiers(lineItem.modifiers)}
            </div>
          </div>
          { !this.props.store.kioskService ? (
            <div className="item-description" 
            style={{borderColor: theme.cartItemDescription,}}>
            <div className="description" 
              style={{
                color: theme.cartItemDescription, 
                fontFamily: theme.fontRegular}}>
              {lineItem.specialInstruction}
            </div>
          </div>
          ) : "" }
          <div className="item-qty">
            <div className="qty" >
              <Button
                variant="secondary"
                className={"minus " +css(themeStyles.qtyBtn)}
                onClick={() => this.changeQuantity(-1)}>
                －
              </Button>
              <span className="number"
                style={{
                  color: theme.cartItemAmount, 
                  fontFamily: theme.fontBold}}>
                {lineItem.quantity.toFixed(0)}
              </span>
              <Button
                variant="secondary"
                className={"plus " +css(themeStyles.qtyBtn)}
                onClick={() => this.changeQuantity(1)}>
                ＋
              </Button>
            </div>
          </div>
          <div className="item-delete">
            <Button
              className="delete-button"
              style={{
                backgroundColor: 'transparent', 
                borderColor: 'transparent',
              }}
              onClick={() => this.setState({ modalShow: true })}>
              <FaTrashAlt
                className="trash-icon" 
                style={{color: theme.cartItemAmountBtnSymbol}}/>
            </Button>
          </div>
        </div>
    );
  }
  renderMobileCardContent=()=>{
    const { lineItem, theme} = this.props;
    const themeStyles = StyleSheet.create({
      qtyBtn: {
        backgroundColor: "transparent",
        color: theme.cartItemAmountBtnSymbol,
        borderColor: theme.cartItemAmountBtnSymbol,
        fontFamily: theme.fontMedium,
        ":hover": {
          backgroundColor: theme.cartItemAmountBtnHoverBgd,
          color: theme.cartItemAmountBtnHoverSymbol,
          borderColor: theme.cartItemAmountBtnHoverBgd,
        }
      },
    });
    let subtotal = lineItem.priceWithModifier * lineItem.quantity;
    return(
      <div className="info" >
        <div className="item-name-price-mod" >
          <div className="item-name-price">
            <span className="name"
              style={{
                color: theme.cartItemTitle,
                fontFamily: theme.fontBold}}>
                {lineItem.item.displayName}
            </span>
            <div className="price"
              style={{
                color: theme.cartItemTitle,
                fontFamily: theme.fontBold}}> 
              ${subtotal.toFixed(2) || "n.a."} 
            </div>
          </div>
          <div className="item-modifier">
            <div className="modifier" 
              style={{
                color: theme.cartItemModifier,
                fontFamily: theme.fontRegular}}>
              {this.renderModifiers(lineItem.modifiers)}
            </div>
          </div>
        </div>
        <div className="item-descript-qty-del">
          <div className="item-description" 
            style={{borderColor: theme.cartItemDescription,}}>
            <div className="description" 
              style={{
                color: theme.cartItemDescription, 
                fontFamily: theme.fontRegular}}>
              {lineItem.specialInstruction}
            </div>
          </div>
          <div className="item-qty-del">
            <div className="item-qty">
              <div className="qty" >
                <Button
                  variant="secondary"
                  className={"minus " +css(themeStyles.qtyBtn)}
                  onClick={() => this.changeQuantity(-1)}>
                  －
                </Button>
                <span className="number"
                  style={{
                    color: theme.cartItemAmount, 
                    fontFamily: theme.fontBold}}>
                  {lineItem.quantity.toFixed(0)}
                </span>
                <Button
                  variant="secondary"
                  className={"plus " +css(themeStyles.qtyBtn)}
                  onClick={() => this.changeQuantity(1)}>
                  ＋
                </Button>
              </div>
            </div>
            <div className="item-delete">
              <Button
                className="delete-button"
                style={{
                  backgroundColor: 'transparent', 
                  borderColor: 'transparent',
                }}
                onClick={() => this.setState({ modalShow: true })}>
                <FaTrashAlt
                  className="trash-icon" 
                  style={{color: theme.cartItemAmountBtnSymbol}}/>
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  render() {
    const { lineItem, theme } = this.props;
    const themeStyles = StyleSheet.create({
      btn: {
        color: theme.btnText,
        borderColor: "transparent",
        backgroundColor: theme.btnBgd,
        fontFamily: theme.fontMedium,
      },
    });
    let subtotal = lineItem.priceWithModifier * lineItem.quantity;
    return (
      
      <div className="cart-item-card"  
        style={{backgroundColor: theme.cartItemBgd,}}>
        {(this.state.width > 700)? this.renderWebCardContent() :this.renderMobileCardContent()}

        <Modal show={this.state.modalShow} onHide={this.modalClose}>
          <div className="modal-whole" 
            style={{
              backgroundColor: theme.modifierBgd,
              fontFamily: theme.fontBold,
              borderRadius: "10px",
              borderColor: "transparent",
            }}>
          <Modal.Header>
            <Modal.Title
              style={{
                color: theme.modifierModalPrice,
                fontFamily: theme.fontBold,
              }}>
              Remove Item
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              color: theme.modifierModalContent,
              fontFamily: theme.fontRegular,
            }}>
            Are you sure you want to remove this item?
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.modalClose}
              className={css(themeStyles.btn)}>
              Cancel
            </Button>
            <Button onClick={this.removeItem} variant="primary"
              className={css(themeStyles.btn)}>
              OK
            </Button>
          </Modal.Footer>
          </div>
        </Modal>
      </div>
    );
  }
}

const reduxStateToProps = state => {
  return {
    order: state.cartState.order,
    store: state.storeState.store,
  };
};

export default withTheme(connect(reduxStateToProps, { validateOrder, setTip })(CartItemCard));
