import React, { Component } from "react";
import { connect } from "react-redux";
import { registerCustomer, getStates } from "../redux/actions";
import Nav from "../components/Nav";
import { Form, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { confirm } from "../util/confirm";
import { StyleSheet, css } from 'aphrodite';
import { withTheme } from "../styles/theming"
import "../styles/Register.css";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      street: "",
      city: "",
      zip: "",
      state: "",
      phone: ""
    };
  }

  componentDidMount = () => {
    this.props.getStates();
  };

  onTextChange = e => {
    const { id, value } = e.target;
    this.setState({ [id]: value });
  };

  onStateChange = e => {
    const state = e.target.value;

    this.setState({ state: state });
  };

  getPwdConfirmValidation = () => {
    var isValid = this.state.confirmPassword === this.state.password;

    return isValid;
  };

  getPwdValidation = () => {
    var isValid = false;
    const rule = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;

    isValid = this.state.password.toString().match(rule);

    return isValid;
  };

  register = async e => {
    e.preventDefault();

    const {
      email,
      password,
      firstName,
      lastName,
      street,
      city,
      state,
      zip,
      phone
    } = this.state;

    const isValid = this.getPwdValidation() && this.getPwdConfirmValidation();

    const { theme } = this.props;

    if (isValid) {
      const customer = {
        firstName: firstName,
        lastName: lastName,
        email: email
      };

      const contact = {
        address: {
          street1: street,
          city: city,
          state: state,
          country: "US",
          zip1: zip
        },
        mainNumber: {
          main: true,
          number: phone
        }
      };

      const { history, store, registerCustomer } = this.props;

      const success = await registerCustomer(customer, contact, password);

      if (success) {
        confirm(
          "Registration successful! Please check your email for confirmation.",
          true,
          {theme: theme}
        ).then(() => {
          history.push({ pathname: `/store/${store.urlName}` });
        });
      } else {
        confirm(
          "Registration failed, please try again later!", 
          true,
          {theme: theme}
          );
      }
    } else {
      confirm(
        "Password Error, please check the rules and the password confirmation!",
        true,
        {theme: theme}
      );
    }
  };

  render() {
    const { theme } = this.props;
    const tooltip = (
      <Tooltip id="tooltip">
        Minimum 6 characters, 1 uppercase, 1 lowercase, 1 number.
      </Tooltip>
    );
    const themeStyles = StyleSheet.create({
      formControl:{
        height: 50, 
        fontSize: 16,
        backgroundColor: theme.placeholderBgd,
        borderColor: theme.modifierModalDescription,
        color: theme.placeholderTitle,
        fontFamily: theme.fontRegular,
        ':focus':{
          borderColor: theme.placeholderSelectBorder,
          borderWidth: 2,
        }
      },
      btn: {
        color: theme.btnText,
        borderColor: "transparent",
        backgroundColor: theme.btnBgd,
        fontFamily: theme.fontMedium,
        borderRadius: '6px',
      },
    });

    return (
      <div className="d-flex flex-column"
        style={{
          backgroundColor: theme.pageBgd,
          fontFamily: theme.fontMedium,
          color: theme.placeholderTitle,
          minHeight: "100vh",
        }}>
        <Nav />
        <Form
          className="d-flex flex-wrap ml-5 mr-5 mt-5"
          onSubmit={this.register}>
          <Form.Group className="col-12 col-md-6 col-lg-4">
            <Form.Label>
              Email
            </Form.Label>
            <Form.Control
              className={"input-control "+css(themeStyles.formControl)}
              id="email"
              type="email"
              placeholder="Email"
              onChange={this.onTextChange}
              required
            />
          </Form.Group>
          <div className="d-none d-md-block d-lg-none col-md-6" />
          <Form.Group className="col-12 col-md-6 col-lg-4">
            <Form.Label>
              Password
            </Form.Label>
            <OverlayTrigger placement="bottom-end" overlay={tooltip}>
              <Form.Control
                className={"input-control "+css(themeStyles.formControl)}
                id="password"
                type="password"
                placeholder="Password"
                onChange={this.onTextChange}
                required
              />
            </OverlayTrigger>
          </Form.Group>
          <Form.Group className="col-12 col-md-6 col-lg-4">
            <Form.Label>
              Confirm Password
            </Form.Label>
            <Form.Control
              className={"input-control "+css(themeStyles.formControl)}
              id="confirmPassword"
              type="password"
              placeholder="Confirm password"
              onChange={this.onTextChange}
              required
            />
          </Form.Group>
          <Form.Group className="col-12 col-md-6 col-lg-4">
            <Form.Label>
              First Name
            </Form.Label>
            <Form.Control
              className={"input-control "+css(themeStyles.formControl)}
              id="firstName"
              type="text"
              placeholder="First name"
              value={this.state.firstName}
              onChange={this.onTextChange}
              required
            />
          </Form.Group>
          <Form.Group className="col-12 col-md-6 col-lg-4">
            <Form.Label>
              Last Name
            </Form.Label>
            <Form.Control
              className={"input-control "+css(themeStyles.formControl)}
              id="lastName"
              type="text"
              placeholder="Last name"
              value={this.state.lastName}
              onChange={this.onTextChange}
              required
            />
          </Form.Group>
          <Form.Group className="col-12 col-md-6 col-lg-4">
            <Form.Label>
              Street
            </Form.Label>
            <Form.Control
              className={"input-control "+css(themeStyles.formControl)}
              id="street"
              type="text"
              placeholder="Street"
              value={this.state.street}
              onChange={this.onTextChange}
              required
            />
          </Form.Group>
          <Form.Group className="col-12 col-md-6 col-lg-4">
            <Form.Label>
              City
            </Form.Label>
            <Form.Control
              className={"input-control "+css(themeStyles.formControl)}
              id="city"
              type="text"
              placeholder="City"
              value={this.state.city}
              onChange={this.onTextChange}
              required
            />
          </Form.Group>
          <Form.Group className="col-12 col-md-6 col-lg-4">
            <Form.Label>
              State
            </Form.Label>
            <Form.Control
              className={"input-control "+css(themeStyles.formControl)}
              as="select"
              placeholder="State"
              onChange={this.onStateChange}
            >
              {this.props.states
                ? this.props.states.map(state => {
                    return (
                      <option key={state.id} value={state.name}>
                        {state.displayName}
                      </option>
                    );
                  })
                : null}
            </Form.Control>
          </Form.Group>
          <Form.Group className="col-12 col-md-6 col-lg-4">
            <Form.Label>
              Zip Code
            </Form.Label>
            <Form.Control
              className={"input-control "+css(themeStyles.formControl)}
              id="zip"
              type="text"
              placeholder="Zip code"
              value={this.state.zip}
              onChange={this.onTextChange}
              required
            />
          </Form.Group>
          <Form.Group className="col-12 col-md-6 col-lg-4">
            <Form.Label>
              Phone
            </Form.Label>
            <Form.Control
              className={"input-control "+css(themeStyles.formControl)}
              id="phone"
              type="text"
              placeholder="Phone number"
              value={this.state.phone}
              onChange={this.onTextChange}
              required
            />
          </Form.Group>
          <Form.Group className="col-12">
            <Button type="submit" className={css(themeStyles.btn)}>
              Sign Up
            </Button>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

const reduxStateToProps = state => {
  return {
    states: state.adminState.states,
    store: state.storeState.store
  };
};

export default withTheme(connect(reduxStateToProps, { registerCustomer, getStates })(
  Register
));
