import React, { Component } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { confirmable } from "react-confirm";
import { StyleSheet, css } from 'aphrodite';
import { withTheme } from "../styles/theming"
import "../styles/PopupWindow.css";

class PersonalInfoSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: ""
    };
  }

  handleSubmit = e => {
    e.preventDefault();

    if (
      this.getRequiredFieldValidation(this.state.firstName) &&
      this.getRequiredFieldValidation(this.state.lastName)
    ) {
      const customer = {
        firstName: this.state.firstName,
        lastName: this.state.lastName
      };

      const { showLoading, proceed } = this.props;
      showLoading();
      proceed(customer);
    }
  };

  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  getRequiredFieldValidation = value => {
    return value.toString().length > 0;
  };

  render() {
    const { show, dismiss, theme } = this.props;
    const themeStyles = StyleSheet.create({
      formControl:{
        height: 50, 
        fontSize: 16,
        backgroundColor: theme.placeholderBgd,
        borderColor: theme.modifierModalDescription,
        color: theme.placeholderTitle,
        fontFamily: theme.fontRegular,
        ':focus':{
          borderColor: theme.placeholderSelectBorder,
          borderWidth: 2,
        }
      },
      btn: {
        color: theme.btnText,
        borderColor: "transparent",
        backgroundColor: theme.btnBgd,
        fontFamily: theme.fontMedium,
      },
    });
    return (
      <div>
        <Modal show={show} onHide={dismiss}>
          <div className="modal-whole" 
            style={{
              backgroundColor: theme.modifierBgd,
              fontFamily: theme.fontBold,
              borderRadius: "10px",
              borderColor: "transparent",
            }}>
            <Modal.Header>
              <Modal.Title
                style={{
                  color: theme.modifierModalPrice,
                  fontFamily: theme.fontBold,
                }}>
                Change Personal Information
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                color: theme.modifierModalContent,
                fontFamily: theme.fontRegular,
              }}>
              <Form className="window-margin">
                <Form.Group
                  controlId="firstName"
                  validationState={
                    this.getRequiredFieldValidation(this.state.firstName)
                      ? "success"
                      : "error"
                  }>
                  <Form.Label
                    className="placeholder-title"
                    style={{color: theme.placeholderTitle}}>
                    First Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="First name"
                    onChange={this.handleChange}
                    className={"input-control "+css(themeStyles.formControl)}
                  />
                </Form.Group>
                <Form.Group
                  controlId="lastName"
                  validationState={
                    this.getRequiredFieldValidation(this.state.lastName)
                      ? "success"
                      : "error"
                  }>
                  <Form.Label
                    className="placeholder-title"
                    style={{color: theme.placeholderTitle}}>
                    Last Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Last name"
                    onChange={this.handleChange}
                    className={"input-control "+css(themeStyles.formControl)}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer className="button-block-margin">
            <Button onClick={dismiss}
              className={"button-width " +css(themeStyles.btn)}>
                Cancel
              </Button>
              <Button onClick={this.handleSubmit} variant="primary"
                className={"button-width " +css(themeStyles.btn)}>
                Save
              </Button>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withTheme(confirmable(PersonalInfoSetting));
