import {
  SET_STATES,
  SET_CREDIT_CARD_TYPES,
  SHOW_LOADING,
  HIDE_LOADING,
  SET_CARRIERS
} from "./types";
import { PUBLIC_1_0 } from "../../config/config";
import { getOption } from "./utils";

export const getStates = () => {
  return dispatch => {
    const uri = `${PUBLIC_1_0}/enum/state`;
    const options = getOption();

    const request = new Request(uri, options);
    fetch(request)
      .then(res => res.json())
      .then(states =>
        states.map(state => {
          return {
            id: state.id,
            name: state.name,
            displayName: state.displayName
          };
        })
      )
      .then(states => dispatch({ type: SET_STATES, payload: states }))
      .catch(() => []);
  };
};

export const getCreditCardTypes = () => {
  return dispatch => {
    const uri = `${PUBLIC_1_0}/enum/creditCardType`;
    const options = getOption();

    const request = new Request(uri, options);
    fetch(request)
      .then(res => res.json())
      .then(states =>
        states.map(state => {
          return {
            id: state.id,
            name: state.name,
            displayName: state.displayName
          };
        })
      )
      .then(states =>
        dispatch({ type: SET_CREDIT_CARD_TYPES, payload: states })
      )
      .catch(() => []);
  };
};

export const getCarriers = () => {
  return dispatch => {
    const uri = `${PUBLIC_1_0}/enum/carrierGateway`;
    const options = getOption();

    const request = new Request(uri, options);
    fetch(request)
      .then(res => res.json())
      .then(carriers => dispatch({ type: SET_CARRIERS, payload: carriers }));
  };
};

export const showLoading = () => ({
  type: SHOW_LOADING
});

export const hideLoading = () => ({
  type: HIDE_LOADING
});
