import { configureStore } from "redux-starter-kit";
import { persistStore, persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import rootReducer from "../reducers";

const persistConfig = {
  key: "root",
  storage: storageSession
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  const store = configureStore({ reducer: persistedReducer });
  const persistor = persistStore(store);

  return { store, persistor };
};
