export function getOption() {
  return {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  };
}

export function getOptionWithLogin() {
  return {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
}

export function postOption(payload, headers) {
  return {
    method: "POST",
    headers: headers,
    body: payload,
    credentials: "include"
  };
}
