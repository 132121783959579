import {
  SET_CUSTOMER,
  SET_CUSTOMER_INFO,
  SET_CONTACT_INFO,
  SET_PHONE_INFO,
  SET_PAYMENT_TOKEN,
  SET_CUSTOMER_EMAIL,
  CLEAR_CUSTOMER,
  SET_CUSTOMER_AUTH_INFO
} from "../actions/types";
import { createReducer } from "redux-starter-kit";

const DEFAULT_STATE = {
  customer: {}
};

export default createReducer(DEFAULT_STATE, {
  [SET_CUSTOMER]: (state, action) => {
    state.customer = action.payload;
  },
  [SET_CUSTOMER_INFO]: (state, action) => {
    state.customer.customer = action.payload;
  },
  [SET_CUSTOMER_AUTH_INFO]: (state, action) => {
    state.customer.isEmailVerified = action.payload;
  },
  [SET_CONTACT_INFO]: (state, action) => {
    state.customer.contact = action.payload;
  },
  [SET_PHONE_INFO]: (state, action) => {
    state.customer.phone = action.payload;
  },
  [SET_PAYMENT_TOKEN]: (state, action) => {
    state.customer.tokenData = action.payload;
  },
  [SET_CUSTOMER_EMAIL]: (state, action) => {
    state.customer.customer.email = action.payload;
  },
  [CLEAR_CUSTOMER]: state => {
    state.customer = {};
  }
});
