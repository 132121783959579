import React, { Component } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { confirmable } from "react-confirm";
import { StyleSheet, css } from "aphrodite";
import { withTheme } from "../styles/theming";
import "../styles/PopupWindow.css";
import "../styles/PaymentMethodConfirmation.css";
import { creditCardTypeConfirmation } from "../util/confirm";
class PaymentMethodConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payment: {
        method: "",
        cardType: "",
      },
    };
  }

  handleSubmit = () => {
    const { payment } = this.state;
    const { proceed, showLoading, cancel } = this.props;
    if (this.props.isKiosk) {
      showLoading();
    }
    proceed(payment);
  };

  handleMethodChange = (e) => {
    if (e.target.value === "CreditCard" && this.props.isKiosk) {
      creditCardTypeConfirmation({
        theme: this.props.theme,
        acceptableCCTypes: this.props.acceptableCCTypes,
      }).then(async (creditCard) => {
        this.setState({
          payment: {
            method: "CreditCard",
            cardType: creditCard.type,
          },
        });
      });
    } else {
      this.setState({
        payment: {
          method: e.target.value,
          cardType: null,
        },
      });
    }
  };

  render() {
    const { show, cancel, theme, acceptableKioskPaymentTypes } = this.props;
    const themeStyles = StyleSheet.create({
      btn: {
        color: theme.btnText,
        backgroundColor: theme.btnBgd,
        fontFamily: theme.fontMedium,
      },
      methodBtn: {
        color: theme.btnTipText,
        fontFamily: theme.fontRegular,
        borderColor: theme.btnTipBorder,
        backgroundColor: theme.btnTipBgd,
        ":hover": {
          color: theme.btnTipHoverText,
          backgroundColor: theme.btnTipHoverBgd,
        },
        ":focus": {
          backgroundColor: theme.btnTipHoverBgd,
        },
      },
      methodGroup: {
        justifyContent:
          acceptableKioskPaymentTypes && acceptableKioskPaymentTypes.length > 1
            ? "space-between !important"
            : "center",
      },
    });
    return (
      <Modal show={show} onHide={cancel}>
        <div
          className="modal-whole payment-method-modal"
          style={{
            backgroundColor: theme.modifierBgd,
            fontFamily: theme.fontBold,
          }}
        >
          <Modal.Header className="payment-method-modal-header">
            <Modal.Title
              className="payment-method-modal-title"
              style={{
                color: theme.modifierModalPrice,
                fontFamily: theme.fontBold,
              }}
            >
              Choose Payment Method
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            className="payment-method-modal-body"
            style={{
              color: theme.modifierModalContent,
              fontFamily: theme.fontRegular,
            }}
          >
            <div className="button-block-margin">
              {this.props.isKiosk ? (
                <Form.Group className={"payment-method-group " + css(themeStyles.methodGroup)}>
                  {acceptableKioskPaymentTypes.map((type) => {
                    return (
                      <Button
                        className={"payment-method-btn " + css(themeStyles.methodBtn)}
                        onClick={this.handleMethodChange}
                        value={type.name}
                        key={type.id}
                      >
                        {type.displayName}
                      </Button>
                    );
                  })}
                </Form.Group>
              ) : (
                <Form.Group className={"payment-method-group " + css(themeStyles.methodGroup)}>
                  <Button
                    className={css(themeStyles.methodBtn)}
                    onClick={this.handleMethodChange}
                    value={"Cash"}
                    style={{
                      minWidth: "10rem",
                      minHeight: "10rem",
                      fontSize: "large",
                      margin: "2rem",
                    }}
                  >
                    Cash
                  </Button>
                  <Button
                    className={css(themeStyles.methodBtn)}
                    onClick={this.handleMethodChange}
                    value={"CreditCard"}
                    style={{
                      minWidth: "10rem",
                      minHeight: "10rem",
                      fontSize: "large",
                      margin: "2rem",
                    }}
                  >
                    Credit Card
                  </Button>
                </Form.Group>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className="button-block-margin payment-method-modal-footer">
            <Button
              onClick={cancel}
              className={"button-width payment-action-btn " + css(themeStyles.btn)}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleSubmit}
              className={"button-width payment-action-btn " + css(themeStyles.btn)}
              disabled={this.state.payment.method === "" ? true : false}
            >
              Proceed
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
}

export default withTheme(confirmable(PaymentMethodConfirmation));
