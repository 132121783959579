import React from "react";
import { connect } from "react-redux";
import { toggleModifier, validateOrder } from "../../redux/actions";
import { Modal, Button, Form } from "react-bootstrap";
import { StyleSheet, css } from "aphrodite";
import { withTheme } from "../../styles/theming";
import { BsCashCoin, BsFillCreditCardFill } from 'react-icons/bs';
import "../../styles/ModifierOption.css";

class ModifierOption extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {},
      modifiers: [],
      quantity: 1,
      specialInstruction: "",
    };
  }

  componentDidMount = () => {
    window.onpopstate = () => {
      if (document.getElementById("modalConfirm")) {
        this.props.dismiss();
      }
    };
  };

  UNSAFE_componentWillReceiveProps() {
    if(this.state.specialInstruction !== ""){
      this.setState({
        specialInstruction: "",
      })
    }
  }

  handleOnClick = async () => {
    await this.addItem();
    this.setState({
      quantity: 1
    });
    this.props.onHide();
  };

  handleOnHide = () => {
    this.setState({
      quantity: 1
    });
    this.props.onHide();
  };

  addItem = async () => {
    const {
      modifierCategories,
      category,
      item,
      order,
      store,
      validateOrder
    } = this.props;

    // build modifiers array for item
    let _modifiers = [].concat.apply(
      [],
      modifierCategories.map(mc =>
        mc.firstLayerModifiers
          .filter(m => m.modifier.checked)
          .map(m => {
            let _modifiersWithin = [];

            // attach second layer modifiers to current first layer modifier
            if (
              m.secondLayerModifierCategories &&
              m.secondLayerModifierCategories.length > 0
            ) {
              _modifiersWithin = [].concat.apply(
                [],
                m.secondLayerModifierCategories.map(smc => {
                  if (
                    smc.secondLayerModifiers &&
                    smc.secondLayerModifiers.length > 0
                  ) {
                    return smc.secondLayerModifiers
                      .filter(sm => sm.modifier.checked)
                      .map(sm => {

                        let _secondModifiersWithin = [];
                        if (
                          sm.thirdLayerModifierCategories &&
                          sm.thirdLayerModifierCategories.length > 0
                        ) {
                          _secondModifiersWithin = [].concat.apply(
                              [],
                              sm.thirdLayerModifierCategories.map(thirdmc => {
                                if (
                                  thirdmc.thirdLayerModifiers &&
                                  thirdmc.thirdLayerModifiers.length > 0
                                ) {
                                  return thirdmc.thirdLayerModifiers
                                    .filter(thirdm => thirdm.modifier.checked)
                                    .map(thirdm => {
                                      let _thirdModifiersWithin = [];
                                      if (
                                        thirdm.fourthLayerModifierCategories &&
                                        thirdm.fourthLayerModifierCategories.length > 0
                                      ) {
                                        
                                        _thirdModifiersWithin = [].concat.apply(
                                            [],
                                            thirdm.fourthLayerModifierCategories.map(fourthmc => {
                                              if (
                                                fourthmc.fourthLayerModifiers &&
                                                fourthmc.fourthLayerModifiers.length > 0
                                              ) {
                                                return fourthmc.fourthLayerModifiers
                                                  .filter(fourthm => fourthm.checked)
                                                  .map(fourthm => {
                                                    return {
                                                      category: {
                                                        id: fourthmc.categoryId,
                                                        name: fourthmc.name
                                                      },
                                                      modifier: {
                                                        objectId: fourthm.objectId,
                                                        creationUser: fourthm.creationUser,
                                                        id: fourthm.id,
                                                        companyId: fourthm.companyId,
                                                        departmentId: fourthm.departmentId,
                                                        upc: fourthm.upc,
                                                        name: fourthm.name,
                                                        ftsName: fourthm.ftsName,
                                                        displayName: fourthm.displayName,
                                                        description: fourthm.description,
                                                        categories: fourthm.categories ? [...fourthm.categories] : [],
                                                        modifierCategories: fourthm.modifierCategories
                                                          ? [...fourthm.modifierCategories]
                                                          : [],
                                                        cost: fourthm.cost,
                                                        msrp: fourthm.msrp,
                                                        price: fourthm.price ? fourthm.price : 0,
                                                        currency: "USD",
                                                        inclusiveTax: fourthm.inclusiveTax,
                                                        openPrice: fourthm.openPrice,
                                                        rollUp: fourthm.rollUp,
                                                        ignorePrice: fourthm.ignorePrice,
                                                        doNotPrint: fourthm.doNotPrint,
                                                        storeOverrides: fourthm.storeOverrides
                                                      }
                                                    };
                                                  });
                                              } else {
                                                return null;
                                              }
                                            })
                                            );
                                        }

                                        let thirdresult = {
                                        category: {
                                          id: thirdmc.categoryId,
                                          name: thirdmc.name
                                        },
                                        modifier: {
                                          objectId: thirdm.modifier.objectId,
                                          creationUser: thirdm.modifier.creationUser,
                                          id: thirdm.modifier.id,
                                          companyId: thirdm.modifier.companyId,
                                          departmentId: thirdm.modifier.departmentId,
                                          upc: thirdm.modifier.upc,
                                          name: thirdm.modifier.name,
                                          ftsName: thirdm.modifier.ftsName,
                                          displayName: thirdm.modifier.displayName,
                                          description: thirdm.modifier.description,
                                          categories: thirdm.modifier.categories ? [...thirdm.modifier.categories] : [],
                                          modifierCategories: thirdm.modifier.modifierCategories
                                            ? [...thirdm.modifier.modifierCategories]
                                            : [],
                                          cost: thirdm.modifier.cost,
                                          msrp: thirdm.modifier.msrp,
                                          price: thirdm.modifier.price ? thirdm.modifier.price : 0,
                                          currency: "USD",
                                          inclusiveTax: thirdm.modifier.inclusiveTax,
                                          openPrice: thirdm.modifier.openPrice,
                                          rollUp: thirdm.modifier.rollUp,
                                          ignorePrice: thirdm.modifier.ignorePrice,
                                          doNotPrint: thirdm.modifier.doNotPrint,
                                          storeOverrides: thirdm.modifier.storeOverrides
                                        }
                                      };
                                      if (_thirdModifiersWithin.length > 0)
                                      thirdresult.lineItemModifiers = _thirdModifiersWithin;
                                        return thirdresult
                                    });
                                } else {
                                  return null;
                                }
                              })
                            );
                        }

                        let secondresult = {
                          category: {
                            id: smc.categoryId,
                            name: smc.name
                          },
                          modifier: {
                            objectId: sm.modifier.objectId,
                            creationUser: sm.modifier.creationUser,
                            id: sm.modifier.id,
                            companyId: sm.modifier.companyId,
                            departmentId: sm.modifier.departmentId,
                            upc: sm.modifier.upc,
                            name: sm.modifier.name,
                            ftsName: sm.modifier.ftsName,
                            displayName: sm.modifier.displayName,
                            description: sm.modifier.description,
                            categories: sm.modifier.categories ? [...sm.modifier.categories] : [],
                            modifierCategories: sm.modifier.modifierCategories
                              ? [...sm.modifier.modifierCategories]
                              : [],
                            cost: sm.modifier.cost,
                            msrp: sm.modifier.msrp,
                            price: sm.modifier.price ? sm.modifier.price : 0,
                            currency: "USD",
                            inclusiveTax: sm.modifier.inclusiveTax,
                            openPrice: sm.modifier.openPrice,
                            rollUp: sm.modifier.rollUp,
                            ignorePrice: sm.modifier.ignorePrice,
                            doNotPrint: sm.modifier.doNotPrint,
                            storeOverrides: sm.modifier.storeOverrides,
                            lineItemModifiers:[_secondModifiersWithin.length > 0 ? _secondModifiersWithin: ""]
                          }
                        };
                        if (_secondModifiersWithin.length > 0)
                        secondresult.lineItemModifiers = _secondModifiersWithin;
                        return secondresult
                      });
                  } else {
                    return null;
                  }
                })
              );
            }

            const { modifier } = m;
            let result = {
              category: {
                id: mc.categoryId
              },
              modifier: {
                objectId: modifier.objectId,
                creationUser: modifier.creationUser,
                id: modifier.id,
                companyId: modifier.companyId,
                departmentId: modifier.departmentId,
                upc: modifier.upc,
                name: modifier.name,
                ftsName: modifier.ftsName,
                displayName: modifier.displayName,
                description: modifier.description,
                categories: modifier.categories ? [...modifier.categories] : [],
                modifierCategories: modifier.modifierCategories
                  ? [...modifier.modifierCategories]
                  : [],
                cost: modifier.cost,
                msrp: modifier.msrp,
                price: modifier.price ? modifier.price : 0,
                currency: "USD",
                inclusiveTax: modifier.inclusiveTax,
                openPrice: modifier.openPrice,
                rollUp: modifier.rollUp,
                ignorePrice: modifier.ignorePrice,
                doNotPrint: modifier.doNotPrint,
                storeOverrides: modifier.storeOverrides
              }
            };

            if (_modifiersWithin.length > 0)
              result.lineItemModifiers = _modifiersWithin;

            return result;
          })
      )
    );

    let newItem = {
      id: item.id,
      price: item.price,
      upc: item.upc,
      categories: item.categories,
      openPrice: item.openPrice,
      alternatePricings: item.alternatePricings,
      inclusiveTax: item.inclusiveTax,
      storeOverrides: item.storeOverrides,
      composite: item.composite,
      compositeUnique: item.compositeUnique,
      name: item.name,
      description: item.description,
      displayName: item.displayName
    };

    let lineItem = {
      category: category,
      item: newItem,
      effectivePrice: 0,
      quantity: this.state.quantity,
      modifiers: _modifiers,
      specialInstruction: this.state.specialInstruction
    };

    let newLineItems = [...order.lineItemGroups[0].lineItems];
    newLineItems.push(lineItem);

    let newOrder = {
      ...order,
      company: { id: store.companyId },
      store: store,
      lineItemGroups: [
        {
          lineItems: newLineItems
        }
      ],
      serviceMode: order.serviceMode
    };

    validateOrder(newOrder);
  };

  changeQuantity = change => {
    var newQ = this.state.quantity + change;

    if (newQ > 0) {
      this.setState({
        quantity: newQ,
        itemPrice: this.state.item.price * change + this.state.itemPrice
      });
    }
  };

  renderModifierPrice = (modifier) => {
    const { theme, store, order }= this.props;
    const ccFeePercentage = store.onlineOrderProgram.ccFeePercentage;
    if(store.onlineOrderProgram.creditCardProgram == "dualPrice") {
      if(order.kioskName || order.tableName) {
        return(
          <span className="modifier-price price"
            style={{ 
              height: "100%", 
              marginBottom: "0.25rem", 
              marginTop: "0.25rem",
              fontFamily: theme.fontRegular,
              color: theme.modifierModalContent}}
          >
            + ${modifier.price.toFixed(2) || "0.00"} / ${(modifier.price + modifier.price * ccFeePercentage).toFixed(2) || "0.00"}
          </span>
        )
      } else {
        return(
          <span className="modifier-price price"
            style={{ 
              height: "100%", 
              marginBottom: "0.25rem", 
              marginTop: "0.25rem",
              fontFamily: theme.fontRegular,
              color: theme.modifierModalContent}}
          >
            + ${(modifier.price + modifier.price * ccFeePercentage).toFixed(2) || "0.00"}
          </span>
        )
      }
    } else {
      return(
        <span className="modifier-price price"
          style={{ 
            height: "100%", 
            marginBottom: "0.25rem", 
            marginTop: "0.25rem",
            fontFamily: theme.fontRegular,
            color: theme.modifierModalContent}}
        >
          + ${(modifier.price).toFixed(2) || "0.00"}
        </span>
      )
    }
  }

  renderItemPrice =() => {
    const { item, theme, store, order } = this.props;
    if (store.onlineOrderProgram.creditCardProgram == "dualPrice") {
      if(order.kioskName || order.tableName) {
        return (
          <div style={{display: "flex", flexDirection: "row", alignItems: "center" }}>
            <BsCashCoin style={{marginRight: "1vw", fontSize: "1.3rem", color: theme.modifierModalTitle}}/>
            <div className="item-price font-weight-bold"
              style={{
                color: theme.modifierModalPrice,
                fontFamily: theme.fontBold}}
              >
                {item && item.price ? `$${item.price.toFixed(2)}` : ""}
              </div>
              <BsFillCreditCardFill style={{margin: "0 1vw 0 2vw", fontSize: "1.3rem", color: theme.modifierModalTitle}}/>
              <div className="item-price font-weight-bold"
                style={{
                  color: theme.modifierModalPrice,
                  fontFamily: theme.fontBold}}
                >
                  {item && item.price ? `$${(item.price + item.price * store.onlineOrderProgram.ccFeePercentage).toFixed(2)}` : ""}
                </div>
          </div> 
        )
      } else {
        return (
          <div className="item-price font-weight-bold"
            style={{
              color: theme.modifierModalPrice,
              fontFamily: theme.fontBold}}
          >
            {item && item.price ? `$${(item.price + item.price * store.onlineOrderProgram.ccFeePercentage).toFixed(2)}` : ""}
          </div>
        )
      }
    } else {
      return (
        <div className="item-price font-weight-bold"
          style={{
            color: theme.modifierModalPrice,
            fontFamily: theme.fontBold}}
        >
          {item && item.price ? `$${(item.price).toFixed(2)}` : ""}
        </div>
      )
    }
  }

  renderFourthLayerModifiers = (
    firstLayerModifierCategoryId,
    firstLayerModifierId,
    secondLayerModifierCategoryId,
    secondLayerModifierId,
    thirdLayerModifierCategoryId,
    thirdLayerModifierId,
    modifierCategory
  ) => {
    const modifiers = modifierCategory.fourthLayerModifiers;
    const { theme, store }= this.props;
    if (modifiers && modifiers.length > 0) {
      const selectedCount = modifiers.reduce(
        (acc, m) => (m.checked ? acc + 1 : acc),
        0
      );
      const max = modifierCategory.numberOfModifiers;
      const maxSelected = max !== 0 && selectedCount >= max;
      let fourthLayerModifiers = [];
      modifierCategory.fourthLayerModifiers.map((m) => {
        fourthLayerModifiers.push({modifier: m})
      })
      fourthLayerModifiers.sort(function(a, b) {
        let displaySeqA = -1;
        let displaySeqB = -1;
  
        a.modifier.categories.map((category) => {
          if(category.categoryId === modifierCategory.categoryId) {
            displaySeqA= category.displaySeq
          }
        })
        
        b.modifier.categories.map((category) => {
          if(category.categoryId === modifierCategory.categoryId) {
            displaySeqB= category.displaySeq
          }
        })

        return displaySeqA - displaySeqB;
      })
      
      return (
        <div>
          {fourthLayerModifiers.map((m, i) => {
            const modifier = m.modifier;
            return (
              <div key={i} 
                className="d-flex justify-content-between modifier-name"
                style={{
                  fontFamily: theme.fontRegular,
                  color: theme.modifierModalContent,
                }}>
                <Form.Check
                  type="checkbox"
                  style={{ height: "100%", marginBottom: "0.25rem", marginTop: "0.25rem" }}
                  label={modifier.displayName}
                  value={modifier.checked}
                  onChange={() =>
                    this.props.toggleModifier({
                      firstLayerModifierCategoryId: firstLayerModifierCategoryId,
                      firstLayerModifierId: firstLayerModifierId,
                      secondLayerModifierCategoryId: secondLayerModifierCategoryId,
                      secondLayerModifierId: secondLayerModifierId,
                      thirdLayerModifierCategoryId,
                      thirdLayerModifierId,
                      fourthLayerModifierCategoryId: modifierCategory.id,
                      fourthLayerModifierId: modifier.id
                    })
                  }
                  disabled={!modifier.checked && maxSelected}
                />
                {modifier.ignorePrice ? (
                  ""
                ) : this.renderModifierPrice(modifier)}
                </div>
            );
          })}
        </div>
      );
    }
  };

  renderFourthLayerModifierCategories = (
    firstLayerModifierCategoryId,
    firstLayerModifierId,
    secondLayerModifierCategoryId,
    secondLayerModifierId,
    thirdLayerModifierCategoryId,
    thirdLayerModifierId,
    modifierCategories
  ) => {
    const { theme } = this.props;
    return modifierCategories && modifierCategories.length > 0
      ? modifierCategories.map((mc, i) => {
          return (
            <div key={i} className="ml-3" style={{ marginBottom: "0.5rem"}}>
              <div className="font-italic font-weight-bold modifier-name"
                style={{
                  color: theme.modifierModalContent,
                  fontFamily: theme.fontRegular}}>
                {mc.displayName}
                {mc.numberOfModifiers === 0 ?
                  <span 
                    style={{
                      color: theme.modifierModalContent,
                      fontFamily: theme.fontRegular}}>
                    (Optional)
                  </span> 
                  : ""}
                {mc.forced ? (
                  <div className="modifier-warning" 
                    style={{
                      color: theme.modifierModalWarning,
                      fontFamily: theme.fontRegular}}>
                    {`You have to choose ${mc.numberOfModifiers}`}
                  </div>
                ) : (
                  mc.numberOfModifiers > 0 ?
                  <div className="modifier-warning" 
                    style={{
                      color: theme.modifierModalWarning,
                      fontFamily: theme.fontRegular}}>
                    {`You can choose up to ${mc.numberOfModifiers}`}
                  </div> 
                    : ""
                )}
              </div>
              <div className="second-layer" 
                style={{
                  color: theme.modifierModalContent,
                  fontFamily: theme.fontRegular}}>
                {mc.fourthLayerModifiers && mc.fourthLayerModifiers.length > 0
                  ? this.renderFourthLayerModifiers(
                      firstLayerModifierCategoryId,
                      firstLayerModifierId,
                      secondLayerModifierCategoryId,
                      secondLayerModifierId,
                      thirdLayerModifierCategoryId,
                      thirdLayerModifierId,
                      mc
                    )
                  : ""}
                </div>
            </div>
          );
        })
      : null;
  };

  renderThirdLayerModifiers = (
    firstLayerModifierCategoryId,
    firstLayerModifierId,
    secondLayerModifierCategoryId,
    secondLayerModifierId,
    modifierCategory
  ) => {
    const { theme, store }= this.props;
    const modifiers = modifierCategory.thirdLayerModifiers;
    const ccFeePercentage = store.onlineOrderProgram.ccFeePercentage;
    if (modifiers && modifiers.length > 0) {
      const selectedCount = modifiers.reduce(
        (acc, m) => (m.modifier.checked ? acc + 1 : acc),
        0
      );
      const max = modifierCategory.numberOfModifiers;
      const maxSelected = max !== 0 && selectedCount >= max;
      let thirdLayerModifiers = [];
      modifierCategory.thirdLayerModifiers.map((m) => {
        thirdLayerModifiers.push({modifier: m})
      })
      thirdLayerModifiers.sort(function(a, b) {
        let displaySeqA = -1;
        let displaySeqB = -1;
  
        a.modifier.modifier.categories.map((category) => {
          if(category.categoryId === modifierCategory.categoryId) {
            displaySeqA= category.displaySeq
          }
        })
        
        b.modifier.modifier.categories.map((category) => {
          if(category.categoryId === modifierCategory.categoryId) {
            displaySeqB= category.displaySeq
          }
        })

        return displaySeqA - displaySeqB;
      })
      
      return (
        <div>
          {thirdLayerModifiers.map((m, i) => {
            const modifier = m.modifier.modifier;
            return (
              <div key={i} className="d-flex flex-column" style={{ marginBottom: "0.05rem", marginTop: "0.05rem"}}>
              <div key={i} 
                className="d-flex justify-content-between modifier-name"
                style={{
                  fontFamily: theme.fontRegular,
                  color: theme.modifierModalContent,
                }}>
                <Form.Check
                  type="checkbox"
                  style={{ height: "100%", marginBottom: "0.25rem", marginTop: "0.25rem" }}
                  label={modifier.displayName}
                  value={modifier.checked}
                  onChange={() =>
                    this.props.toggleModifier({
                      firstLayerModifierCategoryId: firstLayerModifierCategoryId,
                      firstLayerModifierId: firstLayerModifierId,
                      secondLayerModifierCategoryId: secondLayerModifierCategoryId,
                      secondLayerModifierId: secondLayerModifierId,
                      thirdLayerModifierCategoryId: modifierCategory.id,
                      thirdLayerModifierId: modifier.id
                    })
                  }
                  disabled={!modifier.checked && maxSelected}
                />
                {modifier.ignorePrice ? (
                  ""
                ) : this.renderModifierPrice(modifier)}
                </div>
                {modifier.checked
                    ? this.renderFourthLayerModifierCategories(
                        firstLayerModifierCategoryId,
                        firstLayerModifierId,
                        secondLayerModifierCategoryId,
                        secondLayerModifierId,
                        modifierCategory.id,
                        modifier.id,
                        m.modifier.fourthLayerModifierCategories
                      )
                    : ""}
              </div>
            );
          })}
        </div>
      );
    }
  };

  renderThirdLayerModifierCategories = (
    firstLayerModifierCategoryId,
    firstLayerModifierId,
    secondLayerModifierCategoryId,
    secondLayerModifierId,
    modifierCategories
  ) => {
    const { theme } = this.props;
    return modifierCategories && modifierCategories.length > 0
      ? modifierCategories.map((mc, i) => {
          return (
            <div key={i} className="ml-3" style={{ marginBottom: "0.5rem"}}>
              <div className="font-italic font-weight-bold modifier-name"
                style={{
                  color: theme.modifierModalContent,
                  fontFamily: theme.fontRegular}}>
                {mc.displayName}
                {mc.numberOfModifiers === 0 ?
                  <span 
                    style={{
                      color: theme.modifierModalContent,
                      fontFamily: theme.fontRegular}}>
                    (Optional)
                  </span> 
                  : ""}
                {mc.forced ? (
                  <div className="modifier-warning" 
                    style={{
                      color: theme.modifierModalWarning,
                      fontFamily: theme.fontRegular}}>
                    {`You have to choose ${mc.numberOfModifiers}`}
                  </div>
                ) : (
                  mc.numberOfModifiers > 0 ?
                  <div className="modifier-warning" 
                    style={{
                      color: theme.modifierModalWarning,
                      fontFamily: theme.fontRegular}}>
                    {`You can choose up to ${mc.numberOfModifiers}`}
                  </div> 
                    : ""
                )}
              </div>
              <div className="second-layer" 
                style={{
                  color: theme.modifierModalContent,
                  fontFamily: theme.fontRegular}}>
                {mc.thirdLayerModifiers && mc.thirdLayerModifiers.length > 0
                  ? this.renderThirdLayerModifiers(
                      firstLayerModifierCategoryId,
                      firstLayerModifierId,
                      secondLayerModifierCategoryId,
                      secondLayerModifierId,
                      mc
                    )
                  : ""}
                </div>
            </div>
          );
        })
      : null;
  };

  renderSecondLayerModifiers = (
    firstLayerModifierCategoryId,
    firstLayerModifierId,
    modifierCategory
  ) => {
    const modifiers = modifierCategory.secondLayerModifiers;
    const { theme, store }= this.props;
    if (modifiers && modifiers.length > 0) {
      const selectedCount = modifiers.reduce(
        (acc, m) => (m.modifier.checked ? acc + 1 : acc),
        0
      );
      const max = modifierCategory.numberOfModifiers;
      const maxSelected = max !== 0 && selectedCount >= max;

      let secondLayerModifiers = [];
      modifierCategory.secondLayerModifiers.map((m) => {
        secondLayerModifiers.push({modifier: m})
      })
      secondLayerModifiers.sort(function(a, b) {
        let displaySeqA = -1;
        let displaySeqB = -1;
  
        a.modifier.modifier.categories.map((category) => {
          if(category.categoryId === modifierCategory.categoryId) {
            displaySeqA= category.displaySeq
          }
        })
        
        b.modifier.modifier.categories.map((category) => {
          if(category.categoryId === modifierCategory.categoryId) {
            displaySeqB= category.displaySeq
          }
        })

        return displaySeqA - displaySeqB;
      })
      
      return (
        <div>
          {secondLayerModifiers.map((m, i) => {
            const modifier = m.modifier.modifier;
            return (
              <div key={i} className="d-flex flex-column" style={{ marginBottom: "0.05rem", marginTop: "0.05rem"}}>
              <div key={i} 
                className="d-flex justify-content-between modifier-name"
                style={{
                  fontFamily: theme.fontRegular,
                  color: theme.modifierModalContent,
                }}>
                <Form.Check
                  type="checkbox"
                  style={{ height: "100%", marginBottom: "0.25rem", marginTop: "0.25rem" }}
                  label={modifier.displayName}
                  value={modifier.checked}
                  onChange={() =>
                    this.props.toggleModifier({
                      firstLayerModifierCategoryId: firstLayerModifierCategoryId,
                      firstLayerModifierId: firstLayerModifierId,
                      secondLayerModifierCategoryId: modifierCategory.id,
                      secondLayerModifierId: modifier.id
                    })
                  }
                  disabled={!modifier.checked && maxSelected}
                />
                {modifier.ignorePrice ? (
                  ""
                ) : this.renderModifierPrice(modifier)}
              </div>
              {modifier.checked
                    ? this.renderThirdLayerModifierCategories(
                        firstLayerModifierCategoryId,
                        firstLayerModifierId,
                        modifierCategory.id,
                        modifier.id,
                        m.modifier.thirdLayerModifierCategories
                      )
                    : ""}
              </div>
            );
          })}
        </div>
      );
    }
  };

  renderSecondLayerModifierCategories = (
    firstLayerModifierCategoryId,
    firstLayerModifierId,
    modifierCategories
  ) => {
    const { theme } = this.props;
    return modifierCategories && modifierCategories.length > 0
      ? modifierCategories.map((mc, i) => {
          return (
            <div key={i} className="ml-3" style={{ marginBottom: "0.5rem"}}>
              <div className="font-italic font-weight-bold modifier-name"
                style={{
                  color: theme.modifierModalContent,
                  fontFamily: theme.fontRegular}}>
                {mc.displayName}
                {mc.numberOfModifiers === 0 ?
                  <span 
                    style={{
                      color: theme.modifierModalContent,
                      fontFamily: theme.fontRegular}}>
                    (Optional)
                  </span> 
                  : ""}
                {mc.forced ? (
                  <div className="modifier-warning" 
                    style={{
                      color: theme.modifierModalWarning,
                      fontFamily: theme.fontRegular}}>
                    {`You have to choose ${mc.numberOfModifiers}`}
                  </div>
                ) : (
                  mc.numberOfModifiers > 0 ?
                  <div className="modifier-warning" 
                    style={{
                      color: theme.modifierModalWarning,
                      fontFamily: theme.fontRegular}}>
                    {`You can choose up to ${mc.numberOfModifiers}`}
                  </div> 
                    : ""
                )}
              </div>
              <div className="second-layer" 
                style={{
                  color: theme.modifierModalContent,
                  fontFamily: theme.fontRegular}}>
                {mc.secondLayerModifiers && mc.secondLayerModifiers.length > 0
                  ? this.renderSecondLayerModifiers(
                      firstLayerModifierCategoryId,
                      firstLayerModifierId,
                      mc
                    )
                  : ""}
                </div>
            </div>
          );
        })
      : null;
  };

  renderFirstLayerModifiers = modifierCategory => {
    const modifiers = modifierCategory.firstLayerModifiers;
    const { theme, store } = this.props;
    if (modifiers && modifiers.length > 0) {
      const selectedCount = modifiers.reduce(
        (acc, m) => (m.modifier.checked ? acc + 1 : acc),
        0
      );
      const max = modifierCategory.numberOfModifiers;
      const maxSelected = max !== 0 && selectedCount >= max;
      let firstLayerModifiers = [];
      modifierCategory.firstLayerModifiers.map((m) => {
        firstLayerModifiers.push({modifier: m})
      })
      firstLayerModifiers.sort(function(a, b) {
        let displaySeqA = -1;
        let displaySeqB = -1;
  
        a.modifier.modifier.categories.map((category) => {
          if(category.categoryId === modifierCategory.categoryId) {
            displaySeqA= category.displaySeq
          }
        })
        
        b.modifier.modifier.categories.map((category) => {
          if(category.categoryId === modifierCategory.categoryId) {
            displaySeqB= category.displaySeq
          }
        })

        return displaySeqA - displaySeqB;
      })

      return (
        <div>
          <Form.Group>
            {firstLayerModifiers.map((m, i) => {
              const modifier = m.modifier.modifier;
              return (
                <div key={i} className="d-flex flex-column" style={{ marginBottom: "0.05rem", marginTop: "0.05rem"}}>
                  <div 
                    className="d-flex justify-content-between modifier-name"
                    style={{
                      color: theme.modifierModalContent,
                      fontFamily: theme.fontRegular}}>
                    <Form.Check
                      type="checkbox"
                      label={modifier.displayName}
                      value={modifier.checked}
                      style={{
                        color: theme.modifierModalContent,
                        fontFamily: theme.fontRegular}}
                      onChange={() =>
                        this.props.toggleModifier({
                          firstLayerModifierCategoryId: modifierCategory.id,
                          firstLayerModifierId: modifier.id
                        })
                      }
                      disabled={!modifier.checked && maxSelected}
                    />
                    {modifier.ignorePrice ? (
                      ""
                    ) : this.renderModifierPrice(modifier)}
                  </div>
                  {modifier.checked
                    ? this.renderSecondLayerModifierCategories(
                        modifierCategory.id,
                        modifier.id,
                        m.modifier.secondLayerModifierCategories
                      )
                    : ""}
                </div>
              );
            })}
          </Form.Group>
        </div>
      );
    }
  };
  handleChange = e => {
    this.setState({ specialInstruction: e.target.value });
  };
  renderFirstLayerModifierCategories = () => {
    
    const { modifierCategories, theme } = this.props;

    return modifierCategories && modifierCategories.length > 0
      ? modifierCategories.map((mc, i) => {
          return (
            <Form.Group key={i} className="ml-3 mr-3">
              <div className="font-weight-bold modifier-name">
                <span 
                  style={{
                    color: theme.modifierModalContent,
                    fontFamily: theme.fontRegular}}>
                  {mc.displayName}
                </span>
                {mc.numberOfModifiers === 0 ?
                  <span 
                    style={{
                      color: theme.modifierModalContent,
                      fontFamily: theme.fontRegular}}>
                    (Optional)
                  </span> 
                  : ""}
                {mc.forced ? (
                  <div className="modifier-warning" 
                    style={{
                      color: theme.modifierModalWarning,
                      fontFamily: theme.fontRegular}}>
                    {`You have to choose ${mc.numberOfModifiers}`}
                  </div>
                ) : (
                  mc.numberOfModifiers > 0 ?
                  <div className="modifier-warning" 
                    style={{
                      color: theme.modifierModalWarning,
                      fontFamily: theme.fontRegular}}>
                    {`You can choose up to ${mc.numberOfModifiers}`}
                  </div> 
                    : ""
                )}
              </div>
              {mc.firstLayerModifiers && mc.firstLayerModifiers.length > 0
                ? this.renderFirstLayerModifiers(mc)
                : ""}
            </Form.Group>
          );
        })
      : null;
  };

  renderItemMessage = () => {
    const { theme } = this.props;
    const specialInstructionLimit = 60;
    return (            
    <div className="item-message-section">
      <textarea 
        className={"item-message "+css(
          StyleSheet.create({
            itemMessage: {
              borderColor: theme.modifierModalContent,
              color: theme.modifierModalDescription,
              fontFamily: theme.fontRegular,
              backgroundColor: theme.modifierBgd,
              ":focus": {
                borderColor: theme.modifierModalPrice,
                color: theme.modifierModalDescription,
                outline: 'none',
              }
            }
          }).itemMessage
        )}
        maxRows="4"
        maxLength={specialInstructionLimit}
        type="text"
        placeholder="Special Instruction"
        onChange={this.handleChange}/>
      <span className="item-message-limit"
        style={{
          color: theme.modifierModalContent,
          fontFamily: theme.fontMeidum}}>
        {this.state.specialInstruction.length}/{specialInstructionLimit}
      </span>
    </div>
    )
  };

  render() {

    const { show, onHide, item, modifierCategories, theme, store } = this.props;
    const themeStyles = StyleSheet.create({
      btn: {
        color: theme.btnText,
        borderColor: "transparent",
        backgroundColor: theme.btnBgd,
        fontFamily: theme.fontRegular,
      },
      qtyBtn:{
        backgroundColor: "transparent",
        color: theme.cartItemAmountBtnSymbol,
        borderColor: theme.cartItemAmountBtnSymbol,
        fontFamily: theme.fontBold,
        paddingBottom: "0.25vw",
        ":hover": {
          borderColor: theme.cartItemAmountBtnHoverBgd,
          backgroundColor: theme.cartItemAmountBtnHoverBgd,
          color: theme.cartItemAmountBtnHoverSymbol,
        }
      },
    });
    let isSelectedValid = true;

    for (let i = 0; i < modifierCategories.length; i++) {
      if (!isSelectedValid) break;

      // check first layer modifier category, see if all forced categories have
      // valid modifier number selected
      if (
        modifierCategories[i].firstLayerModifiers &&
        modifierCategories[i].firstLayerModifiers.length > 0
      ) {
        if (modifierCategories[i].forced) {
          const selectedCount = modifierCategories[
            i
          ].firstLayerModifiers.reduce(
            (acc, m) => (m.modifier.checked ? acc + 1 : acc),
            0
          );
          if (modifierCategories[i].numberOfModifiers !== selectedCount) {
            isSelectedValid = false;
            break;
          }
        }

        // if a first layer modifier is checked, check second layer modifier
        // category, see if all forced categories have valid modifier number
        // selected
        const { firstLayerModifiers } = modifierCategories[i];
        for (let j = 0; j < firstLayerModifiers.length; j++) {
          if (
            firstLayerModifiers[j].modifier.checked &&
            firstLayerModifiers[j].secondLayerModifierCategories &&
            firstLayerModifiers[j].secondLayerModifierCategories.length > 0
          ) {
            if (!isSelectedValid) break;

            const { secondLayerModifierCategories } = firstLayerModifiers[j];
            for (let k = 0; k < secondLayerModifierCategories.length; k++) {
              if (!isSelectedValid) break;

              if (
                secondLayerModifierCategories[k].secondLayerModifiers &&
                secondLayerModifierCategories[k].secondLayerModifiers.length > 0 
              ) {
                if(secondLayerModifierCategories[k].forced) {
                  const selectedCount = secondLayerModifierCategories[
                    k
                  ].secondLayerModifiers.reduce(
                    (acc, m) => (m.modifier.checked ? acc + 1 : acc),
                    0
                  );
                  if (
                    secondLayerModifierCategories[k].numberOfModifiers !==
                    selectedCount
                  ) {
                    isSelectedValid = false;
                    break;
                  }
                }

                const { secondLayerModifiers }  =  secondLayerModifierCategories[k];
                for(let l = 0; l<secondLayerModifiers.length; l++) {
                  if (
                    secondLayerModifiers[l].modifier.checked &&
                    secondLayerModifiers[l].thirdLayerModifierCategories &&
                    secondLayerModifiers[l].thirdLayerModifierCategories.length > 0
                  ) {
                    if (!isSelectedValid) break;
                    
                    const { thirdLayerModifierCategories } = secondLayerModifiers[l];
                    for (let m = 0; m < thirdLayerModifierCategories.length; m++) {
                      if (!isSelectedValid) break;

                      if (
                        thirdLayerModifierCategories[m].thirdLayerModifiers &&
                        thirdLayerModifierCategories[m].thirdLayerModifiers.length > 0
                      ) {
                        if(thirdLayerModifierCategories[m].forced) {
                          const selectedCount = thirdLayerModifierCategories[
                            m
                          ].thirdLayerModifiers.reduce(
                            (acc, m) => (m.modifier.checked ? acc + 1 : acc),
                            0
                          );
                          if (
                            thirdLayerModifierCategories[m].numberOfModifiers !==
                            selectedCount
                          ) {
                            isSelectedValid = false;
                            break;
                          }
                        }
                        
                        const { thirdLayerModifiers }  =  thirdLayerModifierCategories[m];
                        for(let n = 0; n<thirdLayerModifiers.length; n++) {
                          if (
                            thirdLayerModifiers[n].modifier.checked &&
                            thirdLayerModifiers[n].fourthLayerModifierCategories &&
                            thirdLayerModifiers[n].fourthLayerModifierCategories.length > 0
                          ) {
                            if (!isSelectedValid) break;
                            
                            const { fourthLayerModifierCategories } = thirdLayerModifiers[n];
                            for (let o = 0; o < fourthLayerModifierCategories.length; o++) {
                              if (!isSelectedValid) break;

                              if (
                                fourthLayerModifierCategories[o].fourthLayerModifiers &&
                                fourthLayerModifierCategories[o].fourthLayerModifiers.length > 0
                              ) {
                                if(fourthLayerModifierCategories[o].forced) {
                                  const selectedCount = fourthLayerModifierCategories[
                                    o
                                  ].fourthLayerModifiers.reduce(
                                    (acc, m) => (m.checked ? acc + 1 : acc),
                                    0
                                  );
                                  if (
                                    fourthLayerModifierCategories[o].numberOfModifiers !==
                                    selectedCount
                                  ) {
                                    isSelectedValid = false;
                                    break;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    return (
      <Modal show={show} onHide={(onHide, this.handleOnHide)} >
        <div className="modal-whole" 
          style={{
            backgroundColor: theme.modifierBgd,
            fontFamily: theme.fontBold,
            borderRadius: "10px",
            borderColor: "transparent",
          }}>
          <Modal.Header className="d-flex flex-column align-items-center">
            <Modal.Title className="d-flex flex-column align-items-center">
              <div className="item-title font-weight-bold"
                style={{
                  color: theme.modifierModalTitle,
                  fontFamily: theme.fontBold}}>
                {item ? item.displayName : ""}
              </div>
              {this.renderItemPrice()}
            </Modal.Title>
              <div className="item-description" 
                style={{
                  color: theme.modifierModalDescription,
                  fontFamily: theme.fontRegular}}>
                {item ? item.description : ""}
              </div>
          </Modal.Header>
          <div className="dividing-line" 
            style={{
              color: theme.modifierModalDividLine,
              fontFamily: theme.fontRegular}}/>
          <Modal.Body>
            {item ? this.renderFirstLayerModifierCategories() : ""}
            {store.specialInstruction ? this.renderItemMessage():""}
          </Modal.Body>
          <Modal.Footer>
            <div className="modifier-stepper col-4">
              <Button
                className={"minus " + css(themeStyles.qtyBtn)}
                variant="secondary mr-2 minus"
                onClick={() => this.changeQuantity(-1)}>
                －
              </Button>
              <span className="number"
                style={{color:theme.cartContent, fontFamily: theme.fontRegular}}>
                {this.state.quantity}
              </span>
              <Button
                className={"plus "+ css(themeStyles.qtyBtn)}
                variant="secondary ml-2 plus"
                onClick={() => this.changeQuantity(1)}>
                ＋
              </Button>
            </div>
            {item ? (
              isSelectedValid ? (
                <Button 
                  className={"col-8 modifier-btn "+css(themeStyles.btn)}
                  onClick={this.handleOnClick}>
                  Add to Cart
                </Button>
              ) : (
                <Button 
                  className={"col-8 modifier-btn "+css(themeStyles.btn)}
                  disabled>
                  Add to Cart
                </Button>
              )
            ) : (
              "loading..."
            )}
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
}

const reduxStateToProps = state => {
  return {
    store: state.storeState.store,
    category: state.menuState.category,
    item: state.menuState.item,
    modifierCategories: state.menuState.modifierCategories,
    order: state.cartState.order
  };
};

export default withTheme(connect(reduxStateToProps, {
  toggleModifier,
  validateOrder
})(ModifierOption));
