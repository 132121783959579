import React, { Component } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { confirmable } from "react-confirm";
import { StyleSheet, css } from 'aphrodite';
import { withTheme } from "../styles/theming"
import "../styles/PopupWindow.css";

class PhoneInfoSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: ""
    };
  }

  handleSubmit = () => {
    if (
      this.getRequiredFieldValidation(this.state.phone)
    ) {
      const phone = {
        mainNumber: {
          main: true,
          number: this.state.phone
        }
      };

      const { showLoading, proceed } = this.props;
      showLoading();
      proceed(phone);
    }
  };

  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  getRequiredFieldValidation = value => {
    return value.toString().length > 0;
  };

  render() {
    const { show, dismiss, theme } = this.props;
    const themeStyles = StyleSheet.create({
      formControl:{
        height: 50, 
        fontSize: 16,
        backgroundColor: theme.placeholderBgd,
        borderColor: theme.modifierModalDescription,
        color: theme.placeholderTitle,
        fontFamily: theme.fontRegular,
        ':focus':{
          borderColor: theme.placeholderSelectBorder,
          borderWidth: 2,
        }
      },
      btn: {
        color: theme.btnText,
        borderColor: "transparent",
        backgroundColor: theme.btnBgd,
        fontFamily: theme.fontMedium,
      },
    });
    return (
      <Modal show={show} onHide={dismiss}>
        <div className="modal-whole" 
          style={{
            backgroundColor: theme.modifierBgd,
            fontFamily: theme.fontBold,
            borderRadius: "10px",
            borderColor: "transparent",
          }}>
          <Modal.Header>
            <Modal.Title
              style={{
                color: theme.modifierModalPrice,
                fontFamily: theme.fontBold,
              }}>
              Change Contact Information
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              color: theme.modifierModalContent,
              fontFamily: theme.fontRegular,
            }}>
            <div className="window-margin">
              <Form.Group
                controlId="phone"
                validationState={
                  this.getRequiredFieldValidation(this.state.phone)
                    ? "success"
                    : "error"
                }>
                <Form.Label
                  className="placeholder-title"
                  style={{color: theme.placeholderTitle}}>
                  Phone Number
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder=""
                  onChange={this.handleChange}
                  className={"input-control "+css(themeStyles.formControl)}/>
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer className="button-block-margin">
            <Button onClick={dismiss}
              className={"button-width " +css(themeStyles.btn)}>
              Cancel
            </Button>
            <Button onClick={this.handleSubmit}
              className={"button-width " +css(themeStyles.btn)}>
              Save
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
}

export default withTheme(confirmable(PhoneInfoSetting));
