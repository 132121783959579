import React, { Component } from "react";
import { connect } from "react-redux";
import qs from "qs";
import Headroom from "react-headroom";
import {
  getStoreByUrlName,
  getCustomerInfo,
  getValidDeliveryAddresses,
  getMenu,
  setActiveCategory,
  clearActiveCategory,
  setActiveItem,
  clearActiveItem,
  getModifiers,
  clearModifiers,
  setInStoreOrder,
} from "../redux/actions";
import HeaderNav from "../components/Nav";
import ItemCard from "../components/ItemCard";
import ModifierOption from "../components/modals/ModifierOption";
import InactiveStore from "../components/InactiveStore";
import {
  formatPhoneNumber,
  formatEnumArrayForDisplay,
  formatTime,
  getStateAbbr,
  formatUserAddressForDeliveryValidation,
  getWeekday,
  getWeekdayStr,
  convertCamelToRegular,
} from "../util/util";
import { Button, Dropdown, Navbar, Nav } from "react-bootstrap";
import MenuSummary from "../components/MenuSummary";
import { FaStore, FaPhoneAlt, FaCar, FaRegClock } from "react-icons/fa";
import { MdRestaurant } from "react-icons/md";
import storeImg from "../styles/img/store_logo.png";
import { withTheme } from "../styles/theming";
import { css, StyleSheet } from "aphrodite";
import "../styles/Store.css";
class Store extends Component {
  constructor(props) {
    super(props);
    this.scrollRef = React.createRef(null);
    this.state = {
      categoryOpen: true,
      deliveryEnabled: false,
      toGoEnabled: false,
      dineInEnabled: false,
      pickUpEnabled: false,
      showModifierOption: false,
      deliveryAllowed: false,
      width: window.innerWidth,
      height: window.innerHeight,
      headRectHeight: 0,
      category: null
    };
  }

  componentDidMount = () => {
    const { match, customer, getStoreByUrlName, getMenu } = this.props;

    var isGuestCheckout = customer.customer ? false : true;
    if (this.props.location.search) {
      const { setInStoreOrder } = this.props;
      const query = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true,
      });
      setInStoreOrder(query);
    }

    if (match && match.params && match.params.id) {
      getStoreByUrlName(match.params.id).then(async (store) => {
        getMenu(store.companyId);
        const isValidAddress = await getValidDeliveryAddresses(
          store.id,
          formatUserAddressForDeliveryValidation(customer)
        );

        if(this.props.menu.categories) {
          this.setState({
            category: this.props.menu.categories[0]
          })
        }

        if (store.acceptedServiceModes.includes("toGo")) {
          this.setState({ toGoEnabled: true });
        }
        if (store.acceptedServiceModes.includes("dineIn")) {
          this.setState({ dineInEnabled: true });
        }
        if (store.acceptedServiceModes.includes("pickUp")) {
          this.setState({ pickUpEnabled: true });
        }
        if (isGuestCheckout) {
          this.setState({
            deliveryEnabled: true,
            deliveryAllowed: store.acceptedServiceModes.includes("delivery"),
          });
        } else {
          this.setState({
            deliveryEnabled: isValidAddress,
            deliveryAllowed: store.acceptedServiceModes.includes("delivery"),
          });
        }
      });

      window.addEventListener("scroll", this.handleScroll);
      this.handleWindowResize();
      window.addEventListener("resize", this.handleWindowResize);
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleWindowResize);
  };

  handleWindowResize = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  handleScroll = () => {
    const { store } = this.props;
    const headRect = document.getElementById("rHero").getBoundingClientRect();
    const orderSummary = document.getElementById("summary");
    const menuHeader = document.getElementById("menuHeader");
    const menuMobileHeader = document.getElementById("menuMobileHeader");
    if (store.active === true) {
      if (window.scrollY > headRect.height) {
        if (this.state.width > 700 && this.state.height <= 1500) {
          orderSummary.style.position = `fixed`;
          menuHeader.style.position = `fixed`;
          if (this.state.width > 800) {
            orderSummary.style.top = `3vw`;
            orderSummary.style.right = `3vw`;
            menuHeader.style.top = `3vw`;
          } else {
            orderSummary.style.top = `4vw`;
            orderSummary.style.right = `3vw`;
            menuHeader.style.top = `4vw`;
          }
        } else if (this.state.width <= 700 || this.state.height >= 1500) {
          menuMobileHeader.style.position = `fixed`;
          if ((this.state.width > 570 && this.state.width <= 700) ) {
            menuMobileHeader.style.top = `5.8vw`;
          } else {
            menuMobileHeader.style.top = `7vw`;
          }
        }
      } else {
        if (this.state.width <= 700 || this.state.height>=1500) {
          menuMobileHeader.style.position = `sticky`;
        } else {
          menuHeader.style.position = `sticky`;
          orderSummary.style.position = `sticky`;
        }
      }
    }
  };

  gotoCart = () => {
    this.props.history.push({ pathname: "/cart" });
  };

  showModifierOption = async (item, category) => {
    const {
      store,
      getModifiers,
      setActiveCategory,
      setActiveItem,
    } = this.props;
    setActiveCategory(category);
    setActiveItem(item);
    await getModifiers(store.companyId, store.id, item.id, item.price);

    this.setState({ showModifierOption: true });
  };

  handleOnHide = () => {
    const { clearActiveItem, clearModifiers } = this.props;
    clearActiveCategory();
    clearActiveItem();
    clearModifiers();

    this.setState({ showModifierOption: false });
  };
  getCharLimit = () => {
    let windowWidth =
      this.state.width >= 700 ? ((this.state.width - 700) / 100).toFixed(2) : 0;
    let charLimit =
      this.state.width >= 700 ? Math.round(30 + 3 * windowWidth) : 0;
    return charLimit;
  };
  getCharSize = (displayName) => {
    let windowWidth =
      this.state.width < 700 ? (this.state.width / 100).toFixed(2) : 0;
    let charSize = this.state.width < 700 ? Math.round(5 * windowWidth) : 0;
    return displayName > charSize;
  };
  getCategoryNum = () => {
    const { categories } = this.props.menu;
    let index = 0,
      sum = 0;
    let charLimit = this.getCharLimit();
    if (!categories) {
      return 0;
    }
    for (let i = 0; i < categories.length; i++) {
      sum += categories[i].displayName.length;
      if (sum >= charLimit) {
        index = i - 1;
        break;
      } else if (sum < charLimit && i === categories.length - 1) {
        index = i;
      }
    }
    return index;
  };
  renderInactive = () => {
    const { store } = this.props;
    return <InactiveStore store={store} />;
  };

  renderItems = (category) => {
    const { store, menu, order } = this.props;

    return menu.items[category.id].map((item, i) => {
      return (
        <ItemCard
          item={item}
          category={category}
          store={store}
          onCardClick={this.showModifierOption}
          key={i}
          order={order}
        />
      );
    });
  };

  renderCategories = () => {
    const { menu } = this.props;
    return menu.categories
      ? menu.categories.map((category, i) => {
          if (category.itemIds.length > 0) {
            return (
              <div key={i} id={category.id} style={{ marginBottom: "3vh" }}>
                <h5 className="category-name">
                  <span className="cat-span">{category.displayName}</span>
                </h5>
                <div className="item-card-list">
                  {this.renderItems(category)}
                </div>
              </div>
            );
          } else {
            return <div key={i}></div>;
          }
        })
      : null;
  };

  handleCategoryOnClick = (selectedCategory) => (e) => {
    e.preventDefault();
    this.setState({
      display: true,
      category: selectedCategory,
    });
    this.scrollRef.current.scrollIntoView(0, 0);
  };

  renderItemList = (display, selectedCategory) => {
    const { menu } = this.props;
    return menu.categories
      ? menu.categories.map((category, i) => {
          if (
            category.itemIds.length > 0 &&
            display &&
            category === selectedCategory
          ) {
            return (
              <div key={i} id={category.id}>
                <div className="item-card-list">
                  {this.renderItems(category)}
                </div>
              </div>
            );
          } else {
            return <div key={i}></div>;
          }
        })
      : null;
  };

  detectZoom() {
    var ratio = 0,
      screen = window.screen,
      ua = navigator.userAgent.toLowerCase();

    if (window.devicePixelRatio !== undefined) {
      ratio = window.devicePixelRatio;
    } else if (~ua.indexOf("msie")) {
      if (screen.deviceXDPI && screen.logicalXDPI) {
        ratio = screen.deviceXDPI / screen.logicalXDPI;
      }
    } else if (
      window.outerWidth !== undefined &&
      window.innerWidth !== undefined
    ) {
      ratio = window.outerWidth / window.innerWidth;
    }

    if (ratio) {
      ratio = Math.round(ratio * 100);
    }
    return ratio;
  }

  renderDefaultCategories(defaultCategory) {
    const { menu } = this.props;
    return menu.categories
      ? menu.categories.map((category, i) => {
          if (category.itemIds.length > 0 && category === defaultCategory) {
            return (
              <div key={i} id={category.id}>
                <div className="item-card-list">
                  {this.renderItems(category)}
                </div>
              </div>
            );
          } else {
            return <div key={i}></div>;
          }
        })
      : null;
  }

  renderCategoryListWeb = () => {
    const { categories } = this.props.menu;
    const { menu, theme } = this.props;
    const categoryNum = this.getCategoryNum();
    let zoom = this.detectZoom();
    if (!categories) {
      return <div />;
    }
    if (zoom <= 125) {
      zoom = Math.floor(zoom / 100);
      if (zoom == 0) {
        zoom = 1;
      }
      zoom *= 860;
    } else {
      zoom = Math.floor(zoom / 100);
      zoom *= 600;
    }
    const themeStyles = StyleSheet.create({
      categoryBtn: {
        backgroundColor: theme.cardHeaderBgd,
        color: theme.cardHeader,
        fontFamily: theme.fontRegular,
        ":hover": {
          borderBottomColor: theme.cardHeader,
          fontFamily: theme.fontBold,
        },
      },
      categorySelectBtn: {
        backgroundColor: theme.cardHeaderBgd,
        borderBottomColor: theme.cardHeader,
        borderBottomWidth: "2px",
        borderBottomStyle: "solid",
        boxShadow: "none",
        fontWeight: "bold",
        ":hover": {
          borderBottomColor: theme.cardHeader,
          fontFamily: theme.fontBold,
        },
      },
      dropDownBtn: {
        backgroundColor: "transparent",
        borderColor: theme.cardHeader,
        color: theme.cardHeader,
        fontFamily: theme.fontMedium,
        ":hover": {
          borderColor: theme.cardHeader,
        },
      },
      dropDownItem: {
        fontFamily: theme.fontRegular,
        color: theme.btnNavText,
        ":hover": {
          backgroundColor: theme.btnNavHoverBgd,
          fontFamily: theme.fontMedium,
          borderRadius: "5px",
        },
      },
    });
    return (
      <div className="menu-nav">
        <Navbar
          className="categoryNavWeb"
          style={{
            backgroundColor: theme.cardHeaderBgd,
            fontFamily: theme.fontMedium,
            borderColor: "transparent",
          }}
        >
          {this.props.menu.categories.map((category, index) => {
            if (category.itemIds.length > 0 && index <= categoryNum) {
              return (
                <Nav.Link
                  className="categoryNavLink"
                  style={{}}
                  eventKey={category.id}
                  key={category.id}
                  href={`#${category.id}`}
                >
                  <Button
                    className={
                      this.state.category == null && menu.categories[0].displayName === category.displayName
                      ? "categoryBtn " + css(themeStyles.categorySelectBtn) 
                      : this.state.category && this.state.category.displayName === category.displayName 
                      ? "categoryBtn " + css(themeStyles.categorySelectBtn) 
                      : "categoryBtn " + css(themeStyles.categoryBtn)
                    }
                    value={category}
                    onClick={this.handleCategoryOnClick(category)}
                  >
                    {category.displayName}
                  </Button>
                </Nav.Link>
              );
            } else if (this.props.menu.categories.length - index === 1) {
              return (
                <Dropdown>
                  <Dropdown.Toggle
                    className={
                      "more-category-dropdown " + css(themeStyles.dropDownBtn)
                    }
                    title=""
                    id="more-category-selection"
                    variant="secondary"
                    drop="down"
                  />
                  <Dropdown.Menu
                    className="more-category-dropdown-menu"
                    style={{
                      backgroundColor: theme.btnNavBgd,
                      fontFamily: theme.fontMedium,
                      maxHeight: zoom + "%",
                    }}
                  >
                    {this.props.menu.categories
                      ? this.props.menu.categories.map(
                          (category2, moreIndex) => {
                            if (
                              moreIndex > categoryNum &&
                              moreIndex <= this.props.menu.categories.length
                            ) {
                              return (
                                <Dropdown.Item
                                  className={
                                    "more-category-dropdown-item " +
                                    css(themeStyles.dropDownItem)
                                  }
                                  eventKey={category2.id}
                                  key={category2.id}
                                  href={`#${category2.id}`}
                                  onClick={this.handleCategoryOnClick(
                                    category2
                                  )}
                                >
                                  {category2.displayName}
                                </Dropdown.Item>
                              );
                            }
                          }
                        )
                      : null}
                  </Dropdown.Menu>
                </Dropdown>
              );
            }
          })}
          {/* {order.serviceMode === "dineIn" && (
            <span className="mr-2" style={{ fontSize: 20 }}>
              {" "}
              {order.tableName
                ? "Table: "
                : order.tentName
                ? "Tent: "
                : ""}{" "}
              {order.tableName ? order.tableName : order.tentName}
            </span>
          )} */}
        </Navbar>
      </div>
    );
  };

  renderCategoryListMobile = () => {
    const { categories } = this.props.menu;
    const { order, theme, store } = this.props;
    const themeStyles = StyleSheet.create({
      dropDownItem: {
        fontFamily: theme.fontRegular,
        color: theme.btnNavText,
        ":hover": {
          backgroundColor: theme.btnNavHoverBgd,
          fontFamily: theme.fontMedium,
          borderRadius: "5px",
        },
      },
    });
    if (!categories) {
      return <div />;
    }
    return (
      <div
        className="menu-nav-mobile"
        style={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {order.serviceMode === "dineIn" && !order.kioskName && (
          <div 
            className="table-info-mobile" 
            style={{ backgroundColor: theme.pageBgd, fontFamily: theme.fontBold,}}
          >
            <span 
              className="table-info-text-mobile"
              style={{color: theme.tableMobileText,}}
            >
              Dine In
            </span>
            <div className="table-info-text-mobile">
              <span className="mr-2" style={{ color: theme.tableMobileText}}>
                {" "}
                {order.tableName ? "Table: " : order.tentName ? "Tent: " : ""}{" "}
              </span>
              <span style={{ color: theme.tableMobileNum}}>
                {order.tableName ? order.tableName : order.tentName}
              </span>
            </div>
          </div>
        )}
        <Dropdown
          className="category-dropdown-mobile"
          style={{ backgroundColor: theme.cardHeaderBgd }}
          id="category-selection"
          drop="down"
        >
          <Dropdown.Toggle
            className="dropdown-toggle-mobile"
            style={{
              color: theme.cardHeader,
              fontFamily: theme.fontMedium,
              borderColor: theme.cardHeaderBgd,
              backgroundColor: theme.cardHeaderBgd,
            }}
          >
            Category
          </Dropdown.Toggle>
          <Dropdown.Menu
            className="category-dropdown-menu"
            style={{
              backgroundColor: theme.btnNavBgd,
              fontFamily: theme.fontMedium,
            }}
          >
            {categories.map((category) => {
              if (category.itemIds.length > 0) {
                if (
                  category.displayName.length >
                  this.getCharSize(category.displayName.length)
                ) {
                  return (
                    <Dropdown.Item
                      className={
                        "category-dropdown-item category-dropdown-item-large " +
                        css(themeStyles.dropDownItem)
                      }
                      style={{ display: "inline-block" }}
                      eventKey={category.id}
                      key={category.id}
                      href={`#${category.id}`}
                      onClick={this.handleCategoryOnClick(category)}
                    >
                      {category.displayName}
                    </Dropdown.Item>
                  );
                } else {
                  return (
                    <Dropdown.Item
                      className={
                        "category-dropdown-item " +
                        css(themeStyles.dropDownItem)
                      }
                      style={{ display: "inline-block" }}
                      eventKey={category.id}
                      key={category.id}
                      href={`#${category.id}`}
                      onClick={this.handleCategoryOnClick(category)}
                    >
                      {category.displayName}
                    </Dropdown.Item>
                  );
                }
              } else {
                return <div key={category.id}></div>;
              }
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  renderCheckOutButtonMobile = () => {
    const { store, order, theme } = this.props;
    const minOrderAmt = store.minOrderAmount;
    const subtotal = order ? order.subtotal : 0;
    const total = order ? order.total : 0;
    const ccSubTotal = order? order.ccSubTotal : 0;
    const themeStyles = StyleSheet.create({
      btn: {
        color: theme.btnText,
        borderColor: "transparent",
        backgroundColor: theme.btnBgd,
        fontFamily: theme.fontBold,
      },
    });
    return (
      <div
        className="checkout-button-mobile"
        style={{ backgroundColor: theme.pageBgd }}
      >
        {subtotal === 0 ? (
          <Button
            variant="primary"
            className={"bottom-button " + css(themeStyles.btn)}
            block
            disabled
          >
            Cart Empty
          </Button>
        ) : minOrderAmt >= subtotal ? (
          <Button
            variant="primary"
            className={"bottom-button " + css(themeStyles.btn)}
            block
            disabled
          >
            Min Amount ${minOrderAmt ? minOrderAmt.toFixed(2) : "0.00"} (Total:
            ${total.toFixed(2)})
          </Button>
        ) : (
          store.onlineOrderProgram.creditCardProgram == "dualPrice" ?
          <Button
            variant="primary"
            className={"bottom-button " + css(themeStyles.btn)}
            block
            onClick={this.gotoCart}
          >
            Proceed to Cart (${(Math.round((subtotal + Number.EPSILON) * 100) / 100).toFixed(2)} / ${(Math.round((ccSubTotal + Number.EPSILON) * 100) / 100).toFixed(2)})
          </Button>
          :
          <Button
            variant="primary"
            className={"bottom-button " + css(themeStyles.btn)}
            block
            onClick={this.gotoCart}
          >
            Proceed to Cart (${(Math.round((subtotal + Number.EPSILON) * 100) / 100).toFixed(2)})
          </Button>
        )}
      </div>
    );
  };

  renderHoursSection = () => {
    const { store, theme } = this.props;
    const weekdayStr = getWeekdayStr();
    const weekday = getWeekday();
    let hours = [];
    store.businessHours.forEach((item) => {
      hours[item.weekday] =
        formatTime(item.openTime) + " - " + formatTime(item.closeTime);
    });
    if (window.innerWidth > 570) {
      return (
        <div
          className="hours-section"
          style={{ fontFamily: theme.fontRegular }}
        >
          <div className="hours-date-section" style={{ marginRight: "1vw" }}>
            <div className="hours-item">Mon</div>
            <div className="hours-item">Tue</div>
            <div className="hours-item">Wed</div>
            <div className="hours-item">Thu</div>
            <div className="hours-item">Fri</div>
            <div className="hours-item">Sat</div>
            <div className="hours-item">Sun</div>
          </div>
          <div className="hours-time-section">
            <div className={`hours-item ${hours["mon"] ? "" : "hours-closed"}`}>
              {hours["mon"] ? hours["mon"] : "Closed"}
            </div>
            <div className={`hours-item ${hours["tue"] ? "" : "hours-closed"}`}>
              {hours["tue"] ? hours["tue"] : "Closed"}
            </div>
            <div className={`hours-item ${hours["wed"] ? "" : "hours-closed"}`}>
              {hours["wed"] ? hours["wed"] : "Closed"}
            </div>
            <div className={`hours-item ${hours["thu"] ? "" : "hours-closed"}`}>
              {hours["thu"] ? hours["thu"] : "Closed"}
            </div>
            <div className={`hours-item ${hours["fri"] ? "" : "hours-closed"}`}>
              {hours["fri"] ? hours["fri"] : "Closed"}
            </div>
            <div className={`hours-item ${hours["sat"] ? "" : "hours-closed"}`}>
              {hours["sat"] ? hours["sat"] : "Closed"}
            </div>
            <div className={`hours-item ${hours["sun"] ? "" : "hours-closed"}`}>
              {hours["sun"] ? hours["sun"] : "Closed"}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="hours-section"
          style={{ fontFamily: theme.fontRegular }}
        >
          <FaRegClock
            className="item-pic"
            style={{
              display: "flex",
              alignSelf: "center",
              color: theme.bannerIcon,
            }}
          />
          <div
            className="hours-item"
            style={{ marginLeft: "0px", display: "flex", alignItems: "center" }}
          >
            {weekday}
          </div>
          <div
            className={`hours-item ${hours[weekdayStr] ? "" : "hours-closed"}`}
            style={{ marginLeft: "1vw", display: "flex", alignItems: "center" }}
          >
            {hours[weekdayStr] ? hours[weekdayStr] : "Closed"}
          </div>
        </div>
      );
    }
  };
  judgeDescritpionChar = (description) => {
    const limit = 255;
    let splitDescription = description.substring(0, limit - 1) + "...";
    return description.length > limit ? splitDescription : description;
  };
  renderStoreInfo = () => {
    const { store, theme } = this.props;
    const themeStyles = StyleSheet.create({
      restaurantHero: {
        color: theme.restaurantInfo,
        fontFamily: theme.fontRegular,
        backgroundImage: 
          store.bannerUrl
          ? `url(${store.bannerUrl})`
          : theme.bannerImg,
        backgroundColor: "rgba(0,0,0,0.1)",
        backgroundBlendMode: "soft-light",
      },
      storePic: {
        backgroundColor: "white",
        backgroundImage: `url(${store.logoUrl})`,
      },
      storeName: {
        color: theme.restaurantName,
        fontFamily: theme.fontRestaurant,
        flex: 1,
        width: "100%",
      },
      storeDescription: {
        fontFamily: theme.fontRegular,
        color: theme.restaurantName,
        flex: 1,
      },
      itemPic: {
        color: theme.bannerIcon,
      },
    });
    let hours = [];
    store.businessHours.forEach((item) => {
      hours[item.weekday] =
        formatTime(item.openTime) + " - " + formatTime(item.closeTime);
    });
    if (window.innerWidth > 999) {
      return (
        <div
          ref="resthero"
          className={"restaurant-hero " + css(themeStyles.restaurantHero)}
          id="rHero"
        >
          <div className="store-info" style={{ fontFamily: theme.fontRegular }}>
            <div className="store-name-info">
              <div className={"store-pic " + css(themeStyles.storePic)} />
              <div className="store-name-descript">
                <h3 className={"store-name " + css(themeStyles.storeName)}>
                  {store.name}
                </h3>
                <div
                  className={
                    "store-description " + css(themeStyles.storeDescription)
                  }
                >
                  {store.description
                    ? this.judgeDescritpionChar(store.description)
                    : ""}
                </div>
              </div>
            </div>
            <div className="store-detail-info">
              <div className="store-address">
                <div>
                  <FaStore className={"item-pic " + css(themeStyles.itemPic)} />
                </div>
                <div className="item-dbline">
                  <p className="item-inline">
                    {store.address.street1 ? store.address.street1 : "Not set"}
                  </p>
                  <p className="item-inline">
                    {(store.address.city
                      ? store.address.city
                      : "City not set") +
                      ", " +
                      (store.address.state
                        ? getStateAbbr(store.address.state)
                        : "State not set") +
                      " " +
                      (store.address.zip1 ? store.address.zip1 : "Zip not set")}
                  </p>
                </div>
              </div>
              <div className="store-phone">
                <FaPhoneAlt
                  className={"item-pic " + css(themeStyles.itemPic)}
                />
                <p className="item-inline">
                  {formatPhoneNumber(store.phoneNumber)}
                </p>
              </div>
              <div className="store-cuisine">
                <MdRestaurant
                  className={"item-pic " + css(themeStyles.itemPic)}
                />
                <p className="item-inline">
                  {formatEnumArrayForDisplay(store.cuisineTypes)}
                </p>
              </div>
              <div className="store-service">
                <FaCar className={"item-pic " + css(themeStyles.itemPic)} />
                <p className="item-inline">
                  {formatEnumArrayForDisplay(store.acceptedServiceModes)}
                </p>
              </div>
            </div>
          </div>
          <div style={{}}>{this.renderHoursSection()}</div>
        </div>
      );
    } else if (window.innerWidth <= 999 && window.innerWidth > 570) {
      return (
        <div
          ref="resthero"
          className={"restaurant-hero " + css(themeStyles.restaurantHero)}
          id="rHero"
        >
          <div
            className="store-info mobile-store-info"
            style={{ fontFamily: theme.fontRegular }}
          >
            <div className="store-name-info">
              <div className={"store-pic " + css(themeStyles.storePic)} />
              <div className="store-name-descript">
                <h3 className={"store-name " + css(themeStyles.storeName)}>
                  {store.name}
                </h3>
                <div
                  className={
                    "store-description " + css(themeStyles.storeDescription)
                  }
                >
                  {store.description
                    ? this.judgeDescritpionChar(store.description)
                    : ""}
                </div>
              </div>
            </div>
            {this.renderHoursSection()}
          </div>
          <div className="store-detail-info">
            <div className="store-address">
              <div>
                <FaStore className={"item-pic " + css(themeStyles.itemPic)} />
              </div>
              <div className="item-dbline">
                <p className="item-inline">
                  {store.address.street1 ? store.address.street1 : "Not set"}
                </p>
                <p className="item-inline">
                  {(store.address.city ? store.address.city : "City not set") +
                    ", " +
                    (store.address.state
                      ? getStateAbbr(store.address.state)
                      : "State not set") +
                    " " +
                    (store.address.zip1 ? store.address.zip1 : "Zip not set")}
                </p>
              </div>
            </div>
            <div className="store-phone">
              <FaPhoneAlt className={"item-pic " + css(themeStyles.itemPic)} />
              <p className="item-inline">
                {formatPhoneNumber(store.phoneNumber)}
              </p>
            </div>
            <div className="store-cuisine">
              <MdRestaurant
                className={"item-pic " + css(themeStyles.itemPic)}
              />
              <p className="item-inline">
                {formatEnumArrayForDisplay(store.cuisineTypes)}
              </p>
            </div>
            <div>
              <FaCar className={"item-pic " + css(themeStyles.itemPic)} />
              <p className="item-inline">
                {formatEnumArrayForDisplay(store.acceptedServiceModes)}
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          ref="resthero"
          className={"restaurant-hero " + css(themeStyles.restaurantHero)}
          id="rHero"
        >
          <div className="store-info mobile-store-info">
            <div className="store-name-info">
              <div className={"store-pic " + css(themeStyles.storePic)} />
              <div className="store-name-descript">
                <h3 className={"store-name " + css(themeStyles.storeName)}>
                  {store.name}
                </h3>
                <div
                  className={
                    "store-description " + css(themeStyles.storeDescription)
                  }
                >
                  {store.description
                    ? this.judgeDescritpionChar(store.description)
                    : ""}
                </div>
              </div>
            </div>
          </div>
          <div>{this.renderHoursSection()}</div>
          <div className="store-detail-info">
            <div className="store-address">
              <div>
                <FaStore className={"item-pic " + css(themeStyles.itemPic)} />
              </div>
              <div className="item-dbline">
                <p className="item-inline">
                  {store.address.street1 ? store.address.street1 : "Not set"}
                </p>
                <p className="item-inline">
                  {(store.address.city ? store.address.city : "City not set") +
                    ", " +
                    (store.address.state
                      ? getStateAbbr(store.address.state)
                      : "State not set") +
                    " " +
                    (store.address.zip1 ? store.address.zip1 : "Zip not set")}
                </p>
              </div>
            </div>
            <div className="store-phone">
              <FaPhoneAlt className={"item-pic " + css(themeStyles.itemPic)} />
              <p className="item-inline">
                {formatPhoneNumber(store.phoneNumber)}
              </p>
            </div>
            <div className="store-cuisine">
              <MdRestaurant
                className={"item-pic " + css(themeStyles.itemPic)}
              />
              <p className="item-inline">
                {formatEnumArrayForDisplay(store.cuisineTypes)}
              </p>
            </div>
            <div>
              <FaCar className={"item-pic " + css(themeStyles.itemPic)} />
              <p className="item-inline">
                {formatEnumArrayForDisplay(store.acceptedServiceModes)}
              </p>
            </div>
          </div>
        </div>
      );
    }
  };
  renderDefaultCategoryContent = (category) => {
    const { theme } = this.props;
    const themeStyles = StyleSheet.create({
      categoryTitle: {
        color: theme.menuCardTitle,
        fontFamily: theme.fontRegular
      },
      categoryDescription: {
        color: theme.menuCardDescription,
        fontFamily: theme.fontRegular
      },
      categoryContent: {
        backgroundColor: theme.menuCardBgd
      }
    });
    return (
      <div className={"categoryContent " + css(themeStyles.categoryContent)}>
        {/* <div 
          className={"nameCategoryTitle " + css(themeStyles.categoryTitle)}>
          {category && category.displayName}
        </div> */}
        <div 
          className={"categoryDescription " + css(themeStyles.categoryDescription)}>
          {category && category.description}
        </div>
      </div>
    )
  }
  renderCategoryContent = (display, selectedCategory) => {
    const { menu, theme } = this.props;
    const themeStyles = StyleSheet.create({
      categoryTitle: {
        color: theme.menuCardTitle,
        fontFamily: theme.fontRegular
      },
      categoryDescription: {
        color: theme.menuCardDescription,
        fontFamily: theme.fontRegular
      },
      categoryContent: {
        backgroundColor: theme.menuCardBgd
      }
    });
    return menu.categories
      ? menu.categories.map((category, i) => {
          if (
            category.itemIds.length > 0 &&
            display &&
            category === selectedCategory
          ) {
            return (
              <div className={"categoryContent " + css(themeStyles.categoryContent)}>
                {/* <div 
                  className={"nameCategoryTitle " + css(themeStyles.categoryTitle)}>
                  {category && category.displayName}
                </div> */}
                <div 
                  className={"categoryDescription " + css(themeStyles.categoryDescription)}>
                  {category && category.description}
                </div>
              </div>
            );
          } else {
            return <div key={i}></div>;
          }
        })
      : null;
  }
  renderTempItems = (category) => {
    const { store, menu } = this.props;
    return menu.items[category.id].map((item, i) => {
      return (
        <ItemCard
          item={item}
          category={category}
          store={store}
          onCardClick={this.showModifierOption}
          key={i}
        />
      );
    });
  };
  renderWebCheckout = () => {
    const { deliveryEnabled, deliveryAllowed, toGoEnabled, pickUpEnabled, dineInEnabled } = this.state;
    return (
      <div>
        <MenuSummary
          className="menuSummary"
          toGoEnabled={toGoEnabled}
          deliveryEnabled={deliveryEnabled}
          deliveryAllowed={deliveryAllowed}
          pickUpEnabled={pickUpEnabled}
          dineInEnabled={dineInEnabled}
          gotoCart={this.gotoCart}
        />
      </div>
    );
  };
  renderWebStoreContent = () => {
    const { menu } = this.props;
    return (
      <div className="storeWebContent">
        <div className="categoryAndMenuWeb">
          <div
            className="categoryAndMenuHeaderWeb"
            id="menuHeader"
            style={{ flex: 5 }}
          >
            {this.renderCategoryListWeb()}
          </div>
          {this.state.display
            ? this.renderCategoryContent(this.state.display, this.state.category)
            : undefined != menu.categories
            ? this.renderDefaultCategoryContent(menu.categories[0])
            : " "
          }
          <div className="itemList">
            <div>
              {this.state.display
                ? this.renderItemList(this.state.display, this.state.category)
                : undefined != menu.categories
                ? this.renderDefaultCategories(menu.categories[0])
                : " "}
            </div>
          </div>
        </div>
        <div style={{ width: "10vw" }} />
        <div className="checkoutWeb">{this.renderWebCheckout()}</div>
      </div>
    );
  };
  renderMobileStoreContent = () => {
    const { menu, theme } = this.props;
    return (
      <div
        className="storeMobileContent"
        style={{ backgroundColor: theme.pageBgdm, marginBottom: "7vh", paddingBottom: this.state.height >= 1500 ? '9vh' : '1vh' }}
      >
        <div className="categoryAndMenuMobile">
          <div
            className="categoryAndMenuHeaderMobile"
            id="menuMobileHeader"
            style={{ width: "100%" }}
          >
            {this.renderCategoryListMobile()}
          </div>
          <div>
            <div>
              {this.state.display
                ? this.renderItemList(this.state.display, this.state.category)
                : undefined != menu.categories
                ? this.renderDefaultCategories(menu.categories[0])
                : " "}
            </div>
          </div>
        </div>
        <div className="menuMobile">{this.renderCheckOutButtonMobile()}</div>
      </div>
    );
  };
  renderContent = () => {
    const { store, theme } = this.props;
    const { showModifierOption } = this.state;
    if (store.id) {
      return (
        <>
          <Headroom
            className="header-nav"
            style={{
              position: "fixed",
              transform: "translate3d(0%, -100%, 0%)",
              zIndex: "10000",
            }}
          >
            <HeaderNav />
          </Headroom>
          <div
            className="store"
            style={{
              backgroundColor: theme.pageBgd,
              marginBottom: "5vh",
              minHeight: '95vh',
            }}
          >
            <div ref={this.scrollRef}>
              {store.id ? this.renderStoreInfo() : ""}
            </div>
            <div className="categoryNav">
              {store.active === true
                ? this.state.width >= 700 && this.state.height <= 1500
                  ? this.renderWebStoreContent()
                  : this.renderMobileStoreContent()
                : this.renderInactive()}
            </div>
          </div>
          <ModifierOption
            show={showModifierOption}
            onHide={this.handleOnHide}
          />
        </>
      );
    } else {
      return "";
    }
  };
  render() {
    return this.renderContent();
  }
}

const reduxStateToProps = (state) => {
  return {
    customer: state.customerState.customer,
    store: state.storeState.store,
    menu: state.menuState.menu,
    items: state.cartState.items,
    order: state.cartState.order,
  };
};

export default withTheme(
  connect(reduxStateToProps, {
    getStoreByUrlName,
    getCustomerInfo,
    getValidDeliveryAddresses,
    getMenu,
    setActiveCategory,
    clearActiveCategory,
    setActiveItem,
    clearActiveItem,
    getModifiers,
    clearModifiers,
    setInStoreOrder,
  })(Store)
);
