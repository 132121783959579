import React, { Component } from "react";
import { connect } from "react-redux";
import { getStates, getCreditCardTypes } from "../redux/actions";
import "../styles/LoadingScreen.css";

class LoadingScreen extends Component {
  componentDidMount = () => {
    const { getStates, getCreditCardTypes } = this.props;
    getStates();
    getCreditCardTypes();
  };

  render() {
    const { loading } = this.props;

    return (
      <div className={`overlay ${loading ? "show" : "hide"}`}>
        <div id="loader" className={loading ? "show" : "hide"} />
      </div>
    );
  }
}

const mapReduxToProps = state => {
  return {
    loading: state.adminState.loading
  };
};

export default connect(
  mapReduxToProps,
  { getStates, getCreditCardTypes }
)(LoadingScreen);
