import React, { Component } from "react";
import Nav from "../components/Nav";
import { withTheme } from "../styles/theming";
import "../styles/legal-doc.css";

class ContactUs extends Component {
  render() {
    const { theme } = this.props;
    return (
      <div style={{
        backgroundColor: theme.pageBgd, 
        color: theme.cartContent, 
        fontFamily:theme.fontRegular,
        minHeight: "100vh"}}>
        <Nav />
        <div className="doc-body">
          <h5 style={{
            color: theme.cartTitle, 
            fontFamily:theme.fontBold}}>
            HotSauce Technologies
          </h5>
          <p>7001 Peachtree Industrial Blvd Suite 4200</p>
          <p>Norcross, GA 30092</p>
          <p>Tel: 678.325.5300</p>
          <p>Fax: 678.325.4741</p>
          <br />
          <br />
          <p style={{color: theme.cartTitle, fontFamily: theme.fontBold}}>
            HotSauce Sales
          </p>
          <p>678.325.5300 opt #3</p>
          <p>sales@hotsaucepos.com</p>
          <br />
          <br />
          <p style={{color: theme.cartTitle, fontFamily: theme.fontBold}}>
            HotSauce Support
          </p>
          <p>678.325.5300 opt #2</p>
          <p>support@hotsaucepos.com</p>
        </div>
      </div>
    );
  }
}

export default withTheme(ContactUs);
