import React, { Component } from "react";
import { connect } from "react-redux";
import { validateOrder } from "../redux/actions";
import { Button, Form } from "react-bootstrap";
import { formatPrice } from "../util/util";
import { withTheme } from "../styles/theming";
import { StyleSheet, css } from "aphrodite";
import MenuItemCard from "../components/MenuItemCard";
import "../styles/MenuSummary.css";

class MenuSummary extends Component {
  componentDidMount = () => {
    this.scrollToItemBottom();
  }
  
  componentDidUpdate = (prevProps) => {
    let oldItemNum = prevProps.order.lineItemGroups[0].lineItems.length;
    let newItemNum = this.props.order.lineItemGroups[0].lineItems.length;
    if(newItemNum != oldItemNum && newItemNum > oldItemNum){
      this.scrollToItemBottom();
    }
  }
  
  renderCheckOut = () => {
    const { store, order, gotoCart, theme } = this.props;
    const minOrderAmt = store.minOrderAmount || 0;
    const subtotal = order.subtotal || 0;
    const themeStyles = StyleSheet.create({
      checkoutBtn: {
        color: theme.btnText,
        borderColor: "transparent",
        backgroundColor: theme.btnBgd,
        fontFamily: theme.fontMedium,
      },
    });
    return (
      <div className="checkout-button">
        {!store.open && !store.timeScheduler ? (
          <Button
            className={"checkout-button " + css(themeStyles.checkoutBtn)}
            block
            disabled
          >
            Restaurant is Closed
          </Button>
        ) : !store.open && store.timeScheduler ? (
          order.lineItemGroups[0].lineItems.length > 0 ? (
            subtotal >= minOrderAmt ? (
              <Button
                className={"checkout-button " + css(themeStyles.checkoutBtn)}
                block
                onClick={gotoCart}
              >
                Please Schedule Order
              </Button>
            ) : (
              <Button
                className={"checkout-button " + css(themeStyles.checkoutBtn)}
                block
                disabled
              >
                Min Amount ${minOrderAmt.toFixed(2)}
              </Button>
            )
          ) : (
            <Button
              className={"checkout-button " + css(themeStyles.checkoutBtn)}
              block
              disabled
            >
              No Item in Cart
            </Button>
          )
        ) : order.lineItemGroups[0].lineItems.length > 0 ? (
          subtotal >= minOrderAmt ? (
            <Button
              className={"checkout-button " + css(themeStyles.checkoutBtn)}
              block
              onClick={gotoCart}
            >
              Proceed to Cart
            </Button>
          ) : (
            <Button
              className={"checkout-button " + css(themeStyles.checkoutBtn)}
              block
              disabled
            >
              Min Amount ${minOrderAmt.toFixed(2)}
            </Button>
          )
        ) : (
          <Button
            className={"checkout-button " + css(themeStyles.checkoutBtn)}
            block
            disabled
          >
            No Item in Cart
          </Button>
        )}
      </div>
    );
  };

  changeDeliverType = (e) => {
    const { store, order, validateOrder } = this.props;
    let updatedOrder = Object.assign({}, order);
    const company = { id: store.companyId };
    updatedOrder.serviceMode = e.target.value;
    updatedOrder.company = company;
    updatedOrder.store = store;

    validateOrder(updatedOrder);
  };

  renderDeliveryType = () => {
    const {
      deliveryEnabled,
      deliveryAllowed,
      toGoEnabled,
      pickUpEnabled,
      dineInEnabled,
      store,
      order,
      theme,
    } = this.props;
    const deliveryType = order.serviceMode || "delivery";
    if(store.kioskService && order.kioskName) {
      return (
        <Form.Group className="delivery-type">
          <div
            className="radio-option"
            style={{
              backgroundColor: theme.cardHeaderBgd,
              color: theme.cardHeader,
              fontFamily: theme.fontBold,
            }}
          >
            {toGoEnabled && (
              <Form.Check
                type="radio"
                label="To Go"
                onChange={this.changeDeliverType}
                checked={deliveryType === "toGo"}
                value="toGo"
                inline
              />
            )}
            {dineInEnabled && (
              <Form.Check
                type="radio"
                label="Dine In"
                onChange={this.changeDeliverType}
                checked={deliveryType === "dineIn"}
                value="dineIn"
                inline
              />
            )}
          </div>
        </Form.Group>
      );
    }
    else {
      return (
        <Form.Group className="delivery-type">
          <div
            className="radio-option"
            style={{
              backgroundColor: theme.cardHeaderBgd,
              color: theme.cardHeader,
              fontFamily: theme.fontBold,
            }}
          >
            {pickUpEnabled && (
              <Form.Check
                type="radio"
                label="Pick Up"
                onChange={this.changeDeliverType}
                checked={deliveryType === "pickUp"}
                value="pickUp"
                inline
              />
            )}
            {toGoEnabled && (
              <Form.Check
                type="radio"
                label="To Go"
                onChange={this.changeDeliverType}
                checked={deliveryType === "toGo"}
                value="toGo"
                inline
              />
            )}
            {deliveryAllowed && (
              <Form.Check
                type="radio"
                label="Delivery"
                onChange={this.changeDeliverType}
                checked={deliveryEnabled && deliveryType === "delivery"}
                value="delivery"
                disabled={!deliveryEnabled}
                inline
              />
            )}
          </div>
          {!deliveryEnabled ? (
            <p
              className="text-danger"
              style={{
                color: theme.centerCategoryTitle,
                fontFamily: theme.fontRegular,
              }}
            >
              {!deliveryAllowed
                ? "This store does not offer delivery"
                : !deliveryEnabled
                ? "Your address is out of our delivery range."
                : ""}
            </p>
          ) : null}
        </Form.Group>
      );
    }
  };

  renderConvenienceFee = (order) => {
    const { theme } = this.props;
    let convenienceFee = order.convenienceFee;
    if (convenienceFee > 0) {
      return (
        <div
          className="suboption-fee d-flex justify-content-between"
          style={{ color: theme.cardContent, fontFamily: theme.fontRegular }}
        >
          <span>Convenience Fee: </span>
          <span>${convenienceFee.toFixed(2)}</span>
        </div>
      );
    }
  };
  renderTableInfo = () => {
    const { order, theme } = this.props;

    return (
      <div 
        className="table-info" 
        style={{ backgroundColor: theme.cardHeaderBgd,}}
      >
        <span 
          className="table-info-text"
          style={{
            color: theme.cardHeader,
            fontFamily: theme.fontBold,}}
        >
          Dine In
        </span>
        <div className="table-info-text">
          <span 
            style={{
              color: theme.cardHeader,
              fontFamily: theme.fontBold,}}>
            {order.tableName ? "Table " : order.tentName ? "Tent " : " "}
          </span>
          <span style={{
              color: theme.cardTableNum,
              fontFamily: theme.fontBold,}}>
            {order.tableName
              ? order.tableName
              : order.tentName
              ? order.tentName
              : ""}
          </span>
        </div>
      </div>
    );
  };

  scrollToItemBottom = () => {
    if(this.itemsEnd) {
      this.itemsEnd.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
    }
  };

  renderItems = () => {
    const { store } = this.props;
    const { lineItems } = this.props.order.lineItemGroups[0];

    return lineItems.map((lineItem, i) => {
      return (
        <div key={lineItem.id}>
          <div id={"item" + i}>
            <MenuItemCard lineItem={lineItem} index={i} />
          </div>
        </div>
      );
    });
  };

  renderSubTotal = () => {
    const { order, theme, store } = this.props;
    const subtotal = order.subtotal || 0;
    const ccSubTotal = order.ccSubTotal || 0;
    const { creditCardProgram } = store.onlineOrderProgram;
    const themeStyles = StyleSheet.create({
      feeText: {
        color: theme.cardContent,
        fontFamily: theme.fontRegular,
      },
    });

    if(creditCardProgram == "dualPrice") {
      if(order.kioskName || order.tableName) {
        return (
          <div
            className={
              "suboption-fee d-flex justify-content-between " +
              css(themeStyles.feeText)
            }
          >
            <span>Subtotal: </span>
            <div>
              <span>${subtotal.toFixed(2)}</span>
              <span> / </span>
              <span>${ccSubTotal.toFixed(2)}</span>
            </div>
          </div>
        )
      } else {
        return (
          <div
            className={
              "suboption-fee d-flex justify-content-between " +
              css(themeStyles.feeText)
            }
          >
            <span>Subtotal: </span>
            <span>${ccSubTotal.toFixed(2)}</span>
          </div>
        )
      }
    } else if(creditCardProgram == "nonCashFee" && order.tableName) {
      return (
        <div
          className={
            "suboption-fee d-flex justify-content-between " +
            css(themeStyles.feeText)
          }
        >
          <span>Subtotal: </span>
          <div>
            <span>${subtotal.toFixed(2)}</span>
            <span> / </span>
            <span>${ccSubTotal.toFixed(2)}</span>
          </div>
        </div>
      )
    } else {
      return (
        <div
          className={
            "suboption-fee d-flex justify-content-between " +
            css(themeStyles.feeText)
          }
        >
          <span>Subtotal: </span>
          <span>${ccSubTotal.toFixed(2)}</span>
        </div>
      )
    }
  }

  renderEstimatedTax = () => {
    const { order, theme, store } = this.props;
    const tax = order.tax || 0;
    const ccTax = order.ccTax || 0;
    const { creditCardProgram } = store.onlineOrderProgram;
    const themeStyles = StyleSheet.create({
      feeText: {
        color: theme.cardContent,
        fontFamily: theme.fontRegular,
      },
    });

    if(creditCardProgram == "dualPrice") {
      if(order.kioskName || order.tableName) {
        return (
          <div
            className={
              "suboption-fee d-flex justify-content-between " +
              css(themeStyles.feeText)
            }
          >
            <span>Estimated tax: </span>
            <div>
              <span>${tax.toFixed(2)}</span>
              <span> / </span>
              <span>${ccTax.toFixed(2)}</span>
            </div>
          </div>
        )
      } else {
        return (
          <div
            className={
              "suboption-fee d-flex justify-content-between " +
              css(themeStyles.feeText)
            }
          >
            <span>Estimated tax: </span>
            <span>${ccTax.toFixed(2)}</span>
          </div>
        )
      }
    } else if(creditCardProgram == "nonCashFee" && order.tableName) {
      return (
        <div
          className={
            "suboption-fee d-flex justify-content-between " +
            css(themeStyles.feeText)
          }
        >
          <span>Estimated tax: </span>
          <div>
            <span>${tax.toFixed(2)}</span>
            <span> / </span>
            <span>${ccTax.toFixed(2)}</span>
          </div>
        </div>
      )
    } else {
      return (
        <div
          className={
            "suboption-fee d-flex justify-content-between " +
            css(themeStyles.feeText)
          }
        >
          <span>Estimated tax: </span>
          <span>${ccTax.toFixed(2)}</span>
        </div>
      )
    }
  }

  renderDeliveryFee = () => {
    const { order, theme } = this.props;
    const deliveryFee = order.deliveryFee || 0;
    const themeStyles = StyleSheet.create({
      feeText: {
        color: theme.cardContent,
        fontFamily: theme.fontRegular,
      },
    });

    if(deliveryFee > 0) {
      return (
        <div
          className={
            "suboption-fee d-flex justify-content-between " +
            css(themeStyles.feeText)
          }
        >
          <span>Delivery fee: </span>
          <span>${deliveryFee.toFixed(2)}</span>
        </div>
      )
    }
  }

  renderTotal = () => {
    const { order, theme, store } = this.props;
    const { ccFeePercentage, creditCardProgram } = store.onlineOrderProgram;
    const subtotal = order.subtotal || 0;
    const tax = order.tax || 0;
    const convenienceFee = order.convenienceFee || 0;
    const deliveryFee = order.deliveryFee || 0;
    const total = order.total || 0;
    const ccTotal = order.ccTotal || 0;
    const tip = order.tip || 0;
    
    if(creditCardProgram == "dualPrice") {
      if(order.kioskName || order.tableName) {
        return (
          <div
            className="suboption-fee d-flex justify-content-between"
            style={{ color: theme.cardContent, fontFamily: theme.fontBold }}
          >
            <span>Total: </span>
            <div>
              <span>${formatPrice(total - tip)}</span>
              <span> / </span>
              <span>${formatPrice(ccTotal - tip)}</span>
            </div> 
          </div>
        )
      } else {
        return (
          <div
            className="suboption-fee d-flex justify-content-between"
            style={{ color: theme.cardContent, fontFamily: theme.fontBold }}
          >
            <span>Total: </span>
            <span>${formatPrice(ccTotal - tip)}</span> 
          </div>
        )
      }
    } else if(creditCardProgram == "nonCashFee" ){
      if(order.tableName) {
        return (
          <div
            className="suboption-fee d-flex justify-content-between"
            style={{ color: theme.cardContent, fontFamily: theme.fontBold }}
          >
            <span>Total: </span>
            <div>
              <span>${formatPrice(total - tip)}</span>
              <span> / </span>
              <span>${formatPrice(ccTotal - tip - (subtotal + tax ) * ccFeePercentage)}</span>
            </div> 
          </div>
        )
      } else {
        return (
          <div
            className="suboption-fee d-flex justify-content-between"
            style={{ color: theme.cardContent, fontFamily: theme.fontBold }}
          >
            <span>Total: </span>
            <span>${formatPrice(ccTotal - tip - (subtotal + tax + convenienceFee + deliveryFee) * ccFeePercentage)}</span>
          </div>
        )
      }
    } else {
      return (
        <div
          className="suboption-fee d-flex justify-content-between"
          style={{ color: theme.cardContent, fontFamily: theme.fontBold }}
        >
          <span>Total: </span>
          <span>${formatPrice(ccTotal - tip)}</span> 
        </div>
      )
    }
  }

  render() {
    const { order, theme } = this.props;
    return (
      <Form
        className="menu-summary"
        id="summary"
        style={{ backgroundColor: theme.cardBgd }}
      >
        {order.serviceMode === "dineIn" && !order.kioskName
          ? this.renderTableInfo()
          : this.renderDeliveryType()}
        <div className="menu-items-list">
          {this.renderItems()}
          <div style={{ float:"left", clear: "both" }}
             ref={(el) => { this.itemsEnd = el; }}></div>
        </div>
        <div className="fee">
          {this.renderSubTotal()}
          {this.renderEstimatedTax()}
          {order.tableName || order.kioskName ? "" : this.renderConvenienceFee(order)}
          {this.renderDeliveryFee()}
          <br />
          {this.renderTotal()}
        </div>
        <div className="checkout-button-section">{this.renderCheckOut()}</div>
      </Form>
    );
  }
}

const reduxStateToProps = (state) => {
  return {
    store: state.storeState.store,
    order: state.cartState.order,
    nonCashFee: state.cartState.nonCashFee,
  };
};

export default withTheme(
  connect(reduxStateToProps, { validateOrder })(MenuSummary)
);
