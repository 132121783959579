import React, { Component } from "react";
import FlatPickr from 'react-flatpickr';
import moment from 'moment';
import { StyleSheet, css } from "aphrodite";
import { withTheme } from "../styles/theming";
import "../styles/TimeScheduler.css";
class TimeScheduler extends Component {
  
  render() {
    const { scheduleDate, scheduleTime, handleScheduleTimeChange, handleScheduleDateChange, minTime, maxTime, closeTime, theme, isDateTimeValid } = this.props;
    const themeStyles = StyleSheet.create({
      themeVariables: {
        "--theme-cart-title": theme.cartTitle,
        "--theme-cart-subtitle": theme.cartSubTitle,
        "--theme-cart-decorate-line": theme.btnNavBgd,
        "--theme-font-bold": theme.fontBold,
        "--theme-font-regular": theme.fontRegular,
        "--theme-btn-nav-background": theme.btnNavBgd,
        "--theme-btn-nav-text": theme.btnNavText,
        "--theme-btn-nav-hover-background": theme.btnNavHoverBgd,
        "--theme-btn-nav-hover-text": theme.btnNavHoverText,
      }
    })
    return (
      <div className={css(themeStyles.themeVariables)}>
        <FlatPickr
          className={"time-display"}
          onChange={handleScheduleDateChange}
          value={scheduleDate}
          options={{
            altInput: true,
            noCalendar: "false",
            dateFormat: "Y-m-d",
            enable: [
              { from: "today", to: new Date().fp_incr(7)},
            ],
            defaultDate: "today",
            static: true,
          }}
        />
        {isDateTimeValid ? (
          <FlatPickr
            className={"time-display"}
            data-enable-time
            onChange={handleScheduleTimeChange}
            value={scheduleTime}
            options={{
              altInput: true,
              noCalendar: "true",
              dateFormat: "H:i K",
              maxTime: maxTime,
              minTime: minTime,
              minuteIncrement: 15,
              static: true,
              disableMobile: "true"
            }}
          />
        ): undefined === minTime && undefined === maxTime ? (
          <p className={"text-danger "+css(themeStyles.cartContent)}>
            The store is closed on {moment(scheduleDate.toString(), 'YYYY-MM-DD').format('MM/DD')}. Please choose other date to schedule.
          </p>
        ): (
          <p className={"text-danger "+css(themeStyles.cartContent)}>
          The store is closed at {closeTime}. Please choose other date to schedule.
          </p>
        )}
        
      </div>
    );
  }
}

export default withTheme(TimeScheduler);
