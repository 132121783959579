import React, { Component } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { confirmable } from "react-confirm";
import { StyleSheet, css } from 'aphrodite';
import { withTheme } from "../styles/theming"
import "../styles/PopupWindow.css";
import "../styles/CreditCardTypeConfirmation.css"

class CreditCardTypeConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creditCard: {
        type: ""
      }
    };
  }

  handleSubmit = () => {
    const { creditCard } = this.state;
    const { proceed } = this.props;
    proceed(creditCard);
  };

  handleMethodChange = e => {
    this.setState({
      creditCard: {
        type: e.target.value
      }
    });
  };

  render() {
    const { show, dismiss, theme, acceptableCCTypes } = this.props;
    const themeStyles = StyleSheet.create({
      btn: {
        color: theme.btnTipText,
        backgroundColor: theme.btnBgd,
        fontFamily: theme.fontMedium,
        ":hover": {
          color: theme.btnText,
          backgroundColor: theme.btnTipHoverBgd,
        },
        ":focus": {
          backgroundColor: theme.btnTipHoverBgd,
        }
      },
      methodBtn: {
        color: theme.btnTipText,
        fontFamily: theme.fontRegular,
        borderColor: theme.btnTipBorder,
        backgroundColor: theme.btnTipBgd,
        ":hover": {
          color: theme.btnTipHoverText,
          backgroundColor: theme.btnTipHoverBgd,
        },
        ":focus": {
          backgroundColor: theme.btnTipHoverBgd,
        }
      },
    });
    return (
      <Modal show={show} onHide={dismiss}>
        <div className="modal-whole credit-card-type-modal"
          style={{
            backgroundColor: theme.modifierBgd,
            fontFamily: theme.fontBold,
          }}>
          <Modal.Header className="credit-card-type-modal-header">
            <Modal.Title
              className="credit-card-type-modal-title"
              style={{
                color: theme.modifierModalPrice,
                fontFamily: theme.fontBold,
              }}>
              Choose Credit Card Type
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            className="credit-card-type-modal-body"
            style={{
              color: theme.modifierModalContent,
              fontFamily: theme.fontRegular,
            }}>
            <div className="button-block-margin" >
              <Form.Group 
                className='credit-card-type-group'
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}>
              {acceptableCCTypes.map(cardType => {
                return(
                  <Button
                  className={"credit-card-type-btn " + css(themeStyles.methodBtn)}
                  onClick={this.handleMethodChange}
                  value={cardType.name}
                  key={cardType.id}>
                  {cardType.displayName}
                </Button>
                );
              })}
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer 
            className="button-block-margin credit-card-type-modal-footer" >
            <Button onClick={dismiss}
              className={"button-width payment-action-btn " + css(themeStyles.btn)}>
              Cancel
            </Button>
            <Button onClick={this.handleSubmit}
              className={"button-width payment-action-btn " + css(themeStyles.btn)}
              disabled={this.state.creditCard.type === '' ? true : false}>
              Confirm
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
}

export default withTheme(confirmable(CreditCardTypeConfirmation));
