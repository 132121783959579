import React, { Component } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { confirmable } from "react-confirm";
import { StyleSheet, css } from 'aphrodite';
import { withTheme } from "../styles/theming"
import "../styles/PopupWindow.css";
import "../styles/SwipeCardConfirmation.css";
import {
  swipeCardConfirmation,
} from "../util/confirm";
class SwipeCardConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payment: {
        method: "",
        cardType:"",
      },
      cardType: "",
    };
    this.btnRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.timer = null;
  }

  componentDidMount = async () => {
    this.timer = setTimeout(() => this.btnRef.current.click(), 5000);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  handleSubmit = () => {
    const { payment, cardType } = this.state;
    const { proceed, showLoading } = this.props;
    showLoading();
    proceed(payment, cardType);
  };
  delay = ms => new Promise(res => setTimeout(res, ms));
  handleMethodChange = e => {
    if(e.target.value === "CreditCard"){
      swipeCardConfirmation({
        theme:this.props.theme, 
        acceptableCCTypes: this.props.acceptableCCTypes
      }).then(async (creditCard) => {
        // this.inputElement.click();
        this.setState({
          cardType: creditCard.type,
          payment: {
            method: "CreditCard",
            cardType: creditCard.type,
          }
        });
      })
    }
    else{
      this.setState({
        payment: {
          method: e.target.value,
          cardType: null
        }
      });
    }
  };

  render() {
    const { show, dismiss, theme } = this.props;
    const themeStyles = StyleSheet.create({
      btn: {
        color: theme.btnTipText,
        backgroundColor: theme.btnBgd,
        fontFamily: theme.fontMedium,
        ":hover": {
          color: theme.btnText,
          backgroundColor: theme.btnTipHoverBgd,
        },
        ":focus": {
          backgroundColor: theme.btnTipHoverBgd,
        }
      },
      methodBtn: {
        color: theme.btnTipText,
        fontFamily: theme.fontRegular,
        borderColor: theme.btnTipBorder,
        backgroundColor: theme.btnTipBgd,
        ":hover": {
          color: theme.btnTipHoverText,
          backgroundColor: theme.btnTipHoverBgd,
        },
        ":focus": {
          backgroundColor: theme.btnTipHoverBgd,
        }
      },
    });
    return (
      <Modal show={show} onHide={dismiss}>
        <div className="modal-whole swipe-card-modal"
          style={{
            backgroundColor: theme.modifierBgd,
            fontFamily: theme.fontBold,
          }}>
          <Modal.Body
            className="swipe-card-modal-body"
            style={{
              color: theme.modifierModalContent,
              fontFamily: theme.fontRegular,
            }}>
            <div className="button-block-margin" >
              <Form.Group className='swipe-card-group'>
              Please swipe your card
              </Form.Group>
              <Form.Group className='swipe-card-btn-group'>
                <Button ref={this.btnRef} onClick={this.handleSubmit}> Confirm </Button>
              </Form.Group>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    );
  }
}

export default withTheme(confirmable(SwipeCardConfirmation));
