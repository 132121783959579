export function getUniqId(item, modifiers) {
  return (
    item.id +
    "_" +
    (modifiers.length > 0
      ? modifiers
          .slice(0)
          .map(m => m.modifier.id)
          .reduce((acc, val) => acc + "" + val, "")
      : "")
  );
}

export function getLineItemPrice(item, modifiers) {
  return (
    item.price +
    (modifiers.length > 0
      ? modifiers
          .slice(0)
          .map(m => m.modifier.price)
          .reduce((acc, val) => acc + val)
      : 0)
  );
}

export function setLineItemId(lineItems) {
  var newL = lineItems
    .map(l => Object.assign({ id: l.item.id }, l))
    .sort((a, b) => a.id - b.id);

  for (var i = 1; i < newL.length; i++) {
    if (newL[i].id === newL[i - 1].id) {
      var newItem = newL.slice(0)[i];
      newL.splice(i);
      newItem.id = newL[0].id + 1000;
      newL = [newItem, ...newL];
    }
  }

  return newL;
}

export function groupBy(xs, key) {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function compareDate(a, b) {
  var aDate = new Date(a);
  var bDate = new Date(b);

  return aDate > bDate ? 1 : -1;
}

export function parsePropsAddress(customer) {
  if (customer && customer.contact) {
    return customer.contact.address
      ? customer.contact.address.street1 + " " + customer.contact.address.city
      : "";
  } else {
    return "";
  }
}

export function formatTime(time) {
  var t = time.split(":");
  var result = "";

  if (t[0] < 12) {
    result = t[0] + ":" + t[1] + " am";
  } else if (t[0] === "12") {
    result = "12:" + t[1] + " pm";
  } else {
    result = (t[0] - 12 > 9 ? "" : "0") + (t[0] - 12) + ":" + t[1] + " pm";
  }

  return result;
}

export function formatMilitaryTime(m) {
  return m.substring(0, m.length - 3);
}

export function formatPhoneNumber(s) {
  return s
    ? "(" +
        s.substring(0, 3) +
        ")" +
        s.substring(3, 6) +
        "-" +
        s.substring(6, s.length)
    : "Phone number not set";
}

export function formatEnumArrayForDisplay(array) {
  var result = "";

  if (!array) return result;

  for (var i = 0; i < array.length; i++) {
    result += convertCamelToRegular(array[i]) + " | ";
  }

  return result.substring(0, result.length - 3);
}

export function convertCamelToRegular(s) {
  var regex = /[A-Z]/;
  var result = "";

  for (var i = 0; i < s.length; i++) {
    if (i === 0) {
      result += s.substring(i, i + 1).toUpperCase();
    } else {
      if (regex.test(s.substring(i, i + 1))) {
        result += " " + s.substring(i, i + 1);
      } else {
        result += s.substring(i, i + 1);
      }
    }
  }

  return result;
}

export function formatStateForDisplay(state) {
  return state.substring(0, 1).toUpperCase() + state.substring(1, state.length);
}

export function formatCCTypeForSalsa(type) {
  switch (type) {
    case "visa":
      return "Visa";
    case "mastercard":
      return "MasterCard";
    case "discover":
      return "Discover";
    case "amex":
      return "AmericanExpress";
    default:
      return "";
  }
}

export function getStateAbbr(name) {
  switch (name) {
    case "alabama":
      return "AL";
    case "alaska":
      return "AK";
    case "arizona":
      return "AZ";
    case "arkansas":
      return "AR";
    case "california":
      return "CA";
    case "colorado":
      return "CO";
    case "connecticut":
      return "CT";
    case "delaware":
      return "DE";
    case "florida":
      return "FL";
    case "georgia":
      return "GA";
    case "hawaii":
      return "HI";
    case "idaho":
      return "ID";
    case "illinois":
      return "IL";
    case "indiana":
      return "IN";
    case "iowa":
      return "IA";
    case "kansas":
      return "KS";
    case "kentucky":
      return "KY";
    case "louisiana":
      return "LA";
    case "maine":
      return "ME";
    case "maryland":
      return "MD";
    case "massachusetts":
      return "MA";
    case "michigan":
      return "MI";
    case "minnesota":
      return "MN";
    case "mississippi":
      return "MS";
    case "missouri":
      return "MO";
    case "montana":
      return "MT";
    case "nebraska":
      return "NE";
    case "nevada":
      return "NV";
    case "newHampshire":
      return "NH";
    case "newJersey":
      return "NJ";
    case "newMexico":
      return "NM";
    case "newYork":
      return "NY";
    case "northCarolina":
      return "NC";
    case "northDakota":
      return "ND";
    case "ohio":
      return "OH";
    case "oklahoma":
      return "OK";
    case "oregon":
      return "OR";
    case "pennsylvania":
      return "PA";
    case "rhodeIsland":
      return "RI";
    case "southCarolina":
      return "SC";
    case "southDakota":
      return "SD";
    case "tennessee":
      return "TN";
    case "texas":
      return "TX";
    case "utah":
      return "UT";
    case "vermont":
      return "VT";
    case "verginia":
      return "VA";
    case "washington":
      return "WA";
    case "westVirginia":
      return "WV";
    case "wisconsin":
      return "WI";
    case "wyoming":
      return "WY";
    default:
      return "";
  }
}

export function formatUserAddressForDeliveryValidation(user) {
  if (user && user.contact && user.contact.address) {
    let address = {};

    address.street1 = user.contact.address.street1;
    address.city = user.contact.address.city;
    address.zip1 = user.contact.address.zip1;
    address.state = user.contact.address.state;

    return address;
  } else {
    return undefined;
  }
}

export function getWeekdayStr (day) {
  var weekdayStr = "";
  const weekday = undefined !== day ? day : new Date().getDay();

  switch(weekday){
    case 0:
      weekdayStr = "sun";
      break;
    case 1:
      weekdayStr = "mon";
      break;
    case 2:
      weekdayStr = "tue";
      break;
    case 3:
      weekdayStr = "wed";
      break;
    case 4:
      weekdayStr = "thu";
      break;
    case 5:
      weekdayStr = "fri";
      break;
    case 6:
      weekdayStr = "sat";
      break;
    default:
      weekdayStr = "";
  }
  return weekdayStr;
}

export function getWeekday(){
  var weekdayStr = "";
  var weekday = new Date().getDay();
  switch(weekday){
    case 0:
      weekdayStr = "Sun";
      break;
    case 1:
      weekdayStr = "Mon";
      break;
    case 2:
      weekdayStr = "Tue";
      break;
    case 3:
      weekdayStr = "Wed";
      break;
    case 4:
      weekdayStr = "Thu";
      break;
    case 5:
      weekdayStr = "Fri";
      break;
    case 6:
      weekdayStr = "Sat";
      break;
    default:
      weekdayStr = "Error";
  }
  return weekdayStr;
}

export function formatPrice(price) {
  return (Math.round((price + Number.EPSILON) * 100) / 100).toFixed(2);
}
