import React, { Component } from "react";
import { connect } from "react-redux";
import { checkAuth } from "../redux/actions";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ContactUs from "../pages/ContactUs";
import Cart from "../pages/Cart";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsOfUse from "../pages/TermsOfUse";
import Store from "../pages/Store";
import Login from "../pages/Login";
import User from "../pages/User";
import Register from "../pages/Register";
import OrderHistory from "../pages/OrderHistory";
import ScrollToTop from "../components/ScrollToTop"
import { withTheme, ThemeProvider, themes } from "../styles/theming";
import Footer from "../components/Footer";

class AppRouting extends Component {
  componentDidMount = () => {
    this.props.checkAuth();
  };
  render() {
    const themeId = this.props.store.themeId;
    return (
      <ThemeProvider theme={themeId ? themes[themeId] : themes[0]}>
        <BrowserRouter >
          <div>
            <ScrollToTop/>
            <Switch>
              <Route exact path="/store/:id" component={Store} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/user" component={User} />
              <Route exact path="/orderHistory" component={OrderHistory} />
              <Route exact path="/cart" component={Cart} />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route exact path="/terms-of-use" component={TermsOfUse} />
              <Route exact path="/contact-us" component={ContactUs} />
            </Switch>
          {!this.props.store.kioskService && <Footer />}
          </div>
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}

const reduxStateToProps = state => {
  return {
    store: state.storeState.store
  };
};

export default withTheme(connect(reduxStateToProps, { checkAuth })(AppRouting));
