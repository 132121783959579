import {
  SET_CARRIERS,
  SET_STATES,
  SET_CREDIT_CARD_TYPES,
  SHOW_LOADING,
  HIDE_LOADING
} from "../actions/types";
import { createReducer } from "redux-starter-kit";

const DEFAULT_STATE = {
  states: [],
  loading: false
};

export default createReducer(DEFAULT_STATE, {
  [SET_CARRIERS]: (state, action) => {
    state.carriers = action.payload;
  },
  [SET_STATES]: (state, action) => {
    state.states = action.payload;
  },
  [SET_CREDIT_CARD_TYPES]: (state, action) => {
    state.creditCardTypes = action.payload;
  },
  [SHOW_LOADING]: state => {
    state.loading = true;
  },
  [HIDE_LOADING]: state => {
    state.loading = false;
  }
});
