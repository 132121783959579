import { PUBLIC_1_0 } from "../../config/config";
import { 
  SET_STORE, 
  CLEAR_STORE, 
  SET_ACCEPTABLE_CC_TYPES, 
  SET_ACCEPTABLE_KIOSK_PAYMENT_TYPES 
} from "./types";

import { getOption, postOption } from "./utils";

export const getStoreByUrlName = urlName => {
  return async dispatch => {
    const uri = `${PUBLIC_1_0}/organization/store/urlName/${urlName}`;
    const options = getOption();

    try {
      const request = new Request(uri, options);
      const response = await fetch(request);
      const store = await response.json();

      dispatch({ type: SET_STORE, payload: store });

      return store;
    } catch (e) {
      return {};
    }
  };
};

export const getStoreAcceptableCCTypes = storeId => {
  return async dispatch => {
    const uri = `${PUBLIC_1_0}/organization/store/acceptableCCType?storeId=${storeId}`;
    let options = getOption();

    try {
      const request = new Request(uri, options);
      const response = await fetch(request);
      const acceptableCCTypes = await response.json();

      dispatch({ type: SET_ACCEPTABLE_CC_TYPES, payload: acceptableCCTypes });

      return acceptableCCTypes;
    } catch (e) {
      return [];
    }
  };
};

export const getStoreAcceptableKioskPaymentTypes = storeId => {
  return async dispatch => {
    const uri = `${PUBLIC_1_0}/organization/store/acceptableKioskPaymentType?storeId=${storeId}`;
    let options = getOption();

    try {
      const request = new Request(uri, options);
      const response = await fetch(request);
      const acceptableKioskPaymentTypes = await response.json();

      dispatch({ type: SET_ACCEPTABLE_KIOSK_PAYMENT_TYPES, payload: acceptableKioskPaymentTypes });

      return acceptableKioskPaymentTypes;
    } catch (e) {
      return [];
    }
  };
};

export const getValidDeliveryAddresses = async (storeId, address) => {
  const uri = `${PUBLIC_1_0}/order/validateDeliveryAddress/${storeId}`;
  const options = postOption(JSON.stringify(address), {
    "Content-Type": "application/json"
  });

  try {
    const request = new Request(uri, options);
    const response = await fetch(request);
    const isValid = await response.json();

    return isValid;
  } catch (e) {
    return false;
  }
};

export const setStore = store => ({
  type: SET_STORE,
  payload: store
});

export const clearStore = () => ({
  type: CLEAR_STORE
});
