import React, { Component } from "react";
import { connect } from "react-redux";
import { validateOrder } from "../redux/actions";
import { Modal, Button } from "react-bootstrap";
import { StyleSheet, css } from "aphrodite";
import { withTheme } from "../styles/theming";
import "../styles/MenuItemCard.css";

class MenuItemCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMouseInside: false,
      modalShow: false,
      stopRemove: true,
    };
  }

  modalClose = () => { 
    const { order, index, validateOrder } = this.props;
    let lineItem = { ...order.lineItemGroups[0].lineItems[index] };
    lineItem.quantity=1
    this.setState({ modalShow: false });
  }

  removeItem = () => {
    const { order, index, validateOrder } = this.props;
    const { stopRemove } = this.state;

    if (stopRemove) {
      this.setState({ stopRemove: false });
      let lineItems = [...order.lineItemGroups[0].lineItems];

      lineItems.splice(index, 1);

      const newOrder = { ...order, lineItemGroups: [{ lineItems: lineItems }] };

      validateOrder(newOrder);

      const that = this;

      setTimeout(function() {
        that.setState({ stopRemove: true });
      }, 1500);
    }
  };

  changeQuantity = change => {
    const { order, index, validateOrder } = this.props;
    let lineItem = { ...order.lineItemGroups[0].lineItems[index] };
    change > 0 ? lineItem.quantity++ : lineItem.quantity--;
    if (lineItem.quantity < 1) {
      this.setState({ modalShow: true });      
    }
    if (lineItem.quantity <= 1) {
      lineItem.quantity = 1;
    }
    let lineItems = [...order.lineItemGroups[0].lineItems];
    lineItems[index] = lineItem;

    const newOrder = { ...order, lineItemGroups: [{ lineItems: lineItems }] };

    validateOrder(newOrder);
  };
  mouseEnter = () => {
    this.setState({ isMouseInside: true });
  };

  mouseLeave = () => {
    this.setState({ isMouseInside: false });
  };

  renderModifiersOfModifiers = modifiers => {
    const {theme} =this.props;
    if (modifiers !== undefined) {
      return modifiers
        .map(modifier => modifier.modifier)
        .map((modifier, i) => {
          return (
            <div className="second-level-modifier" key={i}
              style={{color: theme.cartContent, fontFamily: theme.fontRegular}}>
              {modifier.displayName +
                (modifier.ignorePrice
                  ? ""
                  : " - $" + modifier.price.toFixed(2))}
            </div>
          );
        });
    }
  };

  renderModifiers = modifiers => {
    const {theme} = this.props;
    if (modifiers !== undefined) {
      return modifiers.map((modifier, i) => {
        return (
          <div key={i}>
            <div style={{color: theme.cartContent, fontFamily: theme.fontRegular}}>
              {modifier.modifier.displayName +
                (modifier.modifier.ignorePrice
                  ? ""
                  : " - $" + modifier.modifier.price.toFixed(2))}
            </div>
            {this.renderModifiersOfModifiers(modifier.lineItemModifiers)}
          </div>
        );
      });
    }
  };
  render() {
    const { lineItem, theme, order } = this.props;
    const creditCardProgram = order.store.onlineOrderProgram.creditCardProgram;
    const themeStyles = StyleSheet.create({
      qtyBtn: {
        backgroundColor: "transparent",
        color: theme.cartItemAmountBtnSymbol,
        borderColor: theme.cartItemAmountBtnSymbol,
        fontFamily: theme.fontMedium,
        paddingBottom: "0.25vw",
        ":hover": {
          backgroundColor: theme.cartItemAmountBtnHoverBgd,
          color: theme.cartItemAmountBtnHoverSymbol,
          borderColor: theme.cartItemAmountBtnHoverBgd,
        }
      },
      btn:{
        color: theme.btnText,
        borderColor: "transparent",
        backgroundColor: theme.btnBgd,
        fontFamily: theme.fontMedium,
        ":hover": {
          outline: "none",
          boxShadow: "none",
        },
        ":focus": {
          outline: "none",
          boxShadow: "none",
        }
      }
    });
    let subtotal = 0;
    if(creditCardProgram == "dualPrice" && !order.kioskName && !order.tableName) {
      subtotal = lineItem.ccPriceWithModifier * lineItem.quantity;
    } else {
      subtotal = lineItem.priceWithModifier * lineItem.quantity;
    }

    return (
      <div className="menu-item-card">
        <span className="name" 
          style={{color: theme.cardContent, fontFamily: theme.fontMedium }}>
          {lineItem.item.displayName}
        </span>
        <div className="qty">
          <Button
            variant="secondary"
            className={"minus " +css(themeStyles.qtyBtn)}
            onClick={() => this.changeQuantity(-1)}>
            －
          </Button>
          <span className="number" 
            style={{color:theme.cartContent, fontFamily: theme.fontMedium}}>
            {lineItem.quantity.toFixed(0)}
          </span>
          <Button
            variant="secondary"
            className={"plus " +css(themeStyles.qtyBtn)}
            onClick={() => this.changeQuantity(1)}>
            ＋
          </Button>
        </div>
        <div className="price"
          style={{color: theme.cardContent, fontFamily: theme.fontMedium, }}>
          ${subtotal.toFixed(2) || "n.a."} 
        </div>
        <Modal show={this.state.modalShow} onHide={this.modalClose}>
          <div className="modal-whole" 
            style={{
              backgroundColor: theme.modifierBgd,
              fontFamily: theme.fontBold,
              borderRadius: "10px",
              borderColor: "transparent",
            }}>
            <Modal.Header>
              <Modal.Title 
                style={{
                  color: theme.modifierModalPrice,
                  fontFamily: theme.fontBold,
                }}>
                Remove Item
              </Modal.Title>
            </Modal.Header>
            <Modal.Body 
              style={{
                color: theme.modifierModalContent,
                fontFamily: theme.fontMedium,
              }}>
              Are you sure you want to remove this item?
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.modalClose}
                className={css(themeStyles.btn)}>
                Cancel
              </Button>
              <Button onClick={this.removeItem} variant="primary"
                className={css(themeStyles.btn)}>
                OK
              </Button>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    );
  };
}

const reduxStateToProps = state => {
  return {
    order: state.cartState.order
  };
};

export default withTheme(connect(reduxStateToProps, { validateOrder })(MenuItemCard));
