// prod
// ECS
  export const PUBLIC_1_0 =
    "https://oo-salsa-prod.hotsaucepos.com/salsa/api/rest/p/v1.0";
  export const S_1_0 =
    "https://oo-salsa-prod.hotsaucepos.com/salsa/api/rest/s/v1.0";
  export const S_1_1 =
    "https://oo-salsa-prod.hotsaucepos.com/salsa/api/rest/s/v1.1";

  export const FirebaseConfig = {
    apiKey: "AIzaSyDLOqDjHop_jWNKPO1NG13SqRg0l7fYKZQ",
    authDomain: "online-order-production.firebaseapp.com",
    databaseURL: "https://online-order-production.firebaseio.com",
    projectId: "online-order-production",
    storageBucket: "online-order-production.appspot.com",
    messagingSenderId: "528089983100",
    appId: "1:528089983100:web:90a81e757080f66c3d01f9",
    measurementId: "G-ZSF8EKCECL"
  };

