import { createConfirmation } from "react-confirm";
import PaymentSetting from "../components/PaymentSetting";
import PaymentOneTime from "../components/PaymentOneTime";
import Confirmation from "../components/Confirmation";
import ExistingPaymentConfirmation from "../components/ExistingPaymentConfirmation";
import EmailSetting from "../components/EmailSetting";
import PasswordSetting from "../components/PasswordSetting";
import PersonalInfoSetting from "../components/PersonalInfoSetting";
import ContactInfoSetting from "../components/ContactInfoSetting";
import PhoneInfoSetting from "../components/PhoneInfoSetting";
import PaymentMethodConfirmation from "../components/PaymentMethodConfirmation";
import CreditCardTypeConfirmation from "../components/CreditCardTypeConfirmation";
import SwipeCardConfirmation from "../components/SwipeCardConfirmation";
// create confirm function
const defaultConfirmation = createConfirmation(Confirmation);

export function confirm(confirmation, singleButton, options = {}) {
  return defaultConfirmation({ confirmation, singleButton, ...options });
}

export const confirmPayment = createConfirmation(PaymentSetting);
export const existingPaymentConfirmation = createConfirmation(
  ExistingPaymentConfirmation
);
export const paymentMethodConfirmation = createConfirmation(
  PaymentMethodConfirmation
);
export const creditCardTypeConfirmation = createConfirmation(
  CreditCardTypeConfirmation
);
export const swipeCardConfirmation = createConfirmation(
  SwipeCardConfirmation
);
export const confirmPaymentOneTime = createConfirmation(PaymentOneTime);
export const confirmEmail = createConfirmation(EmailSetting);
export const confirmPassword = createConfirmation(PasswordSetting);
export const confirmPersonalInfo = createConfirmation(PersonalInfoSetting);
export const confirmContactInfo = createConfirmation(ContactInfoSetting);
export const confirmPhoneInfo = createConfirmation(PhoneInfoSetting);