import { combineReducers } from "redux";

//Reducers ----------------------
import storeReducer from "./storeReducer";
import menuReducer from "./menuReducer";
import customerReducer from "./customerReducer";
import adminReducer from "./adminReducer";
import cartReducer from "./cartReducer";
// ------------------------------

const Reducers = combineReducers({
  storeState: storeReducer,
  menuState: menuReducer,
  customerState: customerReducer,
  adminState: adminReducer,
  cartState: cartReducer
});

export default Reducers;
