import {
  createTheming
} from "@callstack/react-theme-provider";

export const themes = [{
  pageBgd: "#F7F6F5",
  primary: "#EA6A43",
  restaurantName: "#FFFFFF",
  restaurantInfo: "#FFFFFF",
  bannerIcon: "#EA6A43",
  content: "#222222",
  centerCategoryTitle: "#EA6A43",

  /* Login Page */
  loginLeftBgd: "#FFFFFF",
  loginLeftTitle: "#EA6A43",
  loginLeftContent: "#6A6A6A",
  loginLeftBtnBgd: "#EA6A43",
  loginLeftBtnText: "#FFFFFF",

  loginRightBgd: "#EA6A43",
  loginRightTitle: "#FFFFFF",
  loginRightBtnBgd: "#F08D6F",
  loginRightBtnText: "#FFFFFF",

  /* Order History Page */
  orderHistoryDate: "#EA6A43",
  orderHistoryDateSelect: "#222222",

  /* Store Page - category & menuCard, 
     OrderHistory Page - orderHistoryCard */
  cardHeader: "#F0F0F0",
  cardHeaderBgd: "#EA6A43",
  cardTableNum: "#FFFFFF",
  cardBgd: "#FFFFFF",
  cardContent: "#222222",

  /* Cart Page */
  cartTitle: "#000000",
  cartSubTitle: "#EA6A43",
  cartContent: "#2F2F2F",
  cartDecorateLine: "#DB714E",
  cartFunctionBtnTitle: "#6A6A6A",
  cartFunctionBtnBgd: "#F5F5F5",

  /* Cart Page - CartItemCard */
  cartItemTitle: "#000000",
  cartItemBgd: "#FFFFFF",
  cartItemDescription: "#6A6A6A",
  cartItemModifier: "#6A6A6A",
  cartItemAmount: "#000000",
  cartItemAmountBtnBgd: "#FFFFFF",
  cartItemAmountBtnSymbol: "#EA6A43",
  cartItemAmountBtnHoverBgd: "#EA6A43",
  cartItemAmountBtnHoverSymbol: "#FFFFFF",
  cartTotalAmountText: "#EA6A43",
  cartTotalAmountBgd: "#E6E6E6",

  /* Store Page - orderSummary of menuCard
     Cart Page - orderSummary of orderCard */
  orderSummaryTitle: "#EA6A43",
  orderSummaryContent: "#222222",

  /* Store Page - dineIn mode in mobile
     Cart Page - dineIn mode in mobile */
  tableMobileText: "#222222",
  tableMobileNum: "#EA6A43",

  /* HistoryItemCard & (Menu)ItemCard */
  menuCardBgd: "#FFFFFF",
  menuCardTitle: "#000000",
  menuCardTableTitle: "#6A6A6A",
  menuCardPrice: "#EA6A43",
  menuCardPicBgd: "#E6E6E6",
  menuCardPicIcon: "#EA6A43",
  menuCardContent: "#EA6A43",
  menuCardDescription: "#6A6A6A",

  /* MenuItemModifier */
  modifierBgd: "#FFFFFF",
  modifierModalTitle: "#000000",
  modifierModalPrice: "#EA6A43",
  modifierModalDividLine: "#EA6A43",
  modifierModalWarning: "#909090",
  modifierModalDescription: "#6A6A6A",
  modifierModalContent: "#3B3B3B",

  /* All to Pay or proceed button */
  btnBgd: "#EA6A43",
  btnText: "#FFFFFF",

  /* Nav - DropdownButton */
  btnNavBgd: "#F7F4F4",
  btnNavText: "#000000",
  btnNavHoverBgd: "#FF9777",

  /* Cart Page - Tip buttons */
  btnTipText: "#000000",
  btnTipBorder: "#B1B1B1",
  btnTipBgd: "#FFFFFF",
  btnTipHoverText: "#FFFFFF",
  btnTipHoverBgd: "#F08D6F",

  /* All placeholders on 
    Users Setting Page, Payment Modal and Register Page */
  placeholderTitle: "#3B3B3B",
  placeholderBgd: "#F7F6F5",
  placeholderSelectBorder: "#EA6A43",
  placeholderText: "#222222",

  fontBold: "HelveticaNeueBold",
  fontLight: "HelveticaNeueLight",
  fontMedium: "HelveticaNeueMedium",
  fontRegular: "HelveticaNeueRegular",
  fontBlack: "HelveticaNeueCondensedBlack",
  fontImpact: "Impact",
  fontRestaurant: "Impact",

  bannerImg: 'url("/img/home-background01.png")',
}, {
  pageBgd: "#D9E3E5",
  primary: "#5A97B5",
  restaurantName: "#5A97B5",
  restaurantInfo: "#FFFFFF",
  bannerIcon: "#5A97B5",
  content: "#71635E",
  centerCategoryTitle: "#DDBF6D",

  /* Login Page */
  loginLeftBgd: "#FFFFFF",
  loginLeftTitle: "#71635E",
  loginLeftContent: "#71635E",
  loginLeftBtnBgd: "#E0C681",
  loginLeftBtnText: "#FFFFFF",

  loginRightBgd: "#5A97B5",
  loginRightTitle: "#E0C681",
  loginRightBtnBgd: "#B9DBE2",
  loginRightBtnText: "#5A97B5",

  /* Order History Page */
  orderHistoryDate: "#DDBF6D",
  orderHistoryDateSelect: "#71635E",

  /* Store Page - category & menuCard, 
     OrderHistory Page - orderHistoryCard */
  cardHeader: "#D5BC78",
  cardHeaderBgd: "#5A97B5",
  cardTableNum: "#FFFFFF",
  cardBgd: "#FFFFFF",
  cardContent: "#71635E",

  /* Cart Page */
  cartTitle: "#71635E",
  cartSubTitle: "#5A97B5",
  cartContent: "#71635E",
  cartDecorateLine: "#5A97B5",
  cartFunctionBtnTitle: "#71635E",
  cartFunctionBtnBgd: "#E9EDEF",

  /* Cart Page - CartItemCard */
  cartItemTitle: "#71635E",
  cartItemBgd: "#FFFFFF",
  cartItemDescription: "#71635E",
  cartItemModifier: "#71635E",
  cartItemAmount: "#71635E",
  cartItemAmountBtnBgd: "#FFFFFF",
  cartItemAmountBtnSymbol: "#5A97B5",
  cartItemAmountBtnHoverBgd: "#5A97B5",
  cartItemAmountBtnHoverSymbol: "#FFFFFF",
  cartTotalAmountText: "#71635E",
  cartTotalAmountBgd: "#D0D7D9",

  /* Store Page - orderSummary of menuCard
     Cart Page - orderSummary of orderCard */
  orderSummaryTitle: "#5A97B5",
  orderSummaryContent: "#71635E",

  /* Store Page - dineIn mode in mobile
     Cart Page - dineIn mode in mobile */
  tableMobileText: "#71635E",
  tableMobileNum: "#5A97B5",

  /* HistoryItemCard & (Menu)ItemCard */
  menuCardBgd: "#FFFFFF",
  menuCardTitle: "#71635E",
  menuCardTableTitle: "#E0C681",
  menuCardPrice: "#76AAC4",
  menuCardPicBgd: "#76AAC4",
  menuCardPicIcon: "#E0C681",
  menuCardContent: "#E0C681",
  menuCardDescription: "#71635E",

  /* MenuItemModifier */
  modifierBgd: "#FFFFFF",
  modifierModalTitle: "#71635E",
  modifierModalPrice: "#6796B2",
  modifierModalDividLine: "#E0C681",
  modifierModalWarning: "#909090",
  modifierModalDescription: "#71635E",
  modifierModalContent: "#71635E",

  /* All to Pay or proceed button */
  btnBgd: "#E0C681",
  btnText: "#FFFFFF",

  /* Nav - DropdownButton */
  btnNavBgd: "#EAEDED",
  btnNavText: "#71635E",
  btnNavHoverBgd: "#B9DBE2",

  /* Cart Page - Tip buttons */
  btnTipText: "#71635E",
  btnTipBorder: "#B1B1B1",
  btnTipBgd: "#FFFFFF",
  btnTipHoverText: "#71635E",
  btnTipHoverBgd: "#B9DBE2",

  /* All placeholders on 
    Users Setting Page, Payment Modal and Register Page */
  placeholderTitle: "#71635E",
  placeholderBgd: "#FFFFFF",
  placeholderSelectBorder: "#5A97B5",
  placeholderText: "#8A6D53",

  fontBold: "HelveticaNeueBold",
  fontLight: "HelveticaNeueLight",
  fontMedium: "HelveticaNeueMedium",
  fontRegular: "HelveticaNeueRegular",
  fontBlack: "HelveticaNeueCondensedBlack",
  fontImpact: "Impact",
  fontRestaurant: "Impact",

  bannerImg: 'url("/img/home-background02.png")',
}, {
  pageBgd: "#F3E2D3",
  primary: "#2D3239",
  restaurantName: "#DECBB9",
  restaurantInfo: "#FFFFFF",
  bannerIcon: "#9A7E63",
  content: "#8A6D53",
  centerCategoryTitle: "#2D3239",

  /* Login Page */
  loginLeftBgd: "#FFF4EA",
  loginLeftTitle: "#2D3239",
  loginLeftContent: "#2D3239",
  loginLeftBtnBgd: "#2D3239",
  loginLeftBtnText: "#E3C9AF",

  loginRightBgd: "#2D3239",
  loginRightTitle: "#9A7E63",
  loginRightBtnBgd: "#EAC7A5",
  loginRightBtnText: "#2D3239",

  /* Order History Page */
  orderHistoryDate: "#2D3239",
  orderHistoryDateSelect: "#8A6D53",

  /* Store Page - category & menuCard, 
     OrderHistory Page - orderHistoryCard */
  cardHeader: "#9A7E63",
  cardHeaderBgd: "#2D3239",
  cardTableNum: "#FFF4EA",
  cardBgd: "#FFF4EA",
  cardContent: "#1E150F",

  /* Cart Page */
  cartTitle: "#2D3239",
  cartSubTitle: "#9A7E63",
  cartContent: "#866E57",
  cartDecorateLine: "#9A7E63",
  cartFunctionBtnTitle: "#71635E",
  cartFunctionBtnBgd: "#F0E7DE",

  /* Cart Page - CartItemCard */
  cartItemTitle: "#2D3239",
  cartItemBgd: "#FFF4EA",
  cartItemDescription: "#2D3239",
  cartItemModifier: "#71635E",
  cartItemAmount: "#2D3239",
  cartItemAmountBtnBgd: "#FFF4EA",
  cartItemAmountBtnSymbol: "#967F67",
  cartItemAmountBtnHoverBgd: "#967F67",
  cartItemAmountBtnHoverSymbol: "#FFF4EA",
  cartTotalAmountText: "#2D3239",
  cartTotalAmountBgd: "#CCC1B6",

  /* Store Page - orderSummary of menuCard
     Cart Page - orderSummary of orderCard */
  orderSummaryTitle: "#2D3239",
  orderSummaryContent: "#2D3239",

  /* Store Page - dineIn mode in mobile
     Cart Page - dineIn mode in mobile */
  tableMobileText: "#967F67",
  tableMobileNum: "#2D3239",

  /* HistoryItemCard & (Menu)ItemCard */
  menuCardBgd: "#FFF4EA",
  menuCardTitle: "#2D3239",
  menuCardTableTitle: "#8A6D53",
  menuCardPrice: "#8A6D53",
  menuCardPicBgd: "#2D3239",
  menuCardPicIcon: "#9A7E63",
  menuCardContent: "#9A7E63",
  menuCardDescription: "#8A6D53",

  /* MenuItemModifier */
  modifierBgd: "#FFF4EA",
  modifierModalTitle: "#2D3239",
  modifierModalPrice: "#967F67",
  modifierModalDividLine: "#967F67",
  modifierModalWarning: "#909090",
  modifierModalDescription: "#2D3239",
  modifierModalContent: "#1E150F",

  /* All to Pay or proceed button */
  btnBgd: "#2D3239",
  btnText: "#E5C9AF",

  /* Nav - DropdownButton */
  btnNavBgd: "#F0E7DE",
  btnNavText: "#2D3239",
  btnNavHoverBgd: "#E5C9AF",

  /* Cart Page - Tip buttons */
  btnTipText: "#2D3239",
  btnTipBorder: "#B1B1B1",
  btnTipBgd: "#FFF4EA",
  btnTipHoverText: "#2D3239",
  btnTipHoverBgd: "#EAC7A5",

  /* All placeholders on 
    Users Setting Page, Payment Modal and Register Page */
  placeholderTitle: "#2D3239",
  placeholderBgd: "#FFF4EA",
  placeholderSelectBorder: "#8A6D53",
  placeholderText: "#2D3239",

  fontBold: "HelveticaNeueBold",
  fontLight: "HelveticaNeueLight",
  fontMedium: "HelveticaNeueMedium",
  fontRegular: "HelveticaNeueRegular",
  fontBlack: "HelveticaNeueCondensedBlack",
  fontImpact: "Impact",
  fontRestaurant: "Impact",

  bannerImg: 'url("/img/home-background03.png")',
}, {
  pageBgd: "#C2D8C2",
  primary: "#599850",
  restaurantName: "#FFFFFF",
  restaurantInfo: "#FFFFFF",
  bannerIcon: "#E6BA4E",
  content: "#000000",
  centerCategoryTitle: "#599850",

  /* Login Page */
  loginLeftBgd: "#FFFFFF",
  loginLeftTitle: "#000000",
  loginLeftContent: "#000000",
  loginLeftBtnBgd: "#E6BA4E",
  loginLeftBtnText: "#FFFFFF",

  loginRightBgd: "#599850",
  loginRightTitle: "#E6BA4E",
  loginRightBtnBgd: "#FCDB89",
  loginRightBtnText: "#599850",

  /* Order History Page */
  orderHistoryDate: "#000000",
  orderHistoryDateSelect: "#E6BA4E",

  /* Store Page - category & menuCard, 
     OrderHistory Page - orderHistoryCard */
  cardHeader: "#E6BA4E",
  cardHeaderBgd: "#599850",
  cardTableNum: "#FFFFFF",
  cardBgd: "#FFFFFF",
  cardContent: "#000000",

  /* Cart Page */
  cartTitle: "#000000",
  cartSubTitle: "#E6BA4E",
  cartContent: "#599850",
  cartDecorateLine: "#E0BB61",
  cartFunctionBtnTitle: "#599850",
  cartFunctionBtnBgd: "#D5DBD5",

  /* Cart Page - CartItemCard */
  cartItemTitle: "#000000",
  cartItemBgd: "#FFFFFF",
  cartItemDescription: "#000000",
  cartItemModifier: "#000000",
  cartItemAmount: "#000000",
  cartItemAmountBtnBgd: "#FFFFFF",
  cartItemAmountBtnSymbol: "#E6BA4E",
  cartItemAmountBtnHoverBgd: "#E6BA4E",
  cartItemAmountBtnHoverSymbol: "#FFFFFF",
  cartTotalAmountText: "#599850",
  cartTotalAmountBgd: "#D5DBD5",

  /* Store Page - orderSummary of menuCard
     Cart Page - orderSummary of orderCard */
  orderSummaryTitle: "#2D3239",
  orderSummaryContent: "#2D3239",

  /* Store Page - dineIn mode in mobile
     Cart Page - dineIn mode in mobile */
  tableMobileText: "#2D3239",
  tableMobileNum: "#599850",

  /* HistoryItemCard & (Menu)ItemCard */
  menuCardBgd: "#FFFFFF",
  menuCardTitle: "#000000",
  menuCardTableTitle: "#E0BB61",
  menuCardPrice: "#599850",
  menuCardPicBgd: "#599850",
  menuCardPicIcon: "#E0BB61",
  menuCardContent: "#E0BB61",
  menuCardDescription: "#599850",

  /* MenuItemModifier */
  modifierBgd: "#FFFFFF",
  modifierModalTitle: "#000000",
  modifierModalPrice: "#599850",
  modifierModalDividLine: "#E6BA4E",
  modifierModalWarning: "#909090",
  modifierModalDescription: "#2D3239",
  modifierModalContent: "#000000",

  /* All to Pay or proceed button */
  btnBgd: "#E6BA4E",
  btnText: "#FFFFFF",

  /* Nav - DropdownButton */
  btnNavBgd: "#C2D8C2",
  btnNavText: "#3B3B3B",
  btnNavHoverBgd: "#E6BA4E",

  /* Cart Page - Tip buttons */
  btnTipText: "#2D3239",
  btnTipBorder: "#B1B1B1",
  btnTipBgd: "#FFFFFF",
  btnTipHoverText: "#2D3239",
  btnTipHoverBgd: "#FCDB89",

  /* All placeholders on 
    Users Setting Page, Payment Modal and Register Page */
  placeholderTitle: "#3B3B3B",
  placeholderBgd: "#FFFFFF",
  placeholderSelectBorder: "#598650",
  placeholderText: "#3B3B3B",

  fontBold: "HelveticaNeueBold",
  fontLight: "HelveticaNeueLight",
  fontMedium: "HelveticaNeueMedium",
  fontRegular: "HelveticaNeueRegular",
  fontBlack: "HelveticaNeueCondensedBlack",
  fontImpact: "Impact",
  fontRestaurant: "Impact",

  bannerImg: 'url("/img/home-background04.png")',
}, {
  pageBgd: "#1F2943",
  primary: "#9D252A",
  restaurantName: "#9FA7BA",
  restaurantInfo: "#FFFFFF",
  bannerIcon: "#9D252A",
  content: "#909090",
  centerCategoryTitle: "#9D252A",

  /* Login Page */
  loginLeftBgd: "#2A3657",
  loginLeftTitle: "#9FA7BA",
  loginLeftContent: "#9FA7BA",
  loginLeftBtnBgd: "#9D252A",
  loginLeftBtnText: "#FFFFFF",

  loginRightBgd: "#253150",
  loginRightTitle: "#9D252A",
  loginRightBtnBgd: "#A9474B",
  loginRightBtnText: "#253150",

  /* Order History Page */
  orderHistoryDate: "#9FA7BA",
  orderHistoryDateSelect: "#596584",

  /* Store Page - category & menuCard, 
     OrderHistory Page - orderHistoryCard */
  cardHeader: "#9D252A",
  cardHeaderBgd: "#253150",
  cardTableNum: "#9FA7BA",
  cardBgd: "#2A3657",
  cardContent: "#CBCBCB",

  /* Cart Page */
  cartTitle: "#9FA7BA",
  cartSubTitle: "#9D252A",
  cartContent: "#9FA7BA",
  cartDecorateLine: "#9D252A",
  cartFunctionBtnTitle: "#9FA7BA",
  cartFunctionBtnBgd: "#1F2943",

  /* Cart Page - CartItemCard */
  cartItemTitle: "#9FA7BA",
  cartItemBgd: "#2A3657",
  cartItemDescription: "#9FA7BA",
  cartItemModifier: "#9FA7BA",
  cartItemAmount: "#9FA7BA",
  cartItemAmountBtnBgd: "#2A3657",
  cartItemAmountBtnSymbol: "#9D252A",
  cartItemAmountBtnHoverBgd: "#9D252A",
  cartItemAmountBtnHoverSymbol: "#2A3657",
  cartTotalAmountText: "#9D252A",
  cartTotalAmountBgd: "#9FA7BA",

  /* Store Page - orderSummary of menuCard
     Cart Page - orderSummary of orderCard */
  orderSummaryTitle: "#9FA7BA",
  orderSummaryContent: "#9FA7BA",

  /* Store Page - dineIn mode in mobile
     Cart Page - dineIn mode in mobile */
  tableMobileText: "#9D252A",
  tableMobileNum: "#9FA7BA",

  /* HistoryItemCard & (Menu)ItemCard */
  menuCardBgd: "#2A3657",
  menuCardTitle: "#9FA7BA",
  menuCardTableTitle: "#9D252A",
  menuCardPrice: "#9D252A",
  menuCardPicBgd: "#253150",
  menuCardPicIcon: "#9D252A",
  menuCardContent: "#9D252A",
  menuCardDescription: "#596584",

  /* MenuItemModifier */
  modifierBgd: "#2A3657",
  modifierModalTitle: "#9FA7BA",
  modifierModalPrice: "#9D252A",
  modifierModalDividLine: "#9D252A",
  modifierModalWarning: "#9FA7BA",
  modifierModalDescription: "#596584",
  modifierModalContent: "#9FA7BA",

  /* All to Pay or proceed button */
  btnBgd: "#9D252A",
  btnText: "#9FA7BA",

  /* Nav - DropdownButton */
  btnNavBgd: "#253150",
  btnNavText: "#9FA7BA",
  btnNavHoverBgd: "#AA484C",

  /* Cart Page - Tip buttons */
  btnTipText: "#9FA7BA",
  btnTipBorder: "#B1B1B1",
  btnTipBgd: "#2A3657",
  btnTipHoverText: "#9FA7BA",
  btnTipHoverBgd: "#AA484C",

  /* All placeholders on 
    Users Setting Page, Payment Modal and Register Page */
  placeholderTitle: "#CBCBCB",
  placeholderBgd: "#9FA7BA",
  placeholderSelectBorder: "#9D252A",
  placeholderText: "#253150",

  fontBold: "HelveticaNeueBold",
  fontLight: "HelveticaNeueLight",
  fontMedium: "HelveticaNeueMedium",
  fontRegular: "HelveticaNeueRegular",
  fontBlack: "HelveticaNeueCondensedBlack",
  fontImpact: "Impact",
  fontRestaurant: "Impact",

  bannerImg: 'url("/img/home-background05.png")',
}, {
  pageBgd: "#DEDBDB",
  primary: "#808080",
  restaurantName: "#FFFFFF",
  restaurantInfo: "#FFFFFF",
  bannerIcon: "#ABE3F9",
  content: "#808080",
  centerCategoryTitle: "#808080",

  /* Login Page */
  loginLeftBgd: "#EBEAEA",
  loginLeftTitle: "#808080",
  loginLeftContent: "#808080",
  loginLeftBtnBgd: "#FADC4F",
  loginLeftBtnText: "#808080",

  loginRightBgd: "#FADC4F",
  loginRightTitle: "#ABE3F9",
  loginRightBtnBgd: "#F6E79C",
  loginRightBtnText: "#808080",

  /* Order History Page */
  orderHistoryDate: "#808080",
  orderHistoryDateSelect: "#FADC4F",

  /* Store Page - category & menuCard, 
     OrderHistory Page - orderHistoryCard */
  cardHeader: "#808080",
  cardHeaderBgd: "#FADC4F",
  cardTableNum: "#FFFFFF",
  cardBgd: "#EBEAEA",
  cardContent: "#808080",

  /* Cart Page */
  cartTitle: "#808080",
  cartSubTitle: "#FADC4F",
  cartContent: "#808080",
  cartDecorateLine: "#FADC4F",
  cartFunctionBtnTitle: "#808080",
  cartFunctionBtnBgd: "#F6E79C",

  /* Cart Page - CartItemCard */
  cartItemTitle: "#808080",
  cartItemBgd: "#EBEAEA",
  cartItemDescription: "#808080",
  cartItemModifier: "#5D5D5D",
  cartItemAmount: "#808080",
  cartItemAmountBtnBgd: "#EBEAEA",
  cartItemAmountBtnSymbol: "#FADC4F",
  cartItemAmountBtnHoverBgd: "#FADC4F",
  cartItemAmountBtnHoverSymbol: "#EBEAEA",
  cartTotalAmountText: "#808080",
  cartTotalAmountBgd: "#F6E79C",

  /* Store Page - orderSummary of menuCard
     Cart Page - orderSummary of orderCard */
  orderSummaryTitle: "#808080",
  orderSummaryContent: "#808080",

  /* Store Page - dineIn mode in mobile
     Cart Page - dineIn mode in mobile */
  tableMobileText: "#F0C757",
  tableMobileNum: "#808080",

  /* HistoryItemCard & (Menu)ItemCard */
  menuCardBgd: "#EBEAEA",
  menuCardTitle: "#808080",
  menuCardTableTitle: "#FADC4F",
  menuCardPrice: "#F0C757",
  menuCardPicBgd: "#FADC4F",
  menuCardPicIcon: "#ABE3F9",
  menuCardContent: "#ABE3F9",
  menuCardDescription: "#5D5D5D",

  /* MenuItemModifier */
  modifierBgd: "#EBEAEA",
  modifierModalTitle: "#808080",
  modifierModalPrice: "#FADC4F",
  modifierModalDividLine: "#FADC4F",
  modifierModalWarning: "#909090",
  modifierModalDescription: "#5D5D5D",
  modifierModalContent: "#808080",

  /* All to Pay or proceed button */
  btnBgd: "#FADC4F",
  btnText: "#808080",

  /* Nav - DropdownButton */
  btnNavBgd: "#E0E0E0",
  btnNavText: "#808080",
  btnNavHoverBgd: "#F6E79C",

  /* Cart Page - Tip buttons */
  btnTipText: "#808080",
  btnTipBorder: "#808080",
  btnTipBgd: "#EBEAEA",
  btnTipHoverText: "#808080",
  btnTipHoverBgd: "#F6E79C",

  /* All placeholders on 
    Users Setting Page, Payment Modal and Register Page */
  placeholderTitle: "#808080",
  placeholderBgd: "#EBEAEA",
  placeholderSelectBorder: "#FADC4F",
  placeholderText: "#808080",

  fontBold: "HelveticaNeueBold",
  fontLight: "HelveticaNeueLight",
  fontMedium: "HelveticaNeueMedium",
  fontRegular: "HelveticaNeueRegular",
  fontBlack: "HelveticaNeueCondensedBlack",
  fontImpact: "Impact",
  fontRestaurant: "Impact",

  bannerImg: 'url("/img/home-background06.png")',
}, {
  pageBgd: "#EFBBB1",
  primary: "#808080",
  restaurantName: "#FFFFFF",
  restaurantInfo: "#FFFFFF",
  bannerIcon: "#DB6864",
  content: "#5D5D5D",
  centerCategoryTitle: "#5D5D5D",

  /* Login Page */
  loginLeftBgd: "#F4A59E",
  loginLeftTitle: "#DB6864",
  loginLeftContent: "#F2E0DC",
  loginLeftBtnBgd: "#DB6864",
  loginLeftBtnText: "#FFFFFF",

  loginRightBgd: "#DB6864",
  loginRightTitle: "#F2E0DC",
  loginRightBtnBgd: "#F8938F",
  loginRightBtnText: "#F2E0DC",

  /* Order History Page */
  orderHistoryDate: "#5D5D5D",
  orderHistoryDateSelect: "#DB6864",

  /* Store Page - category & menuCard, 
     OrderHistory Page - orderHistoryCard */
  cardHeader: "#F0CFC9",
  cardHeaderBgd: "#DB6864",
  cardTableNum: "#FFFFFF",
  cardBgd: "#F4A59E",
  cardContent: "#5D5D5D",

  /* Cart Page */
  cartTitle: "#5D5D5D",
  cartSubTitle: "#DB6864",
  cartContent: "#5D5D5D",
  cartDecorateLine: "#DB6864",
  cartFunctionBtnTitle: "#DB6864",
  cartFunctionBtnBgd: "#F2E0DC",

  /* Cart Page - CartItemCard */
  cartItemTitle: "#F2E0DC",
  cartItemBgd: "#F4A59E",
  cartItemDescription: "#5D5D5D",
  cartItemModifier: "#5D5D5D",
  cartItemAmount: "#5D5D5D",
  cartItemAmountBtnBgd: "#F4A59E",
  cartItemAmountBtnSymbol: "#DB6864",
  cartItemAmountBtnHoverBgd: "#DB6864",
  cartItemAmountBtnHoverSymbol: "#F4A59E",
  cartTotalAmountText: "#5D5D5D",
  cartTotalAmountBgd: "#F5C8C0",

  /* Store Page - orderSummary of menuCard
     Cart Page - orderSummary of orderCard */
  orderSummaryTitle: "#5D5D5D",
  orderSummaryContent: "#5D5D5D",

  /* Store Page - dineIn mode in mobile
     Cart Page - dineIn mode in mobile */
  tableMobileText: "#808080",
  tableMobileNum: "#D86864",

  /* HistoryItemCard & (Menu)ItemCard */
  menuCardBgd: "#F4A59E",
  menuCardTitle: "#F2E0DC",
  menuCardTableTitle: "#DB6864",
  menuCardPrice: "#DB6864",
  menuCardPicBgd: "#DB6864",
  menuCardPicIcon: "#F0CFC9",
  menuCardContent: "#F0CFC9",
  menuCardDescription: "#5D5D5D",

  /* MenuItemModifier */
  modifierBgd: "#EFBBB1",
  modifierModalTitle: "#F2E0DC",
  modifierModalPrice: "#DB6864",
  modifierModalDividLine: "#DB6864",
  modifierModalWarning: "#5D5D5D",
  modifierModalDescription: "#5D5D5D",
  modifierModalContent: "#5D5D5D",

  /* All to Pay or proceed button */
  btnBgd: "#DB6864",
  btnText: "#FFFFFF",

  /* Nav - DropdownButton */
  btnNavBgd: "#FDD2CE",
  btnNavText: "#5D5D5D",
  btnNavHoverBgd: "#F8938F",

  /* Cart Page - Tip buttons */
  btnTipText: "#DB6864",
  btnTipBorder: "#DB6864",
  btnTipBgd: "#F4A59E",
  btnTipHoverText: "#F2E0DC",
  btnTipHoverBgd: "#F8938F",

  /* All placeholders on 
    Users Setting Page, Payment Modal and Register Page */
  placeholderTitle: "#5D5D5D",
  placeholderBgd: "#F5C8C0",
  placeholderSelectBorder: "#DB6864",
  placeholderText: "#5D5D5D",

  fontBold: "HelveticaNeueBold",
  fontLight: "HelveticaNeueLight",
  fontMedium: "HelveticaNeueMedium",
  fontRegular: "HelveticaNeueRegular",
  fontBlack: "HelveticaNeueCondensedBlack",
  fontImpact: "Impact",
  fontRestaurant: "Impact",

  bannerImg: 'url("/img/home-background07.png")',
}, {
  pageBgd: "#022A3C",
  primary: "#8D8D8D",
  restaurantName: "#DAE6E4",
  restaurantInfo: "#DAE6E4",
  bannerIcon: "#DAE6E4",
  content: "#808080",
  centerCategoryTitle: "#808080",

  /* Login Page */
  loginLeftBgd: "#DAE6E4",
  loginLeftTitle: "#05344A",
  loginLeftContent: "#808080",
  loginLeftBtnBgd: "#05344A",
  loginLeftBtnText: "#FFFFFF",

  loginRightBgd: "#05344A",
  loginRightTitle: "#808080",
  loginRightBtnBgd: "#073C55",
  loginRightBtnText: "#808080",

  /* Order History Page */
  orderHistoryDate: "#808080",
  orderHistoryDateSelect: "#DAE6E4",

  /* Store Page - category & menuCard, 
     OrderHistory Page - orderHistoryCard */
  cardHeader: "#808080",
  cardHeaderBgd: "#05344A",
  cardTableNum: "#FFFFFF",
  cardBgd: "#DAE6E4",
  cardContent: "#808080",

  /* Cart Page */
  cartTitle: "#808080",
  cartSubTitle: "#DAE6E4",
  cartContent: "#808080",
  cartDecorateLine: "#DAE6E4",
  cartFunctionBtnTitle: "#808080",
  cartFunctionBtnBgd: "#073C55",

  /* Cart Page - CartItemCard */
  cartItemTitle: "#808080",
  cartItemBgd: "#DAE6E4",
  cartItemDescription: "#808080",
  cartItemModifier: "#808080",
  cartItemAmount: "#808080",
  cartItemAmountBtnBgd: "#DAE6E4",
  cartItemAmountBtnSymbol: "#05344A",
  cartItemAmountBtnHoverBgd: "#05344A",
  cartItemAmountBtnHoverSymbol: "#DAE6E4",
  cartTotalAmountText: "#8D8D8D",
  cartTotalAmountBgd: "#073C55",

  /* Store Page - orderSummary of menuCard
     Cart Page - orderSummary of orderCard */
  orderSummaryTitle: "#808080",
  orderSummaryContent: "#808080",

  /* Store Page - dineIn mode in mobile
     Cart Page - dineIn mode in mobile */
  tableMobileText: "#808080",
  tableMobileNum: "#DAE6E4",

  /* HistoryItemCard & (Menu)ItemCard */
  menuCardBgd: "#DAE6E4",
  menuCardTitle: "#808080",
  menuCardTableTitle: "#05344A",
  menuCardPrice: "#05344A",
  menuCardPicBgd: "#05344A",
  menuCardPicIcon: "#5D5D5D",
  menuCardContent: "#5D5D5D",
  menuCardDescription: "#5D5D5D",

  /* MenuItemModifier */
  modifierBgd: "#DAE6E4",
  modifierModalTitle: "#808080",
  modifierModalPrice: "#05344A",
  modifierModalDividLine: "#05344A",
  modifierModalWarning: "#909090",
  modifierModalDescription: "#5D5D5D",
  modifierModalContent: "#808080",

  /* All to Pay or proceed button */
  btnBgd: "#05344A",
  btnText: "#FFFFFF",

  /* Nav - DropdownButton */
  btnNavBgd: "#E7EFED",
  btnNavText: "#808080",
  btnNavHoverBgd: "#72B0CC",

  /* Cart Page - Tip buttons */
  btnTipText: "#5D5D5D",
  btnTipBorder: "#5D5D5D",
  btnTipBgd: "#DAE6E4",
  btnTipHoverText: "#5D5D5D",
  btnTipHoverBgd: "#72B0CC",

  /* All placeholders on 
    Users Setting Page, Payment Modal and Register Page */
  placeholderTitle: "#808080",
  placeholderBgd: "#DAE6E4",
  placeholderSelectBorder: "#05344A",
  placeholderText: "#808080",

  fontBold: "HelveticaNeueBold",
  fontLight: "HelveticaNeueLight",
  fontMedium: "HelveticaNeueMedium",
  fontRegular: "HelveticaNeueRegular",
  fontBlack: "HelveticaNeueCondensedBlack",
  fontImpact: "Impact",
  fontRestaurant: "Impact",

  bannerImg: 'url("/img/home-background08.png")',
}, {
  pageBgd: "#F0F0F0",
  primary: "#000000",
  restaurantName: "#FFFFFF",
  restaurantInfo: "#FFFFFF",
  bannerIcon: "#FFFFFF",
  content: "#2F2F2F",
  centerCategoryTitle: "#000000",

  /* Login Page */
  loginLeftBgd: "#FFFFFF",
  loginLeftTitle: "#000000",
  loginLeftContent: "#6A6A6A",
  loginLeftBtnBgd: "#289F72",
  loginLeftBtnText: "#FFFFFF",

  loginRightBgd: "#E6E6E6",
  loginRightTitle: "#FFFFFF",
  loginRightBtnBgd: "#5DCCA2",
  loginRightBtnText: "#FFFFFF",

  /* Order History Page */
  orderHistoryDate: "#000000",
  orderHistoryDateSelect: "#289F72",

  /* Store Page - category & menuCard, 
     OrderHistory Page - orderHistoryCard */
  cardHeader: "#000000",
  cardHeaderBgd: "#E5E5E5",
  cardTableNum: "#289F72",
  cardBgd: "#FFFFFF",
  cardContent: "#222222",

  /* Cart Page */
  cartTitle: "#000000",
  cartSubTitle: "#289F72",
  cartContent: "#2F2F2F",
  cartDecorateLine: "#289F72",
  cartFunctionBtnTitle: "#AAAAAA",
  cartFunctionBtnBgd: "#F5F5F5",

  /* Cart Page - CartItemCard */
  cartItemTitle: "#000000",
  cartItemBgd: "#FFFFFF",
  cartItemDescription: "#6A6A6A",
  cartItemModifier: "#6A6A6A",
  cartItemAmount: "#000000",
  cartItemAmountBtnBgd: "#FFFFFF",
  cartItemAmountBtnSymbol: "#289F72",
  cartItemAmountBtnHoverBgd: "#289F72",
  cartItemAmountBtnHoverSymbol: "#FFFFFF",
  cartTotalAmountText: "#000000",
  cartTotalAmountBgd: "#E6E6E6",

  /* Store Page - orderSummary of menuCard
     Cart Page - orderSummary of orderCard */
  orderSummaryTitle: "#000000",
  orderSummaryContent: "#222222",
  
  /* Store Page - dineIn mode in mobile
     Cart Page - dineIn mode in mobile */
  tableMobileText: "#000000",
  tableMobileNum: "#289F72",

  /* HistoryItemCard & (Menu)ItemCard */
  menuCardBgd: "#FFFFFF",
  menuCardTitle: "#000000",
  menuCardTableTitle: "#289F72",
  menuCardPrice: "#000000",
  menuCardPicBgd: "#E6E6E6",
  menuCardPicIcon: "#FFFFFF",
  menuCardContent: "#FFFFFF",
  menuCardDescription: "#6A6A6A",

  /* MenuItemModifier */
  modifierBgd: "#FFFFFF",
  modifierModalTitle: "#000000",
  modifierModalPrice: "#289F72",
  modifierModalDividLine: "#289F72",
  modifierModalWarning: "#909090",
  modifierModalDescription: "#6A6A6A",
  modifierModalContent: "#3B3B3B",

  /* All to Pay or proceed button */
  btnBgd: "#289F72",
  btnText: "#FFFFFF",

  /* Nav - DropdownButton */
  btnNavBgd: "#F7F4F4",
  btnNavText: "#000000",
  btnNavHoverBgd: "#5DCCA2",

  /* Cart Page - Tip buttons */
  btnTipText: "#000000",
  btnTipBorder: "#B1B1B1",
  btnTipBgd: "#FFFFFF",
  btnTipHoverText: "#FFFFFF",
  btnTipHoverBgd: "#5DCCA2",

  /* All placeholders on 
    Users Setting Page, Payment Modal and Register Page */
  placeholderTitle: "#000000",
  placeholderBgd: "#E6E6E6",
  placeholderSelectBorder: "#289F72",
  placeholderText: "#3B3B3B",

  fontBold: "HelveticaNeueBold",
  fontLight: "HelveticaNeueLight",
  fontMedium: "HelveticaNeueMedium",
  fontRegular: "HelveticaNeueRegular",
  fontBlack: "HelveticaNeueCondensedBlack",
  fontImpact: "Impact",
  fontRestaurant: "Impact",

  bannerImg: 'url("/img/home-background09.png")',
}, {
  pageBgd: "#000000",
  primary: "#8815BA",
  restaurantName: "#FFFFFF",
  restaurantInfo: "#FFFFFF",
  bannerIcon: "#9946B7",
  content: "#B2B2B2",
  centerCategoryTitle: "#FFFFFF",

  /* Login Page */
  loginLeftBgd: "#141313",
  loginLeftTitle: "#FFFFFF",
  loginLeftContent: "#FFFFFF",
  loginLeftBtnBgd: "#9946B7",
  loginLeftBtnText: "#FFFFFF",

  loginRightBgd: "#1D1D1D",
  loginRightTitle: "#9946B7",
  loginRightBtnBgd: "#9461A7",
  loginRightBtnText: "#1D1D1D",

  /* Order History Page */
  orderHistoryDate: "#FFFFFF",
  orderHistoryDateSelect: "#9946B7",

  /* Store Page - category & menuCard, 
     OrderHistory Page - orderHistoryCard */
  cardHeader: "#9946B7",
  cardHeaderBgd: "#1D1D1D",
  cardTableNum: "#FFFFFF",
  cardBgd: "#141313",
  cardContent: "#FFFFFF",

  /* Cart Page */
  cartTitle: "#FFFFFF",
  cartSubTitle: "#9946B7",
  cartContent: "#FFFFFF",
  cartDecorateLine: "#9946B7",
  cartFunctionBtnTitle: "#FFFFFF",
  cartFunctionBtnBgd: "#1F1F1F",

  /* Cart Page - CartItemCard */
  cartItemTitle: "#FFFFFF",
  cartItemBgd: "#141313",
  cartItemDescription: "#FFFFFF",
  cartItemModifier: "#B2B2B2",
  cartItemAmount: "#FFFFFF",
  cartItemAmountBtnBgd: "#141313",
  cartItemAmountBtnSymbol: "#9946B7",
  cartItemAmountBtnHoverBgd: "#9946B7",
  cartItemAmountBtnHoverSymbol: "#141313",
  cartTotalAmountText: "#FFFFFF",
  cartTotalAmountBgd: "#1F1F1F",

  /* Store Page - orderSummary of menuCard
     Cart Page - orderSummary of orderCard */
  orderSummaryTitle: "#FFFFFF",
  orderSummaryContent: "#FFFFFF",

  /* Store Page - dineIn mode in mobile
     Cart Page - dineIn mode in mobile */
  tableMobileText: "#9946B7",
  tableMobileNum: "#FFFFFF",

  /* HistoryItemCard & (Menu)ItemCard */
  menuCardBgd: "#141313",
  menuCardTitle: "#FFFFFF",
  menuCardTableTitle: "#9946B7",
  menuCardPrice: "#9946B7",
  menuCardPicBgd: "#1D1D1D",
  menuCardPicIcon: "#994687",
  menuCardContent: "#9946B7",
  menuCardDescription: "#B2B2B2",

  /* MenuItemModifier */
  modifierBgd: "#1D1D1D",
  modifierModalTitle: "#FFFFFF",
  modifierModalPrice: "#9946B7",
  modifierModalDividLine: "#9946B7",
  modifierModalWarning: "#B2B2B2",
  modifierModalDescription: "#B2B2B2",
  modifierModalContent: "#FFFFFF",

  /* All to Pay or proceed button */
  btnBgd: "#9946B7",
  btnText: "#FFFFFF",

  /* Nav - DropdownButton */
  btnNavBgd: "#242424",
  btnNavText: "#FFFFFF",
  btnNavHoverBgd: "#9461A7",

  /* Cart Page - Tip buttons */
  btnTipText: "#FFFFFF",
  btnTipBorder: "#FFFFFF",
  btnTipBgd: "#141313",
  btnTipHoverText: "#FFFFFF",
  btnTipHoverBgd: "#9461A7",

  /* All placeholders on 
    Users Setting Page, Payment Modal and Register Page */
  placeholderTitle: "#FFFFFF",
  placeholderBgd: "#141313",
  placeholderSelectBorder: "#9946B7",
  placeholderText: "#B2B2B2",

  fontBold: "HelveticaNeueBold",
  fontLight: "HelveticaNeueLight",
  fontMedium: "HelveticaNeueMedium",
  fontRegular: "HelveticaNeueRegular",
  fontBlack: "HelveticaNeueCondensedBlack",
  fontImpact: "Impact",
  fontRestaurant: "Impact",

  bannerImg: 'url("/img/home-background10.png")',
}, {
  pageBgd: "#F0F0F0",
  primary: "#152C55",
  restaurantName: "#FFFFFF",
  restaurantInfo: "#FFFFFF",
  bannerIcon: "#FFFFFF",
  content: "#B2B2B2",
  centerCategoryTitle: "#FFFFFF",

  /* Login Page */
  loginLeftBgd: "#FFFFFF",
  loginLeftTitle: "#000000",
  loginLeftContent: "#6A6A6A",
  loginLeftBtnBgd: "#152C55",
  loginLeftBtnText: "#FFFFFF",

  loginRightBgd: "#152C55",
  loginRightTitle: "#FFFFFF",
  loginRightBtnBgd: "#7287AC",
  loginRightBtnText: "#FFFFFF",

  /* Store Page-category & menucard, 
     OrderHistory Page- orderhistorycard */
  cardHeader: "#FFFFFF",
  cardHeaderBgd: "#001233",
  cardTableNum: "#E6E6E6",
  cardBgd: "#FFFFFF",
  cardContent: "#222222",

  /* Cart Page */
  cartTitle: "#000000",
  cartSubTitle: "#152C55",
  cartContent: "#2F2F2F",
  cartDecorateLine: "#325FAF",
  cartFunctionBtnTitle: "#FFFFFF",
  cartFunctionBtnBgd: "#1F1F1F",

  /* Cart Page - CartItemCard */
  cartItemTitle: "#000000",
  cartItemBgd: "#FFFFFF",
  cartItemDescription: "#6A6A6A",
  cartItemModifier: "#6A6A6A",
  cartItemAmount: "#000000",
  cartItemAmountBtnBgd: "#FFFFFF",
  cartItemAmountBtnSymbol: "#152C55",
  cartItemAmountBtnHoverBgd: "#152C55",
  cartItemAmountBtnHoverSymbol: "#FFFFFF",
  cartTotalAmountText: "#000000",
  cartTotalAmountBgd: "#E6E6E6",

  /* Store Page - orderSummary of menuCard
     Cart Page - orderSummary of orderCard */
  orderSummaryTitle: "#152C55",
  orderSummaryContent: "#000000",

  /* Order History Page */
  orderHistoryDate: "#001233",
  orderHistoryDateSelect: "#5A7DBC",

  /* Store Page - dineIn mode in mobile
     Cart Page - dineIn mode in mobile */
  tableMobileText: "#6A6A6A",
  tableMobileNum: "#152C55",

  /* HistoryItemCard & (Menu)ItemCard */
  menuCardBgd: "#FFFFFF",
  menuCardTitle: "#000000",
  menuCardTableTitle: "#152C55",
  menuCardPrice: "#001233",
  menuCardPicBgd: "#152C55",
  menuCardPicIcon: "#FFFFFF",
  menuCardContent: "#6A6A6A",
  menuCardDescription: "#B2B2B2",

  /* MenuItemModifier */
  modifierBgd: "#FFFFFF",
  modifierModalTitle: "#000000",
  modifierModalPrice: "#001233",
  modifierModalDividLine: "#FFFFFF",
  modifierModalWarning: "#909090",
  modifierModalDescription: "#6A6A6A",
  modifierModalContent: "#3B3B3B",

  /* All to Pay or proceed button */
  btnBgd: "#152C55",
  btnText: "#FFFFFF",

  /* Nav - DropdownButton */
  btnNavBgd: "#F7F4F4",
  btnNavText: "#000000",
  btnNavHoverBgd: "#7287AC",

  /* Cart Page - Tip buttons */
  btnTipText: "#000000",
  btnTipBorder: "#B1B1B1",
  btnTipBgd: "#FFFFFF",
  btnTipHoverText: "#FFFFFF",
  btnTipHoverBgd: "#5A7DBC",

  /* All placeholders on 
    Users Setting Page, Payment Modal and Register Page */
  placeholderTitle: "#001233",
  placeholderBgd: "#FFFFFF",
  placeholderSelectBorder: "#707070",
  placeholderText: "#000000",

  fontBold: "HelveticaNeueBold",
  fontLight: "HelveticaNeueLight",
  fontMedium: "HelveticaNeueMedium",
  fontRegular: "HelveticaNeueRegular",
  fontBlack: "HelveticaNeueCondensedBlack",
  fontImpact: "Impact",
  fontRestaurant: "AbrilFatface",

  bannerImg: 'url("/img/home-background11.png")',
}, {
  pageBgd: "#000000",
  primary: "#BE3E2D",
  restaurantName: "#FFFFFF",
  restaurantInfo: "#FFFFFF",
  bannerIcon: "#C95040",
  content: "#B2B2B2",
  centerCategoryTitle: "#FFFFFF",

  /* Login Page */
  loginLeftBgd: "#141313",
  loginLeftTitle: "#FFFFFF",
  loginLeftContent: "#FFFFFF",
  loginLeftBtnBgd: "#BE3E2D",
  loginLeftBtnText: "#FFFFFF",

  loginRightBgd: "#1D1D1D",
  loginRightTitle: "#BE3E2D",
  loginRightBtnBgd: "#BE3E2D",
  loginRightBtnText: "#1D1D1D",

  /* Store Page-category & menuCard, 
     OrderHistory Page- orderHistoryCard */
  cardHeader: "#C95040",
  cardHeaderBgd: "#1D1D1D",
  cardTableNum: "#FFFFFF",
  cardBgd: "#141313",
  cardContent: "#FFFFFF",

  /* Cart Page */
  cartTitle: "#FFFFFF",
  cartSubTitle: "#BE3E2D",
  cartContent: "#FFFFFF",
  cartDecorateLine: "#BE3E2D",
  cartFunctionBtnTitle: "#FFFFFF",
  cartFunctionBtnBgd: "#1F1F1F",

  /* Cart Page - CartItemCard */
  cartItemTitle: "#FFFFFF",
  cartItemBgd: "#141313",
  cartItemDescription: "#FFFFFF",
  cartItemModifier: "#B2B2B2",
  cartItemAmount: "#FFFFFF",
  cartItemAmountBtnBgd: "#141313",
  cartItemAmountBtnSymbol: "#BE3E2D",
  cartItemAmountBtnHoverBgd: "#BE3E2D",
  cartItemAmountBtnHoverSymbol: "#FFFFFF",
  cartTotalAmountText: "#FFFFFF",
  cartTotalAmountBgd: "#1F1F1F",

  /* Store Page - orderSummary of menuCard
     Cart Page - orderSummary of orderCard */
  orderSummaryTitle: "#FFFFFF",
  orderSummaryContent: "#FFFFFF",
  
  /* Order History Page */
  orderHistoryDate: "#FFFFFF",
  orderHistoryDateSelect: "#D06153",

  /* Store Page - dineIn mode in mobile
     Cart Page - dineIn mode in mobile */
  tableMobileText: "#FFFFFF",
  tableMobileNum: "#BE3E2D",

  /* HistoryItemCard & (Menu)ItemCard */
  menuCardBgd: "#141313",
  menuCardTitle: "#FFFFFF",
  menuCardTableTitle: "#FFFFFF",
  menuCardPrice: "#C95040",
  menuCardPicBgd: "#1D1D1D",
  menuCardPicIcon: "#C95040",
  menuCardContent: "#B2B2B2",
  menuCardDescription: "#B2B2B2",

  /* MenuItemModifier */
  modifierBgd: "#1D1D1D",
  modifierModalTitle: "#FFFFFF",
  modifierModalPrice: "#BE3E2D",
  modifierModalDividLine: "#1D1D1D",
  modifierModalWarning: "#B2B2B2",
  modifierModalDescription: "#B2B2B2",
  modifierModalContent: "#FFFFFF",

  /* All to Pay or proceed button */
  btnBgd: "#BE3E2D",
  btnText: "#FFFFFF",

  /* Nav - DropdownButton */
  btnNavBgd: "#242424",
  btnNavText: "#FFFFFF",
  btnNavHoverBgd: "#D06153",

  /* Cart Page - Tip buttons */
  btnTipText: "#FFFFFF",
  btnTipBorder: "#FFFFFF",
  btnTipBgd: "#141313",
  btnTipHoverText: "#FFFFFF",
  btnTipHoverBgd: "#D06153",

  /* All placeholders on 
    Users Setting Page, Payment Modal and Register Page */
  placeholderTitle: "#FFFFFF",
  placeholderBgd: "#141313",
  placeholderSelectBorder: "#9FA7BA",
  placeholderText: "#FFFFFF",

  fontBold: "HelveticaNeueBold",
  fontLight: "HelveticaNeueLight",
  fontMedium: "HelveticaNeueMedium",
  fontRegular: "HelveticaNeueRegular",
  fontBlack: "HelveticaNeueCondensedBlack",
  fontImpact: "Impact",
  fontRestaurant: "AbrilFatface",

  bannerImg: 'url("/img/home-background12.png")',
}, {
  pageBgd: "#FDF8F0",
  primary: "#A5432B",
  restaurantName: "#FFFFFF",
  restaurantInfo: "#FFFFFF",
  bannerIcon: "#FFFFFF",
  content: "#6A6A6A",
  centerCategoryTitle: "#222222",

  /* Login Page */
  loginLeftBgd: "#FAF7F2",
  loginLeftTitle: "#A5432B",
  loginLeftContent: "#6A6A6A",
  loginLeftBtnBgd: "#A5432B",
  loginLeftBtnText: "#FFFFFF",

  loginRightBgd: "#A5432B",
  loginRightTitle: "#FFFFFF",
  loginRightBtnBgd: "#B46451",
  loginRightBtnText: "#FFFFFF",

  /* Store Page - category & menuCard, 
     OrderHistory Page- orderHistoryCard */
  cardHeader: "#FFFFFF",
  cardHeaderBgd: "#A5432B",
  cardTableNum: "#F0F0F0",
  cardBgd: "#FDFAF2",
  cardContent: "#222222",

  /* Store Page - orderSummary of menuCard
     Cart Page - orderSummary of orderCard */
  orderSummaryTitle: "#222222",
  orderSummaryContent: "#222222",

  /* Store Page - dineIn mode in mobile
     Cart Page - dineIn mode in mobile */
  tableMobileText: "#222222",
  tableMobileNum: "#BC4123",

  /* Cart Page */
  cartTitle: "#222222",
  cartSubTitle: "#BC4123",
  cartContent: "#2F2F2F",
  cartDecorateLine: "#BC4123",
  cartFunctionBtnTitle: "#6A6A6A",
  cartFunctionBtnBgd: "#EFEBE1",
  cartTotalAmountText: "#222222",
  cartTotalAmountBgd: "#EFEBE1",

  /* Cart Page - CartItemCard */
  cartItemTitle: "#222222",
  cartItemBgd: "#FDFAF2",
  cartItemDescription: "#6A6A6A",
  cartItemModifier: "#6A6A6A",
  cartItemAmount: "#222222",
  cartItemAmountBtnBgd: "#FDFAF2",
  cartItemAmountBtnSymbol: "#BC4123",
  cartItemAmountBtnHoverBgd: "#BC4123",
  cartItemAmountBtnHoverSymbol: "#FDFAF2",

  /* Cart Page - Tip buttons */
  btnTipText: "#000000",
  btnTipBorder: "#B1B1B1",
  btnTipBgd: "#FDFAF2",
  btnTipHoverText: "#FFFFFF",
  btnTipHoverBgd: "#D1836F",

  /* Order History Page */
  orderHistoryDate: "#222222",
  orderHistoryDateSelect: "#D06153",

  /* HistoryItemCard & (Menu)ItemCard */
  menuCardBgd: "#FDFAF2",
  menuCardTitle: "#222222",
  menuCardTableTitle: "#BC4123",
  menuCardPrice: "#BC4123",
  menuCardPicBgd: "#A5432B",
  menuCardPicIcon: "#FDF8F0",
  menuCardContent: "#6A6A6A",
  menuCardDescription: "#6A6A6A",

  /* MenuItemModifier */
  modifierBgd: "#FFFFFF",
  modifierModalTitle: "#222222",
  modifierModalPrice: "#BC4123",
  modifierModalDividLine: "#FFFFFF",
  modifierModalWarning: "#909090",
  modifierModalDescription: "#909090",
  modifierModalContent: "#3B3B3B",

  /* All to Pay or proceed button */
  btnBgd: "#BC4123",
  btnText: "#FFFFFF",

  /* Nav - DropdownButton */
  btnNavBgd: "#FAF7F2",
  btnNavText: "#222222",
  btnNavHoverBgd: "#D1836F",

  /* All placeholders on 
    Users Setting Page, Payment Modal and Register Page */
  placeholderTitle: "#6A6A6A",
  placeholderBgd: "#FAF7F2",
  placeholderSelectBorder: "#707070",
  placeholderText: "#6A6A6A",

  fontBold: "HelveticaNeueBold",
  fontLight: "HelveticaNeueLight",
  fontMedium: "HelveticaNeueMedium",
  fontRegular: "HelveticaNeueRegular",
  fontBlack: "HelveticaNeueCondensedBlack",
  fontImpact: "Impact",
  fontRestaurant: "AbrilFatface",

  bannerImg: 'url("/img/home-background13.png")',
}, {
  pageBgd: "#F2EFEC",
  primary: "#212121",
  restaurantName: "#212121",
  restaurantInfo: "#212121",
  bannerIcon: "#212121",
  content: "#212121",
  centerCategoryTitle: "#212121",

  /* Login Page */
  loginLeftBgd: "#FFFFFF",
  loginLeftTitle: "#212121",
  loginLeftContent: "#6A6A6A",
  loginLeftBtnBgd: "#CBB69F",
  loginLeftBtnText: "#FFFFFF",

  loginRightBgd: "#212121",
  loginRightTitle: "#FFFFFF",
  loginRightBtnBgd: "#CBB69F",
  loginRightBtnText: "#212121",

  /* Store Page - category & menuCard, 
     OrderHistory Page - orderHistoryCard */
  cardHeader: "#F0F0F0",
  cardHeaderBgd: "#212121",
  cardTableNum: "#FAF7F4",
  cardBgd: "#FAF7F4",
  cardContent: "#222222",

  /* Store Page - orderSummary of menuCard
     Cart Page - orderSummary of orderCard */
  orderSummaryTitle: "#222222",
  orderSummaryContent: "#222222",

  /* Store Page - dineIn mode in mobile
     Cart Page - dineIn mode in mobile */
  tableMobileText: "#AD967D",
  tableMobileNum: "#222222",

  /* Cart Page */
  cartTitle: "#222222",
  cartSubTitle: "#222222",
  cartContent: "#2F2F2F",
  cartDecorateLine: "#212121",
  cartFunctionBtnTitle: "#AAAAAA",
  cartFunctionBtnBgd: "#EAE9E8",
  cartTotalAmountText: "#222222",
  cartTotalAmountBgd: "#EAE9E8",

  /* Cart Page - CartItemCard */
  cartItemTitle: "#222222",
  cartItemBgd: "#FAF7F4",
  cartItemDescription: "#6A6A6A",
  cartItemModifier: "#6A6A6A",
  cartItemAmount: "#222222",
  cartItemAmountBtnBgd: "#FAF7F4",
  cartItemAmountBtnSymbol: "#212121",
  cartItemAmountBtnHoverBgd: "#212121",
  cartItemAmountBtnHoverSymbol: "#FAF7F4",

  /* Cart Page - Tip buttons */
  btnTipText: "#000000",
  btnTipBorder: "#B1B1B1",
  btnTipBgd: "#FAF7F4",
  btnTipHoverText: "#212121",
  btnTipHoverBgd: "#EADED0",

  /* Order History Page */
  orderHistoryDate: "#222222",
  orderHistoryDateSelect: "#CBB69F",

  /* HistoryItemCard & (Menu)ItemCard */
  menuCardBgd: "#FAF7F4",
  menuCardTitle: "#222222",
  menuCardTableTitle: "#CBB69F",
  menuCardPrice: "#212121",
  menuCardPicBgd: "#212121",
  menuCardPicIcon: "#FFF7ED",
  menuCardContent: "#6A6A6A",
  menuCardDescription: "#6A6A6A",

  /* MenuItemModifier */
  modifierBgd: "#FFFFFF",
  modifierModalTitle: "#222222",
  modifierModalPrice: "#212121",
  modifierModalDividLine: "#FFFFFF",
  modifierModalWarning: "#909090",
  modifierModalDescription: "#6A6A6A",
  modifierModalContent: "#3B3B3B",

  /* All to Pay or proceed button */
  btnBgd: "#CBB69F",
  btnText: "#212121",

  /* Nav - DropdownButton */
  btnNavBgd: "#FAF7F2",
  btnNavText: "#222222",
  btnNavHoverBgd: "#EADED0",

  /* All placeholders on 
    Users Setting Page, Payment Modal and Register Page */
  placeholderTitle: "#6A6A6A",
  placeholderBgd: "#FFFFFF",
  placeholderSelectBorder: "#707070",
  placeholderText: "#6A6A6A",

  fontBold: "HelveticaNeueBold",
  fontLight: "HelveticaNeueLight",
  fontMedium: "HelveticaNeueMedium",
  fontRegular: "HelveticaNeueRegular",
  fontBlack: "HelveticaNeueCondensedBlack",
  fontImpact: "Impact",
  fontRestaurant: "AbrilFatface",

  bannerImg: 'url("/img/home-background14.png")',
}, {
  pageBgd: "#000000",
  primary: "#AC7637",
  restaurantName: "#FFFFFF",
  restaurantInfo: "#FFFFFF",
  bannerIcon: "#AC7637",
  content: "#B2B2B2",
  centerCategoryTitle: "#FFFFFF",

  /* Login Page */
  loginLeftBgd: "#141313",
  loginLeftTitle: "#AC7637",
  loginLeftContent: "#FFFFFF",
  loginLeftBtnBgd: "#AC7637",
  loginLeftBtnText: "#141313",

  loginRightBgd: "#1D1D1D",
  loginRightTitle: "#AC7637",
  loginRightBtnBgd: "#AD8658",
  loginRightBtnText: "#1D1D1D",

  /* Store Page - category & menuCard, 
     OrderHistory Page - orderHistoryCard */
  cardHeader: "#FFFFFF",
  cardHeaderBgd: "#1D1D1D",
  cardTableNum: "#AC7637",
  cardBgd: "#141313",
  cardContent: "#FFFFFF",

  /* Store Page - orderSummary of menuCard
     Cart Page - orderSummary of orderCard */
  orderSummaryTitle: "#FFFFFF",
  orderSummaryContent: "#FFFFFF",

  /* Store Page - dineIn mode in mobile
     Cart Page - dineIn mode in mobile */
  tableMobileText: "#FFFFFF",
  tableMobileNum: "#AC7637",

  /* Cart Page */
  cartTitle: "#FFFFFF",
  cartSubTitle: "#AC7637",
  cartContent: "#FFFFFF",
  cartDecorateLine: "#AC7637",
  cartFunctionBtnTitle: "#FFFFFF",
  cartFunctionBtnBgd: "#1F1F1F",
  cartTotalAmountText: "#FFFFFF",
  cartTotalAmountBgd: "#1F1F1F",

  /* Cart Page - CartItemCard */
  cartItemTitle: "#FFFFFF",
  cartItemBgd: "#141313",
  cartItemDescription: "#B2B2B2",
  cartItemModifier: "#B2B2B2",
  cartItemAmount: "#222222",
  cartItemAmountBtnBgd: "#141313",
  cartItemAmountBtnSymbol: "#AC7637",
  cartItemAmountBtnHoverBgd: "#AC7637",
  cartItemAmountBtnHoverSymbol: "#FFFFFF",

  /* Cart Page - Tip buttons */
  btnTipText: "#FFFFFF",
  btnTipBorder: "#FFFFFF",
  btnTipBgd: "#141313",
  btnTipHoverText: "#FFFFFF",
  btnTipHoverBgd: "#AD8B63",

  /* Order History Page */
  orderHistoryDate: "#FFFFFF",
  orderHistoryDateSelect: "#AC7637",

  /* HistoryItemCard & (Menu)ItemCard */
  menuCardBgd: "#141313",
  menuCardTitle: "#FFFFFF",
  menuCardTableTitle: "#FFFFFF",
  menuCardPrice: "#AC7637",
  menuCardPicBgd: "#1D1D1D",
  menuCardPicIcon: "#AC7637",
  menuCardContent: "#B2B2B2",
  menuCardDescription: "#B2B2B2",

  /* MenuItemModifier */
  modifierBgd: "#1D1D1D",
  modifierModalTitle: "#FFFFFF",
  modifierModalPrice: "#AC7637",
  modifierModalDividLine: "#1D1D1D",
  modifierModalWarning: "#B2B2B2",
  modifierModalDescription: "#B2B2B2",
  modifierModalContent: "#FFFFFF",

  /* All to Pay or proceed button */
  btnBgd: "#AC7637",
  btnText: "#FFFFFF",

  /* Nav - DropdownButton */
  btnNavBgd: "#242424",
  btnNavText: "#FFFFFF",
  btnNavHoverBgd: "#AD8B63",

  /* All placeholders on 
    Users Setting Page, Payment Modal and Register Page */
  placeholderTitle: "#FFFFFF",
  placeholderBgd: "#141313",
  placeholderSelectBorder: "#AC7637",
  placeholderText: "#FFFFFF",

  fontBold: "HelveticaNeueBold",
  fontLight: "HelveticaNeueLight",
  fontMedium: "HelveticaNeueMedium",
  fontRegular: "HelveticaNeueRegular",
  fontBlack: "HelveticaNeueCondensedBlack",
  fontImpact: "Impact",
  fontRestaurant: "AbrilFatface",

  bannerImg: 'url("/img/home-background15.png")',
}];

const {
  ThemeProvider,
  withTheme,
  useTheme
} = createTheming(themes[0]);
export {
  ThemeProvider,
  withTheme,
  useTheme
};