import React, { Component } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { confirmable } from "react-confirm";
import { StyleSheet, css } from 'aphrodite';
import { withTheme } from "../styles/theming"
import "../styles/PopupWindow.css";

class EmailSetting extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = e => {
    e.preventDefault();

    const { showLoading, proceed } = this.props;
    showLoading();
    proceed(this.emailInput.value);
  };

  render() {
    const { show, dismiss, theme } = this.props;
    const themeStyles = StyleSheet.create({
      formControl:{
        height: 50, 
        fontSize: 16,
        backgroundColor: theme.placeholderBgd,
        borderColor: theme.modifierModalDescription,
        color: theme.placeholderTitle,
        fontFamily: theme.fontRegular,
        ':focus':{
          borderColor: theme.placeholderSelectBorder,
          borderWidth: 2,
        }
      },
      btn: {
        color: theme.btnText,
        borderColor: "transparent",
        backgroundColor: theme.btnBgd,
        fontFamily: theme.fontMedium,
      },
    });
    return (
      <div>
        <Modal show={show} onHide={dismiss}>
          <div className="modal-whole" 
            style={{
              backgroundColor: theme.modifierBgd,
              fontFamily: theme.fontBold,
              borderRadius: "10px",
              borderColor: "transparent",
            }}>
            <Modal.Header>
              <Modal.Title
                style={{
                  color: theme.modifierModalPrice,
                  fontFamily: theme.fontBold,
                }}>
                Change Email
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                color: theme.modifierModalContent,
                fontFamily: theme.fontRegular,
              }}>
              <Form className="window-margin" onSubmit={this.handleSubmit}>
                <Form.Group>
                  <Form.Label
                    className="placeholder-title"
                    style={{color: theme.placeholderTitle}}>
                    New Email Address
                  </Form.Label>
                  <Form.Control
                    className={"input-control "+css(themeStyles.formControl)}
                    type="email"
                    ref={ref => {
                      this.emailInput = ref;
                    }}
                    placeholder="Email address"/>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer className="button-block-margin">
              <Button onClick={dismiss}
                className={"button-width "+css(themeStyles.btn)}>
                Cancel
              </Button>
              <Button
                className={"button-width "+css(themeStyles.btn)}
                onClick={this.handleSubmit.bind(this)}>
                Save
              </Button>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withTheme(confirmable(EmailSetting));
