import React, { Component } from "react";
import { connect } from "react-redux";
import { login, resetPassword } from "../redux/actions";
import { Form, CardGroup, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { confirm } from "../util/confirm";
import { StyleSheet, css } from 'aphrodite';
import { withTheme } from "../styles/theming";
import Nav from "../components/Nav";
import PasswordReset from "../components/modals/PasswordReset";
import "../styles/Login.css";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      showModal: false
    };
  }

  onTextChange = e => {
    const { id, value } = e.target;
    this.setState({ [id]: value });
  };

  showResetPasswordWindow = () => {
    this.setState({ showModal: true });
  };

  hideResetPasswordWindow = () => {
    this.setState({ showModal: false });
  };

  loginUser = async e => {
    e.preventDefault();

    const { store, order, history, login, theme } = this.props;
    const { username, password } = this.state;
    const success = await login(username, password);

    if (success) {
      if (order.lineItemGroups[0].lineItems.length > 0) {
        history.push({ pathname: "/cart" });
      } else if (store.id) {
        history.push({ pathname: `/store/${store.urlName}` });
      }
    } else {
      confirm(
        "Login failed!",
        true,
        { theme: theme }
      );
    }
  };

  render() {
    const { theme } = this.props;
    const themeStyles = StyleSheet.create({
      leftFormControl: {
        color: theme.loginLeftContent,
        backgroundColor: theme.loginLeftBgd,
        borderColor: theme.loginLeftContent,
        fontFamily: theme.fontRegular,
        ':hover': {
          borderWidth: '2px'
        }
      },

    });
    return (
      <div
        className="d-flex flex-column align-items-center"
        style={{
          backgroundColor: theme.pageBgd,
          minHeight: "100vh"
        }}>
        <Nav />
        <CardGroup className="col-12 col-md-8 col-xl-6 mt-5"
          style={{ fontFamily: theme.fontMedium }}>
          <Card
            style={{
              backgroundColor: theme.loginLeftBgd,
              borderColor: "transparent",
              borderBottomLeftRadius: "10px",
              padding: "5px 20px"
            }}>
            <Form onSubmit={this.loginUser}>
              <div className="card-block">
                <h2
                  style={{
                    color: theme.loginLeftTitle,
                    fontFamily: theme.fontBold,
                    marginBottom: "1rem"
                  }}>
                  Login
                </h2>
                <p className="text-muted"
                  style={{
                    color: theme.loginLeftContent,
                    fontFamily: theme.fontRegular,
                    marginBottom: "0.5rem"
                  }}>
                  Sign in to your account
                </p>
                <Form.Control
                  id="username"
                  className={"mb-2 " + css(themeStyles.leftFormControl)}
                  type="text"
                  placeholder="Username"
                  value={this.state.username}
                  onChange={this.onTextChange}
                  required
                />
                <Form.Control
                  id="password"
                  className={"mb-2 " + css(themeStyles.leftFormControl)}
                  type="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.onTextChange}
                  required
                />
                <div className="row">
                  <div className="col-6">
                    <button
                      type="submit"
                      className="btn btn-primary px-4"
                      style={{
                        backgroundColor: theme.loginLeftBtnBgd,
                        borderColor: "transparent",
                        color: theme.loginLeftBtnText,
                        fontFamily: theme.fontRegular,
                        marginTop: "0.5rem"
                      }}
                      onSubmit={this.login}>
                      Login
                    </button>
                  </div>
                  <div className="col-6 text-right register-link">
                    <Link
                      to="/register"
                      style={{
                        color: theme.loginLeftContent,
                        fontFamily: theme.fontRegular
                      }}>
                      Register Now!
                    </Link>
                  </div>
                </div>
                <div
                  className="password-reset"
                  onClick={this.showResetPasswordWindow}
                  style={{
                    textDecorationLine: 'underline',
                    fontStyle: 'italic',
                    color: theme.loginLeftContent,
                    fontFamily: theme.fontRegular,
                  }}>
                  Forget password?
                </div>
              </div>
            </Form>
          </Card>
          <Card
            className="card-inverse card-primary py-5 sign-up-card"
            style={{
              width: "44%",
              backgroundColor: theme.loginRightBgd,
              borderColor: "transparent",
              borderBottomRightRadius: "10px",
            }}>
            <div className="card-block text-center">
              <div>
                <h2
                  style={{
                    color: theme.loginRightTitle,
                    fontFamily: theme.fontBold,
                    marginTop: "1rem",
                    marginBottom: 0
                  }}>
                  Sign Up
                </h2>
                <button type="button"
                  className="btn btn-primary active mt-3"
                  style={{
                    borderColor: "transparent",
                    backgroundColor: "transparent",
                    color: "transparent",
                    padding: 0,
                  }}>
                  <Link to="/register"
                    className="btn btn-primary active mt-3 buttonRegister"
                    style={{
                      backgroundColor: theme.loginRightBtnBgd,
                      borderColor: "transparent",
                      borderRadius: "6px",
                      color: theme.loginRightBtnText,
                    }}>
                    Register Now!
                  </Link>
                </button>
              </div>
            </div>
          </Card>
        </CardGroup>
        <PasswordReset
          show={this.state.showModal}
          onHide={this.hideResetPasswordWindow}
        />
      </div>
    );
  }
}

const reduxStateToProps = state => {
  return {
    store: state.storeState.store,
    order: state.cartState.order
  };
};

export default withTheme(connect(reduxStateToProps, { login, resetPassword })(Login));
