import { PUBLIC_1_0 } from "../../config/config";
import {
  CLEAR_CART_ITEMS,
  SET_ORDER,
  SET_ORDER_STORE,
  CLEAR_ORDER,
  SET_ORDER_TIP,
  SET_ORDER_TIPPERCENT,
  SET_INSTORE_ORDER,
  SET_ORDER_TOTAL
} from "./types";
import { AUTH_ERROR } from "./errors";
import * as firebase from "firebase";
import { postOption } from "./utils";

export const clearCartItems = () => ({
  type: CLEAR_CART_ITEMS
});

export const setOrderStore = store => ({
  type: SET_ORDER_STORE,
  payload: store
});

export const clearOrder = () => ({
  type: CLEAR_ORDER
});

export const validateOrder = order => {
  return async dispatch => {
    const uri = `${PUBLIC_1_0}/order/validate`;
    let options = postOption(JSON.stringify(order), {
      "Content-Type": "application/json"
    });

    try {
      const request = new Request(uri, options);
      const response = await fetch(request);
      if (response.ok) {
        const order = await response.json();
        dispatch({ type: SET_ORDER, payload: order });
      }
    } catch (e) {}
  };
};

export const setTip = tipAndNonCashFee => (
  {
  type: SET_ORDER_TIP,
  payload: tipAndNonCashFee
});

export const setTipPercent = (accounting) => ({
  type: SET_ORDER_TIPPERCENT,
  payload: accounting
});

export const setTotal = total => ({
  type: SET_ORDER_TOTAL,
  payload: total
})

export const createOrder = order => {
  return async () => {
    const user = firebase.auth().currentUser;
    if (user) {
      const { uid } = user;

      return user
        .getIdToken(true)
        .then(async idToken => {
          const body = {
            order: JSON.stringify(order),
            token: idToken,
            uid: uid
          };

          const uri = `${PUBLIC_1_0}/order`;
          let options = postOption(JSON.stringify(body), {
            "Content-Type": "application/json"
          });

          try {
            const request = new Request(uri, options);
            const response = await fetch(request);
            if (response.ok) {
              const order = await response.json();
              return order;
            } else {
              const error = await response.json();
              return { error: true, message: error.message };
            }
          } catch (e) {
            return { error: true };
          }
        })
        .catch(() => {
          return { error: true, message: AUTH_ERROR };
        });
    } else {
      const body = {
        order: JSON.stringify(order)
      };
      const uri = `${PUBLIC_1_0}/order`;
      let options = postOption(JSON.stringify(body), {
        "Content-Type": "application/json"
      });
      const request = new Request(uri, options);

      return await fetch(request)
        .then(res => {
          return res.json();
        })
        .catch(e => {
          return { error: true, message: e.message };
        });
    }
  };
};

export const createOrderWithoutPayment = (order) => {
  return async () => {
    const uri = `${PUBLIC_1_0}/order/createOrderWithoutPayment`;
    const user = firebase.auth().currentUser;
    
    const idToken = user ? await user.getIdToken(true) : null;

    const body = {
      order: JSON.stringify(order),
      token: idToken,
      uid: user ? user.uid : null
    };
    const options = postOption(JSON.stringify(body), {
      "Content-Type": "application/json"
    });
    const request = new Request(uri, options);
    return await fetch(request)
      .then(res => {
        if (res.ok) {
          return res.json();
        } else {
          throw res.json();
        }
      })
      .catch(e => {
        return { error: true, message: e.message ? e.message : AUTH_ERROR };
      });
  };
};

export const createPaymentBySavedCard = (order, tokenData, cvv) => {
  return async () => {
    const user = firebase.auth().currentUser;
    if (user) {
      const { uid } = user;

      return user
        .getIdToken(true)
        .then(async idToken => {
          const payment = {
            orderId: order.id,
            billId: 0,
            amount: order.total,
            tip: order.tip,
            currency: "USD",
            storeId: order.store.id,
            companyId: order.company.id,
            cardAccount: {
              zip: tokenData.zip,
              entryMode: "Keyed",
              token: tokenData.token,
              cardType: tokenData.cardType,
              accountNumber: tokenData.last4Digit,
              customerProfileId: tokenData.customerProfileId,
              customerPaymentProfileId: tokenData.customerPaymentProfileId,
              cvv: cvv
            },
            processor: "velocity",
            notes: {
              goodsType: "PhysicalGoods",
              customerPresent: "Ecommerce",
              reference: "asdf",
              cvDataProvided: "Provided",
              industryType: "Ecommerce"
            }
          };

          const body = {
            payment: JSON.stringify(payment),
            token: idToken,
            uid: uid
          };

          const uri = `${PUBLIC_1_0}/order/payment/authorizeAndCapture`;
          let options = postOption(JSON.stringify(body), {
            "Content-Type": "application/json"
          });

          try {
            const request = new Request(uri, options);
            const response = await fetch(request);
            if (response.ok) {
              const result = await response.json();
              return result;
            } else {
              const error = await response.json();
              return { error: true, message: error.message };
            }
          } catch (e) {
            return { error: true };
          }
        })
        .catch(() => {
          return { error: true, message: AUTH_ERROR };
        });
    } else {
      return { error: true, message: AUTH_ERROR };
    }
  };
};

export const createPaymentByNewCard = (order, card) => {
  return async () => {
    const uri = `${PUBLIC_1_0}/order/payment/authorizeAndCapture`;
    const user = firebase.auth().currentUser;
    const payment = {
      orderId: order.id,
      billId: 0,
      amount: order.total,
      tip: order.tip,
      currency: "USD",
      storeId: order.store.id,
      companyId: order.company.id,
      cardAccount: {
        holderName: card.holderName,
        accountNumber: card.accountNumber,
        cardType: card.cardType,
        expiryMonth: card.expiryMonth,
        expiryYear: card.expiryYear,
        street: card.street,
        city: card.city,
        stateProvince: card.stateProvince,
        country: "USA",
        zip: card.cardZip,
        entryMode: "Keyed",
        cvv: card.cvc
      },
      processor: "velocity",
      notes: {
        remark: card.remark,
        deliveryZip: card.deliveryZip,
        phone: card.phone,
        email: card.email,
        customerName: card.customerName,
        deliveryAddress: card.deliveryAddress,
        cashBackAmount: "0.00",
        goodsType: "NotSet",
        customerPresent: "NotSet",
        partialApprovalCapable: "NotSet",
        invoiceNumber: "123",
        cvDataProvided: "Provided",
        employeeId: "123",
        accountType: "NotSet",
        industryType: "Ecommerce",
        feeAmount: "0.00",
        smsNotification: card.textNotification,
        smsCarrierDomain: card.carrier,
        deliveryState: card.deliveryState,
        deliveryCity: card.deliveryCity
      }
    };
    const idToken = user ? await user.getIdToken(true) : null;

    const body = {
      payment: JSON.stringify(payment),
      token: idToken,
      uid: user ? user.uid : null
    };
    const options = postOption(JSON.stringify(body), {
      "Content-Type": "application/json"
    });
    const request = new Request(uri, options);
    return await fetch(request)
      .then(res => {
        if (res.ok) {
          return res.json();
        } else {
          const errormessage = {
            res: res.json(),
            status: res.status
          }
          throw errormessage;
        }
      })
      .catch(e => {
        return { error: true, message: e.message ? e.message : AUTH_ERROR, status: e.status };
      });
  };
};
export const setInStoreOrder = query => {
  return dispatch => {
    dispatch({ type: SET_INSTORE_ORDER, payload: query });
  };
};
