import React, { Component } from "react";
import LoadingScreen from "./components/LoadingScreen";
import AppRouting from "./components/AppRouting";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import configureStore from "./redux/store";

class App extends Component {
  render() {
    const { store, persistor } = configureStore();

    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LoadingScreen />
          <AppRouting />
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
