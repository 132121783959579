import React, { Component } from "react";
import { connect } from "react-redux";
import { resetPassword } from "../../redux/actions";
import {
  Modal,
  Button,
  Form,
  FormGroup,
  FormLabel,
  FormControl
} from "react-bootstrap";
import { StyleSheet, css } from 'aphrodite';
import { withTheme } from "../../styles/theming";

class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ""
    };
  }

  onEmailChange = e => {
    this.setState({ email: e.target.value });
  };

  handleSubmit = () => {
    const { resetPassword, onHide } = this.props;
    resetPassword(this.state.email);
    onHide();
  };

  render() {
    const { show, onHide, theme } = this.props;
    const themeStyles = StyleSheet.create({
      button:{
        color: theme.btnText,
        borderColor: "transparent",
        backgroundColor: theme.btnBgd,
        fontFamily: theme.fontMedium,
      }
    });
    return (
      <Modal show={show} onHide={onHide}>
        <div className="modal-whole" 
          style={{
            backgroundColor: theme.modifierBgd,
            fontFamily: theme.fontBold,
            borderRadius: "10px",
            borderColor: "transparent",
          }}>
          <Modal.Header
            style={{
              color: theme.modifierModalPrice,
              fontFamily: theme.fontBold,
            }}>
            <Modal.Title
              style={{color: theme.placeholderTitle}}>
              Reset Password
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <FormGroup>
                <FormLabel
                  style={{color: theme.placeholderTitle}}>
                  Email Address
                </FormLabel>
                <FormControl
                  className= {css(StyleSheet.create({
                    emailAddress:{
                      height: 50, 
                      fontSize: 16,
                      backgroundColor: theme.placeholderBgd,
                      borderColor: theme.modifierModalDescription,
                      color: theme.placeholderTitle,
                      fontFamily: theme.fontRegular,
                      ':focus':{
                        borderColor: theme.placeholderSelectBorder,
                        borderWidth: 2,
                      }
                    }}).emailAddress)}
                  type="email"
                  placeholder="Email address"
                  value={this.state.email}
                  onChange={this.onEmailChange}
                />
              </FormGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onHide}
              className={css(themeStyles.button)}>
              Cancel
            </Button>
            <Button onClick={this.handleSubmit} variant="primary"
              className={css(themeStyles.button)}>
              Submit
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
}

export default withTheme(connect(
  null,
  { resetPassword }
)(PasswordReset));
