import React, { Component } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { confirmable } from "react-confirm";
import { StyleSheet, css } from 'aphrodite';
import { withTheme } from "../styles/theming";
import "../styles/PopupWindow.css";

class ContactInfoSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      street: "",
      city: "",
      zip: "",
      phone: ""
    };
  }

  handleSubmit = () => {
    if (
      this.getRequiredFieldValidation(this.state.street) &&
      this.getRequiredFieldValidation(this.state.city) &&
      this.getRequiredFieldValidation(this.state.zip) &&
      this.getRequiredFieldValidation(this.state.phone)
    ) {
      const contact = {
        address: {
          city: this.state.city,
          state: this.stateInput.value,
          country: "US",
          street1: this.state.street,
          zip1: this.state.zip
        },
        mainNumber: {
          main: true,
          number: this.state.phone
        }
      };

      const { showLoading, proceed } = this.props;
      showLoading();
      proceed(contact);
    }
  };

  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  getRequiredFieldValidation = value => {
    return value.toString().length > 0;
  };

  render() {
    const { show, dismiss, theme } = this.props;
    const themeStyles = StyleSheet.create({
      formControl:{
        height: 50, 
        fontSize: 16,
        backgroundColor: theme.placeholderBgd,
        borderColor: theme.modifierModalDescription,
        color: theme.placeholderTitle,
        fontFamily: theme.fontRegular,
        ':focus':{
          borderColor: theme.placeholderSelectBorder,
          borderWidth: 2,
        }
      },
      btn: {
        color: theme.btnText,
        borderColor: "transparent",
        backgroundColor: theme.btnBgd,
        fontFamily: theme.fontMedium,
      },
    });
    return (
      <Modal show={show} onHide={dismiss}>
        <div className="modal-whole" 
            style={{
              backgroundColor: theme.modifierBgd,
              fontFamily: theme.fontBold,
              borderRadius: "10px",
              borderColor: "transparent",
            }}>
          <Modal.Header>
            <Modal.Title
              style={{
                color: theme.modifierModalPrice,
                fontFamily: theme.fontBold,
              }}>
              Change Contact Information
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              color: theme.modifierModalContent,
              fontFamily: theme.fontRegular,
            }}>
            <div className="window-margin">
              <Form.Group
                controlId="street"
                validationState={
                  this.getRequiredFieldValidation(this.state.street)
                    ? "success"
                    : "error"
                }>
                <Form.Label
                  className="placeholder-title"
                  style={{color: theme.placeholderTitle}}>
                  Street
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  onChange={this.handleChange}
                  className={"input-control "+css(themeStyles.formControl)}
                />
              </Form.Group>
              <Form.Group
                controlId="city"
                validationState={
                  this.getRequiredFieldValidation(this.state.city)
                    ? "success"
                    : "error"
                }>
                <Form.Label
                  className="placeholder-title"
                  style={{color: theme.placeholderTitle}}>
                  City
                </Form.Label>
                <Form.Control
                  className={"input-control "+css(themeStyles.formControl)}
                  type="text"
                  placeholder=""
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group controlId="state">
                <Form.Label
                  className="placeholder-title"
                  style={{color: theme.placeholderTitle}}>
                  State
                </Form.Label>
                <Form.Control
                  as="select"
                  placeholder="State"
                  className={"input-control "+css(themeStyles.formControl)}
                  ref={ref => {
                    this.stateInput = ref;
                  }}>
                  {this.props.states.map(type => {
                    return (
                      <option key={type.id} value={type.name}>
                        {type.displayName}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
              <Form.Group
                controlId="zip"
                validationState={
                  this.getRequiredFieldValidation(this.state.zip)
                    ? "success"
                    : "error"}>
                <Form.Label
                  className="placeholder-title"
                  style={{color: theme.placeholderTitle}}>
                  Zip Code
                </Form.Label>
                <Form.Control
                  className={"input-control "+css(themeStyles.formControl)}
                  type="number"
                  placeholder=""
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group
                controlId="phone"
                validationState={
                  this.getRequiredFieldValidation(this.state.phone)
                    ? "success"
                    : "error"
                }>
                <Form.Label
                  className="placeholder-title"
                  style={{color: theme.placeholderTitle}}>
                  Phone Number
                </Form.Label>
                <Form.Control
                  className={"input-control "+css(themeStyles.formControl)}
                  type="number"
                  placeholder=""
                  onChange={this.handleChange}
                />
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer className="button-block-margin">
            <Button 
              className={"button-width " +css(themeStyles.btn)}
              onClick={dismiss}>
              Cancel
            </Button>
            <Button 
              className={"button-width "+css(themeStyles.btn)} 
              onClick={this.handleSubmit} >
              Save
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
}

export default withTheme(confirmable(ContactInfoSetting));
