import { 
  SET_STORE, 
  SET_ACCEPTABLE_CC_TYPES, 
  SET_ACCEPTABLE_KIOSK_PAYMENT_TYPES 
} from "../actions/types";
import { createReducer } from "redux-starter-kit";

const DEFAULT_STATE = {
  store: {},
  acceptableCCTypes: [],
  acceptableKioskPaymentTypes: []
};

export default createReducer(DEFAULT_STATE, {
  [SET_STORE]: (state, action) => {
    state.store = action.payload;
  },
  [SET_ACCEPTABLE_CC_TYPES]: (state, action) => {
    state.acceptableCCTypes = action.payload;
  },
  [SET_ACCEPTABLE_KIOSK_PAYMENT_TYPES]: (state, action) => {
    state.acceptableKioskPaymentTypes = action.payload;
  }
});
