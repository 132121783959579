import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import { confirmable } from "react-confirm";
import { css, StyleSheet } from "aphrodite";
import { withTheme } from "../styles/theming";
class Confirmation extends React.Component {
  render() {
    const {
      okLabbel = "OK",
      cancelLabel = "Cancel",
      title,
      confirmation,
      show,
      proceed,
      dismiss,
      cancel,
      enableEscape = true,
      singleButton,
      theme,
    } = this.props;

    const themeStyles = StyleSheet.create({
      btn: {
        color: theme.btnText,
        borderColor: "transparent",
        backgroundColor: theme.btnBgd,
        fontFamily: theme.fontMedium,
      },
    });
    return (
      <div className="static-modal">
        <Modal
          show={show}
          onHide={dismiss}
          backdrop={enableEscape ? true : "static"}
          keyboard={enableEscape}>
          <div className="modal-whole" 
          style={{
            backgroundColor: theme.modifierBgd,
            fontFamily: theme.fontBold,
            borderRadius: "10px",
            borderColor: "transparent",
          }}>
          <Modal.Header>
            <Modal.Title
              style={{
                color: theme.modifierModalPrice,
                fontFamily: theme.fontBold,
              }}>
              {title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              color: theme.modifierModalContent,
              fontFamily: theme.fontRegular,
            }}>
            {confirmation}
          </Modal.Body>
          <Modal.Footer>
            {singleButton ? (
              ""
            ) : (
              <Button onClick={cancel}
                className={css(themeStyles.btn)}>
                {cancelLabel}
              </Button>
            )}
            <Button 
              className={"button-l "+css(themeStyles.btn)}
              variant="primary" 
              onClick={proceed}>
              {okLabbel}
            </Button>
          </Modal.Footer>
          </div>
        </Modal>
      </div>
    );
  }
}

Confirmation.propTypes = {
  okLabbel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  confirmation: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
  cancel: PropTypes.func, // called when cancel button is clicked.
  dismiss: PropTypes.func, // called when backdrop is clicked or escaped.
  enableEscape: PropTypes.bool
};

export default withTheme(confirmable(Confirmation));
