import React, { Component } from "react";
import Nav from "../components/Nav";
import { withTheme } from "../styles/theming";
import { css, StyleSheet } from "aphrodite";
import "../styles/legal-doc.css";


class TermsOfUse extends Component {
  render() {
    const { theme } = this.props;
    const themeStyles = StyleSheet.create({
      h5Title: {
        color: theme.cartTitle,
        fontFamily: theme.fontBold
      },
      subTitle: {
        color: theme.cartTitle,
        fontFamily: theme.fontRegular
      },
      paragraph: {
        color: theme.cartContent,
        fontFamily: theme.fontRegular
      }
    });

    return (
      <div style={{
        backgroundColor: theme.pageBgd, 
        color: theme.cartContent, 
        fontFamily:theme.fontRegular,
        minHeight: "100vh"}}>
        <Nav />
        <div className="doc-body">
          <h4 className={css(themeStyles.h5Title)}>
            Terms Of Use
          </h4>
          <p className={"bold " +css(themeStyles.subTitle)}>
            Version: 1.1
          </p>
          <p className={"bold " +css(themeStyles.subTitle)}>
            Last Updated: December 15, 2020
          </p>
          <p className={"bold " +css(themeStyles.subTitle)}>
            Welcome! Thanks for your interest in using our service! Before you
            use our Sites, please carefully read these Terms of Use. They may be
            updated from time to time, as reflected by the version number and
            effective date change.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            HSAC, LLC d/b/a HotSauce Technologies and its subsidiaries and
            affiliates (“HotSauce,” “we,” “our,” or “us”) own and operate
            certain websites (including hotsaucepos.com and
            onlineordering.hotsaucepos.com), certain technology platforms, and
            certain other related online and mobile services (including, without
            limitation, our Mobile Applications, as defined below) that
            reference or will reference these Terms of Use (collectively, the
            “Sites”). These Terms of Use (the “Agreement”) constitute a contract
            between you and us that governs your access and use of the Sites. By
            accessing and/or using the Sites or our services through the Sites,
            or by clicking a button or checking a box marked “I Agree” (or
            something similar), you agree to all the terms and conditions of
            this Agreement. If you do not agree, your only recourse is to
            refrain from using the Sites. As used in this Agreement, “you” means
            any visitor, user, or other person who accesses our Sites, whether
            or not such person registered for an Account (as defined below).
            Unless otherwise agreed by HotSauce in a separate written agreement
            with you or your authorized representative, the Sites are made
            available solely for your personal, non-commercial use.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            IMPORTANT: PLEASE REVIEW THE “DISPUTE RESOLUTION” SECTION SET FORTH
            BELOW CAREFULLY, AS IT WILL REQUIRE YOU TO RESOLVE ALL DISPUTES WITH
            HOTSAUCE THROUGH BINDING INDIVIDUAL ARBITRATION. YOU ACKNOWLEDGE AND
            AGREE THAT YOU AND HOTSAUCE ARE EACH WAIVING THE RIGHT TO A TRIAL BY
            JURY. YOU FURTHER ACKNOWLEDGE AND AGREE THAT YOU WAIVE YOUR RIGHT TO
            PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS
            ACTION OR REPRESENTATIVE PROCEEDING AGAINST HOTSAUCE. BY ENTERING
            THIS AGREEMENT, YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ AND
            UNDERSTOOD, AND AGREE TO BE BOUND BY, ALL OF THE TERMS AND
            CONDITIONS OF THIS AGREEMENT AND HAVE TAKEN TIME TO CONSIDER THE
            CONSEQUENCES OF THIS IMPORTANT DECISION.
          </p>
          <h5 className={css(themeStyles.h5Title)}>
            ABOUT HOTSAUCE
          </h5>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            HotSauce connects you with local restaurants and gives you the
            option of ordering food through the Sites in cities throughout the
            United States where HotSauce provides such services.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            HotSauce is not a restaurant or food preparation entity. The
            restaurants and other food purveyors available on our Sites
            (collectively, the “restaurants”) operate independently of HotSauce.
            The restaurants are required to comply with federal, state, and
            local laws, rules, regulations, and standards pertaining to the
            preparation, sale, and marketing of food, including, without
            limitation, food preparation and safety and menu disclosure.
            HotSauce is not liable or responsible for the restaurants&#39; food
            preparation or safety and does not verify their compliance with all
            applicable laws. In addition, HotSauce does not guarantee the
            quality of what the restaurants or other food purveyors sell, nor
            does it guarantee the services provided by them, including, without
            limitation, in those cases where they provide the delivery services
            or engage another third-party delivery service. HotSauce does not
            independently verify, and is not liable for, representations made by
            restaurants regarding their food, including, without limitation, any
            menu- or restaurant-level descriptors or disclosures.
          </p>
          <h5 className={css(themeStyles.h5Title)}>
            USING THE SERVICE
          </h5>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            You may only create and hold one account on our Site for your
            personal use (an “Account”). You may have another account if you are
            using the Site as part of a corporate account created for business
            purposes pursuant to a separate agreement with HotSauce. In
            consideration of the use of the Sites and the services contained
            therein, you agree that you are able to create a binding legal
            obligation with HotSauce, and you also agree to: (a) provide true,
            accurate, current, and complete information about yourself, and (b)
            maintain and promptly update the personal information you provide to
            keep it true, accurate, current, and complete.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            The Sites may permit you to make purchases without an Account or
            without logging in to your Account. If you make a purchase in this
            manner, we may create an Account for you based on the information
            provide to us in connection with the transaction (e.g., your name,
            phone number, email address, and other transaction information). If
            you are a minor in the jurisdiction in which you reside (generally
            under the age of 18), you must have the permission of, and be
            directly supervised by, your parent or legal guardian to use the
            Sites, and your parent or legal guardian must read and agree to this
            Agreement prior to your using the Sites. Notwithstanding the
            foregoing, you are not authorized to use the Sites if you are under
            the age of 13. If you are using the Sites on behalf of an entity,
            organization, or company, you represent and warrant that you have
            the authority to bind that organization to this Agreement and you
            agree to be bound by this Agreement on behalf of that organization.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            If you provide any information that is untrue, inaccurate, not
            current or incomplete, including, without limitation, having an
            invalid or expired payment method on file, or if HotSauce has
            reasonable grounds to suspect that any information you provide is
            untrue, inaccurate, not current or incomplete, HotSauce has the
            right to block your current or future use of the Sites (or any
            portion thereof) and/or terminate this Agreement with you. If your
            Account is cancelled for any or no reason, you may forfeit any
            pending, current, or future account credits or promotional offers
            and any other forms of unredeemed value in or associated with your
            Account without prior notice to you.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            You are responsible for maintaining the confidentiality and security
            of your Account including your password and, if applicable, any
            password for Facebook, Google, or other third party login. You are
            also responsible for all activities or any other actions that occur
            under or that are taken in connection with your Account. You agree
            to: (a) immediately notify HotSauce of any known or suspected
            unauthorized use(s) of your password or Account, or any known or
            suspected breach of security, including, without limitation, loss,
            theft, or unauthorized disclosure of your password or credit card
            information; and (b) ensure that you exit from your Account at the
            end of each session. HotSauce will not be liable for any injury,
            loss, or damage of any kind arising from or relating to your failure
            to comply with (a) and/or (b) or for any acts or omissions by you or
            someone else who is using your Account and/or password.
          </p>
          <h5 className={css(themeStyles.h5Title)}>
            OUR ALCOHOLIC BEVERAGES POLICY
          </h5>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            Some jurisdictions permit the ordering and delivery of alcoholic
            beverages. In such jurisdictions, if you place an order that
            includes any alcoholic beverage, you represent and warrant that you
            are at least 21 years of age. Upon delivery or pickup, as
            applicable, you shall present a government-issued identification
            card, evidencing your age, consistent with applicable legal
            requirements. If you do not comply with these terms, you agree that
            the alcoholic beverage(s) will not be released to you, and you may
            forfeit the cost of such beverages.
          </p>
          <h5 className={css(themeStyles.h5Title)}>
            PAYMENT AND OUR CREDIT POLICY
          </h5>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            Certain features of the Sites, including, without limitation, the
            placing of orders, may require you to make certain payments. When
            paid by you, these payments are final and non-refundable, unless
            otherwise determined by HotSauce. HotSauce, in its sole discretion,
            may offer credits or refunds on a case-by-case basis including, by
            way of example, in the event of an error with your order or in the
            amounts you were charged.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            HotSauce will charge, and you authorize HotSauce to charge, the
            payment method you specify at the time of purchase. If you pay any
            amounts with a credit card, HotSauce may seek pre- authorization of
            your credit card account prior to your purchase to verify that the
            credit card is valid and has credit available for your intended
            purchase.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            HotSauce reserves the right to establish, remove, and/or revise
            prices, fees, and/or surcharges for any or all services or goods
            obtained through the use of the services at any time. HotSauce may
            also, in its sole discretion, make promotional offers with different
            features and different rates to any or all of our customers. Unless
            made to you, these promotional offers will have no bearing on your
            obligation to pay the amounts charged.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            Your payment and personal information is always safe. 
            Our Secure Sockets Layer (SSL) is the industry standard and among the 
            best software available today for secure commerce transactions. 
            It encrypts all of your personal information, including credit card number, 
            name, and address, so that it cannot be read over the internet.
          </p>
          <p className="paragraph">
            The provider of services is set forth herein. If you are a
            California resident, in accordance with Cal. Civ. Code §1789.3, you
            may report complaints to the Complaint Assistance Unit of the
            Division of Consumer Services of the California Department of
            Consumer Affairs by contacting them in writing at 1625 North Market
            Blvd., Suite N 112 Sacramento, CA 95834, or by telephone at (800)
            952-5210 or (916) 445-1254.
          </p>
          <h5 className={css(themeStyles.h5Title)}>
            OUR MATERIALS AND LICENSE TO YOU
          </h5>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            With the exception of User Content (defined below), the Sites and
            everything on them, from text to photos to videos to graphics and
            software, (collectively, the &quot;Materials&quot;) are owned by or
            licensed to HotSauce. The Sites and the Materials are protected by
            copyright, trademark, trade dress, domain name, patent, trade
            secret, international treaties, and/or other proprietary rights and
            laws of the United States and other countries. Except as otherwise
            indicated on the Sites and Materials and except for the trademarks,
            service marks, logos and trade names of other companies that are
            displayed on the Sites and Materials, all trademarks, service marks,
            logos, trade dress, and trade names are proprietary to HotSauce,
            including, without limitation, HotSauce; HotSauce Technologies; and
            the hotsaucepos.com trade dress. Please be advised that HotSauce
            enforces its intellectual property rights to the fullest extent of
            the law.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            Subject to your compliance with this Agreement, we grant you a
            limited, non-exclusive, non- transferable, non-sublicensable, and
            revocable license to access and use the Sites and/or the Materials
            for your personal and noncommercial use, solely as expressly
            permitted by this Agreement and subject to all the terms and
            conditions of this Agreement, all applicable intellectual property
            laws, and any Additional Terms (as defined below) contained on the
            Sites and/or the Materials. Any other use of the Sites and/or the
            Materials is strictly prohibited. The Sites and the Materials may
            not be copied, republished, uploaded, posted, transmitted,
            distributed in any way, and/or modified without our express written
            permission. Nothing contained on the Sites and/or Materials should
            be interpreted as granting to you any license or right to use any of
            the Materials (other than as provided herein) and/or third party
            proprietary content on the Sites without the express written
            permission of HotSauce or the appropriate third party owner, as
            applicable. Any rights not expressly granted herein are reserved by
            HotSauce and HotSauce’s licensors.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            You may not rent, lease, redistribute, sell, sublicense, decompile,
            reverse engineer, disassemble, or otherwise reduce the Sites and/or
            the Materials, in whole or in part, to a human-perceivable form for
            any purpose, including, without limitation, to build a product
            and/or service competitive with the Sites and its related services.
            You are not authorized to download any content from the Sites,
            including, without limitation, the Materials, and if you do,
            HotSauce will not be responsible in any way for any damage to your
            computer system or loss of data that results from such download.
          </p>
          <h5 className={css(themeStyles.h5Title)}>
            YOUR CONTENT AND CONDUCT
          </h5>
          <p className={"paragraph "+css(themeStyles.paragraph)}>I. User Conduct</p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>By accessing the Sites, you agree:</p>
          <p className="paragraph bullet-point">
            to comply with all applicable laws in connection with your use of
            the Sites, including, without limitation, laws regarding online
            conduct and submission of acceptable User Content;
          </p>
          <p className="paragraph bullet-point">
            not to use the Sites or the services or submit content to the Sites
            if you are under the age of 13;
          </p>
          <p className="paragraph bullet-point">
            not to use the Sites to purchase alcohol unless you and the alcohol
            recipient are 21 or older and present a valid photo
            identification(s) verifying your or the recipient’s age at the time
            of alcohol delivery;
          </p>
          <p className="paragraph bullet-point">
            not to access the Sites or services using a third party&#39;s
            account/registration without the express consent of the account -
            holder and not to attempt to impersonate another user or person;
          </p>
          <p className="paragraph bullet-point">
            not to attempt, through any means, to gain unauthorized access to
            any part of the Sites and/or any service, other account, computer
            system, and/or network connected to any HotSauce server and not to
            otherwise use the Sites or services in any manner that could damage,
            disable, overburden, and/or impair any HotSauce server, or the
            network(s) connected to any HotSauce server, and/or interfere with
            any other party&#39;s use and enjoyment of the Sites;
          </p>
          <p className="paragraph bullet-point">
            not to advertise to, or solicit, any user, restaurant, or other
            business to buy or sell any products or services, or use any
            information obtained from the Sites or the HotSauce services in
            order to contact, solicit, or advertise or sell to any user,
            restaurant, or other business, in each case, unless specifically
            authorized in writing by HotSauce;
          </p>
          <p className="paragraph bullet-point">
            not to deep-link or frame to the Sites and/or access the Sites
            manually and/or with any robot, spider, web crawler, extraction
            software, automated process, and/or device or other means to scrape,
            copy, and/or monitor any portion of the Sites and/or any Materials
            and/or other content on the Sites, unless specifically authorized in
            writing by HotSauce;
          </p>
          <p className="paragraph bullet-point">
            not to conduct any scraping, indexing, surveying, data mining, or
            any other kind of systematic retrieval of data or other content from
            the Sites;
          </p>
          <p className="paragraph bullet-point">
            not to create or compile, directly or indirectly, any collection,
            compilation, database, or directory from the Site or Materials;
          </p>
          <p className="paragraph bullet-point">
            not to copy, publish, or redistribute any coupon or discount code or
            act in bad faith in an attempt to manipulate or gain an unintended
            commercial benefit from incentive offers;
          </p>
          <p className="paragraph bullet-point">
            not to harass, annoy, intimidate, or threaten any HotSauce
            employees, contractors, or agents engaged in providing any portion
            of HotSauce&#39;s services;
          </p>
          <p className="paragraph bullet-point">
            not to engage in any criminal or tortious activity, including,
            without limitation, fraud, spamming (including, without limitation,
            by email or instant message), sending of viruses or other harmful
            files, copyright infringement, patent infringement, or theft of
            trade secrets or otherwise deleting the copyright or other
            proprietary rights notice from any User Content (as defined below)
            or from any portion of the Sites or HotSauce&#39;s services;
          </p>
          <p className="paragraph bullet-point">
            not to disrupt, interfere with, or otherwise harm or violate the
            security of the Sites, or any services, system resources, accounts,
            passwords, servers or networks connected to or accessible through
            the Sites or affiliated or linked sites (including, without
            limitation, those of our restaurant partners); and
          </p>
          <p className="paragraph bullet-point">
            not to use the Sites for any illegal purposes or in any way
            otherwise inconsistent with any and all applicable laws, rules, and
            regulations.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            You agree to comply with the above user conduct, and agree not
            assist or permit any person in engaging in any conduct that does not
            comply with the above user conduct. Further, you agree that the
            consequences of commercial use or re-publication of User Content or
            Materials from the Sites or other violations of the foregoing
            proscriptions may be so serious and incalculable that monetary
            compensation may not be a sufficient or appropriate remedy, and that
            HotSauce will be entitled to temporary and permanent injunctive
            relief to prohibit such use or activity without the need to prove
            damages.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>II. Content You Provide</p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            HotSauce may now or in the future provide you with interactive
            opportunities (i) on the Sites, including, without limitation,
            features such as user ratings and reviews, saved favorites, liked
            items and bookmarked restaurants, user profiles and pictures, as
            well as (ii) through other communications with you, including,
            without limitation, through text (“SMS”) or multimedia (“MMS”)
            messages (collectively, &quot;Interactive Areas&quot;). You
            represent and warrant that you are the owner of and/or otherwise
            have the right to provide all information, comments, reviews,
            ratings, photographs and/or other materials and/or content that you
            submit, upload, post, publish, and/or otherwise make available to
            HotSauce through the Sites or otherwise in connection with your use
            of our services, including, without limitation, information and
            materials provided or made available in connection with any
            Facebook, Google, or other third party login (“User Content”). User
            Content includes, without limitation, textual, visual, or audio
            content and information, whether transmitted via the Sites, SMS or
            MMS message, or otherwise.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>III. Use of Your Content</p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            You grant HotSauce an irrevocable, transferable, paid up,
            royalty-free, perpetual, non-exclusive worldwide sublicensable
            license to use, copy, display, publish, modify, remove, publicly
            perform, translate, create derivative works from, distribute, and/or
            otherwise use User Content in connection with HotSauce&#39;s or its
            partners’ or affiliates’ business and in all forms of media now
            known or hereafter invented (collectively, the “Uses”) without
            notification to and/or approval by you. You further grant HotSauce a
            license to use your username and/or other user profile information,
            including, without limitation, your order history and how long you
            have been a HotSauce diner, to attribute User Content to you in
            connection with the Uses, if we choose to do so, again without
            notification to and/or approval by you. Further, if you provide any
            suggestions, input, or other feedback relating to the Sites or the
            services we provide (collectively, the “Feedback”), HotSauce shall
            have the right to freely and fully exercise and exploit the Feedback
            in connection with our business or the business of our partners or
            affiliates, without notice to, approval by, or compensation to you.
            Accordingly, you hereby grant HotSauce a royalty-free, perpetual,
            irrevocable, transferable, sublicensable, worldwide, nonexclusive
            license to all rights necessary to incorporate and use the Feedback
            for any purpose.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            User Content transmitted to certain parts of the Sites, including,
            without limitation, restaurant pages and certain Interactive Areas,
            may be posted in public areas on our Sites, including, without
            limitation, in a compilation format, and as such will be publicly
            visible and accessible. You further understand and agree that you
            may be exposed to third party User Content that is inaccurate,
            objectionable, inappropriate for children, or otherwise unsuited to
            your purpose. HotSauce and its parents, subsidiaries, affiliates,
            and each of their officers, directors, employees, successors,
            assigns, licensors, licensees, designees, business partners,
            contractors, agents and representatives (collectively, the
            &quot;Released Parties&quot;) will not be responsible for, and you
            hereby expressly release the Released Parties from, any and all
            liability for the action of any and all third parties with respect
            to your User Content, or for any damages you allege to incur as a
            result of or relating to any third party User Content.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>IV. Conduct within Interactive Areas</p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            By transmitting User Content, you agree to follow the standards of
            conduct below, and any additional standards that may be stated on
            the Sites. We expect your cooperation in upholding our standards.
            You are responsible for all of your User Content. You agree not to
            provide any User Content that:
          </p>
          <p className="paragraph bullet-point">
            is unlawful, harmful to adults or minors, threatening, abusive,
            harassing, tortious, defamatory, vulgar, obscene, profane,
            offensive, invasive of another&#39;s privacy, hateful, and/or
            racially, ethnically, and/or otherwise objectionable; has a
            commercial, political, or religious purpose;
          </p>
          <p className="paragraph bullet-point">
            is false, misleading, and/or not written in good faith;
          </p>
          <p className="paragraph bullet-point">
            infringes any patent, trademark, trade secret, copyright, right of
            privacy and/or publicity, and/or other proprietary rights of any
            person and/or entity;
          </p>
          <p className="paragraph bullet-point">
            is illegal and/or promotes illegal activity;
          </p>
          <p className="paragraph bullet-point">
            contains unauthorized advertising and/or solicits users to a
            business other than those on the Sites; and/or
          </p>
          <p className="paragraph bullet-point">
            is intended to interrupt, destroy, or limit the functionality or
            integrity of any computer software, hardware, or Materials on the
            Sites or other websites.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            We do our best to encourage civility and discourage disruptive
            communication on the Sites. We also do our best to discourage
            communications that incite others to violate our standards. HotSauce
            may monitor any and all use of the Sites, including, without
            limitation, interactions between our users; however, we are under no
            obligation to do so. We may manage the Sites in a manner intended to
            protect our property and rights and to facilitate the proper
            functioning of the Sites. If any User Content or conduct on our
            Sites violates our standards, or any other terms and conditions of
            this Agreement; or interferes with other peoples&#39; enjoyment of
            the Materials or our Sites; or is inappropriate in our judgment; we
            reserve the right, in our sole discretion, to change, delete or
            remove, in part or in full, any such User Content or Materials, and
            we further reserve the right to terminate or suspend access to any
            Interactive Areas or any Sites. HotSauce will cooperate with local,
            state, and/or federal authorities to the extent required by
            applicable law in connection with User Content.
          </p>
          <h5 className={css(themeStyles.h5Title)}>
            DISCLAIMER
          </h5>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            THE SITES, THE MATERIALS, AND ALL OTHER CONTENT ON THE SITES ARE
            PROVIDED &quot;AS IS&quot; AND “AS AVAILABLE” AND WITHOUT WARRANTIES
            OF ANY KIND, EITHER EXPRESS OR IMPLIED, UNLESS OTHERWISE SPECIFIED
            IN WRITING. TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, THE
            RELEASED PARTIES DISCLAIM, WITH RESPECT TO THE MATERIALS AND ALL
            OTHER CONTENT ON THE SITES, ALL WARRANTIES, EXPRESS OR IMPLIED,
            STATUTORY OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
            NON-INFRINGEMENT. HOTSAUCE DOES NOT REPRESENT OR WARRANT THAT THE
            SITES, THE MATERIALS AND/OR THE OTHER CONTENT ON THE SITES WILL BE
            SECURE, UNINTERRUPTED, AND/OR ERROR-FREE, THAT DEFECTS WILL BE
            CORRECTED, AND/OR THAT THE SITES, THE MATERIALS, AND/OR OTHER
            CONTENT ON THE SITES ARE FREE FROM VIRUSES OR OTHER HARMFUL
            COMPONENTS. HOTSAUCE DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS
            REGARDING THE USE OR THE RESULTS OF THE USE OF THE SITES, THE
            MATERIALS, AND/OR ANY OTHER CONTENT ON THE SITES IN TERMS OF THEIR
            CORRECTNESS, ACCURACY, RELIABILITY, TIMELINESS, COMPLETENESS,
            CURRENTNESS, OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, THE
            SAFETY, QUALITY, AND/OR TIMING OF A DELIVERY ORDERED ON THE SITES,
            AND/OR THE FOOD OR OTHER PRODUCTS DELIVERED. YOU (AND NOT HOTSAUCE)
            ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR, OR
            CORRECTION RELATING TO YOUR USE OF THE SITES, THE MATERIALS, AND/OR
            OTHER CONTENT ON THE SITES. APPLICABLE LAW MAY NOT ALLOW THE
            EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT
            FULLY APPLY TO YOU.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            HOTSAUCE SHALL NOT BE LIABLE FOR DELAY OR FAILURE IN PERFORMANCE
            RESULTING FROM CAUSES BEYOND HOTSAUCE&#39;S REASONABLE CONTROL,
            INCLUDING, WITHOUT LIMITATION, DELAYS AND OTHER PROBLEMS INHERENT IN
            THE USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS. HOTSAUCE IS
            NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES, OR OTHER DAMAGE
            RESULTING FROM SUCH PROBLEMS.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            HOTSAUCE RELIES UPON RESTAURANTS AND OTHER THIRD-PARTY FOOD AND
            BEVERAGE PROVIDERS TO PROVIDE ACCURATE ALLERGEN AND DIETARY
            INFORMATION AND GENERAL PRODUCT SAFETY. HOTSAUCE DOES NOT REPRESENT
            OR WARRANT THAT THE INFORMATION ACCESSIBLE THROUGH THE SERVICE IS
            ACCURATE, COMPLETE, RELIABLE, CURRENT, OR ERROR- FREE, INCLUDING,
            WITHOUT LIMITATION, MENUS, NUTRITIONAL AND ALLERGEN INFORMATION,
            PHOTOS, FOOD QUALITY OR DESCRIPTIONS, PRICING, HOURS OF OPERATION,
            OR REVIEWS. ALL CONTENT IS PROVIDED FOR INFORMATIONAL PURPOSES ONLY.
            THE RELIANCE ON ANY INFORMATION PROVIDED THROUGH THE SERVICE IS
            SOLELY AT YOUR OWN RISK, INCLUDING, WITHOUT LIMITATION, NUTRITIONAL
            AND ALLERGEN INFORMATION.
          </p>
          <h5 className={css(themeStyles.h5Title)}>
            LIMITATION OF LIABILITY
          </h5>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, IN NO EVENT
            SHALL HOTSAUCE BE LIABLE TO YOU FOR ANY INDIRECT, SPECIAL,
            INCIDENTAL, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, OR ANY
            LOSS OR DAMAGES WHATSOEVER (INCLUDING PERSONAL INJURY, LOSS OF DATA,
            REVENUE, PROFITS, REPUTATION, USE, OR OTHER ECONOMIC ADVANTAGE),
            EVEN IF HOTSAUCE HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGES, ARISING OUT OF A WARRANTY, CONTRACT, OR NEGLIGENCE
            ACTION THAT IN ANY MANNER ARISES OUT OF OR IN CONNECTION WITH THE
            USE, INABILITY TO USE, PERFORMANCE OF, OR SERVICES PROVIDED ON OR
            THROUGH THE SITES. HOTSAUCE ASSUMES NO RESPONSIBILITY AND SHALL NOT
            BE LIABLE FOR ANY DAMAGES TO, OR VIRUSES THAT MAY INFECT YOUR
            COMPUTER EQUIPMENT OR OTHER PROPERTY ON ACCOUNT OF YOUR ACCESS TO,
            USE OF, BROWSING OF, OR DOWNLOADING OF ANY MATERIAL FROM THE SITES.
            HOTSAUCE ASSUMES NO RESPONSIBILITY OR LIABILITY IN ANY MANNER
            ARISING OUT OF OR IN CONNECTION WITH ANY INFORMATION, CONTENT,
            PRODUCTS, SERVICES, OR MATERIAL AVAILABLE ON OR THROUGH THE SITES,
            AS WELL AS ANY THIRD PARTY WEBSITE PAGES OR ADDITIONAL WEBSITES
            LINKED TO THIS SITE, FOR ANY ERROR, DEFAMATION, LIBEL, SLANDER,
            OMISSION, FALSEHOOD, OBSCENITY, PORNOGRAPHY, PROFANITY, DANGER,
            INACCURACY CONTAINED THEREIN, OR HARM TO PERSON OR PROPERTY CAUSED
            THEREBY. THESE LIMITATIONS SHALL APPLY NOTWITHSTANDING ANY FAILURE
            OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY. IN NO EVENT SHALL
            HOTSAUCE&#39;S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES AND
            CAUSES OF ACTION, WHETHER IN WARRANTY, CONTRACT, OR TORT EXCEED (A)
            THE AMOUNT PAID BY YOU TO HOTSAUCE OR A HOTSAUCE RESTAURANT PARTNER,
            IF ANY, OR (B) $1,000 (WHICHEVER IS LESS). BECAUSE SOME
            JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY
            FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATIONS MAY
            NOT APPLY TO YOU.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            YOU AND HOTSAUCE AGREE THAT THE WARRANTY DISCLAIMERS AND LIMITATIONS
            OF LIABILITY IN THIS AGREEMENT ARE MATERIAL, BARGAINED-FOR BASES OF
            THIS AGREEMENT, AND THAT THEY HAVE BEEN TAKEN INTO ACCOUNT IN
            DETERMINING THE CONSIDERATION TO BE GIVEN BY EACH PARTY UNDER THIS
            AGREEMENT AND IN THE DECISION BY EACH PARTY TO ENTER INTO THIS
            AGREEMENT. YOU AND HOTSAUCE AGREE THAT THE WARRANTY DISCLAIMERS AND
            LIMITATIONS OF LIABILITY IN THESE TERMS OF USE ARE FAIR AND
            REASONABLE. IF YOU ARE DISSATISFIED WITH THE SITE OR DO NOT AGREE TO
            ANY PROVISIONS OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY
            IS TO DISCONTINUE USING THE SITE, EXCEPT AS MAY BE OTHERWISE
            PROVIDED FOR IN THIS SECTION.
          </p>
          <h5 className={css(themeStyles.h5Title)}>
            THIRD PARTY LINKS
          </h5>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            The Sites may contain links to websites that are owned, controlled,
            developed, sponsored and/or maintained by third parties and which
            may be subject to additional terms and conditions (&quot;Third Party
            Websites&quot;). HotSauce does not review, monitor, operate and/or
            control the Third Party Websites and HotSauce makes no guarantees,
            representations, and/or warranties as to, and shall have no
            liability for, the content available on or through and/or the
            functioning of the Third Party Websites. By providing access to
            Third Party Websites, HotSauce is not recommending and/or otherwise
            endorsing the products and/or services provided by the sponsors
            and/or owners of those websites. Your access to and/or use of the
            Third Party Websites, including, without limitation, providing
            information, materials and/or other content to the Third Party
            Websites, is entirely at your own risk. HotSauce reserves the right
            to discontinue links to any Third Party Websites at any time and for
            any reason, without notice.
          </p>
          <h5 className={css(themeStyles.h5Title)}>
            ADDITIONAL TERMS
          </h5>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            Your use of the Sites is subject to any and all additional terms,
            policies, rules, or guidelines applicable to HotSauce&#39;s services
            or certain features of the Sites that we may post or link to on the
            Sites (collectively, the &quot;Additional Terms&quot;), such as
            end-user license agreements, or other agreements or rules applicable
            to particular features, promotions, or content on the Sites.
          </p>
          <h5 className={css(themeStyles.h5Title)}>
            PRIVACY POLICY
          </h5>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            The terms and conditions of the Privacy Policy located
            at https://onlineordering.hotsaucepos.com/#/privacy-policy are
            incorporated into this Agreement by reference.
          </p>
          <h5 className={css(themeStyles.h5Title)}>
            COPYRIGHT POLICY
          </h5>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            HotSauce respects the intellectual property of others, and we ask
            all of our users to do the same. If you believe that your
            copyrighted work has been copied and is accessible on the Sites or a
            website through which our services may be accessed in a way that
            constitutes copyright infringement, please provide HotSauce&#39;s
            Copyright Agent (as set forth below) with notification containing
            the following information required by the Digital Millennium
            Copyright Act, 17 U.S.C. §512 (“DMCA”):
          </p>
          <p className="paragraph list-item">
            1. A physical or electronic signature of a person authorized to act
            on behalf of the copyright owner of the work that allegedly has been
            infringed;
          </p>
          <p className="paragraph list-item">
            2. Identification of the copyrighted work claimed to have been
            infringed, or, if multiple copyrighted works allegedly have been
            infringed, then a representative list of such copyrighted works;
          </p>
          <p className="paragraph list-item">
            3. Identification of the material that is claimed to be infringing
            and that is to be removed or access to which is to be disabled, and
            information reasonably sufficient to permit us to locate the
            allegedly infringing material, e.g., the specific web page address
            on the Sites;
          </p>
          <p className="paragraph list-item">
            4. Information reasonably sufficient to permit us to contact the
            party alleging infringement, including an email address;
          </p>
          <p className="paragraph list-item">
            5. A statement that the party alleging infringement has a good-faith
            belief that use of the copyrighted work in the manner complained of
            is not authorized by the copyright owner or its agent, or is not
            otherwise permitted under the law; and
          </p>
          <p className="paragraph list-item">
            6. A statement that the information in the notification is accurate,
            and under penalty of perjury, that the party alleging infringement
            is authorized to act on behalf of the copyright owner of the work
            that allegedly has been infringed.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            Please send this notification to our copyright agent at: HSAC LLC,
            Attention: Copyright Agent, 7001 Peachtree Industrial Boulevard,
            Suite 4200, Norcross, Georgia 30092.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            UNDER FEDERAL LAW, IF YOU KNOWINGLY MISREPRESENT THAT ONLINE
            MATERIAL IS INFRINGING, YOU MAY BE SUBJECT TO CRIMINAL PROSECUTION
            FOR PERJURY AND CIVIL PENALTIES, INCLUDING MONETARY DAMAGES, COURT
            COSTS, AND ATTORNEYS’ FEES.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            Please note that this procedure is exclusively for notifying
            HotSauce and its affiliates that your copyrighted material has been
            infringed. The preceding requirements are intended to comply with
            HotSauce’s rights and obligations under the DMCA, including 17
            U.S.C. §512(c), but do not constitute legal advice. It may be
            advisable to contact an attorney regarding your rights and
            obligations under the DMCA and other applicable laws.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            In accordance with the DMCA and other applicable law, HotSauce has
            adopted a policy of terminating, in appropriate circumstances, users
            who are deemed to be repeat infringers. HotSauce may also in its
            sole discretion limit access to the Sites and/or terminate the
            Accounts of any users who infringe any intellectual property rights
            of others, whether or not there is any repeat infringement.
          </p>
          <h5 className={css(themeStyles.h5Title)}>
            TERMINATION AND VIOLATIONS OF THE AGREEMENT
          </h5>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            Your rights under this Agreement will terminate automatically
            without notice if you fail to comply with any provision of this
            Agreement. Further, HotSauce reserves the right, in its sole and
            absolute discretion, to modify, suspend, or discontinue at any time,
            with or without notice, the Sites and/or services offered on or
            through the Sites (or any part thereof), including but not limited
            to the Sites&#39; features, look and feel, and functional elements
            and related services. We will have no liability whatsoever on
            account of any change to the Sites or any suspension or termination
            of your access to or use of the Sites. You may terminate this
            Agreement at any time by closing your Account, uninstalling all
            Mobile Application(s) (if applicable) and ceasing use of the Sites
            and services provided herein.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            Upon termination of this Agreement for any reason or no reason: (i)
            your access rights will terminate and you must immediately cease all
            use of the Sites; and (ii) any provision of this Agreement that
            contemplates or governs performance or observance subsequent to
            termination of this Agreement will survive the termination of this
            Agreement, including without limitation the following sections: (i)
            “Your Content and Conduct;” (ii) “Disclaimer;” (iii) “Limitation of
            Liability;” (iv) “Termination and Violations of this Agreement;” (v)
            “Dispute Resolution;” and (vi) “Waiver and Severability.”
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            HotSauce reserves the right to seek all remedies available at law
            and in equity for violations of the Agreement, including, without
            limitation, the right to block access to the Sites from a particular
            account, device and/or IP address.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            You may not assign or transfer this Agreement or your rights under
            this Agreement, in whole or in part, by operation of law or
            otherwise, without our prior written consent. We may assign this
            Agreement in whole or in part at any time to any entity without your
            notice or consent. Any purported assignment by you in violation of
            this section shall be null and void.
          </p>
          <h5 className={css(themeStyles.h5Title)}>
            CHANGES TO THE AGREEMENT
          </h5>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            We may change this Agreement from time to time and without prior
            notice. If we make a change to this Agreement, it will be effective
            as soon as we post it and the most current version of this Agreement
            will always be posted under the &quot;Terms of Use&quot; link
            available on our Sites (&quot;Updated Terms&quot;). If we make a
            material change to the Agreement, we may notify you. You agree that
            you will review this Agreement periodically. By continuing to access
            and/or use the Sites after we post Updated Terms, you agree to be
            bound by the Updated Terms, and if you do not agree to the Updated
            Terms, you will stop using the Sites. Any disputes under this
            Agreement will be governed by the version of Agreement in effect at
            the time of the first event which gave rise to the dispute.
          </p>
          <h5 className={css(themeStyles.h5Title)}>
            GOVERNING LAW
          </h5>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            You acknowledge and agree that your access to and/or use of the
            Sites, the Materials, and other content on the Sites is subject to
            all applicable international, federal, state, and local laws and
            regulations. The terms, conditions, and policies contained in this
            Agreement shall be governed by and construed in accordance with the
            laws of the State of Georgia, without regard to its conflict of laws
            principles.
          </p>
          <h5 className={css(themeStyles.h5Title)}>
            DISPUTE RESOLUTION
          </h5>
          <p className={"paragraph "+css(themeStyles.paragraph)}>I. Arbitration and Class Action Waiver.</p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            You agree that all claims, disputes, or disagreements that may arise
            out of the interpretation or performance of this Agreement, or that
            in any way relate to your use of the Sites, the Materials, and/or
            other content on the Sites, shall be submitted exclusively to
            binding arbitration, except that each party retains the right to
            bring an individual action in small claims court and the right to
            seek injunctive or other equitable relief in a court of competent
            jurisdiction to prevent the actual or threatened infringement,
            misappropriation, or violation of a party&#39;s copyrights,
            trademarks, trade secrets, patents, or other intellectual property
            rights. Arbitration means that an arbitrator and not a judge or jury
            will decide the claim. Rights to prehearing exchange of information
            and appeals may also be limited in arbitration. You acknowledge and
            agree that you and HotSauce are each waiving the right to a trial by
            jury. You further acknowledge and agree that you waive your right to
            participate as a plaintiff or class member in any purported class
            action or representative proceeding. Further, unless both you and
            HotSauce otherwise agree in writing, the arbitrator may not
            consolidate more than one person&#39;s claims, and may not otherwise
            preside over any form of any class or representative proceeding. If
            this specific paragraph is held unenforceable, then the entirety of
            this &quot;Dispute Resolution&quot; section will be deemed void.
            Except as provided in the preceding sentence, this &quot;Dispute
            Resolution&quot; section will survive any termination of these Terms
            of Use. You further acknowledge that any claim arising under this
            Agreement must be brought within one year of its accrual or it will
            be waived.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>II. Arbitration Rules</p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            The arbitration will be administered by the American Arbitration
            Association (&quot;AAA&quot;) in accordance with the Commercial
            Arbitration Rules and the Supplementary Procedures for Consumer
            Related Disputes (the &quot;AAA Rules&quot;) then in effect, except
            as modified by this &quot;Dispute Resolution&quot; section. (The AAA
            Rules are available at https://www.adr.org/Rules or by calling the
            AAA at 1-800-778- 7879). The Federal Arbitration Act will govern the
            interpretation and enforcement of this section.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>III. Arbitration Process</p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            A party who desires to initiate arbitration must provide the other
            party with a written Demand for Arbitration as specified in the AAA
            Rules. (The AAA provides a form Demand for Arbitration
            at https://www.adr.org/sites/default/files/Demand%20for%20Arbitration%20Consumer%20Arbitrati
            on%20Rules.pdf and a separate affidavit for waiver of fees for
            California residents only
            at https://www.adr.org/sites/default/files/AAA%20Affidavit%20for%20Waiver%20of%20Fees%20N
            otice%20California%20Consumers.pdf.) The arbitrator will be either a
            retired judge or an attorney licensed to practice law in the county
            in which you reside and will be selected by the parties from the
            AAA&#39;s roster of consumer dispute arbitrators. If the parties are
            unable to agree upon an arbitrator within seven (7) days of delivery
            of the Demand for Arbitration, then the AAA will appoint the
            arbitrator in accordance with the AAA Rules.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>IV. Arbitration Location and Procedure</p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            Unless you and HotSauce otherwise agree, the arbitration will be
            conducted in the county where you reside. If your claim does not
            exceed $10,000, then the arbitration will be conducted solely on the
            basis of documents you and HotSauce submit to the arbitrator, unless
            you request a hearing or the arbitrator determines that a hearing is
            necessary. If your claim exceeds $10,000, your right to a hearing
            will be determined by the AAA Rules. Subject to the AAA Rules, the
            arbitrator will have the discretion to direct a reasonable exchange
            of information by the parties, consistent with the expedited nature
            of the arbitration.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>V. Arbitrator&#39;s Decision</p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            The arbitrator will render an award within the time frame specified
            in the AAA Rules. The arbitrator&#39;s decision will include the
            essential findings and conclusions upon which the arbitrator based
            the award. Judgment on the arbitration award may be entered in any
            court having jurisdiction thereof. The arbitrator&#39;s award
            damages must be consistent with the terms of the &quot;Limitation of
            Liability&quot; section above as to the types and the amounts of
            damages for which a party may be held liable. The arbitrator may
            award declaratory or injunctive relief only in favor of the claimant
            and only to the extent necessary to provide the relief warranted by
            the claimant&#39;s individual claim. If you prevail in arbitration,
            you will be entitled to an award of attorneys&#39; fees and expenses
            to the extent provided under applicable law. HotSauce will not seek,
            and hereby waives, all rights HotSauce may have under applicable law
            to recover attorneys&#39; fees and expenses if HotSauce prevails in
            arbitration.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>VI. Fees</p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            Your responsibility to pay any AAA filing, administrative and
            arbitrator fees will be solely as set forth in the AAA Rules.
          </p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>VII. Changes</p>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            HotSauce reserves the right to change this &quot;Dispute
            Resolution&quot; section, but any such changes will not apply to
            disputes arising before the effective date of such amendment.
            Notwithstanding the provisions of the modification-related
            provisions above, if HotSauce changes this &quot;Dispute
            Resolution&quot; section after the date you first accepted this
            Agreement (or accepted any subsequent changes to this Agreement),
            you agree that your continued use of the Sites after such change
            will be deemed acceptance of those changes. If you do not agree to
            such change, you may reject any such change by providing HotSauce
            written notice of such rejection by mail or hand delivery to:
            HotSauce, Attn: Dispute Resolutions, 7001 Peachtree Industrial
            Boulevard, Suite 4200, Norcross GA 30092, or by email from the email
            address associated with your account
            to: disputeresolution@hotsaucepos.com, within 30 days of the date
            such change became effective, as indicated in the
            &quot;Effective&quot; date above. In order to be effective, the
            notice must include your full name and clearly indicate your intent
            to reject changes to this &quot;Dispute Resolution&quot; section. By
            rejecting changes, you are agreeing that you will arbitrate any
            dispute between you and HotSauce in accordance with the provisions
            of this &quot;Dispute Resolution&quot; section as of the date you
            first accepted this Agreement (or accepted any subsequent changes to
            this Agreement, as applicable).
          </p>
          <h5 className={css(themeStyles.h5Title)}>
            WAIVER AND SEVERABILITY
          </h5>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            Any waiver by HotSauce of any provision of this Agreement must be in
            writing. If any portion of this Agreement is found to be void,
            invalid, or otherwise unenforceable, then that portion shall be
            deemed to be superseded by a valid, enforceable provision that
            matches the intent of the original provision as closely as possible
            except that in the event of unenforceability of the class action
            waiver, the entire arbitration agreement shall be unenforceable. The
            remainder of this Agreement shall continue to be enforceable and
            valid according to terms contained herein.
          </p>
          <h5 className={css(themeStyles.h5Title)}>
            ENTIRE AGREEMENT
          </h5>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            This Agreement, together with any amendments and any additional
            agreements you may enter into with HotSauce in connection with the
            Sites and the services hereunder, shall constitute the entire
            agreement between you and HotSauce concerning the Sites and the
            services hereunder.
          </p>
          <h5 className={css(themeStyles.h5Title)}>
            CONTACT
          </h5>
          <p className={"paragraph "+css(themeStyles.paragraph)}>
            If you have any questions regarding this Agreement, please visit our
            “Contact Us” page for our contact information.
          </p>
        </div>
      </div>
    );
  }
}

export default withTheme(TermsOfUse);
